export const DETAILED_RESULTS_CHART_COLORS = Object.freeze({
  BtesFlowRate: '#5D5A79',
  CO2kg: '#916B5A',
  CO2kgref: '#378776',
  COPInjectionHpa: '#507E9E',
  COPItesHpa: '#AAA495',
  NetOPEX: '#B6A5AB',
  OPEX: '#B4B4B4',
  PVNotUsedForThermalNeeds: '#9878C9',
  PVProd: '#9D515B',
  PVSoldToGrid: '#CCCAA8',
  PVUnused: '#B06355',
  QBtes: '#614A68',
  QGeocooling: '#335960',
  QHeadLoss: '#AE875D',
  QItesCharge: '#3A3A39',
  QSolar: '#BFA4AE',
  Qdemand: '#345382',
  RevPV: '#708B92',
  T1: '#216EAC',
  T2: '#6E6A6E',
  TCondHt: '#CCCAA8',
  TCondHw: '#CF9E8A',
  TDistrib: '#4F8E4A',
  TEauInBtes: '#38633F',
  TEauInSolar: '#B891A0',
  TEauOutBtes: '#A18595',
  TEauOutSolar: '#43436B',
  WColdHpa: '#5491E3',
  WColdItes: '#895ADE',
  WGas: '#9878C9',
  WHeatHpa: '#CF6FA1',
  Whpg: '#68B87D',
  WHotwHpa: '#DE6E81',
  WInjectionHpa: '#DC9055',
  WItesHpa: '#EFCD53',
  Wpac: '#B891A0'
});

export const DETAILED_RESULTS_SST_COLORS = Object.freeze({
  QTotal_Hotwater: '#F2536D',
  QTotal_Heat: '#F7A452',
  QTotal_Cold: '#77B5F2',
  QTotalHotwater: '#F2536D',
  QTotalHeat: '#F7A452',
  QTotalCold: '#77B5F2'
});
