import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { GAS } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import EditTable from '../../../../components/EditTable/EditTable';
import CostPageContext from '../../../../contexts/CostPageContext';

const GasBoilerBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleTableValueChange } =
    useContext(CostPageContext);
  //#endregion

  //#region [render]
  const { InitCapexGas } = tempProject.otherValues;
  return (
    <Bloc title={i18n._('cost.gasBoiler')}>
      <Row>
        <Col xs={6}>
          <span className='form-input-label'>
            {i18n._('cost.InitCapexGasLabel')}
          </span>
          <EditTable
            titles={[
              i18n._('cost.InitCapexGasSlices'),
              i18n._('cost.InitCapexGas')
            ]}
            values={JSON.stringify([InitCapexGas])}
            params={[GAS.InitCapexGas]}
            onBlur={handleTableValueChange}
            addError={addError}
            removeError={removeError}
          />
        </Col>
      </Row>
    </Bloc>
    //#endregion
  );
};

export default GasBoilerBloc;
