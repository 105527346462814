import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import { cloneDeep } from 'lodash';
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { Button, Col, Row } from 'react-bootstrap';
import { STD_COLS } from '../../../../../../server/constants/stdCol.constant';
import { SST } from '../../../../../../server/models/design/description.model';
import { getSummaryData } from '../../../../../../server/utils/std.utils';
import { fetchSTD } from '../../../../api/substation.api';
import DescriptionsPageContext from '../../../../contexts/DescriptionsPageContext';
import PopupContext from '../../../../contexts/PopupContext';
import {
  isArrNullOrEmpty,
  isObjNullOrEmpty
} from '../../../../utils/data.utils';
import {
  checkForFileError,
  downloadFile,
  parseFileName
} from '../../../../utils/file.utils';
import FormInput from '../../../Form/FormInput';
import FormSelect from '../../../Form/FormSelect';
import STDModal from '../STDModal';
import SummaryDataModal from '../SummaryDataModal';
import './SubstationTab.css';

const SubstationTab = ({ substation, sstIndex, descIndex, project }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { setProjectParent, addErrorByDesc, removeErrorsByDesc } = useContext(
    DescriptionsPageContext
  );
  const { openInfoModal, openToast, openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [filename, setFilename] = useState(substation.filesStdOriginalName);
  const [isFileMissing, setIsFileMissing] = useState(false);
  //#endregion

  //#region [refs]
  const inputOpenFileRef = useRef();
  const nbErrors = useRef(0);
  //#endregion

  //#region [effects]
  useEffect(() => {
    return () => removeErrorsByDesc(descIndex, nbErrors.current);
  }, []);

  useEffect(() => {
    if (!substation.filesStdOriginalName && !substation.stdFile) {
      nbErrors.current++;
      addErrorByDesc(descIndex);
      setIsFileMissing(true);
    } else {
      if (isFileMissing) {
        nbErrors.current--;
        removeErrorsByDesc(descIndex, 1);
        setIsFileMissing(false);
      }
      setFilename(
        substation.stdFile
          ? substation.stdFile.name
          : substation.filesStdOriginalName
      );
    }
  }, [substation.filesStdOriginalName, substation.stdFile]);
  //#endregion

  //#region [memos]
  const stdModal = useMemo(() => {
    return STDModal(i18n);
  }, []);
  //#endregion

  //#region [methods]
  const handleFileChange = (evt) => {
    try {
      const csvReader = new FileReader();
      const file = evt.target.files[0];
      if (!file) return;

      csvReader.readAsText(file);
      const { fileExt } = parseFileName(file.name);
      csvReader.onload = (event) => {
        try {
          const error = checkForFileError(
            i18n,
            event.target.result,
            fileExt,
            STD_COLS
          );
          if (error) {
            console.error(error);
            openToast(i18n._('std.error'), error);
          } else {
            setFilename(file.name);
            changeSTD(file, getSummaryData(event.target.result, fileExt));
            if (isFileMissing) {
              nbErrors.current--;
              removeErrorsByDesc(descIndex, 1);
              setIsFileMissing(false);
            }
          }
        } catch (err) {
          console.error(err);
          openErrorToast(err);
        }
      };
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const changeSTD = (file, summaryData) => {
    setProjectParent((projectParent) => {
      const projectParentCopy = cloneDeep(projectParent);
      if (project.AhsID === projectParent.AhsID) {
        // la STD d'une des sous-stations du projet parent a changée
        const setParentValue = (key, value) =>
          (projectParentCopy.substations[sstIndex][key] = value);
        setParentValue('stdFile', file);
        setParentValue('SummaryData', summaryData);

        // si c'est une nouvelle sous-station, on duplique sa STD à toutes les sous-stations enfants si celles-ci n'ont pas de STD
        if (
          !projectParentCopy.substations[sstIndex].InitStationID &&
          !isArrNullOrEmpty(projectParentCopy.children)
        ) {
          projectParentCopy.children
            .filter((child) => !child.substations[sstIndex].stdFile)
            .forEach((child) => {
              child.substations[sstIndex].stdFile = file;
              child.substations[sstIndex].SummaryData = summaryData;
            });
        }
      } else {
        // la STD d'une des sous-stations d'un des projets enfant a changée
        projectParentCopy.children[descIndex - 1].substations[
          sstIndex
        ].stdFile = file;
        projectParentCopy.children[descIndex - 1].substations[
          sstIndex
        ].SummaryData = summaryData;
      }
      return projectParentCopy;
    });
  };

  const handleDlClick = async () => {
    try {
      const { InitStationID } = substation;
      const file = project.stdFile ?? (await fetchSTD(InitStationID));
      downloadFile(file.name, new Blob([file], { type: 'text/csv' }));
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleInputBlur = (key, value) => {
    setProjectParent((projectParent) => {
      const projectParentCopy = cloneDeep(projectParent);
      projectParentCopy.substations[sstIndex][key] = value;
      projectParentCopy.children.forEach(
        (child) => (child.substations[sstIndex][key] = value)
      );
      return projectParentCopy;
    });
  };

  const handleAddError = () => {
    if (descIndex > 0) return;
    nbErrors.current++;
    addErrorByDesc(descIndex);
  };

  const handleRemoveError = () => {
    if (descIndex > 0) return;
    nbErrors.current--;
    removeErrorsByDesc(descIndex, 1);
  };
  //#endregion

  //#region [memos]
  const summaryData = useMemo(() => {
    const { SummaryData } = substation;
    return !isObjNullOrEmpty(SummaryData) && SummaryData !== 'undefined'
      ? typeof SummaryData === 'string'
        ? JSON.parse(SummaryData)
        : substation.SummaryData
      : undefined;
  }, [substation.SummaryData]);
  //#endregion

  //#region [render]
  const { InitBuildingSurface, InitStationUse, InitStationName } = substation;
  const summaryDataModal = SummaryDataModal(i18n, summaryData);
  const isChild = descIndex > 0;
  return (
    <div className='substation-section'>
      <Row>
        <Col>
          <FormInput
            value={InitBuildingSurface}
            disabled={isChild}
            onBlur={(value) => handleInputBlur('InitBuildingSurface', value)}
            param={SST.InitBuildingSurface}
            addError={handleAddError}
            removeError={handleRemoveError}
            unit
            label={i18n._('description.InitBuildingSurface')}
          />
        </Col>
        <Col>
          <FormSelect
            value={InitStationUse}
            disabled={isChild}
            onChange={(value) => handleInputBlur('InitStationUse', value)}
            param={SST.InitStationUse}
            label={i18n._('description.InitStationUse')}
          />
        </Col>
      </Row>
      <Row>
        <Col xs='6'>
          <FormInput
            value={InitStationName}
            disabled={isChild}
            onBlur={(value) => handleInputBlur('InitStationName', value)}
            param={SST.InitStationName}
            addError={handleAddError}
            removeError={handleRemoveError}
            label={i18n._('description.InitStationName')}
          />
        </Col>
      </Row>
      <div>
        <p className='description-file-label'>
          {i18n._('projectNeeds.needsSTD')}
          <FontAwesomeIcon
            icon='circle-question'
            className='info-icon'
            onClick={() => openInfoModal(stdModal.title, stdModal.body)}
          />
        </p>
        <div className='description-file-btns'>
          <input
            ref={inputOpenFileRef}
            type='file'
            accept='.csv, .try'
            style={{ display: 'none' }}
            onChange={(evt) => handleFileChange(evt)}
          />
          <Button
            variant='primary'
            onClick={() => inputOpenFileRef.current.click()}
          >
            <FontAwesomeIcon icon='upload' />
            {i18n._('browse')}
          </Button>
          {project.AhsID && substation.InitStationID && (
            <Button
              variant='outline-secondary'
              onClick={handleDlClick}
              disabled={!filename}
            >
              <FontAwesomeIcon icon='download' />
              {i18n._('download')}
            </Button>
          )}
          <div className='description-file-csv-body'>
            <div className='csv-file'>
              <FontAwesomeIcon icon='file-csv' />
              {isFileMissing ? (
                <span className='description-file-missing'>
                  {i18n._('projectNeeds.noSTD')}
                </span>
              ) : (
                <Fragment>
                  <span>{filename}</span>
                  {!!summaryData && (
                    <FontAwesomeIcon
                      icon='circle-question'
                      className='info-icon'
                      onClick={() =>
                        openInfoModal(
                          summaryDataModal.title,
                          summaryDataModal.body
                        )
                      }
                    />
                  )}
                </Fragment>
              )}
            </div>
            <span className='csv-format'>
              {i18n._('projectNeeds.csvFormat')}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
  //#endregion
};

export default SubstationTab;
