import { cloneDeep } from 'lodash';
import React, { useContext, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { PROJECT_ENDPOINT } from '../../../../../server/constants/endpoint.constant';
import { uploadProjectImgFile } from '../../../api/file.api';
import { updateParams } from '../../../api/project.api';
import GeneralForm from '../../../components/GeneralForm/GeneralForm';
import CompanyContext from '../../../contexts/CompanyContext';
import PopupContext from '../../../contexts/PopupContext';
import ProjectContext from '../../../contexts/ProjectContext';
import './GeneralPage.css';

const GeneralPage = () => {
  //#region [navigate]
  const navigate = useNavigate();
  //#endregion

  //#region [contexts]
  // TODO dans le cas où selectedCompany n'est utilisé que pour selectedCompany.id, utiliser plutôt const { companyId } = useParams();
  const { selectedCompany } = useContext(CompanyContext);
  const { project } = useContext(ProjectContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [tempProject, setTempProject] = useState(project); // TODO tempProject ne doit contenir que les informations liées à la page general
  //#endregion

  //#region [methods]
  const handleSubmitClick = async () => {
    try {
      // TODO n'envoyer que les informations liées à la page général
      const tempProjectCopy = cloneDeep(tempProject);
      delete tempProjectCopy.imgFile;
      await updateParams(tempProjectCopy, PROJECT_ENDPOINT.GENERAL);
      if (tempProject.imgFile) {
        await uploadProjectImgFile(tempProject.AhsID, tempProject.imgFile);
      }
      navigate(
        `/company/${selectedCompany.id}/project/${project.AhsID}/compute`
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  return (
    <div className='general-page-body'>
      <GeneralForm
        project={tempProject}
        setProject={setTempProject}
        onSubmit={handleSubmitClick}
      />
    </div>
  );
  //#endregion
};

export default GeneralPage;
