import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useEffect } from 'react';
import { Form, Nav } from 'react-bootstrap';
import { Link, useSearchParams } from 'react-router-dom';
import CompanyContext from '../../../contexts/CompanyContext';
import useAuth from '../../../hooks/useAuth';
import { isNull } from '../../../utils/data.utils';
import ActiveProjects from './ActiveProjects';
import DeletedProjects from './DeletedProjects';

const AdminProjectsBody = ({ onCompanyChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [search params]
  const [searchParams, setSearchParams] = useSearchParams();
  //#endregion

  //#region [auth]
  const { user } = useAuth();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  //#endregion

  //#region [effects]
  useEffect(() => {
    const isActive = searchParams.get('active');
    if (isNull(isActive)) {
      setSearchParams({ active: 'true' });
    }
  }, [searchParams]);
  //#endregion

  //#region [render]
  const isActive = searchParams.get('active');
  return !isNull(isActive) ? (
    <div className='projects-body'>
      <div className='projects-top-row'>
        <div className='projects-top-row-left'>
          <div className='projects-company-select'>
            <span>{i18n._('company')}</span>
            <Form.Select
              aria-label='select company'
              onChange={(evt) => onCompanyChange(evt.target.value, isActive)}
              value={selectedCompany.id}
            >
              {user.companies.map((company) => (
                <option key={'company_' + company.CoID} value={company.CoID}>
                  {company.CoName}
                </option>
              ))}
            </Form.Select>
          </div>
          <Nav variant='pills' className='projects-nav'>
            <Nav.Item>
              <Nav.Link
                active={isActive === 'true'}
                onClick={() => setSearchParams({ active: 'true' })}
              >
                {i18n._('projects.active')}
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link
                active={isActive === 'false'}
                onClick={() => setSearchParams({ active: 'false' })}
              >
                {i18n._('projects.deleted')}
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </div>
        <Link
          to={`/company/${selectedCompany.id}/new-project`}
          className='projects-new-project'
        >
          {i18n._('projects.new')} <FontAwesomeIcon icon='plus' />
        </Link>
      </div>
      {isActive === 'true' && <ActiveProjects />}
      {isActive === 'false' && <DeletedProjects />}
    </div>
  ) : null;
  //#endregion
};

export default AdminProjectsBody;
