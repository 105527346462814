import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { GAS } from '../../../../../../server/models/design/performance.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import PerformancePageContext from '../../../../contexts/PerformancePageContext';
import { getDefaultValue } from '../../../../utils/project.utils';

const GasBoilerBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleOtherValueChange } =
    useContext(PerformancePageContext);
  //#endregion

  //#region [render]
  const { InitGasEfficiency } = tempProject.otherValues;

  return (
    <Bloc title={i18n._('performance.gasBoiler')}>
      <Row>
        <Col xs={6}>
          <FormInput
            label={i18n._('performance.InitGasEfficiency')}
            value={InitGasEfficiency}
            param={GAS.InitGasEfficiency}
            onBlur={(value) =>
              handleOtherValueChange('InitGasEfficiency', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(i18n, GAS.InitGasEfficiency)}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default GasBoilerBloc;
