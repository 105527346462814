import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { WATERTANK_COLD } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import CostPageContext from '../../../../contexts/CostPageContext';
import { getDefaultValue } from '../../../../utils/project.utils';

const WaterTankColdBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleOtherValueChange } =
    useContext(CostPageContext);
  //#endregion

  //#region [render]
  const {
    InitWaterTankColdCostA,
    InitWaterTankColdCostB,
    InitWaterTankColdExchangerCostA,
    InitWaterTankColdExchangerCostB,
    InitWaterTankColdPumpCostA,
    InitWaterTankColdPumpCostB
  } = tempProject.otherValues;
  return (
    <Bloc title={i18n._('cost.waterTankCold')}>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitWaterTankColdCostA')}
            value={InitWaterTankColdCostA}
            param={WATERTANK_COLD.InitWaterTankColdCostA}
            onBlur={(value) =>
              handleOtherValueChange('InitWaterTankColdCostA', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_COLD.InitWaterTankColdCostA
            )}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitWaterTankColdCostB')}
            value={InitWaterTankColdCostB}
            param={WATERTANK_COLD.InitWaterTankColdCostB}
            onBlur={(value) =>
              handleOtherValueChange('InitWaterTankColdCostB', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_COLD.InitWaterTankColdCostB
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitWaterTankColdExchangerCostA')}
            value={InitWaterTankColdExchangerCostA}
            param={WATERTANK_COLD.InitWaterTankColdExchangerCostA}
            onBlur={(value) =>
              handleOtherValueChange('InitWaterTankColdExchangerCostA', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_COLD.InitWaterTankColdExchangerCostA
            )}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitWaterTankColdExchangerCostB')}
            value={InitWaterTankColdExchangerCostB}
            param={WATERTANK_COLD.InitWaterTankColdExchangerCostB}
            onBlur={(value) =>
              handleOtherValueChange('InitWaterTankColdExchangerCostB', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_COLD.InitWaterTankColdExchangerCostB
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitWaterTankColdPumpCostA')}
            value={InitWaterTankColdPumpCostA}
            param={WATERTANK_COLD.InitWaterTankColdPumpCostA}
            onBlur={(value) =>
              handleOtherValueChange('InitWaterTankColdPumpCostA', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_COLD.InitWaterTankColdPumpCostA
            )}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitWaterTankColdPumpCostB')}
            value={InitWaterTankColdPumpCostB}
            param={WATERTANK_COLD.InitWaterTankColdPumpCostB}
            onBlur={(value) =>
              handleOtherValueChange('InitWaterTankColdPumpCostB', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_COLD.InitWaterTankColdPumpCostB
            )}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default WaterTankColdBloc;
