import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import Section from '../../../../../../../components/Section/Section';
import TemplateTable from '../../../../../../../components/TemplateTables/TemplateTable';
import CompanyContext from '../../../../../../../contexts/CompanyContext';
import ProjectContext from '../../../../../../../contexts/ProjectContext';
import { geoTemplates } from './templates';

const GeologySection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  const { project } = useContext(ProjectContext);
  //#endregion

  //#region [memos]
  const GTemplates = useMemo(() => {
    return geoTemplates(i18n, project);
  }, [project]);
  //#endregion

  //#region [render]
  return (
    <Section
      title={i18n._('page.geology')}
      editPath={`/company/${selectedCompany.id}/project/${project.AhsID}/geology`}
    >
      {GTemplates.map((template, index) => (
        <TemplateTable
          key={'geology_table_' + index}
          template={template}
          level={2}
        />
      ))}
    </Section>
  );
  //#endregion
};

export default GeologySection;
