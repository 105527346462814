import React, { Fragment } from 'react';

const SummaryDataModal = (i18n, summaryData) =>
  summaryData
    ? {
        title: i18n._('projectNeeds.summaryData.modalTitle'),
        body: (
          <Fragment>
            <p>
              {i18n._('projectNeeds.HEAT_NEED.SUM', {
                sum: Math.round(summaryData.HEAT_NEED.SUM).toLocaleString()
              })}
              <br />
              {i18n._('projectNeeds.HEAT_NEED.MAX', {
                max: Math.round(summaryData.HEAT_NEED.MAX).toLocaleString()
              })}
            </p>
            <p>
              {i18n._('projectNeeds.COLD_NEED.SUM', {
                sum: Math.round(summaryData.COLD_NEED.SUM).toLocaleString()
              })}
              <br />
              {i18n._('projectNeeds.COLD_NEED.MAX', {
                max: Math.round(summaryData.COLD_NEED.MAX).toLocaleString()
              })}
            </p>
            <p>
              {i18n._('projectNeeds.HOTWATER_NEED.SUM', {
                sum: Math.round(summaryData.HOTWATER_NEED.SUM).toLocaleString()
              })}
              <br />
              {i18n._('projectNeeds.HOTWATER_NEED.MAX', {
                max: Math.round(summaryData.HOTWATER_NEED.MAX).toLocaleString()
              })}
            </p>
          </Fragment>
        )
      }
    : null;

export default SummaryDataModal;
