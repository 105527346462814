// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.delete-modal .modal-header {
    color: var(--danger-color);
    font-size: 18px;
}

.delete-modal .modal-header svg {
    margin-left: 8px;
    cursor: auto;
}

.delete-modal .result-page-option {
    height: 45px;
    border-bottom: 1px solid rgb(232, 232, 232);
    padding-left: 5px;
    cursor: pointer;
}

.delete-modal .result-page-option:hover {
    background-color: #f6f6f6;
}

.delete-modal .result-page-option input[type='checkbox'] {
    cursor: pointer;
}

.delete-modal .result-page-option input[type='checkbox']:checked {
    background-color: var(--danger-color);
    border-color: var(--danger-color);
}

.delete-warning {
    font-size: 17px;
    color: #363636;
}

.delete-list {
    height: calc(100vh - 300px);
    overflow: auto;
    border-radius: 10px;
    padding: 5px;
    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);
}

.delete-list::-webkit-scrollbar-thumb {
    background-color: rgb(173, 173, 173);
    border: 4px solid transparent;
    border-radius: 10px;
    background-clip: padding-box;
}

.delete-list::-webkit-scrollbar {
    width: 16px;
}`, "",{"version":3,"sources":["webpack://./src/pages/design/result/components/DeleteModal/DeleteModal.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,YAAY;IACZ,2CAA2C;IAC3C,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,qCAAqC;IACrC,iCAAiC;AACrC;;AAEA;IACI,eAAe;IACf,cAAc;AAClB;;AAEA;IACI,2BAA2B;IAC3B,cAAc;IACd,mBAAmB;IACnB,YAAY;IACZ,sDAAsD;YAC9C,8CAA8C;AAC1D;;AAEA;IACI,oCAAoC;IACpC,6BAA6B;IAC7B,mBAAmB;IACnB,4BAA4B;AAChC;;AAEA;IACI,WAAW;AACf","sourcesContent":[".delete-modal .modal-header {\n    color: var(--danger-color);\n    font-size: 18px;\n}\n\n.delete-modal .modal-header svg {\n    margin-left: 8px;\n    cursor: auto;\n}\n\n.delete-modal .result-page-option {\n    height: 45px;\n    border-bottom: 1px solid rgb(232, 232, 232);\n    padding-left: 5px;\n    cursor: pointer;\n}\n\n.delete-modal .result-page-option:hover {\n    background-color: #f6f6f6;\n}\n\n.delete-modal .result-page-option input[type='checkbox'] {\n    cursor: pointer;\n}\n\n.delete-modal .result-page-option input[type='checkbox']:checked {\n    background-color: var(--danger-color);\n    border-color: var(--danger-color);\n}\n\n.delete-warning {\n    font-size: 17px;\n    color: #363636;\n}\n\n.delete-list {\n    height: calc(100vh - 300px);\n    overflow: auto;\n    border-radius: 10px;\n    padding: 5px;\n    -webkit-box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);\n            box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.3);\n}\n\n.delete-list::-webkit-scrollbar-thumb {\n    background-color: rgb(173, 173, 173);\n    border: 4px solid transparent;\n    border-radius: 10px;\n    background-clip: padding-box;\n}\n\n.delete-list::-webkit-scrollbar {\n    width: 16px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
