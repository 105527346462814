import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import {
  NB_HOURS_PER_YEAR,
  NB_MILLISECONDS_PER_HOUR
} from '../../../../../../../../server/constants/date.constant';
import { BTES_TEMP } from '../../../../../../../../server/constants/result.constant';
import LinesChart from '../../../../../../components/Chart/LinesChart';
import { MODE } from '../../../../../../constants/plot.constant';
import ProjectContext from '../../../../../../contexts/ProjectContext';
import { DETAILED_RESULTS_CHART_COLORS } from '../../../../../../styles/colors/detailedResult.color';

const startDate = new Date(new Date().getFullYear(), 0, 0);
const BTES_TEMP_KEYS = Object.keys(BTES_TEMP).filter(
  (key) => key === 'T1' || key === 'T2'
);

const BtesTemperatureLinesChart = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { project } = useContext(ProjectContext);
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    if (!result.details) return null;
    const { InitYearsSimulations } = result.ComputeResult.inp;
    const newData = [];
    BTES_TEMP_KEYS.forEach((key) => {
      const x = new Array(NB_HOURS_PER_YEAR * InitYearsSimulations);
      const y = new Array(NB_HOURS_PER_YEAR * InitYearsSimulations);
      let timestamp = startDate.getTime();
      for (let i = 0; i < NB_HOURS_PER_YEAR * InitYearsSimulations; ++i) {
        timestamp += NB_MILLISECONDS_PER_HOUR;
        x[i] = new Date(timestamp).toISOString();
        y[i] = result.details[key][i];
      }
      newData.push({
        x,
        y,
        line: { color: DETAILED_RESULTS_CHART_COLORS[key] },
        name: i18n._(BTES_TEMP[key].translateId),
        mode: MODE.LINES
      });
    });
    return newData;
  }, [result.details]);
  //#endregion

  //#region [render]
  const { InitYearsSimulations } = result.ComputeResult.inp;
  return data ? (
    <LinesChart
      data={data}
      title={i18n._('btesTempLinesChart.title', {
        years: InitYearsSimulations
      })}
      layout={{
        xaxis: {
          tickformat: '%Y'
        }
      }}
      filename={i18n._('plotly.filename', {
        projectName: project.AhsName,
        title: i18n._('btesTempLinesChart.title', {
          years: InitYearsSimulations
        })
      })}
    />
  ) : null;
  //#endregion
};

export default BtesTemperatureLinesChart;
