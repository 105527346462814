import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  BTES,
  getInitBtesCostAParam,
  getInitBtesCostBParam
} from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import EditTable from '../../../../components/EditTable/EditTable';
import FormInput from '../../../../components/Form/FormInput';
import CostPageContext from '../../../../contexts/CostPageContext';

const BtesBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const {
    tempProject,
    addError,
    removeError,
    handleParamChange,
    handleTableValueChange
  } = useContext(CostPageContext);
  //#endregion

  //#region [memos]
  const InitBtesCostAParam = useMemo(() => {
    return getInitBtesCostAParam(
      tempProject.AhsCountry,
      tempProject.AhsZipCode.substring(0, 2)
    );
  }, [tempProject]);

  const InitBtesCostBParam = useMemo(() => {
    return getInitBtesCostBParam(
      tempProject.AhsCountry,
      tempProject.AhsZipCode.substring(0, 2)
    );
  }, [tempProject]);
  //#endregion

  //#region [render]
  const { InitBtesCostA, InitBtesCostB } = tempProject;
  const { InitBtesDiscountDict } = tempProject.otherValues;

  return (
    <Bloc title={i18n._('cost.btes')}>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitBtesCostA')}
            value={InitBtesCostA}
            param={InitBtesCostAParam}
            onBlur={(value) => handleParamChange('InitBtesCostA', value)}
            addError={addError}
            removeError={removeError}
            unit
            topText={i18n._('cost.topInitBtesCostA')}
            bottomText={i18n._('cost.InitBtesCostA.default', {
              value: InitBtesCostAParam.default,
              unit: i18n._(`unit.${InitBtesCostAParam.unit}`),
              department: InitBtesCostAParam.department
            })}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitBtesCostB')}
            value={InitBtesCostB}
            param={InitBtesCostBParam}
            onBlur={(value) => handleParamChange('InitBtesCostB', value)}
            addError={addError}
            removeError={removeError}
            unit
            bottomText={i18n._('cost.InitBtesCostB.default', {
              value: InitBtesCostBParam.default,
              unit: i18n._(`unit.${InitBtesCostBParam.unit}`),
              department: InitBtesCostBParam.department
            })}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <span className='form-input-label'>
            {i18n._('cost.InitBtesDiscountLabel')}
          </span>
          <EditTable
            titles={[
              i18n._('cost.InitBtesDiscountDictSlices'),
              i18n._('cost.InitBtesDiscountDict')
            ]}
            values={JSON.stringify([InitBtesDiscountDict])}
            params={[BTES.InitBtesDiscountDict]}
            onBlur={handleTableValueChange}
            addError={addError}
            removeError={removeError}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default BtesBloc;
