import { UNIT } from '../../../server/constants/unit.constant';
import { formatValueWithUnit } from './data.utils';

const { COMPASS_STUDY } = require('../../../server/constants/cost.constant');

export const getNextCompassYear = () => COMPASS_STUDY.endYear + 1;

export const getElecEvols = (i18n) => {
  return COMPASS_STUDY.elec.evolPercents.map((evol, index) =>
    i18n._('eneryPriceEvolPerYear', {
      year: COMPASS_STUDY.startYear + index,
      evol: formatValueWithUnit(i18n, evol, UNIT.PERCENT)
    })
  );
};

export const getGasEvols = (i18n) => {
  return COMPASS_STUDY.gas.evolPercents.map((evol, index) =>
    i18n._('eneryPriceEvolPerYear', {
      year: COMPASS_STUDY.startYear + index,
      evol: formatValueWithUnit(i18n, evol, UNIT.PERCENT)
    })
  );
};
