import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useEffect, useState } from 'react';
import { Nav } from 'react-bootstrap';
import './Sidebar.css';

const Sidebar = ({ sections, activeSection }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region states
  const [activeLink, setActiveLink] = useState(activeSection);
  const [isOpen, setIsOpen] = useState(false);
  //#endregion

  //#region [effects]
  useEffect(() => {
    setActiveLink(activeSection);
  }, [activeSection]);
  //#endregion

  //#region render
  const loadIcon = (sectionId) =>
    require(`../../../../../image/section-${sectionId}.png`);
  const loadActiveIcon = (sectionId) =>
    require(`../../../../../image/section-${sectionId}-active.png`);

  return (
    <div className='results-sidebar'>
      <FontAwesomeIcon
        icon={isOpen ? 'circle-arrow-left' : 'circle-arrow-right'}
        className='results-sidebar-arrow'
        onClick={() => setIsOpen(!isOpen)}
      />
      <Nav
        className={
          isOpen ? 'results-sidebar-nav' : 'results-sidebar-nav nav-closed'
        }
      >
        {sections.map((section) => (
          <Nav.Item key={'results_nav_item_' + section.id}>
            <Nav.Link
              href={`#results_section_${section.id}`}
              active={activeLink === section.id}
              eventKey={section.id}
            >
              <img
                key={'results_img_' + section.id}
                src={
                  activeLink === section.id
                    ? loadActiveIcon(section.id)
                    : loadIcon(section.id)
                }
                className='results-sidebar-icon'
                title={!isOpen ? i18n._(`results.${section.id}`) : null}
              />
              <span className={isOpen ? '' : 'hidden'}>
                {i18n._(`results.${section.id}`)}
              </span>
            </Nav.Link>
          </Nav.Item>
        ))}
      </Nav>
    </div>
  );
  //#endregion
};

export default Sidebar;
