import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import CompareResultsTable from '../../../../../components/CompareResultsTable/CompareResultsTable';
import Section from '../../../../../components/Section/Section';
import ResultContext from '../../../../../contexts/ResultContext';
import './SummarySection.css';
import CompareResultsCharts from './components/CompareResultsCharts/CompareResultsCharts';
import { summaryTemplate } from './templates';

const SummarySection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { comparedResults } = useContext(ResultContext);
  //#endregion

  //#region [memos]
  const STemplate = useMemo(() => {
    return summaryTemplate(i18n, comparedResults);
  }, [comparedResults]);
  //#endregion

  //#region [render]
  return (
    <Section title={i18n._('results.summary')} open>
      <CompareResultsTable level={2} template={STemplate} />
      {comparedResults.length > 1 && <CompareResultsCharts />}
    </Section>
  );
  //#endregion
};

export default SummarySection;
