const PROJECT_ENDPOINT = Object.freeze({
  GENERAL: 'general',
  DESCRIPTIONS: 'descriptions',
  COMPUTE: 'compute',
  RESULTS: 'results',
  GEOLOGY: 'geology',
  COST: 'cost',
  PERFORMANCE: 'performance'
});

module.exports = { PROJECT_ENDPOINT };
