import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import Section from '../../../../../../../components/Section/Section';
import TemplateTable from '../../../../../../../components/TemplateTables/TemplateTable';
import CompanyContext from '../../../../../../../contexts/CompanyContext';
import ProjectContext from '../../../../../../../contexts/ProjectContext';
import { perfTemplates } from './templates';

const PerformanceSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  const { project } = useContext(ProjectContext);
  //#endregion

  //#region [memos]
  const PTemplates = useMemo(() => {
    return perfTemplates(i18n, project);
  }, [project]);
  //#endregion

  //#region [render]
  return (
    <Section
      title={i18n._('page.performance')}
      editPath={`/company/${selectedCompany.id}/project/${project.AhsID}/performance`}
    >
      {PTemplates.map((template, index) => (
        <TemplateTable
          template={template}
          key={'performance_table_' + index}
          level={2}
        />
      ))}
    </Section>
  );
  //#endregion
};

export default PerformanceSection;
