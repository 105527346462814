import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import CompanyContext from '../../../contexts/CompanyContext';
import useAuth from '../../../hooks/useAuth';
import ActiveProjects from './ActiveProjects';

const ProjectsBody = ({ onCompanyChange }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [auth]
  const { user } = useAuth();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  //#endregion

  //#region [render]
  return (
    <div className='projects-body'>
      <div className='projects-top-row'>
        <div className='projects-top-row-left'>
          <div className='projects-company-select'>
            <span>{i18n._('company')}</span>
            <Form.Select
              aria-label='select company'
              onChange={(evt) => onCompanyChange(evt.target.value, 'true')}
              value={selectedCompany.id}
            >
              {user.companies.map((company) => (
                <option key={'company_' + company.CoID} value={company.CoID}>
                  {company.CoName}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
        <Link
          to={`/company/${selectedCompany.id}/new-project`}
          className='projects-new-project'
        >
          {i18n._('projects.new')} <FontAwesomeIcon icon='plus' />
        </Link>
      </div>
      <ActiveProjects />
    </div>
  );
  //#endregion
};

export default ProjectsBody;
