import React from 'react';

const defaultState = {
  isLoading: false,
  groupedResults: [],
  selectedResult: {
    id: undefined,
    comment: undefined,
    name: undefined,
    data: undefined,
    tags: undefined,
    substations: [],
    details: undefined,
    error: false
  },
  comparedResults: [],
  setGroupedResults:() => {},
  setSelectedResult: () => {},
  setResultsComparator: () => {},
  loadDetails: async () => {}
};

const ResultContext = React.createContext(defaultState);
export default ResultContext;
