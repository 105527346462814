import { useLingui } from '@lingui/react';
import cloneDeep from 'lodash/cloneDeep';
import React, { useContext, useLayoutEffect, useState } from 'react';
import { Alert, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { EMPTY_PROJECT } from '../../../../server/models/project.model';
import {
  uploadProjectImgFile,
  uploadSTDFiles,
  uploadWeatherFiles
} from '../../api/file.api';
import { updateDescriptionsParams } from '../../api/project.api';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DescriptionsTabs from '../../components/DescriptionsTabs/DescriptionsTabs';
import GeneralForm from '../../components/GeneralForm/GeneralForm';
import OpaqueLayer from '../../components/OpaqueLayer/OpaqueLayer';
import CompanyContext from '../../contexts/CompanyContext';
import PopupContext from '../../contexts/PopupContext';
import { isArrNullOrEmpty } from '../../utils/data.utils';
import { getDescriptionErrors } from '../../utils/project.utils';
import './NewProjectPage.css';

const NewProjectPage = () => {
  //#region [router]
  const navigate = useNavigate();
  //#endregion

  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [project, setProject] = useState({
    ...EMPTY_PROJECT,
    AhsCoID: selectedCompany.id
  });
  const [currentPageIndex, setCurrentPageIndex] = useState(0);
  const [cantSaveErrors, setCantSaveErrors] = useState([]);
  const [isCreating, setIsCreating] = useState(false);
  //#endregion

  //#region [effects]
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, [currentPageIndex]);
  //#endregion

  //#region [methods]
  const handleCreateProjectClick = async () => {
    try {
      setIsCreating(true);

      // on check les erreurs liées aux besoins des descriptions
      const errors = getDescriptionErrors(i18n, project);
      setCantSaveErrors(errors);
      if (errors.some((err) => err)) {
        setIsCreating(false);
        return;
      }

      const createdProject = await updateDescriptionsParams(project);
      const projectCopy = cloneDeep(project);

      // on complète le projet parent et ses sous-stations leurs IDs
      projectCopy.AhsID = createdProject.AhsID;
      projectCopy.substations.forEach((sst, sstIndex) => {
        sst.InitStationID = createdProject.substations[sstIndex].InitStationID;
      });

      // on complète les projets enfants et leurs sous-stations avec leurs IDs
      if (!isArrNullOrEmpty(projectCopy.children)) {
        projectCopy.children.forEach((child, childIndex) => {
          child.AhsID = createdProject.children[childIndex].AhsID;
          child.substations.forEach((sst, sstIndex) => {
            sst.InitStationID =
              createdProject.children[childIndex].substations[
                sstIndex
              ].InitStationID;
          });
        });
      } else {
        projectCopy.children = [];
      }

      // on envoie la photo
      if (projectCopy.imgFile) {
        await uploadProjectImgFile(projectCopy.AhsID, projectCopy.imgFile);
      }

      // on envoie les STD
      const projectAndChildren = [projectCopy, ...projectCopy.children];

      let sstDataToUpload = {};
      projectAndChildren.forEach((proj) => {
        sstDataToUpload = proj.substations
          .filter((sst) => sst.stdFile)
          .reduce((acc, sst) => {
            acc[sst.InitStationID] = {
              file: sst.stdFile,
              filename: sst.stdFile.name,
              summaryData: sst.SummaryData
            };
            return acc;
          }, sstDataToUpload);
      });
      await uploadSTDFiles(sstDataToUpload);

      // on envoie les fichiers météo
      let dataToUpload = {};
      if (projectCopy.weatherFile) {
        dataToUpload[projectCopy.AhsID] = projectCopy.weatherFile;
      }

      projectCopy.children.forEach((child) => {
        if (child.weatherFile) {
          dataToUpload[child.AhsID] = child.weatherFile;
        }
      });

      await uploadWeatherFiles(dataToUpload);

      // on change de page
      setIsCreating(false);
      const url = `/company/${selectedCompany.id}/project/${projectCopy.AhsID}/compute`;
      navigate(url);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
      setIsCreating(false);
    }
  };
  //#endregion

  //#region [render]
  return (
    <div className='new-project-page'>
      <Breadcrumb
        links={[
          {
            to: '/',
            state: { companyId: selectedCompany.id },
            name: i18n._('breadcrumb.company', {
              company: selectedCompany.name
            })
          }
        ]}
        current={i18n._('newProject')}
      />
      <div className='new-project-form'>
        <OpaqueLayer visible={isCreating}>
          <Alert animation='border' variant='primary'>
            {i18n._('saving')}
            <Spinner variant='light' />
          </Alert>
        </OpaqueLayer>
        <div className={currentPageIndex === 0 ? '' : 'hidden'}>
          <GeneralForm
            project={project}
            setProject={setProject}
            onNext={() => setCurrentPageIndex(1)}
          />
        </div>
        <div className={currentPageIndex === 1 ? '' : 'hidden'}>
          <DescriptionsTabs
            project={project}
            setProject={setProject}
            onPrevious={() => setCurrentPageIndex(0)}
            onSubmit={handleCreateProjectClick}
            cantSaveErrors={cantSaveErrors}
          />
        </div>
      </div>
    </div>
  );
  //#endregion
};

export default NewProjectPage;
