import React, { Fragment } from 'react';
import { WEATHER_COLS } from '../../../../../server/constants/weatherCol.constant';
import { isNull } from '../../../utils/data.utils';

const WeatherModal = (i18n) => ({
  title: i18n._('projectNeeds.weatherFileExample.modalTitle'),
  body: (
    <Fragment>
      <p>{i18n._('projectNeeds.weatherDesc.acceptedFiles')}</p>
      <div className='description-file-modal-hl' />
      {Object.entries(WEATHER_COLS).map(([colName, colObj], colIndex) => {
        let text = [colName];
        if (!isNull(colObj.min)) {
          text.push(
            i18n._('projectNeeds.modal.min', {
              min: colObj.min
            })
          );
        }
        if (!isNull(colObj.max)) {
          text.push(
            i18n._('projectNeeds.modal.max', {
              max: colObj.max
            })
          );
        }
        if (colObj.unit) {
          text.push(
            i18n._('projectNeeds.modal.unit', {
              unit: i18n._(`unit.${colObj.unit}`)
            })
          );
        }
        if (!colObj.mandatory) {
          text.push(i18n._('projectNeeds.modal.optional'));
        }
        return (
          <p key={'weatherNeeds_' + colName + '_' + colIndex}>
            {text.join(i18n._('projectNeeds.modal.separator'))}
          </p>
        );
      })}
    </Fragment>
  )
});

export default WeatherModal;
