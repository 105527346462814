// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-modal .modal-header {
    font-size: 18px;
}

.custom-modal .modal-header svg {
    margin-left: 8px;
    cursor: auto;
}

.custom-modal .modal-body {
    background-color: var(--app-bg-color);
    white-space: pre-wrap;
}`, "",{"version":3,"sources":["webpack://./src/components/Modal/Modal.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,gBAAgB;IAChB,YAAY;AAChB;;AAEA;IACI,qCAAqC;IACrC,qBAAqB;AACzB","sourcesContent":[".custom-modal .modal-header {\n    font-size: 18px;\n}\n\n.custom-modal .modal-header svg {\n    margin-left: 8px;\n    cursor: auto;\n}\n\n.custom-modal .modal-body {\n    background-color: var(--app-bg-color);\n    white-space: pre-wrap;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
