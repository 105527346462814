import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useEffect, useMemo, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import './UsersTable.css';

const COLS_WIDTHS = ['50px', 'auto', 'auto', 'auto'];
const DIVIDER = 118;

const UsersTable = ({ users }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const columns = useMemo(
    () => [
      {
        id: 'UserRole',
        Cell: (props) => {
          const { IS_SUPERADMIN } = props.row.original;
          return (
            <FontAwesomeIcon
              icon={IS_SUPERADMIN ? 'user-shield' : 'user'}
              className='users-table-role'
              title={
                IS_SUPERADMIN
                  ? i18n._('users.role.admin')
                  : i18n._('users.role.user')
              }
            />
          );
        }
      },
      {
        id: 'UserName',
        accessor: 'UserName',
        Header: () => <span>{i18n._('users.header.name')}</span>,
        Cell: (props) => {
          const { UserFirstName, UserLastName } = props.row.original;
          return i18n._('users.name', {
            firstName: UserFirstName,
            lastName: UserLastName
          });
        },
        sortType: (a, b) =>
          a.original.UserFirstName.toLowerCase().localeCompare(
            b.original.UserFirstName.toLowerCase()
          )
      },
      {
        id: 'UserJobTitle',
        accessor: 'UserJobTitle',
        Header: () => <span>{i18n._('users.header.job')}</span>
      },
      {
        id: 'UserEmail',
        accessor: 'UserEmail',
        Header: () => <span>{i18n._('users.header.email')}</span>
      }
    ],
    []
  );
  //#endregion

  //#region [react-table]
  const {
    headerGroups,
    page,
    state,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    getTableProps,
    getTableBodyProps,
    prepareRow
  } = useTable(
    {
      columns,
      data: users,
      initialState: {
        sortBy: [
          {
            id: 'UserName',
            desc: false
          }
        ]
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  //#endregion

  //#region [states]
  const [searchValue, setSearchValue] = useState(state.globalFilter);
  const [nbItems, setNbItems] = useState(
    parseInt(window.innerHeight / DIVIDER)
  );
  //#endregion

  //#region [effects]
  useEffect(() => {
    const debounce = (fn, ms) => {
      let timer;
      return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          timer = null;
          fn.apply(this, arguments);
        }, ms);
      };
    };

    const debounceHandleResize = debounce(function handleResize() {
      setNbItems(parseInt(window.innerHeight / DIVIDER));
    }, 300);

    window.addEventListener('resize', debounceHandleResize);
    return () => window.removeEventListener('resize', debounceHandleResize);
  }, []);

  useEffect(() => {
    setPageSize(nbItems);
  }, [nbItems]);
  //#endregion

  //#region [render]
  return (
    <div>
      <div className='users-input-body'>
        <FontAwesomeIcon icon='magnifying-glass' />
        <Form.Control
          type='text'
          placeholder={i18n._('users.search')}
          value={searchValue || ''}
          onChange={(evt) => {
            setSearchValue(evt.target.value);
            onChange(evt.target.value);
          }}
        />
      </div>
      <table className='users-table' {...getTableProps()} border='1'>
        <thead className='users-table-thead'>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  className='users-table-th'
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{ width: COLS_WIDTHS[i] }}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className='users-table-body' {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr className='users-table-tr' {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      className='users-table-td'
                      style={{
                        width: COLS_WIDTHS[i]
                      }}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='pagination'>
        <span className='pagination-page-index'>
          {i18n._('pagination', {
            pageIndex: state.pageIndex + 1,
            nbPages: pageOptions.length
          })}
        </span>
        <div className='pagination-btns'>
          <Button
            variant='secondary'
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className='pagination-btn'
          >
            <FontAwesomeIcon icon='chevron-left' />
          </Button>
          <Button
            variant='secondary'
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className='pagination-btn'
          >
            <FontAwesomeIcon icon='chevron-right' />
          </Button>
        </div>
      </div>
    </div>
  );
  //#endregion
};

export default UsersTable;
