import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { WATERTANK_HOT } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import CostPageContext from '../../../../contexts/CostPageContext';
import { getDefaultValue } from '../../../../utils/project.utils';

const WaterTankHotBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleOtherValueChange } =
    useContext(CostPageContext);
  //#endregion

  //#region [render]
  const { InitWaterTankHotCostA, InitWaterTankHotCostB } =
    tempProject.otherValues;
  return (
    <Bloc title={i18n._('cost.waterTankHot')}>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitWaterTankHotCostA')}
            value={InitWaterTankHotCostA}
            param={WATERTANK_HOT.InitWaterTankHotCostA}
            onBlur={(value) =>
              handleOtherValueChange('InitWaterTankHotCostA', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_HOT.InitWaterTankHotCostA
            )}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitWaterTankHotCostB')}
            value={InitWaterTankHotCostB}
            param={WATERTANK_HOT.InitWaterTankHotCostB}
            onBlur={(value) =>
              handleOtherValueChange('InitWaterTankHotCostB', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_HOT.InitWaterTankHotCostB
            )}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default WaterTankHotBloc;
