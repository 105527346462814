import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Form, InputGroup, Overlay, Tooltip } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import { getInputErrorCode } from '../../utils/error.utils';
import './Form.css';

const FormInput = ({
  className,
  value,
  disabled,
  onBlur,
  param,
  addError,
  removeError,
  label,
  bottomText,
  topText,
  unit,
  labelLeft,
  showInfoIcon,
  infoIcon,
  infoTooltip,
  onInfoClick
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [error, setError] = useState(null);
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  //#endregion

  //#region [refs]
  const inputRef = useRef();
  const iconRef = useRef(null);
  //#endregion

  //#region [effects]
  useEffect(() => {
    if (!inputRef || value === inputRef.current.value) return;
    inputRef.current.value = value;
  }, [value]);

  useEffect(() => {
    const val = inputRef.current.value;
    const errorCode = getInputErrorCode(val, param, false);
    if (!disabled) {
      if (!errorCode && error) {
        setError(null);
        if (removeError) removeError();
      } else if (errorCode && !error) {
        if (!error && addError) addError();
        setError({ ...errorCode });
      }
    } else {
      if (error) {
        setError(null);
        if (removeError) removeError();
      }
    }
  }, [disabled, param, error]);
  //#endregion

  //#region [methods]
  const handleParamBlur = () => {
    const val = inputRef.current.value;
    const errorCode = getInputErrorCode(val, param, false);
    if (errorCode) {
      if (!error && addError) addError();
      setError({ ...errorCode });
    } else {
      if (error) {
        setError(null);
        if (removeError) removeError();
      }
      onBlur(param.type === 'number' ? +val : val);
    }
  };
  //#endregion

  //#region [render]
  const inputId = `input_${param.key}_` + new Date().getTime(); // on s'assure que l'id est unique
  const inputClassName = error
    ? 'form-input-error'
    : !disabled && value && value != param.default
    ? 'form-input-changed'
    : '';
  return (
    <div className={className}>
      <div className={labelLeft ? 'form-input-left' : ''}>
        {label && (
          <span className='form-input-label'>
            {label}
            {param.required ? '*' : ''}
            {showInfoIcon && (
              <>
                <FontAwesomeIcon
                  icon={infoIcon ?? 'circle-question'}
                  className='info-icon'
                  onClick={onInfoClick}
                  onMouseOver={() => setShowInfoTooltip(true)}
                  onMouseOut={() => setShowInfoTooltip(false)}
                  ref={iconRef}
                />
                {infoTooltip && (
                  <Overlay
                    target={iconRef.current}
                    show={showInfoTooltip}
                    placement='right'
                  >
                    {(props) => (
                      <Tooltip id='form-input-info-tooltip' {...props}>
                        {infoTooltip}
                      </Tooltip>
                    )}
                  </Overlay>
                )}
              </>
            )}
          </span>
        )}
        <div className='form-input-body'>
          {topText && (
            <p className='form-input-small-text form-input-top-text'>
              {topText}
            </p>
          )}
          <InputGroup className='form-input'>
            <Form.Control
              className={inputClassName}
              type={param.type}
              disabled={disabled}
              onBlur={handleParamBlur}
              onWheel={(e) => e.target.blur()}
              defaultValue={value !== null || value !== undefined ? value : ''}
              ref={inputRef}
              aria-describedby='form_input'
              data-tip
              data-for={inputId}
              maxLength={param.maxLength}
            />
            {unit && param.unit && (
              <InputGroup.Text id='form_input' className='form-input-unit'>
                {i18n._(`unit.${param.unit}`)}
              </InputGroup.Text>
            )}
          </InputGroup>
          {bottomText && (
            <span className='form-input-small-text'>{bottomText}</span>
          )}
          {error && (
            <ReactTooltip
              id={inputId}
              className='error-tooltip'
              effect='solid'
              place='bottom'
            >
              {i18n._(error.translateId, error.translateParams)}
            </ReactTooltip>
          )}
        </div>
      </div>
    </div>
  );
  //#endregion
};

export default FormInput;
