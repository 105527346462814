import { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { MAX_RESULTS_FOR_COMPARE } from '../../../../../server/constants/result.constant';
import {
  fetchComputeDetails,
  fetchResult,
  fetchResultsByList
} from '../../../api/compute.api';
import { fetchResults } from '../../../api/project.api';
import PopupContext from '../../../contexts/PopupContext';
import ProjectContext from '../../../contexts/ProjectContext';
import {
  getResultNameByNameData,
  getResultNameData,
  getSubstations
} from '../../../utils/compute.utils';

const useResult = (i18n) => {
  //#region [router]
  const navigate = useNavigate();
  const { resultId } = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  //#endregion

  //#region [contexts]
  const { project } = useContext(ProjectContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [isLoading, setIsLoading] = useState(false);
  const [groupedResults, setGroupedResults] = useState([]);

  // le résultat courant
  const [selectedResult, setSelectedResult] = useState();
  // le comparateur de résultats (<-> la liste des résultats comparés au résultat courant)
  const [resultsComparator, setResultsComparator] = useState([]);
  //#endregion

  //#region [refs]
  const loadingDetails = useRef([]);
  //#endregion

  //#region [effects]
  // on récupère tous les résultats du projet courant
  useEffect(() => {
    (async () => {
      try {
        const groups = await fetchResults(project.AhsID, true);
        setGroupedResults(groups);
      } catch (err) {
        console.error(err);
        if (err.response?.status === 404) {
          navigate('/notfound');
        } else {
          openErrorToast(err);
        }
      }
    })();
  }, [project.AhsID]);

  // on met à jour le résultat courant
  useEffect(() => {
    (async () => {
      try {
        setIsLoading(true);
        const result = await fetchResult(resultId, !!selectedResult?.details);
        const { ComputeResult, Comment } = result;
        const substations = getSubstations(ComputeResult.summary);
        const nameData = getResultNameData(result);
        setSelectedResult({
          ...result,
          Comment: Comment ?? '',
          ComputeName: getResultNameByNameData(i18n, project, nameData),
          substations,
          error: false
        });
        setIsLoading(false);
      } catch (err) {
        console.error(err);
        if (err.response?.status === 404) {
          navigate('/notfound');
        } else {
          openErrorToast(err);
        }
      }
    })();
  }, [resultId]);

  // on met à jour le comparateur de résultats grâce aux searchParams "compare" de l'url
  useEffect(() => {
    (async () => {
      try {
        // on récupère les ids des résultats du comparateur
        const compareStr = searchParams.get('compare');

        // on vient de supprimer le seul résultat du comparateur
        if (!compareStr && resultsComparator.length > 0) {
          setResultsComparator([]);
          return;
        }

        // aucun résultat dans le comparateur
        if (!compareStr) return;

        // urlIds est le tableau d'identifiants des résultats du comparateur
        const urlIds = compareStr.split(',');

        // il y a trop de résultats comparés dans l'url
        if (urlIds.length > MAX_RESULTS_FOR_COMPARE - 1) {
          navigate('/notfound');
          return;
        }

        // on supprime un résultat du comparateur de résultats
        if (resultsComparator.length > urlIds.length) {
          const newCompareResults = resultsComparator.filter((result) =>
            urlIds.includes(result.ComputeID)
          );
          setResultsComparator(newCompareResults);
          return;
        }

        // on ajoute un résultat au comparateur de résultats
        const resultsAlreadyLoaded = resultsComparator.map(
          (res) => res.ComputeID
        );
        const resultsToLoad = urlIds.filter(
          (id) => !resultsAlreadyLoaded.includes(id)
        );
        if (resultsToLoad.length === 0) return;

        // on charge le nouveau résultat du comparateur
        const resultsList = await fetchResultsByList(resultsToLoad);

        // on set les nouveaux résultats du comparateur
        const newCompareResults = resultsList.map((result) => {
          const { ComputeResult, Comment } = result;
          const substations = getSubstations(ComputeResult.summary);
          const nameData = getResultNameData(result);
          return {
            ...result,
            Comment: Comment ?? '',
            ComputeName: getResultNameByNameData(i18n, project, nameData),
            substations,
            details: null,
            error: false
          };
        });
        setResultsComparator(resultsComparator.concat(newCompareResults));
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, [resultsComparator, searchParams]);
  //#endregion

  //#region [memos]
  // le résultat courant + les résultats comparés au résultat courant
  const comparedResults = useMemo(() => {
    const results = !selectedResult
      ? []
      : [selectedResult, ...resultsComparator];
    loadingDetails.current = new Array(results.length).fill(false);
    return results;
  }, [resultsComparator, selectedResult]);
  //#endregion

  //#region [methods]
  const addResultToCompare = async (resultId) => {
    try {
      const ids = [
        ...resultsComparator.map((result) => result.ComputeID),
        resultId
      ];
      setSearchParams({ compare: ids.join(',') });
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const removeResultFromCompare = (resultId) => {
    const ids = resultsComparator
      .filter((result) => result.ComputeID !== resultId)
      .map((result) => result.ComputeID);
    setSearchParams(ids.length > 0 ? { compare: ids.join(',') } : {});
  };

  const loadDetails = async () => {
    setIsLoading(true);
    for (let i = 0; i < comparedResults.length; i++) {
      const result = comparedResults[i];
      if (!result.details && !loadingDetails.current[i]) {
        // loadingDetails est une sécurité pour éviter que les détails ne soient chargés plusieurs fois
        loadingDetails.current[i] = true;
        result.details = await fetchComputeDetails(result.ComputeID);
      }
    }
    loadingDetails.current.fill(false);
    setSelectedResult(comparedResults[0]);
    setResultsComparator(comparedResults.slice(1));
    setIsLoading(false);
  };
  //#endregion

  return {
    isLoading,
    groupedResults,
    selectedResult,
    comparedResults,
    setGroupedResults,
    setSelectedResult,
    setResultsComparator,
    addResultToCompare,
    removeResultFromCompare,
    loadDetails
  };
};

export default useResult;
