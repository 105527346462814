// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compare-result-cards-body {
    width: 100%;
}

.compare-result-cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    gap: 5px 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompareResultsCards/CompareResultsCards.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,WAAW;IACX,mBAAmB;QACf,eAAe;IACnB,aAAa;AACjB","sourcesContent":[".compare-result-cards-body {\n    width: 100%;\n}\n\n.compare-result-cards {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    width: 100%;\n    -ms-flex-wrap: wrap;\n        flex-wrap: wrap;\n    gap: 5px 20px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
