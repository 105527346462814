const SOURCE = Object.freeze({
  TFP: 'tfp',
  HPA: 'hpa',
  HPG: 'hpg',
  GAS: 'gas',
  ST: 'st',
  NDW: 'ndw',
  GEOCOOLING: 'geocooling',
  SUM: 'sum'
});

module.exports = { SOURCE };
