import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import dateFormat from 'dateformat';
import React, { useContext } from 'react';
import { Button, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { CALCULATION_STATUS } from '../../../../../../../server/constants/calculation.constant';
import { COMPUTE_STATUS } from '../../../../../../../server/constants/compute.constant';
import { COMPUTE_TYPE } from '../../../../../../../server/constants/constraint.constant';
import { fetchEngineData } from '../../../../../api/calculation.api';
import ExpandableCard from '../../../../../components/ExpandableCard/ExpandableCard';
import CompanyContext from '../../../../../contexts/CompanyContext';
import PopupContext from '../../../../../contexts/PopupContext';
import ProjectContext from '../../../../../contexts/ProjectContext';
import { BASIC } from '../../../../../styles/colors/chart.color';
import { isArrNullOrEmpty } from '../../../../../utils/data.utils';
import { downloadFile } from '../../../../../utils/file.utils';
import ComputeCard from '../ComputeCard/ComputeCard';
import Dataviz from '../Dataviz/Dataviz';
import './Calculation.css';

const Calculation = ({ calculation }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  const { project } = useContext(ProjectContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const handleDlEngineDataClick = async (type, date, descName, projectId) => {
    try {
      const engineData = await fetchEngineData(calculation.id, projectId);
      const filename = i18n._('calculation.engineData.filename', {
        type:
          type === COMPUTE_TYPE.OPTI
            ? i18n._('computeType.opti')
            : i18n._('computeType.simu'),
        date: dateFormat(date, i18n._('calculation.engineData.dateFormat')),
        descName
      });
      downloadFile(
        `${filename}.json`,
        new Blob([JSON.stringify(engineData)], {
          type: 'text/json'
        })
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const { status, descriptions, type, createdAt } = calculation;
  if (status === CALCULATION_STATUS.CONNECTING) {
    return (
      <span className='calculation-connecting'>
        {i18n._('calculation.connecting')}
        <Spinner variant='dark' size='sm' />
      </span>
    );
  } else {
    const cardTitle =
      status === CALCULATION_STATUS.IN_PROGRESS
        ? i18n._('calculation.dataviz.inProgress')
        : i18n._('calculation.dataviz.finished', {
            type:
              type === COMPUTE_TYPE.OPTI
                ? i18n._('computeType.opti')
                : i18n._('computeType.simu'),
            date: dateFormat(createdAt, i18n._('formatDateAndHour'))
          });

    return (
      <ExpandableCard title={cardTitle}>
        {descriptions.map((desc, descIndex) => {
          let url;
          const successComputes = desc.computes.filter(
            (compute) => compute.status === COMPUTE_STATUS.SUCCESS
          );
          if (!isArrNullOrEmpty(successComputes)) {
            const compareResults = [];
            url = `/company/${selectedCompany.id}/project/${project.AhsID}/results/${successComputes[0].computeId}`;
            if (successComputes.length > 1) {
              for (let i = 1; i < successComputes.length; ++i) {
                compareResults.push(successComputes[i].computeId);
              }
              url += `?compare=${compareResults.join(',')}`;
            }
          }
          return (
            <div
              className='calculation-desc'
              key={'calculation_desc_' + desc.projectId}
            >
              <span className='calculation-desc-title'>
                {i18n._('calculation.computesForDesc', {
                  desc: desc.name,
                  nbComputes: desc.computes.length
                })}
              </span>
              <div className='calculation-btns'>
                {desc.projectSaved && (
                  <Button
                    variant='outline-secondary'
                    onClick={async () =>
                      await handleDlEngineDataClick(
                        type,
                        createdAt,
                        desc.name,
                        desc.projectId
                      )
                    }
                  >
                    <FontAwesomeIcon icon='download' />
                    {i18n._('calculation.engineData.dl')}
                  </Button>
                )}
                {url && (
                  <Link className='btn btn-outline-secondary' to={url}>
                    <FontAwesomeIcon icon='table' />
                    {i18n._('calculation.compare')}
                  </Link>
                )}
              </div>
              {desc.computes.map((compute, compIndex) => (
                <ComputeCard
                  compute={compute}
                  key={'calculation_comp_' + compute.computeId}
                  chartColor={
                    BASIC[descIndex * desc.computes.length + compIndex]
                  }
                />
              ))}
            </div>
          );
        })}
        {type === COMPUTE_TYPE.OPTI && <Dataviz calculation={calculation} />}
      </ExpandableCard>
    );
  }
  //#endregion
};
export default React.memo(Calculation);
