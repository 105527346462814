import React from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Route, Routes } from 'react-router-dom';
import AuthProvider from './auth/Auth';
import ErrorFallback from './components/ErrorFallback/ErrorFallback';
import RedirectToProjects from './components/RedirectToProjects/RedirectToProjects';
import RequireAdmin from './components/RequireAdmin';
import RequireAuth from './components/RequireAuth';
import CompanyPage from './pages/company/CompanyPage';
import ComputePage from './pages/design/compute/ComputePage';
import designPages from './pages/design/pages';
import ResultPage from './pages/design/result/ResultPage';
import ResultsPage from './pages/design/results/ResultsPage';
import LoginPage from './pages/login/LoginPage';
import NewProjectPage from './pages/newProject/NewProjectPage';
import NewUserPage from './pages/newUser/NewUserPage';
import NotFoundPage from './pages/notFound/NotFoundPage';
import ProjectPage from './pages/project/ProjectPage';
import ProjectsPage from './pages/projects/ProjectsPage';
import ResetPwdPage from './pages/resetPwd/ResetPwdPage';
import RootPage from './pages/root/RootPage';
import UsersPage from './pages/users/UsersPage';
import VersionPage from './pages/version/VersionPage';

const Router = () => {
  //#region [render]
  return (
    <AuthProvider>
      <Routes>
        <Route
          path='/login'
          element={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <LoginPage />
            </ErrorBoundary>
          }
        />
        <Route
          path='/reset-pwd'
          element={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <ResetPwdPage />
            </ErrorBoundary>
          }
        />
        <Route
          path='/'
          element={
            <ErrorBoundary FallbackComponent={ErrorFallback}>
              <RequireAuth redirectTo='/login'>
                <RootPage />
              </RequireAuth>
            </ErrorBoundary>
          }
        >
          <Route index element={<RedirectToProjects />} />
          <Route
            path='version'
            element={
              <RequireAdmin redirectTo='/notfound'>
                <VersionPage />
              </RequireAdmin>
            }
          />
          <Route path='company/:companyId' element={<CompanyPage />}>
            <Route path='projects' element={<ProjectsPage />} />
            <Route path='new-project' element={<NewProjectPage />} />
            <Route
              path='users'
              element={
                <RequireAdmin redirectTo='/notfound'>
                  <UsersPage />
                </RequireAdmin>
              }
            />
            <Route
              path='new-user'
              element={
                <RequireAdmin redirectTo='/notfound'>
                  <NewUserPage />
                </RequireAdmin>
              }
            />
            <Route path='project/:projectId' element={<ProjectPage />}>
              <Route path='compute' element={<ComputePage />} />
              {designPages.map((page) => {
                let Component = page.component;
                return (
                  <Route
                    path={page.id}
                    element={<Component />}
                    key={'design_route_' + page.id}
                  />
                );
              })}

              <Route path='results' element={<ResultsPage />} />
              <Route path='results/:resultId' element={<ResultPage />} />
            </Route>
          </Route>
        </Route>
        <Route
          path='*'
          element={
            <RequireAuth redirectTo='/login'>
              <NotFoundPage />
            </RequireAuth>
          }
        />
      </Routes>
    </AuthProvider>
  );
  //#endregion
};

export default Router;
