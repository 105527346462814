import React from 'react';

const defaultState = {
  projectParent: undefined,
  setProjectParent: () => {},
  addErrorByDesc: () => {},
  removeErrorsByDesc: () => {}
};

const DescriptionsPageContext = React.createContext(defaultState);
export default DescriptionsPageContext;
