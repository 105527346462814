import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import TemplateTable from '../../../../../../../../components/TemplateTables/TemplateTable';
import { sstTemplate, stdTemplate } from '../templates';
import StdTable from './StdTable/StdTable';

const SstTable = ({ projectAndChildren, substation, sstIndex }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const SSTTemplate = useMemo(() => {
    return sstTemplate(i18n, substation);
  }, [substation]);

  const STDTemplate = useMemo(() => {
    return stdTemplate(projectAndChildren, sstIndex);
  }, [projectAndChildren, sstIndex]);
  //#endregion

  //#region [render]
  return (
    <div>
      <TemplateTable template={SSTTemplate} level={2} />
      <StdTable template={STDTemplate} />
    </div>
  );
  //#endregion
};

export default SstTable;
