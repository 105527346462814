import React from 'react';

const defaultState = {
  tempProject: undefined,
  nbErrors: 0,
  addError: () => {},
  removeError: () => {},
  handleParamChange: () => {},
  handleOtherValueChange: () => {},
  handleTableValueChange: () => {}
};

const CostPageContext = React.createContext(defaultState);
export default CostPageContext;
