// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.weather-section {
    border: 1px solid lightgray;
    border-radius: 0.25rem;
    padding: 15px;
}

.weather-section .fa-file-csv {
    color: green;
    font-size: 20px;
}

.weather-section .csv-file {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 5px;
    font-size: 15px;
}

.weather-section .csv-format {
    color: #999;
    font-size: 12px;
}`, "",{"version":3,"sources":["webpack://./src/components/DescriptionsTabs/components/WeatherSection/WeatherSection.css"],"names":[],"mappings":"AAAA;IACI,2BAA2B;IAC3B,sBAAsB;IACtB,aAAa;AACjB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,yBAAyB;QACrB,sBAAsB;YAClB,mBAAmB;IAC3B,QAAQ;IACR,eAAe;AACnB;;AAEA;IACI,WAAW;IACX,eAAe;AACnB","sourcesContent":[".weather-section {\n    border: 1px solid lightgray;\n    border-radius: 0.25rem;\n    padding: 15px;\n}\n\n.weather-section .fa-file-csv {\n    color: green;\n    font-size: 20px;\n}\n\n.weather-section .csv-file {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    gap: 5px;\n    font-size: 15px;\n}\n\n.weather-section .csv-format {\n    color: #999;\n    font-size: 12px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
