import React, { useContext, useLayoutEffect } from 'react';
import ProjectContext from '../../../contexts/ProjectContext';
import { isObjNullOrEmpty } from '../../../utils/data.utils';
import './ComputePage.css';
import ComputeForm from './components/ComputeForm/ComputeForm';
import ProjectSummary from './components/ProjectSummary/ProjectSummary';

const ComputePage = () => {
  //#region [effects]
  const { project } = useContext(ProjectContext);
  //#endregion

  //#region [effects]
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion

  //#region [render]
  return !isObjNullOrEmpty(project) ? (
    <div>
      <div className='compute-page'>
        <ProjectSummary />
        <ComputeForm />
      </div>
    </div>
  ) : null;
  //#endregion
};

export default ComputePage;
