import { useLingui } from '@lingui/react';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ResultContext from '../../../contexts/ResultContext';
import useScrollPosition from '../../../hooks/useScrollPosition';
import { hasUniqueElements, isArrNullOrEmpty } from '../../../utils/data.utils';
import './ResultPage.css';
import CompareResultSelect from './components/CompareResultSelect/CompareResultSelect';
import Sidebar from './components/Sidebar/Sidebar';
import SECTIONS from './sections/sections.js';
import useResult from './useResult';

const SCROLL_MARGIN_TOP_SECTION = 135;
const PAGE_WIDTHS = ['60', '70', '78', '86', '94', '100'];

const ResultPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  //#endregion

  //#region [logic]
  const {
    isLoading,
    groupedResults,
    selectedResult,
    comparedResults,
    setGroupedResults,
    setSelectedResult,
    setResultsComparator,
    addResultToCompare,
    removeResultFromCompare,
    loadDetails
  } = useResult(i18n);
  //#endregion

  //#region [states]
  const [activeSection, setActiveSection] = useState();
  const [matches, setMatches] = useState(
    window.matchMedia('(max-width: 1007px)').matches
  );
  //#endregion

  //#region [refs]
  const sectionsRefs = SECTIONS.reduce((acc, section) => {
    acc[section.id] = useRef();
    return acc;
  }, {});
  //#endregion

  //#region [effects]
  useEffect(() => {
    window
      .matchMedia('(max-width: 1007px)')
      .addEventListener('change', (e) => setMatches(e.matches));
    return () => {
      window.removeEventListener('change', setMatches);
    };
  }, []);

  useEffect(() => {
    if (!selectedResult || !window.location.hash) return;
    const section = window.location.hash.replace('#results_section_', '');
    sectionsRefs[section].current.scrollIntoView();
  }, [selectedResult?.ComputeID]);

  useEffect(() => {
    if (isArrNullOrEmpty(comparedResults)) return;
    if (hasUniqueElements(comparedResults.map((result) => result.ComputeID)))
      return;
    navigate('/notfound');
  }, [comparedResults]);
  //#endregion

  //#region [custom hooks]
  useScrollPosition(({ prevPos, currPos }) => {
    if (prevPos.y === currPos.y) return;
    const fromTop = -(currPos.y - SCROLL_MARGIN_TOP_SECTION);
    Object.entries(sectionsRefs).forEach(([id, ref]) => {
      if (
        ref.current &&
        ref.current.offsetTop <= fromTop &&
        ref.current.offsetTop + ref.current.offsetHeight > fromTop &&
        activeSection !== id
      ) {
        setActiveSection(id);
      }
    });
  }, []);
  //#endregion

  //#region [render]
  return selectedResult ? (
    <ResultContext.Provider
      value={{
        isLoading,
        groupedResults,
        selectedResult,
        comparedResults,
        setGroupedResults,
        setResultsComparator,
        setSelectedResult,
        loadDetails
      }}
    >
      <CompareResultSelect
        addResultToCompare={addResultToCompare}
        removeResultFromCompare={removeResultFromCompare}
      />
      <Sidebar sections={SECTIONS} activeSection={activeSection} />
      <div
        className='result-body'
        style={{
          width: `${matches ? 100 : PAGE_WIDTHS[comparedResults.length - 1]}%`
        }}
      >
        {selectedResult.error ? (
          <Alert variant='danger'>{i18n._('results.resultError')}</Alert>
        ) : (
          <Fragment>
            {SECTIONS.map((section, index) => {
              let Component = section.component;
              return (
                <div
                  key={'section_' + section.id + '_' + index}
                  className='wrapper-section'
                  ref={sectionsRefs[section.id]}
                  id={`results_section_${section.id}`}
                >
                  <Component />
                </div>
              );
            })}
          </Fragment>
        )}
      </div>
    </ResultContext.Provider>
  ) : null;
  //#endregion
};

export default ResultPage;
