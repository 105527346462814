import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Nav, Tab } from 'react-bootstrap';
import { SST } from '../../../../../../server/models/design/description.model';
import { deleteSubstation } from '../../../../api/substation.api';
import DescriptionsPageContext from '../../../../contexts/DescriptionsPageContext';
import PopupContext from '../../../../contexts/PopupContext';
import SubstationTab from '../SubstationTab/SubstationTab';
import './SubstationTabs.css';

const DEFAULT_ACTIVE_TAB = '0';

const SubstationTabs = ({ project, descIndex }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openConfirmModal, openErrorToast } = useContext(PopupContext);
  const { projectParent, setProjectParent } = useContext(
    DescriptionsPageContext
  );
  //#endregion

  //#region [states]
  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);
  //#endregion

  //#region [refs]
  const nbTabs = useRef(project.substations.length);
  //#endregion

  //#region [effects]
  useEffect(() => {
    const { substations } = project;
    if (substations.length === nbTabs.current) return;
    if (substations.length > nbTabs.current) {
      setActiveTab((substations.length - 1).toString());
      nbTabs.current++;
    } else {
      setActiveTab(DEFAULT_ACTIVE_TAB);
      nbTabs.current--;
    }
  }, [project.substations.length]);
  //#endregion

  //#region [methods]
  const handleNewSubstationClick = () => {
    setProjectParent((projectParent) => {
      const addSubstation = (substations) => {
        const orderMax = Math.max(
          ...substations.map((sst) => sst.InitStationOrder)
        );
        substations.push({
          InitStationName: SST.InitStationName.default,
          InitStationUse: SST.InitStationUse.default,
          InitBuildingSurface: SST.InitBuildingSurface.default,
          InitStationOrder: orderMax + 1
        });
      };

      const projectParentCopy = cloneDeep(projectParent);
      addSubstation(projectParentCopy.substations);
      projectParentCopy.children.forEach((child) => {
        addSubstation(child.substations);
      });
      return projectParentCopy;
    });
  };

  const handleDeleteSubstation = async (sstIndex) => {
    try {
      // on met à jour côté serveur les sous-stations
      const { InitStationID } = projectParent.substations[sstIndex];
      if (InitStationID) await deleteSubstation(InitStationID);

      // on supprime la sous-station côté client
      const projectParentCopy = cloneDeep(projectParent);
      projectParentCopy.substations.splice(sstIndex, 1);
      projectParentCopy.children.forEach((child) =>
        child.substations.splice(sstIndex, 1)
      );
      setProjectParent(projectParentCopy);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const isChild = descIndex > 0;
  return (
    <div className='description-file-bloc'>
      <h1>{i18n._('substationTabs.substations')}</h1>
      <div className='description-file-children'>
        {isChild && (
          <div className='substation-tabs-warning-body'>
            <FontAwesomeIcon icon='exclamation-circle' />
            {i18n._('substationTabs.warning')}
          </div>
        )}
        <Tab.Container
          defaultActiveKey={DEFAULT_ACTIVE_TAB}
          activeKey={activeTab}
          onSelect={setActiveTab}
        >
          <Nav variant='tabs'>
            {project.substations.map((substation, index) => (
              <Nav.Item key={'substation_' + index}>
                <Nav.Link eventKey={index}>
                  <span className='nav-link-span'>
                    <span onClick={() => setActiveTab(index.toString())}>
                      {substation.InitStationName ||
                        i18n._('substationTabs.InitStationName', {
                          index: index + 1
                        })}
                    </span>
                    {!isChild && index > 0 && (
                      <FontAwesomeIcon
                        icon='trash-alt'
                        onClick={() =>
                          openConfirmModal(
                            i18n._('description.deleteSubstation.title'),
                            i18n._('description.deleteSubstation.body'),
                            'danger',
                            async () => await handleDeleteSubstation(index)
                          )
                        }
                      />
                    )}
                  </span>
                </Nav.Link>
              </Nav.Item>
            ))}
            {!isChild && (
              <Nav.Item>
                <Nav.Link onClick={handleNewSubstationClick}>
                  <FontAwesomeIcon icon='plus-square' />
                </Nav.Link>
              </Nav.Item>
            )}
          </Nav>
          <Tab.Content>
            {project.substations.map((substation, index) => (
              <Tab.Pane
                key={'substation_tab_' + index}
                eventKey={index.toString()}
              >
                <SubstationTab
                  substation={substation}
                  sstIndex={index}
                  descIndex={descIndex}
                  project={project}
                />
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
      </div>
    </div>
  );
  //#endregion
};

export default SubstationTabs;
