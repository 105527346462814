import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { Fragment, useLayoutEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import { TYPE } from '../../../../server/constants/ahs.constant';
import {
  GENERAL,
  LOCATION
} from '../../../../server/models/design/general.model';
import { getDefaultValue } from '../../utils/project.utils';
import Bloc from '../Bloc/Bloc';
import FormInput from '../Form/FormInput';
import FormSelect from '../Form/FormSelect';
import './GeneralForm.css';

const GeneralForm = ({ project, setProject, onSubmit, onNext }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [descriptionLength, setDescriptionLength] = useState(
    project.AhsDescription?.length || 0
  );
  const [nbErrors, setNbErrors] = useState(0);
  //#endregion

  //#region [effects]
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion

  //#region [methods]
  const handleDescriptionChange = (evt) => {
    setDescriptionLength(evt.target.value?.length || 0);
  };

  const handleParamChange = (key, value) => {
    const projectCopy = { ...project };
    projectCopy[key] = value;
    setProject(projectCopy);
  };

  const handleFileChange = (evt) => {
    if (!evt.target.files || !evt.target.files[0]) return;
    const img = evt.target.files[0];
    const projectCopy = { ...project };
    projectCopy.imgFile = img;
    projectCopy.ahsImageFileName = img.name;
    setProject(projectCopy);
  };

  const addError = () => setNbErrors((prevErrors) => prevErrors + 1);
  const removeError = () => setNbErrors((prevErrors) => prevErrors - 1);
  //#endregion

  //#region [render]
  const {
    AhsName,
    AhsDescription,
    AhsType,
    InitBuildingSurface,
    AhsMainUsage,
    AhsAddress1,
    AhsZipCode,
    AhsCountry,
    AhsCity,
    InitLatitude,
    InitLongitude,
    InitAltitude
  } = project;
  return (
    <div className='general-form'>
      <Bloc title={i18n._('general.general')}>
        <FormInput
          label={i18n._('general.AhsName')}
          value={AhsName}
          onBlur={(value) => handleParamChange('AhsName', value)}
          param={GENERAL.AhsName}
          addError={addError}
          removeError={removeError}
        />
        <Form.Group>
          <Form.Label className='form-input-label'>
            {i18n._('general.AhsDescription')}
          </Form.Label>
          <Form.Control
            rows={3}
            as='textarea'
            defaultValue={AhsDescription}
            onBlur={(evt) =>
              handleParamChange('AhsDescription', evt.target.value)
            }
            maxLength={GENERAL.AhsDescription.maxLength}
            onChange={handleDescriptionChange}
          />
        </Form.Group>
        <span className='general-remaining'>
          {i18n._('remainingCharacters', {
            remaining: GENERAL.AhsDescription.maxLength - descriptionLength
          })}
        </span>
        <Form.Group>
          <Form.Label className='form-input-label'>
            {i18n._('general.image')}
          </Form.Label>
          <Form.Control
            type='file'
            accept='image/apng, image/avif, image/gif, image/jpeg, image/png, image/svg+xml, image/webp'
            onChange={handleFileChange}
          />
        </Form.Group>
        <Form.Label className='form-input-label'>
          {i18n._('general.AhsType')}
        </Form.Label>
        <div className='general-radio-btns'>
          <Form.Check
            name='ahsTypes'
            type='radio'
            label={i18n._('general.construction')}
            checked={AhsType === TYPE.CONSTRUCTION}
            value={TYPE.CONSTRUCTION}
            onChange={(evt) => handleParamChange('AhsType', evt.target.value)}
          />
          <Form.Check
            name='ahsTypes'
            type='radio'
            label={i18n._('general.renovation')}
            checked={AhsType === TYPE.RENOVATION}
            value={TYPE.RENOVATION}
            onChange={(evt) => handleParamChange('AhsType', evt.target.value)}
          />
        </div>
        <Row>
          <Col>
            <FormInput
              label={i18n._('general.InitBuildingSurface')}
              value={InitBuildingSurface}
              onBlur={(value) =>
                handleParamChange('InitBuildingSurface', value)
              }
              param={GENERAL.InitBuildingSurface}
              addError={addError}
              removeError={removeError}
              unit
            />
          </Col>
          <Col>
            <FormSelect
              label={i18n._('general.AhsMainUsage')}
              value={AhsMainUsage}
              onChange={(value) => handleParamChange('AhsMainUsage', value)}
              param={GENERAL.AhsMainUsage}
            />
          </Col>
        </Row>
      </Bloc>
      <Bloc title={i18n._('general.projectLocation')}>
        <FormInput
          label={i18n._('general.AhsAddress1')}
          value={AhsAddress1}
          onBlur={(value) => handleParamChange('AhsAddress1', value)}
          param={LOCATION.AhsAddress1}
          addError={addError}
          removeError={removeError}
        />
        <Row>
          <Col>
            <FormInput
              label={i18n._('general.AhsZipCode')}
              value={AhsZipCode}
              onBlur={(value) => handleParamChange('AhsZipCode', value)}
              param={LOCATION.AhsZipCode}
              addError={addError}
              removeError={removeError}
            />
          </Col>
          <Col>
            <FormInput
              label={i18n._('general.AhsCity')}
              value={AhsCity}
              onBlur={(value) => handleParamChange('AhsCity', value)}
              param={LOCATION.AhsCity}
              addError={addError}
              removeError={removeError}
            />
          </Col>
        </Row>
        <FormSelect
          label={i18n._('general.AhsCountry')}
          param={LOCATION.AhsCountry}
          value={AhsCountry || ''}
          onChange={(value) => handleParamChange('AhsCountry', value)}
        />
        <Row>
          <Col>
            <FormInput
              label={i18n._('general.InitLatitude')}
              value={InitLatitude}
              onBlur={(value) => handleParamChange('InitLatitude', value)}
              param={LOCATION.InitLatitude}
              addError={addError}
              removeError={removeError}
            />
          </Col>
          <Col>
            <FormInput
              label={i18n._('general.InitLongitude')}
              value={InitLongitude}
              onBlur={(value) => handleParamChange('InitLongitude', value)}
              param={LOCATION.InitLongitude}
              addError={addError}
              removeError={removeError}
            />
          </Col>
        </Row>
        <Row>
          <Col xs='6'>
            <FormInput
              label={i18n._('general.InitAltitude')}
              value={InitAltitude}
              onBlur={(value) => handleParamChange('InitAltitude', value)}
              param={LOCATION.InitAltitude}
              addError={addError}
              removeError={removeError}
              bottomText={getDefaultValue(i18n, LOCATION.InitAltitude)}
              unit
            />
          </Col>
        </Row>
      </Bloc>
      <div className='general-submit-btns'>
        {onNext ? (
          <Button
            variant='secondary'
            className='general-next-btn'
            disabled={nbErrors > 0}
            onClick={onNext}
          >
            {i18n._('next')} <FontAwesomeIcon icon='arrow-right' />
          </Button>
        ) : (
          <Fragment>
            <Button
              variant='primary'
              onClick={onSubmit}
              disabled={nbErrors > 0}
            >
              {i18n._('save')}
            </Button>
          </Fragment>
        )}
      </div>
    </div>
  );
  //#endregion
};

export default GeneralForm;
