// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.select-btn {
    border-radius: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 5px;
    padding: 5px 10px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-size: 14px;
    -webkit-box-shadow: 3px 4px 4px 0px #00000040;
            box-shadow: 3px 4px 4px 0px #00000040;
}

.select-btn-active {
    color: white;
}

.select-btn-inactive {
    background-color: white;
    color: var(--medium-grey);
}`, "",{"version":3,"sources":["webpack://./src/components/SelectBtn/SelectBtn.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,QAAQ;IACR,iBAAiB;IACjB,yBAAyB;QACrB,sBAAsB;YAClB,mBAAmB;IAC3B,eAAe;IACf,6CAA6C;YACrC,qCAAqC;AACjD;;AAEA;IACI,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,yBAAyB;AAC7B","sourcesContent":[".select-btn {\n    border-radius: 20px;\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    gap: 5px;\n    padding: 5px 10px;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    font-size: 14px;\n    -webkit-box-shadow: 3px 4px 4px 0px #00000040;\n            box-shadow: 3px 4px 4px 0px #00000040;\n}\n\n.select-btn-active {\n    color: white;\n}\n\n.select-btn-inactive {\n    background-color: white;\n    color: var(--medium-grey);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
