import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  GOAL,
  OPTIONS_GOAL
} from '../../../../../../../../../../server/models/design/constraint.model';
import { Counter } from '../../../../../../../../components/Counter/Counter';
import FormInput from '../../../../../../../../components/Form/FormInput';
import OptimizationContext from '../../../../../../../../contexts/OptimizationContext';
import { OptiSection } from '../../../../OptiSection/OptiSection';
import './OptiGoalsSection.css';

const OptiGoalsSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const {
    calculationData,
    setCalculationData,
    setConstraint,
    addError,
    removeError
  } = useContext(OptimizationContext);
  //#endregion

  //#region [states]
  const [selectedParam, setSelectedParam] = useState(GOAL.InitConstraintNone);
  //#endregion

  //#region [effects]
  useEffect(() => {
    if (!calculationData.constraints.inp.InitConstraintEnabled) {
      setSelectedParam(GOAL.InitConstraintNone);
    } else {
      const optionsParams = OPTIONS_GOAL.filter(
        (param) => param.key !== GOAL.InitConstraintNone.key
      );
      for (const param of optionsParams) {
        if (calculationData.constraints.inp[param.key]) {
          setSelectedParam(GOAL[param.key]);
          break;
        }
      }
    }
  }, [calculationData.resultId]);
  //#endregion

  //#region [methods]
  const handleSelectChange = (evt) => {
    const newGoalKey = evt.target.value;
    setSelectedParam(GOAL[newGoalKey]);
    setCalculationData((data) => {
      const dataCopy = { ...data };
      dataCopy.constraints.inp.InitConstraintEnabled =
        newGoalKey !== GOAL.InitConstraintNone.key;
      dataCopy.constraints.inp.InitConstraintReference =
        GOAL.InitConstraintReference.default;
      dataCopy.constraints.inp.InitConstraintNbOptim =
        GOAL.InitConstraintNbOptim.default;

      const optionsParams = OPTIONS_GOAL.filter(
        (param) => param.key !== GOAL.InitConstraintNone.key
      );
      optionsParams.forEach((param) => {
        dataCopy.constraints.inp[param.key] = param.key !== newGoalKey ? 0 : 10;
      });

      return dataCopy;
    });
  };
  //#endregion

  //#region [render]
  const { InitConstraintReference, InitConstraintNbOptim } =
    calculationData.constraints.inp;
  const selectedParamValue = calculationData.constraints.inp[selectedParam.key];
  return (
    <OptiSection title={i18n._('compute.opti.goals')}>
      <Row>
        <Col xs={8}>
          <Form.Select onChange={handleSelectChange} value={selectedParam.key}>
            {Object.values(OPTIONS_GOAL).map((param, index) => (
              <option
                value={param.key}
                key={'opti_goals_select_' + param.key + '_' + index}
              >
                {i18n._(`compute.opti.goals.${param.key}`)}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>
      {selectedParam.key !== GOAL.InitConstraintNone.key &&
        selectedParam.ref && (
          <Row>
            <Col className='opti-inputs-label'>
              {i18n._('compute.opti.goals.ref', {
                unit: i18n._(`unit.${selectedParam.unit}`)
              })}
            </Col>
            <Col>
              <FormInput
                value={InitConstraintReference}
                param={GOAL.InitConstraintReference}
                onBlur={(value) =>
                  setConstraint(GOAL.InitConstraintReference, +value)
                }
                addError={addError}
                removeError={removeError}
              />
            </Col>
            <Col>
              <span className='opti-goals-section-caption'>
                {i18n._('compute.opti.goals.refCaption')}
              </span>
            </Col>
          </Row>
        )}
      {selectedParam.key !== GOAL.InitConstraintNone.key && (
        <Row className='opti-goals-section-row'>
          <Col className='opti-inputs-label'>
            {i18n._('compute.opti.goals.percentGoal')}
          </Col>
          <Col>
            <FormInput
              value={selectedParamValue}
              param={selectedParam}
              onBlur={(value) => setConstraint(selectedParam, +value)}
              addError={addError}
              removeError={removeError}
            />
          </Col>
          <Col>
            <div className='opti-goals-counter'>
              <Counter
                value={InitConstraintNbOptim}
                min={1}
                max={5}
                onChange={(value) =>
                  setConstraint(GOAL.InitConstraintNbOptim, value)
                }
              />
              <span className='opti-goals-section-caption'>
                {selectedParam.key === GOAL.InitConstraintGasPart.key
                  ? i18n._('compute.opti.goals.goalCaption.gas')
                  : i18n._('compute.opti.goals.goalCaption')}
              </span>
            </div>
          </Col>
        </Row>
      )}
    </OptiSection>
  );
  //#endregion
};

export default OptiGoalsSection;
