const COMPUTE_TYPE = Object.freeze({
  OPTI: 1,
  SIMU: 2
});

const INIT_OBJECTIVE = Object.freeze({
  CAPEX_OPEX: 'CAPEX+OPEX',
  TRI: 'TRI'
});

const REFERENCE_SOLUTION = Object.freeze({
  GAS_CHILLER: 'Gas',
  HPA: 'Hpa'
});

const CONSTRAINT_KEY = Object.freeze({
  BTES_LENGTH: 'BtesLength',
  BTES_PROBE_DEPTH: 'BtesProbeDepth',
  BTES_PROBE_DISTANCE: 'BtesProbeDistance',
  BTES_FINAL_TEMP: 'T2final_Btes',
  HPG_HEAT_BTES_POWER_MAX: 'HPGHeatBtesPowerMax',
  HPG_COLD_BTES_POWER_MAX: 'HPGColdBtesPowerMax',
  HPA_POWER_MAX: 'HPAPowerMax',
  SOLAR_THERMAL_SURFACE: 'SolarThermalSurface',
  KILOWATT_CRETE_PV: 'KiloWattCretePV',
  QITES_MAX: 'QItesMax'
});

const CONSTRAINT_SST_KEY = Object.freeze({
  HPG_POWER_MAX: 'HPGPowerMaxPerSST'
});

const NB_ITERATIONS = [1000, 2500];
const DEFAULT_CONFIGURABLE = 3000;

module.exports = {
  COMPUTE_TYPE,
  CONSTRAINT_KEY,
  CONSTRAINT_SST_KEY,
  DEFAULT_CONFIGURABLE,
  INIT_OBJECTIVE,
  NB_ITERATIONS,
  REFERENCE_SOLUTION
};
