const OTHER_PER_SUB_KEYS = Object.freeze([
  'MaxLossPressureCond',
  'MaxFlowrateCond',
  'MaxLossPressureEvap',
  'MaxFlowrateEvap',
  'MaxLossPressureInj',
  'MaxFlowrateInj',
  'MaxLossPressureLoop',
  'MaxFlowrateLoop',
  'MaxLossPressureCondTfp',
  'MaxFlowrateCondTfp',
  'MaxLossPressureEvapTfp',
  'MaxFlowrateEvapTfp',
  'MaxLossPressureCondHpg',
  'MaxFlowrateCondHpg',
  'MaxLossPressureEvapHpg',
  'MaxFlowrateEvapHpg'
]);

module.exports = { OTHER_PER_SUB_KEYS };
