import React from 'react';
import { Breadcrumb as RBBreadcrumb } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { isArrNullOrEmpty } from '../../utils/data.utils';
import './Breadcrumb.css';

const Breadcrumb = ({ links, current }) => {
  //#region [render]
  return (
    <RBBreadcrumb>
      {!isArrNullOrEmpty(links) &&
        links.map((link, index) => (
          <LinkContainer
            to={link.to}
            state={link.state}
            key={'breadcrumbItem_' + index}
          >
            <RBBreadcrumb.Item>{link.name}</RBBreadcrumb.Item>
          </LinkContainer>
        ))}
      <RBBreadcrumb.Item active>{current}</RBBreadcrumb.Item>
    </RBBreadcrumb>
  );
  //#endregion
};

export default Breadcrumb;
