import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import CompareResultsTable from '../../../../../../components/CompareResultsTable/CompareResultsTable';
import SectionTitle from '../../../../../../components/SectionTitle';
import ResultContext from '../../../../../../contexts/ResultContext';
import { capexBySstTemplates } from '../templates';

const CapexBySSTTable = ({ substation }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { comparedResults } = useContext(ResultContext);
  //#endregion

  //#region [memos]
  const CBSSTTemplates = useMemo(() => {
    return capexBySstTemplates(i18n, comparedResults, substation);
  }, [comparedResults, substation]);
  //#endregion

  //#region [render]
  return (
    <div>
      <SectionTitle
        level={2}
        title={i18n._('results.economicAnalysis.capexBySst', {
          sst: substation
        })}
      />
      {CBSSTTemplates.map((template, index) => (
        <CompareResultsTable
          template={template}
          key={'economicAnalysis_capexBySst_template_' + index}
          level={3}
        />
      ))}
    </div>
  );
  //#endregion
};

export default CapexBySSTTable;
