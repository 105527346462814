const TAG = Object.freeze({
  FEASIBILITY: {
    key: 'feasibility',
    value: 1 // 2 puissance 0
  },
  PROJECT: {
    key: 'project',
    value: 2 // 2 puissance 1
  },
  CONTRACT_COMMITMENT: {
    key: 'contractCommitment',
    value: 4 // 2 puissance 2
  }
});

module.exports = { TAG };
