const NB_HOURS_PER_YEAR = 8760;

const NB_MILLISECONDS_PER_HOUR = 3600000;

const RESULTS_DETAILS_EMPTY_ARRAY_ONE_YEAR = Object.freeze(
  new Array(NB_HOURS_PER_YEAR).fill(0)
);

const DAYS_BY_MONTH = Object.freeze({
  january: 31,
  february: 28,
  march: 31,
  april: 30,
  may: 31,
  june: 30,
  july: 31,
  august: 31,
  september: 30,
  october: 31,
  november: 30,
  december: 31
});

const MONTHS = Object.keys(DAYS_BY_MONTH);

const HOURS_BY_MONTH = Object.entries(DAYS_BY_MONTH).reduce(
  (acc, [month, nbDays]) => {
    acc[month] = nbDays * 24;
    return acc;
  },
  {}
);

module.exports = {
  MONTHS,
  DAYS_BY_MONTH,
  HOURS_BY_MONTH,
  NB_HOURS_PER_YEAR,
  NB_MILLISECONDS_PER_HOUR,
  RESULTS_DETAILS_EMPTY_ARRAY_ONE_YEAR
};
