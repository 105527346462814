import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { forwardRef, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import './PwdInput.css';

const PwdInput = forwardRef(({ label, placeholder, onChange }, ref) => {
  //#region [states]
  const [pwdVisible, setPwdVisible] = useState(false);
  //#endregion

  //#region [render]
  return (
    <Form.Group className='pwd-input'>
      <Form.Label className='form-input-label'>{label}</Form.Label>
      <InputGroup>
        <Form.Control
          id='confirmPwd'
          name='confirmPwd'
          placeholder={placeholder}
          type={pwdVisible ? 'text' : 'password'}
          onChange={onChange}
          ref={ref}
        />
        <InputGroup.Text onClick={() => setPwdVisible(!pwdVisible)}>
          <FontAwesomeIcon icon={pwdVisible ? 'eye-slash' : 'eye'} />
        </InputGroup.Text>
      </InputGroup>
    </Form.Group>
  );
  //#endregion
});

export default PwdInput;
