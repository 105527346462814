// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.energyProdPieChart {
    overflow-x: auto;
}

.energyProdPieChart-group {
    margin-bottom: 10px;
}

.energyProdPieChart-group>label {
    display: block;
    margin-bottom: 5px;
    font-weight: 500;
}

.energyProdPieChart-group .form-check-label {
    font-size: 15px;
}`, "",{"version":3,"sources":["webpack://./src/pages/design/result/sections/energyCarbon/components/heatpumps/EnergyProducedDistributionPieChart/EnergyProducedDistributionPieChart.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;AACpB;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,cAAc;IACd,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".energyProdPieChart {\n    overflow-x: auto;\n}\n\n.energyProdPieChart-group {\n    margin-bottom: 10px;\n}\n\n.energyProdPieChart-group>label {\n    display: block;\n    margin-bottom: 5px;\n    font-weight: 500;\n}\n\n.energyProdPieChart-group .form-check-label {\n    font-size: 15px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
