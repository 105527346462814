import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useState } from 'react';
import { Alert, Button, Col, Form, Row, Spinner } from 'react-bootstrap';
import { useNavigate, useParams } from 'react-router-dom';
import { EMPTY_USER, PARAM } from '../../../../server/models/user.model';
import { createUser, isEmailAvailable } from '../../api/user.api';
import Bloc from '../../components/Bloc/Bloc';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FormInput from '../../components/Form/FormInput';
import FormSelect from '../../components/Form/FormSelect';
import OpaqueLayer from '../../components/OpaqueLayer/OpaqueLayer';
import CompanyContext from '../../contexts/CompanyContext';
import PopupContext from '../../contexts/PopupContext';
import './NewUserPage.css';

const NewUserPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  const { companyId } = useParams();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  const { openErrorToast, openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [emailError, setEmailError] = useState(false);
  const [newUser, setNewUser] = useState(EMPTY_USER);
  const [nbErrors, setNbErrors] = useState(0);
  const [isCreating, setIsCreating] = useState(false);
  //#endregion

  //#region [methods]
  const addError = () => setNbErrors((prevErrors) => prevErrors + 1);
  const removeError = () => setNbErrors((prevErrors) => prevErrors - 1);

  const handleParamChange = (key, value) => {
    setNewUser((newUser) => ({ ...newUser, [key]: value }));
  };

  const handleEmailChange = async (value) => {
    try {
      const { available } = await isEmailAvailable(value);
      if (!available) {
        if (!emailError) addError();
        setEmailError(true);
      } else {
        if (emailError) {
          removeError();
          setEmailError(false);
        }
        handleParamChange('UserEmail', value);
      }
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleFormSubmit = async (evt) => {
    try {
      setIsCreating(true);
      evt.preventDefault();
      await createUser({
        ...newUser,
        CoName: selectedCompany.name,
        UserCoID: selectedCompany.id
      });
      setIsCreating(true);
      navigate(`/company/${companyId}/users`);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
      setIsCreating(true);
    }
  };
  //#endregion

  //#region [render]
  return (
    <div className='new-user'>
      <Breadcrumb
        links={[
          {
            to: `/company/${companyId}/users`,
            name: i18n._('breadcrumb.company', {
              company: selectedCompany.name
            })
          }
        ]}
        current={i18n._('users.new')}
      />
      <Form onSubmit={handleFormSubmit}>
        <OpaqueLayer visible={isCreating}>
          <Alert animation='border' variant='primary'>
            {i18n._('saving')}
            <Spinner variant='light' />
          </Alert>
        </OpaqueLayer>
        <Bloc>
          <Row>
            <Col>
              <FormInput
                param={PARAM.UserFirstName}
                label={i18n._('users.new.firstName')}
                value={newUser.UserFirstName}
                onBlur={(value) => handleParamChange('UserFirstName', value)}
                addError={addError}
                removeError={removeError}
              />
            </Col>
            <Col>
              <FormInput
                param={PARAM.UserLastName}
                label={i18n._('users.new.lastName')}
                value={newUser.UserLastName}
                onBlur={(value) => handleParamChange('UserLastName', value)}
                addError={addError}
                removeError={removeError}
              />
            </Col>
          </Row>
          <Row>
            <Col>
              <FormInput
                param={PARAM.UserEmail}
                label={i18n._('users.new.email')}
                value={newUser.UserEmail}
                onBlur={(value) => handleEmailChange(value)}
                addError={addError}
                removeError={removeError}
                className={emailError ? 'new-user-input-not-available' : ''}
              />
            </Col>
            <Col>
              <FormInput
                param={PARAM.UserPassword}
                label={i18n._('users.new.pwd')}
                value={newUser.UserPassword}
                onBlur={(value) => handleParamChange('UserPassword', value)}
                addError={addError}
                removeError={removeError}
                type='password'
                showInfoIcon
                onInfoClick={() =>
                  openInfoModal(
                    i18n._('users.new.pwdModal.title'),
                    i18n._('users.new.pwdModal.body')
                  )
                }
              />
            </Col>
          </Row>
          {emailError && (
            <Row>
              <Col xs='6'>
                <span className='new-user-not-available'>
                  <FontAwesomeIcon icon='xmark' />
                  {i18n._('users.new.notAvailable')}
                </span>
              </Col>
            </Row>
          )}
          <Row>
            <Col xs='6'>
              <FormInput
                param={PARAM.UserJobTitle}
                label={i18n._('users.new.job')}
                value={newUser.UserJobTitle}
                onBlur={(value) => handleParamChange('UserJobTitle', value)}
                addError={addError}
                removeError={removeError}
              />
            </Col>
          </Row>
          <Row>
            <Col xs='6'>
              <FormSelect
                label={i18n._('users.new.country')}
                param={PARAM.UserCountry}
                value={newUser.UserCountry}
                onChange={(value) => handleParamChange('AhsCountry', value)}
              />
            </Col>
          </Row>
          <Form.Check
            type='checkbox'
            label={i18n._('users.new.admin')}
            value={newUser.IS_SUPERADMIN}
            onChange={(evt) =>
              handleParamChange('IS_SUPERADMIN', evt.target.checked)
            }
          />
        </Bloc>
        <div className='users-submit-btns'>
          <Button type='submit' variant='primary' disabled={nbErrors > 0}>
            {i18n._('save')}
          </Button>
        </div>
      </Form>
    </div>
  );
  //#endregion
};

export default NewUserPage;
