import axios from '../conf/axios.conf';

//#region [GET]
export const fetchCompany = async (companyId) => {
  try {
    const response = await axios.get(`/company/${companyId}`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchProjects = async (companyId, active) => {
  try {
    const url = `/company/${companyId}/projects`;
    const response = await axios.get(url, { params: { active } });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchUsers = async (companyId) => {
  try {
    const url = `/company/${companyId}/users`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion
