const USE = Object.freeze({
  HEAT: 'heat',
  COLD: 'cold',
  HOTWATER: 'hotwater',
  INJECTION: 'injection',
  SUM: 'sum',
  GLOBAL: 'global'
});

module.exports = { USE };
