import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useState } from 'react';
import ResultContext from '../../contexts/ResultContext';
import { getTableValue } from '../../utils/compute.utils';
import SectionTitle from '../SectionTitle';
import './CompareResultsExpandableTable.css';

const CompareResultsExpandableTable = ({ template, level }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { comparedResults } = useContext(ResultContext);
  //#endregion

  //#region [states]
  const [unfolded, setUnfolded] = useState(false);
  //#endregion

  //#region [render]
  return template ? (
    <div>
      {template.title && <SectionTitle level={level} title={template.title} />}
      <table className='template-table results-table compare-results-table compare-expandable-table'>
        {comparedResults.length > 1 && (
          <thead>
            <tr>
              <th></th>
              {comparedResults.map((result, i) => (
                <th key={'th_result_' + i} title={result.ComputeName}>
                  <p className='result-table-title'>
                    {i18n._('result', { index: i + 1 })}
                  </p>
                  {result.Comment && (
                    <p className='result-table-details ellipsis'>
                      {result.Comment}
                    </p>
                  )}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          <tr className={comparedResults.length > 1 ? '' : 'table-tr'}>
            <td
              className='table-label cursor-pointer'
              onClick={() => setUnfolded(!unfolded)}
            >
              <span>
                <FontAwesomeIcon
                  icon={unfolded ? 'caret-square-down' : 'caret-square-right'}
                />
                {template.fixedRow.label}
              </span>
            </td>
            {template.fixedRow.values.map((value, index) => {
              const tableValue = getTableValue(
                i18n,
                value,
                template.fixedRow.unit,
                template.fixedRow.translateValue,
                template.fixedRow.precision
              );
              return (
                <td
                  key={'fixed_td_' + index}
                  title={tableValue}
                  className={
                    comparedResults.length > 1
                      ? 'compare-table-value'
                      : 'table-value'
                  }
                >
                  {tableValue}
                </td>
              );
            })}
          </tr>
          {template.expandableRows.map((row, rowIndex) => (
            <tr
              key={'expandable_tr_result_' + rowIndex}
              className={unfolded ? '' : 'hidden'}
            >
              <td className='table-label'>{row.label}</td>
              {row.values.map((value, colIndex) => {
                const tableValue = getTableValue(
                  i18n,
                  value,
                  row.unit,
                  row.translateValue,
                  row.precision
                );
                return (
                  <td
                    key={'td_result_' + rowIndex + '_' + colIndex}
                    title={tableValue}
                    className={
                      comparedResults.length > 1
                        ? 'compare-table-value'
                        : 'table-value'
                    }
                  >
                    {tableValue}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;
  //#endregion
};

export default CompareResultsExpandableTable;
