// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.accenta-ref-table th:nth-of-type(1) {
    background-color: transparent;
}

.accenta-ref-table th:nth-child(2n+2) {
    background-color: var(--dark-grey);
}

.accenta-ref-table th:nth-child(2n+3) {
    background-color: var(--primary-color);
}

.accenta-ref-table th:nth-child(4n+5) {
    background-color: #838996;
}

.accenta-ref-table th:not(:nth-of-type(1)):not(:nth-of-type(2)) {
    border-left: 1px solid var(--primary-color);
}

.ref-td,
.accenta-td {
    text-align: right;
    border-left: 1px solid var(--primary-color);
    padding: 5px;
}

.ref-td p,
.accenta-td p {
    margin: 0;
}

.ref-td p:nth-of-type(2),
.accenta-td p:nth-of-type(2) {
    font-weight: 600;
    font-size: 14px;
    line-height: 15px;
}

.ref-td p:nth-of-type(2) {
    visibility: hidden;
}

.accenta-ref-table tbody tr:nth-of-type(2n + 1) {
    background-color: var(--light-grey) !important;
}

.accenta-ref-table tbody tr:nth-of-type(2n) {
    background-color: white !important;
}

.accenta-ref-table-tooltip {
    white-space: pre-line;
}`, "",{"version":3,"sources":["webpack://./src/components/AccentaVsRefTable/AccentaVsRefTable.css"],"names":[],"mappings":"AAAA;IACI,6BAA6B;AACjC;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;;IAEI,iBAAiB;IACjB,2CAA2C;IAC3C,YAAY;AAChB;;AAEA;;IAEI,SAAS;AACb;;AAEA;;IAEI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;AACrB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,8CAA8C;AAClD;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".accenta-ref-table th:nth-of-type(1) {\n    background-color: transparent;\n}\n\n.accenta-ref-table th:nth-child(2n+2) {\n    background-color: var(--dark-grey);\n}\n\n.accenta-ref-table th:nth-child(2n+3) {\n    background-color: var(--primary-color);\n}\n\n.accenta-ref-table th:nth-child(4n+5) {\n    background-color: #838996;\n}\n\n.accenta-ref-table th:not(:nth-of-type(1)):not(:nth-of-type(2)) {\n    border-left: 1px solid var(--primary-color);\n}\n\n.ref-td,\n.accenta-td {\n    text-align: right;\n    border-left: 1px solid var(--primary-color);\n    padding: 5px;\n}\n\n.ref-td p,\n.accenta-td p {\n    margin: 0;\n}\n\n.ref-td p:nth-of-type(2),\n.accenta-td p:nth-of-type(2) {\n    font-weight: 600;\n    font-size: 14px;\n    line-height: 15px;\n}\n\n.ref-td p:nth-of-type(2) {\n    visibility: hidden;\n}\n\n.accenta-ref-table tbody tr:nth-of-type(2n + 1) {\n    background-color: var(--light-grey) !important;\n}\n\n.accenta-ref-table tbody tr:nth-of-type(2n) {\n    background-color: white !important;\n}\n\n.accenta-ref-table-tooltip {\n    white-space: pre-line;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
