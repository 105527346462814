import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ITES } from '../../../../../../server/models/design/performance.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import FormSelect from '../../../../components/Form/FormSelect';
import PerformancePageContext from '../../../../contexts/PerformancePageContext';
import { getDefaultValue } from '../../../../utils/project.utils';

const ColdStorageBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleOtherValueChange } =
    useContext(PerformancePageContext);
  //#endregion

  //#region [render]
  const {
    InitItesModel,
    InitItesPumpConsumptionPercentage,
    InitItesChargingTemperatureIn,
    InitItesChargingTemperatureOut,
    InitItesDischargingTemperatureIn,
    InitItesDischargingTemperatureOut
  } = tempProject.otherValues;

  return (
    <Bloc title={i18n._('performance.coldStorage')}>
      <FormSelect
        label={i18n._('performance.ites')}
        value={InitItesModel}
        param={ITES.InitItesModel}
        onChange={(value) => {
          handleOtherValueChange('InitItesModel', value);
        }}
      />
      <Row>
        <Col xs={6}>
          <FormInput
            label={i18n._('performance.InitItesPumpConsumptionPercentage')}
            value={InitItesPumpConsumptionPercentage}
            param={ITES.InitItesPumpConsumptionPercentage}
            onBlur={(value) =>
              handleOtherValueChange('InitItesPumpConsumptionPercentage', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(
              i18n,
              ITES.InitItesPumpConsumptionPercentage
            )}
            unit
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('performance.InitItesChargingTemperatureIn')}
            value={InitItesChargingTemperatureIn}
            param={ITES.InitItesChargingTemperatureIn}
            onBlur={(value) =>
              handleOtherValueChange('InitItesChargingTemperatureIn', value)
            }
            addError={addError}
            removeError={removeError}
            topText={i18n._('performance.topInitItesChargingTemperatureIn')}
            bottomText={getDefaultValue(
              i18n,
              ITES.InitItesChargingTemperatureIn
            )}
            unit
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('performance.InitItesChargingTemperatureOut')}
            value={InitItesChargingTemperatureOut}
            param={ITES.InitItesChargingTemperatureOut}
            onBlur={(value) =>
              handleOtherValueChange('InitItesChargingTemperatureOut', value)
            }
            addError={addError}
            removeError={removeError}
            topText={i18n._('performance.topInitItesChargingTemperatureOut')}
            bottomText={getDefaultValue(
              i18n,
              ITES.InitItesChargingTemperatureOut
            )}
            unit
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('performance.InitItesDischargingTemperatureIn')}
            value={InitItesDischargingTemperatureIn}
            param={ITES.InitItesDischargingTemperatureIn}
            onBlur={(value) =>
              handleOtherValueChange('InitItesDischargingTemperatureIn', value)
            }
            addError={addError}
            removeError={removeError}
            topText={i18n._('performance.topInitItesDischargingTemperatureIn')}
            bottomText={getDefaultValue(
              i18n,
              ITES.InitItesDischargingTemperatureIn
            )}
            unit
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('performance.InitItesDischargingTemperatureOut')}
            value={InitItesDischargingTemperatureOut}
            param={ITES.InitItesDischargingTemperatureOut}
            onBlur={(value) =>
              handleOtherValueChange('InitItesDischargingTemperatureOut', value)
            }
            addError={addError}
            removeError={removeError}
            topText={i18n._('performance.topInitItesDischargingTemperatureOut')}
            bottomText={getDefaultValue(
              i18n,
              ITES.InitItesDischargingTemperatureOut
            )}
            unit
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default ColdStorageBloc;
