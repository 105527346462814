const OPEX_TYPES = Object.freeze([
  'OpexElecSupply',
  'OpexPVSaved',
  'OpexPVSoldToProject',
  'OpexPVSoldToGrid',
  'OpexElecTransportAndTaxes',
  'OpexGasSupply',
  'OpexGasCarbonTax',
  'OpexOperationAndMaintenance',
  'OpexGaPerf',
  'OpexOther',
  'OpexPerYear'
]);

module.exports = { OPEX_TYPES };
