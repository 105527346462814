import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useState } from 'react';
import {
  COMMON_CONSTRAINT,
  OPTI,
  getBalanceDuration
} from '../../../../../../../../../../server/models/design/constraint.model';
import FormInput from '../../../../../../../../components/Form/FormInput';
import FormSelect from '../../../../../../../../components/Form/FormSelect';
import OptimizationContext from '../../../../../../../../contexts/OptimizationContext';
import OptiDescriptionsSelect from '../../../../OptiDescriptionsSelect';
import { OptiSection } from '../../../../OptiSection/OptiSection';

const OptiGeneralSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { calculationData, setConstraint, addError, removeError } =
    useContext(OptimizationContext);
  //#endregion

  //#region [states]
  const [balanceDuration, setBalanceDuration] = useState(
    getBalanceDuration(calculationData.constraints.inp.InitYearsSimulations)
  );
  //#endregion

  //#region [effects]
  useEffect(() => {
    const { InitYearsSimulations } = calculationData.constraints.inp;
    if (InitYearsSimulations > 20) {
      setBalanceDuration(getBalanceDuration());
    } else {
      setBalanceDuration(getBalanceDuration(InitYearsSimulations));
    }
  }, [calculationData.constraints.inp.InitYearsSimulations]);
  //#endregion

  //#region []

  //#region [render]
  const {
    InitYearsSimulations,
    InitBalanceDuration,
    InitObjective,
    InitReferenceSolution
  } = calculationData.constraints.inp;

  return (
    <OptiSection title={i18n._('compute.opti.general')}>
      <FormInput
        label={i18n._('compute.InitYearsSimulations')}
        value={InitYearsSimulations}
        param={COMMON_CONSTRAINT.InitYearsSimulations}
        onBlur={(value) => {
          setConstraint(COMMON_CONSTRAINT.InitYearsSimulations, value);
        }}
        addError={addError}
        removeError={removeError}
        labelLeft
        className='compute-input'
      />
      <FormInput
        label={i18n._('compute.InitBalanceDuration')}
        value={InitBalanceDuration}
        param={balanceDuration}
        onBlur={(value) => {
          setConstraint(balanceDuration, value);
        }}
        addError={addError}
        removeError={removeError}
        labelLeft
        className='compute-input'
      />
      <FormSelect
        label={i18n._('compute.InitObjective')}
        value={InitObjective}
        param={OPTI.InitObjective}
        onChange={(value) => {
          setConstraint(OPTI.InitObjective, value);
        }}
        labelLeft
        className='compute-input'
      />
      <FormSelect
        label={i18n._('compute.InitReferenceSolution')}
        value={InitReferenceSolution}
        param={OPTI.InitReferenceSolution}
        onChange={(value) => {
          setConstraint(OPTI.InitReferenceSolution, value);
        }}
        labelLeft
        className='compute-input'
      />
      <OptiDescriptionsSelect />
    </OptiSection>
  );
  //#endregion
};

export default OptiGeneralSection;
