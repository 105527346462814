import { FILE_TYPE } from '../../../server/constants/fileType.constant';
import axios from '../conf/axios.conf';

//#region [POST]
export const uploadProjectImgFile = async (projectId, imgFile) => {
  try {
    const formData = new FormData();
    formData.append(
      'IMAGE_FILE',
      imgFile,
      JSON.stringify({
        AhsID: projectId,
        // if filename contains %, https://stackoverflow.com/a/54310080/6400617
        imageName: imgFile.name.replace(/%(?![0-9][0-9a-fA-F]+)/g, '%25')
      })
    );
    const url = `/file/projectimg`;
    await axios.post(url, formData);
  } catch (err) {
    throw err;
  }
};

export const uploadWeatherFiles = async (dataToUpload) => {
  try {
    const formData = new FormData();
    Object.entries(dataToUpload).forEach(([projectId, file]) => {
      formData.append(
        FILE_TYPE.WEATHER,
        file,
        JSON.stringify({
          AhsID: projectId,
          // if filename contains %, https://stackoverflow.com/a/54310080/6400617
          ahsWeatherFileOriginalName: file.name.replace(
            /%(?![0-9][0-9a-fA-F]+)/g,
            '%25'
          )
        })
      );
    });

    const url = `/file/weatherfiles`;
    const response = await axios.post(url, formData);
    return response;
  } catch (err) {
    throw err;
  }
};

export const uploadSTDFiles = async (dataToUpload) => {
  try {
    const formData = new FormData();
    Object.entries(dataToUpload).forEach(([stationId, data]) => {
      formData.append(
        FILE_TYPE.STATION_NEED,
        data.file,
        JSON.stringify({
          InitStationID: stationId,
          // if filename contains %, https://stackoverflow.com/a/54310080/6400617
          filesStdOriginalName: data.filename.replace(
            /%(?![0-9][0-9a-fA-F]+)/g,
            '%25'
          )
        })
      );
      formData.append('summaryData', JSON.stringify(data.summaryData));
    });

    const url = `/file/stdfiles`;
    const response = await axios.post(url, formData);
    return response;
  } catch (err) {
    throw err;
  }
};
//#endregion
