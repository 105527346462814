import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Badge, Button, Card, Collapse } from 'react-bootstrap';
import { Link, NavLink, useLocation } from 'react-router-dom';
import { GENERAL } from '../../../../../../server/models/design/general.model';
import CompanyContext from '../../../../contexts/CompanyContext';
import ProjectContext from '../../../../contexts/ProjectContext';
import defaultProjectImg from '../../../../image/default_project.png';
import { formatValueWithUnit } from '../../../../utils/data.utils';
import './Header.css';

const Header = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const location = useLocation();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  const { project } = useContext(ProjectContext);
  //#endregion

  //#region [states]
  const [activeLink, setActiveLink] = useState();
  const [isOpen, setIsOpen] = useState(false);
  //#endregion

  //#region [effects]
  useEffect(() => {
    if (!location) return;
    if (location.pathname.includes('results') && activeLink !== 'results') {
      setActiveLink('results');
    } else if (
      location.pathname.includes('compute') &&
      activeLink !== 'compute'
    ) {
      setActiveLink('compute');
    }
  }, [activeLink, location]);
  //#endregion

  //#region [memos]
  const formattedAddress = useMemo(() => {
    const { AhsAddress1, AhsCity, AhsCountry, AhsZipCode } = project;
    return `${AhsAddress1}, ${AhsZipCode}, ${AhsCity}, ${AhsCountry}`;
  }, [project.AhsID]);

  const formattedSurface = useMemo(() => {
    const { InitBuildingSurface } = project;
    return formatValueWithUnit(
      i18n,
      InitBuildingSurface,
      GENERAL.InitBuildingSurface.unit
    );
  }, [project.AhsID]);
  //#endregion

  //#region [render]
  const imgSrc = project.ahsImageFileName
    ? `/api-v1/images/${encodeURI(project.ahsImageFileName)}`
    : defaultProjectImg;
  const isConfigPage = location.pathname.includes('configs');
  return (
    <div className='project-header'>
      <Card>
        <Card.Body>
          <div className='project-header-top'>
            <div className='project-header-top-left'>
              <Button
                aria-controls='project-header-details-wrapper'
                aria-expanded={isOpen}
                className='project-header-btn'
                onClick={() => setIsOpen(!isOpen)}
              >
                <img
                  className={`project-header-img ${
                    project.ahsImageFileName ? 'project-header-rounded-img' : ''
                  } `}
                  src={imgSrc}
                  alt='projectImg'
                />
                <span className='project-header-title'>{project.AhsName}</span>
              </Button>
              {!isConfigPage && (
                <nav className='nav-pills'>
                  <NavLink
                    to={`/company/${selectedCompany.id}/project/${project?.AhsID}/compute`}
                    className='nav-link'
                  >
                    {i18n._(`page.compute`)}
                  </NavLink>
                  <NavLink
                    to={`/company/${selectedCompany.id}/project/${project?.AhsID}/results`}
                    className='nav-link'
                  >
                    {i18n._(`page.results`)}
                  </NavLink>
                </nav>
              )}
            </div>
            <Link
              className='edit-link'
              to={`/company/${selectedCompany.id}/project/${project.AhsID}/general`}
              state={{ fromConfig: isConfigPage }}
            >
              <FontAwesomeIcon icon='pen-to-square' />
            </Link>
          </div>
          <Collapse in={isOpen}>
            <div id='project-header-details-wrapper'>
              <div className='project-header-details'>
                <span>{formattedAddress}</span>
                <Badge id='badge-surface' pill>
                  {formattedSurface}
                </Badge>
                <Badge id='badge-type' pill>
                  {project.AhsType}
                </Badge>
              </div>
            </div>
          </Collapse>
        </Card.Body>
      </Card>
    </div>
  );
  //#endregion
};

export default Header;
