import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import { NEED } from '../../../../../../../../server/constants/need.constant';
import CompareResultsCards from '../../../../../../components/CompareResultsCards/CompareResultsCards';
import CompareResultsExpandableTable from '../../../../../../components/CompareResultsExpandableTable/CompareResultsExpandableTable';
import CompareResultsTable from '../../../../../../components/CompareResultsTable/CompareResultsTable';
import ExpandableTable from '../../../../../../components/ExpandableTable/ExpandableTable';
import Section from '../../../../../../components/Section/Section';
import ResultContext from '../../../../../../contexts/ResultContext';
import PowerLoadCurveSst from '../components/PowerLoadCurve/PowerLoadCurveSst';
import {
  sstColdTemplate,
  sstHeatTemplate,
  sstHotwaterTemplate,
  stdTemplate
} from '../templates';
import SubstationNeedsChart from './SubstationNeedsChart';
import COPEERPerYearLinesChart from './heatpumps/COPEERPerYearLinesChart';
import COPEERPerYearTable from './heatpumps/COPEERPerYearTable';
import COPsDistributionTable from './heatpumps/COPsDistributionTable';
import EnergyConsumedDistributionTable from './heatpumps/EnergyConsumedDistributionTable';
import EnergyProducedDistributionPieChart from './heatpumps/EnergyProducedDistributionPieChart/EnergyProducedDistributionPieChart';
import EnergyProducedDistributionTable from './heatpumps/EnergyProducedDistributionTable';

const EnergyCarbonSubstationTable = ({ substation, sstIndex }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { comparedResults, loadDetails } = useContext(ResultContext);
  //#endregion

  //#region [memos]
  const STDTemplate = useMemo(() => {
    return stdTemplate(i18n, comparedResults, substation);
  }, [comparedResults, substation]);

  const SHTemplate = useMemo(() => {
    return sstHeatTemplate(i18n, comparedResults, substation);
  }, [comparedResults, substation]);

  const SCTemplate = useMemo(() => {
    return sstColdTemplate(i18n, comparedResults, substation);
  }, [comparedResults, substation]);

  const SHWTemplate = useMemo(() => {
    return sstHotwaterTemplate(i18n, comparedResults, substation);
  }, [comparedResults, substation]);
  //#endregion

  //#region [render]
  return (
    <Section
      level={2}
      title={i18n._('results.energyAndCarbon.sst', {
        index: (sstIndex + 1).toString(),
        substation
      })}
    >
      <CompareResultsExpandableTable level={3} template={STDTemplate} />
      <ExpandableTable>
        <CompareResultsCards
          title={i18n._('results.energyAndCarbon.sst.needsChart.title')}
          onOpen={loadDetails}
          element={SubstationNeedsChart}
          elementProps={{ substation }}
        />
      </ExpandableTable>
      <CompareResultsTable level={3} template={SHTemplate} />
      <ExpandableTable>
        <CompareResultsCards
          title={i18n._('results.energyAndCarbon.powerLoadCurve')}
          onOpen={loadDetails}
          element={PowerLoadCurveSst}
          elementProps={{ substation, need: NEED.HEAT, sorted: true }}
        />
        <CompareResultsCards
          title={i18n._('results.energyAndCarbon.yearNeedsCoverageCurve')}
          onOpen={loadDetails}
          element={PowerLoadCurveSst}
          elementProps={{ substation, need: NEED.HEAT }}
        />
      </ExpandableTable>
      <CompareResultsTable level={3} template={SCTemplate} />
      <ExpandableTable>
        <CompareResultsCards
          title={i18n._('results.energyAndCarbon.powerLoadCurve')}
          onOpen={loadDetails}
          element={PowerLoadCurveSst}
          elementProps={{ substation, need: NEED.COLD, sorted: true }}
        />
        <CompareResultsCards
          title={i18n._('results.energyAndCarbon.yearNeedsCoverageCurve')}
          onOpen={loadDetails}
          element={PowerLoadCurveSst}
          elementProps={{ substation, need: NEED.COLD }}
        />
      </ExpandableTable>
      <CompareResultsTable level={3} template={SHWTemplate} />
      <ExpandableTable>
        <CompareResultsCards
          title={i18n._('results.energyAndCarbon.powerLoadCurve')}
          onOpen={loadDetails}
          element={PowerLoadCurveSst}
          elementProps={{ substation, need: NEED.HOTWATER, sorted: true }}
        />
        <CompareResultsCards
          title={i18n._('results.energyAndCarbon.yearNeedsCoverageCurve')}
          onOpen={loadDetails}
          element={PowerLoadCurveSst}
          elementProps={{ substation, need: NEED.HOTWATER }}
        />
      </ExpandableTable>
      <ExpandableTable
        level={3}
        title={i18n._('results.energyAndCarbon.energyBalance')}
      >
        <CompareResultsCards
          title={i18n._(
            'results.energyAndCarbon.energyBalance.energyProducedDistributionPieChart.title'
          )}
          element={EnergyProducedDistributionPieChart}
          elementProps={{
            substation
          }}
        />
        <CompareResultsCards
          title={i18n._(
            'results.energyAndCarbon.energyBalance.productionsTable.title'
          )}
          element={EnergyProducedDistributionTable}
          elementProps={{
            substation
          }}
        />
        <CompareResultsCards
          title={i18n._(
            'results.energyAndCarbon.energyBalance.consumptionsTable.title'
          )}
          element={EnergyConsumedDistributionTable}
          elementProps={{
            substation
          }}
        />
        <CompareResultsCards
          title={i18n._(
            'results.energyAndCarbon.energyBalance.copsTable.title'
          )}
          element={COPsDistributionTable}
          elementProps={{
            substation
          }}
        />
        <CompareResultsCards
          title={i18n._(
            'results.energyAndCarbon.energyBalance.copEerPerYearTable.title'
          )}
          element={COPEERPerYearTable}
          elementProps={{
            substation
          }}
        />
        <CompareResultsCards
          title={i18n._(
            'results.energyAndCarbon.energyBalance.copEerPerYearLinesChart.title'
          )}
          element={COPEERPerYearLinesChart}
          elementProps={{
            substation
          }}
        />
      </ExpandableTable>
    </Section>
  );
  //#endregion
};

export default EnergyCarbonSubstationTable;
