import { cloneDeep } from 'lodash';
import { useContext, useMemo, useState } from 'react';
import {
  deleteProject,
  fetchChildCopy,
  fetchEmptyChild
} from '../../api/project.api';
import PopupContext from '../../contexts/PopupContext';
import { isNull } from '../../utils/data.utils';

const DEFAULT_ACTIVE_TAB = '0';

const useDescriptions = (project, setProject) => {
  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [activeTab, setActiveTab] = useState(DEFAULT_ACTIVE_TAB);
  const [nbErrorsByDesc, setNbErrorsByDesc] = useState(
    new Array(1 + project.children?.length || 0).fill(0)
  );
  //#endregion

  //#region [memos]
  const totalDescErrors = useMemo(() => {
    return nbErrorsByDesc.reduce((acc, val) => acc + val, 0);
  }, [nbErrorsByDesc]);
  //#endregion

  //#region [methods]
  const addErrorByDesc = (descIndex) => {
    setNbErrorsByDesc((previousErrors) => {
      previousErrors[descIndex]++;
      return [...previousErrors];
    });
  };

  const removeErrorsByDesc = (descIndex, nbErrors) => {
    setNbErrorsByDesc((previousErrors) => {
      previousErrors[descIndex] -= nbErrors;
      return [...previousErrors];
    });
  };

  const handleNewChildClick = async (descIndex = null) => {
    try {
      let newChild;
      if (isNull(descIndex)) {
        // description vierge : on duplique le projet parent et on reset les paramètres généraux
        newChild = await fetchEmptyChild(project.AhsID);
      } else {
        // on duplique la description d'index descIndex (0 = projet parent, autre = enfant)
        newChild =
          descIndex === 0
            ? cloneDeep(project)
            : cloneDeep(project.children[descIndex - 1]);
        newChild = await fetchChildCopy(newChild);
      }
      // on ajoute un nouvel élément au tableau qui gère les erreurs par description
      setNbErrorsByDesc((prevErrors) => [...prevErrors, 0]);

      // on ajoute la nouvelle description aux enfants du projet parent
      const projectCopy = cloneDeep(project);
      if (!projectCopy.children) projectCopy.children = [];
      projectCopy.children.push(newChild);
      setProject(projectCopy);

      // on set le nouvel onglet actif
      const newDescriptionIndex = projectCopy.children.length;
      setActiveTab(newDescriptionIndex.toString());
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleDeleteChildClick = async (childIndexToDelete) => {
    try {
      const projectCopy = cloneDeep(project);

      // on supprime côté serveur le projet enfant
      await deleteProject(project.children[childIndexToDelete].AhsID);

      // on supprime côté client le projet enfant
      projectCopy.children.splice(childIndexToDelete, 1);
      setProject(projectCopy);

      setActiveTab(DEFAULT_ACTIVE_TAB);

      // on supprime les erreurs liées à la description supprimée
      const errorsCopy = [...nbErrorsByDesc];
      errorsCopy.splice(childIndexToDelete + 1, 1);
      setNbErrorsByDesc(errorsCopy);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  return {
    activeTab,
    nbErrorsByDesc,
    totalDescErrors,
    addErrorByDesc,
    removeErrorsByDesc,
    setActiveTab,
    handleNewChildClick,
    handleDeleteChildClick
  };
};

export default useDescriptions;
