// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expandable-card {
    margin-top: 0 !important;
}

.expandable-card-title {
    padding: 15px 0 15px 11px;
    font-size: 15px;
    font-weight: 600;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 10px;
    background-color: var(--dark-grey);
    color: white;
    cursor: pointer;
}

.expandable-card-title svg {
    font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/components/ExpandableCard/ExpandableCard.css"],"names":[],"mappings":"AAAA;IACI,wBAAwB;AAC5B;;AAEA;IACI,yBAAyB;IACzB,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,yBAAyB;QACrB,sBAAsB;YAClB,mBAAmB;IAC3B,SAAS;IACT,kCAAkC;IAClC,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,eAAe;AACnB","sourcesContent":[".expandable-card {\n    margin-top: 0 !important;\n}\n\n.expandable-card-title {\n    padding: 15px 0 15px 11px;\n    font-size: 15px;\n    font-weight: 600;\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    gap: 10px;\n    background-color: var(--dark-grey);\n    color: white;\n    cursor: pointer;\n}\n\n.expandable-card-title svg {\n    font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
