const EMPTY_CHILD_RESET_KEYS = Object.freeze({
  project: [
    'AhsID',
    'deleted_at',
    'created_at',
    'updated_at',
    'ParentAhsID',
    'children',
    'CalculationID',
    'GeneralDesignDate',
    'LastComputeDate',
    'LastComputeUserID',
    'InitAppointGasEnabled',
    'ahsWeatherFileOriginalName',
    'description'
  ],
  otherValues: [
    'InitWaterLawIsUsed',
    'InitStationTemperatureSetPoint',
    'InitStationWaterTankHotTemperatureMin',
    'InitBtesEnabled',
    'InitLoopIsUsed',
    'InitLoopWaterType',
    'InitReleveEnabled',
    'InitHPAInjectionEnabled',
    'InitHPAHeatingEnabled',
    'InitHPACoolingEnabled',
    'ItesEnabled'
  ],
  substations: [
    'InitStationID',
    'AhsID',
    'created_at',
    'updated_at',
    'ParentInitStationID',
    'InitStationHasHeatNeeds',
    'InitTemperatureHeat',
    'InitTemperatureHeatDelta',
    'InitStationHasColdNeeds',
    'InitTemperatureCold',
    'InitStationEvapTemperatureDelta',
    'InitStationHasHotWaterNeeds',
    'InitTemperatureHotWater',
    'InitTemperatureHotWaterBack',
    'SummaryData',
    'filesStdOriginalName'
  ]
});

const COPIED_CHILD_RESET_KEYS = Object.freeze({
  project: [
    'AhsID',
    'created_at',
    'updated_at',
    'ParentAhsID',
    'children',
    'CalculationID',
    'GeneralDesignDate',
    'LastComputeDate',
    'LastComputeUserID',
    'description'
  ],
  substations: [
    'AhsID',
    'InitStationID',
    'ParentInitStationID',
    'created_at',
    'updated_at'
  ]
});

const CHILDREN_LIMIT = 4;

module.exports = {
  CHILDREN_LIMIT,
  EMPTY_CHILD_RESET_KEYS,
  COPIED_CHILD_RESET_KEYS
};
