// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.project-summary {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 20px;
    width: 50%;
}

@media (max-width: 1007px) {
    .project-summary {
        width: 100%;
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2;
    }
}

.project-summary .custom-table {
    min-width: 400px;
}`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/components/ProjectSummary/ProjectSummary.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,4BAA4B;IAC5B,6BAA6B;QACzB,0BAA0B;YACtB,sBAAsB;IAC9B,SAAS;IACT,UAAU;AACd;;AAEA;IACI;QACI,WAAW;QACX,4BAA4B;YACxB,iBAAiB;gBACb,QAAQ;IACpB;AACJ;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".project-summary {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n        -ms-flex-direction: column;\n            flex-direction: column;\n    gap: 20px;\n    width: 50%;\n}\n\n@media (max-width: 1007px) {\n    .project-summary {\n        width: 100%;\n        -webkit-box-ordinal-group: 3;\n            -ms-flex-order: 2;\n                order: 2;\n    }\n}\n\n.project-summary .custom-table {\n    min-width: 400px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
