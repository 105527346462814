import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { updateInputs } from '../../../../../../../api/project.api';
import PopupContext from '../../../../../../../contexts/PopupContext';
import ProjectContext from '../../../../../../../contexts/ProjectContext';
import RecursiveProperty from '../RecursiveProperty/RecursiveProperty';
import './InputsParamsTable.css';

const InputsParamsTable = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { project } = useContext(ProjectContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const handleUpdateInputsClick = async () => {
    try {
      await updateInputs(project.AhsID, result.ComputeResult.inp);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  return (
    <div>
      <Button
        variant='primary'
        onClick={handleUpdateInputsClick}
        className='results-params-inputs-btn'
      >
        {i18n._(
          'results.simulationParams.otherParams.inputsParams.inputsReturn'
        )}
      </Button>
      <table className='results-params-inputs-table'>
        <tbody>
          <RecursiveProperty
            property={result.ComputeResult.inp}
            propertyName={i18n._(
              'results.simulationParams.otherParams.inputsParams.table.title'
            )}
            rootProperty
            level={0}
          />
        </tbody>
      </table>
    </div>
  );
  //#endregion
};

export default InputsParamsTable;
