import { useLingui } from '@lingui/react';
import React, { useState } from 'react';
import { COMPUTE_TYPE } from '../../../../../../../server/constants/constraint.constant';
import './ComputeForm.css';
import OptimizationForm from './optimization/OptimizationForm';
import SimulationForm from './simulation/SimulationForm';

const ComputeForm = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [currentComputeType, setCurrentComputeType] = useState(
    COMPUTE_TYPE.OPTI
  );
  //#endregion

  //#region [methods]
  const handleComputeTypeClick = (computeType) => {
    setCurrentComputeType(computeType);
  };
  //#endregion

  //#region [render]
  const isSimu = currentComputeType === COMPUTE_TYPE.SIMU;
  return (
    <div className='compute-forms'>
      <div className='compute-forms-tabs'>
        <div
          className={
            'compute-forms-tab compute-forms-opti-tab ' +
            (isSimu ? 'compute-forms-tab-inactive' : 'compute-forms-tab-active')
          }
          onClick={() => handleComputeTypeClick(COMPUTE_TYPE.OPTI)}
        >
          {i18n._('computeType.opti')}
        </div>
        <div
          className={
            'compute-forms-tab compute-forms-simu-tab ' +
            (isSimu ? 'compute-forms-tab-active' : 'compute-forms-tab-inactive')
          }
          onClick={() => handleComputeTypeClick(COMPUTE_TYPE.SIMU)}
        >
          {i18n._('computeType.simu')}
        </div>
      </div>
      {!isSimu && <OptimizationForm />}
      {isSimu && <SimulationForm />}
    </div>
  );
  //#endregion
};

export default ComputeForm;
