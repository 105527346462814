import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import CompareResultsTable from '../../../../../../components/CompareResultsTable/CompareResultsTable';
import Section from '../../../../../../components/Section/Section';
import ResultContext from '../../../../../../contexts/ResultContext';
import { sstTemplates } from '../templates';

const SstTable = ({ substation, sstIndex }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { comparedResults } = useContext(ResultContext);
  //#endregion

  //#region [memos]
  const SSTTemplates = useMemo(() => {
    return sstTemplates(i18n, comparedResults, substation);
  }, [comparedResults]);
  //#endregion

  //#region [render]
  return (
    <Section
      level={2}
      title={i18n._('results.sizing.sst', {
        sst: substation,
        index: sstIndex + 1
      })}
    >
      {SSTTemplates.map((template, index) => (
        <CompareResultsTable
          template={template}
          key={'sizing_sst_template_' + index}
          level={3}
        />
      ))}
    </Section>
  );
  //#endregion
};

export default SstTable;
