import { i18n } from '@lingui/core';
import { I18nProvider } from '@lingui/react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'core-js/stable';
import { en, fr } from 'make-plural/plurals';
import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import 'regenerator-runtime/runtime';
import { DEFAULT_LANGUAGE } from '../../server/constants/language.constant';
import { LANGUAGE_KEY } from '../../server/constants/storage.constant';
import Router from './Router';
import Navigate from './components/Navigate';
import './icons.js';
import { messages as enMessages } from './locale/en/messages';
import { messages as frMessages } from './locale/fr/messages';
import './styles/global.css';

//#region [internationalization]
i18n.loadLocaleData({
  en: { plurals: en },
  fr: { plurals: fr }
});

i18n.load({
  en: enMessages,
  fr: frMessages
});
i18n.activate(localStorage[LANGUAGE_KEY] || DEFAULT_LANGUAGE);
//#endregion

//#region [router]
const root = createRoot(document.getElementById('design-ui'));
root.render(
  <I18nProvider i18n={i18n}>
    <BrowserRouter>
      <Navigate />
      <Router />
    </BrowserRouter>
  </I18nProvider>
);
//#endregion
