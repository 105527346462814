import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { getTableValue } from '../../utils/compute.utils';
import SectionTitle from '../SectionTitle';
import './TemplateTables.css';

const TemplateTable = ({ template, level }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const rows = useMemo(() => {
    return template.rows.map((row) => [
      row.label,
      getTableValue(i18n, row.value, row.unit, row.translateValue)
    ]);
  }, [template]);
  //#endregion

  //#region [render]
  return template ? (
    <div>
      {template.title && <SectionTitle level={level} title={template.title} />}
      <table className='template-table'>
        <tbody>
          {rows.map((row, index) => (
            <tr
              key={'template_row_' + row[0] + '_' + index}
              className='table-tr'
            >
              <td className='table-label' title={row[0]}>
                {row[0]}
              </td>
              <td className='table-value' title={row[1]}>
                {row[1]}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;
  //#endregion
};

export default TemplateTable;
