import { useLingui } from '@lingui/react';
import { cloneDeep } from 'lodash';
import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { updateDescriptionsParams } from '../../../api/project.api';
import { uploadSTDFiles, uploadWeatherFiles } from '../../../api/file.api';
import DescriptionsTabs from '../../../components/DescriptionsTabs/DescriptionsTabs';
import CompanyContext from '../../../contexts/CompanyContext';
import PopupContext from '../../../contexts/PopupContext';
import ProjectContext from '../../../contexts/ProjectContext';
import { isArrNullOrEmpty, isObjNullOrEmpty } from '../../../utils/data.utils';
import { getDescriptionErrors } from '../../../utils/project.utils';
import './DescriptionsPage.css';

const DescriptionsPage = () => {
  //#region [navigate]
  const navigate = useNavigate();
  //#endregion

  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  const { project } = useContext(ProjectContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [tempParentProject, setTempParentProject] = useState(project);
  const [cantSaveErrors, setCantSaveErrors] = useState([]);
  //#endregion

  //#region [methods]
  const handleSaveBtnClick = async () => {
    try {
      const errors = getDescriptionErrors(i18n, tempParentProject);
      setCantSaveErrors(errors);
      if (errors.some((err) => err)) return;

      const updatedProject = await updateDescriptionsParams(tempParentProject);
      const tempParentProjectCopy = cloneDeep(tempParentProject);

      // on complète les sous-stations du projet parent créées avec leurs IDs
      tempParentProjectCopy.substations.forEach((sst, sstIndex) => {
        sst.InitStationID = updatedProject.substations[sstIndex].InitStationID;
      });

      // on complète les projets enfants et leurs sous-stations créés avec leurs IDs
      if (!isArrNullOrEmpty(tempParentProjectCopy.children)) {
        tempParentProjectCopy.children.forEach((child, childIndex) => {
          child.AhsID = updatedProject.children[childIndex].AhsID;
          child.substations.forEach((sst, sstIndex) => {
            sst.InitStationID =
              updatedProject.children[childIndex].substations[
                sstIndex
              ].InitStationID;
          });
        });
      } else {
        tempParentProjectCopy.children = [];
      }

      // on envoie les STD
      const projects = [
        tempParentProjectCopy,
        ...tempParentProjectCopy.children
      ];

      let sstDataToUpload = {};
      projects.forEach((proj) => {
        sstDataToUpload = proj.substations
          .filter((sst) => sst.stdFile)
          .reduce((acc, sst) => {
            acc[sst.InitStationID] = {
              file: sst.stdFile,
              filename: sst.stdFile.name,
              summaryData: sst.SummaryData
            };
            return acc;
          }, sstDataToUpload);
      });
      await uploadSTDFiles(sstDataToUpload);

      // on envoie les fichiers météo
      let dataToUpload = {};
      if (tempParentProjectCopy.weatherFile) {
        dataToUpload[tempParentProject.AhsID] =
          tempParentProjectCopy.weatherFile;
      }

      tempParentProjectCopy.children.forEach((child) => {
        if (child.weatherFile) {
          dataToUpload[child.AhsID] = child.weatherFile;
        }
      });

      await uploadWeatherFiles(dataToUpload);

      // on change de page
      const url = `/company/${selectedCompany.id}/project/${project.AhsID}/compute`;
      navigate(url);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  return !isObjNullOrEmpty(tempParentProject) ? (
    <div className='descriptions-page'>
      <DescriptionsTabs
        project={tempParentProject}
        setProject={setTempParentProject}
        onSubmit={handleSaveBtnClick}
        cantSaveErrors={cantSaveErrors}
      />
    </div>
  ) : null;
  //#endregion
};

export default DescriptionsPage;
