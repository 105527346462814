const { COUNTRY } = require('../constants/country.constant');

const PARAM = Object.freeze({
  UserFirstName: {
    key: 'UserFirstName',
    type: 'text',
    required: true,
    default: null,
    pattern: /^[A-Za-zÀ-ÖØ-öø-ÿ\s-]{3,50}$/,
    example: 'Alexandre'
  },
  UserLastName: {
    key: 'UserLastName',
    type: 'text',
    required: true,
    default: null,
    pattern: /^[A-Za-zÀ-ÖØ-öø-ÿ\s-]{3,50}$/,
    example: 'Dumas'
  },
  UserEmail: {
    key: 'UserEmail',
    type: 'text',
    required: true,
    default: null,
    pattern: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    example: 'alexandre.dumas@mail.com'
  },
  UserPassword: {
    key: 'UserPassword',
    type: 'password',
    required: true,
    default: null,
    pattern: /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9])(?!.*\s).{8,15}$/
  },
  UserJobTitle: {
    key: 'UserJobTitle',
    type: 'text',
    required: true,
    default: null,
    maxLength: 255
  },
  UserCountry: {
    key: 'UserCountry',
    required: true,
    default: 'FR',
    values: Object.keys(COUNTRY),
    translateValuesIds: Object.keys(COUNTRY).map(
      (country) => `country.${country}`
    )
  },
  IS_SUPERADMIN: {
    key: 'IS_SUPERADMIN',
    type: 'boolean',
    default: false
  }
});

const EMPTY_USER = {
  UserFirstName: PARAM.UserFirstName.default,
  UserLastName: PARAM.UserLastName.default,
  UserEmail: PARAM.UserEmail.default,
  UserPassword: PARAM.UserPassword.default,
  UserJobTitle: PARAM.UserJobTitle.default,
  UserCountry: PARAM.UserCountry.default,
  IS_SUPERADMIN: PARAM.IS_SUPERADMIN.default
};

module.exports = { EMPTY_USER, PARAM };
