import React from 'react';
import './Card.css';

const Card = ({ title, tooltip, children }) => {
  //#region [render]
  return (
    <div title={tooltip} className='card-wrapper'>
      {title && <p className='card-title'>{title}</p>}
      <div className='card-content'>{children}</div>
    </div>
  );
  //#endregion
};

export default React.memo(Card);
