import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useMemo } from 'react';
import './SelectBtn.css';

const SelectBtn = ({ children, onClick, selected, disabled, bgColor }) => {
  //#region [memo]
  const style = useMemo(() => {
    return selected
      ? { backgroundColor: bgColor ? bgColor : 'var(--primary-color)' }
      : {};
  }, [selected, bgColor]);
  //#endregion

  //#region [render]
  return (
    <div
      className={
        'select-btn ' +
        (selected ? 'select-btn-active ' : 'select-btn-inactive ') +
        (disabled ? 'cursor-auto' : 'cursor-pointer')
      }
      onClick={onClick}
      style={style}
    >
      <FontAwesomeIcon icon={selected ? 'check' : 'plus'} />
      {children}
    </div>
  );
  //#endregion
};

export default SelectBtn;
