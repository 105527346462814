const roundNumber = (num, precision = 2) =>
  Math.round((num + Number.EPSILON) * Math.pow(10, precision)) /
  Math.pow(10, precision);

const isNull = (val) => val === null || val === undefined;

const isObjNullOrEmpty = (obj) => !obj || Object.keys(obj).length === 0;

const isArrNullOrEmpty = (arr) => !arr || arr.length === 0;

const getPath = (obj, path, index = 0) => {
  const nestedKeys = path.split('.');
  const selectedKey = nestedKeys[index];

  if (index === nestedKeys.length - 1) {
    return obj[selectedKey];
  }

  if (!obj.hasOwnProperty(selectedKey)) {
    return {};
  }

  const nextObj = obj[selectedKey];
  return getPath(nextObj, path, index + 1);
};

const containsNotNullValue = (obj, targetKey) => {
  if (typeof obj === 'object' && !isNull(obj)) {
    if (!isNull(obj[targetKey])) {
      return true;
    }
    for (const key in obj) {
      if (containsNotNullValue(obj[key], targetKey)) {
        return true;
      }
    }
  }
  return false;
};

module.exports = {
  roundNumber,
  isNull,
  isObjNullOrEmpty,
  isArrNullOrEmpty,
  getPath,
  containsNotNullValue
};
