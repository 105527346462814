import { UNIT } from '../../../../../../../server/constants/unit.constant';
import { getSafeData } from '../../../../../utils/data.utils';

export const inputDataTemplates = (i18n, results) => {
  return [
    {
      title: i18n._('total'),
      rows: [
        {
          label: i18n._(
            'results.simulationParams.inputData.total.buildingSurface'
          ),
          values: results.map((result) =>
            getSafeData(() => result.ComputeResult.inp.InitBuildingSurface)
          ),
          unit: UNIT.SQUARE_METER
        },
        {
          label: i18n._(
            'results.simulationParams.inputData.total.delayStartProject'
          ),
          values: results.map((result) =>
            getSafeData(() => result.ComputeResult.inp.InitYearsBeforeStart)
          ),
          unit: UNIT.YEAR
        }
      ]
    },
    {
      title: i18n._(
        'results.simulationParams.inputData.projectCharacteristics'
      ),
      rows: [
        {
          label: i18n._(
            'results.simulationParams.inputData.projectCharacteristics.heatProductionTemperature'
          ),
          values: results.map((result) =>
            getSafeData(() => result.ComputeResult.inp.InitTemperatureHeat)
          ),
          unit: UNIT.CELSIUS_DEGREE
        },
        {
          label: i18n._(
            'results.simulationParams.inputData.projectCharacteristics.heatProductionDelta'
          ),
          values: results.map((result) =>
            getSafeData(() => result.ComputeResult.inp.InitTemperatureHeatDelta)
          ),
          unit: UNIT.CELSIUS_DEGREE
        },
        {
          label: i18n._(
            'results.simulationParams.inputData.projectCharacteristics.coldProductionTemperature'
          ),
          values: results.map((result) =>
            getSafeData(() => result.ComputeResult.inp.InitTemperatureCold)
          ),
          unit: UNIT.CELSIUS_DEGREE
        },
        {
          label: i18n._(
            'results.simulationParams.inputData.projectCharacteristics.coldProductionDelta'
          ),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.inp.InitStationEvapTemperatureDelta
            )
          ),
          unit: UNIT.CELSIUS_DEGREE
        },
        {
          label: i18n._(
            'results.simulationParams.inputData.projectCharacteristics.hotwaterProductionTemperature'
          ),
          values: results.map((result) =>
            getSafeData(() => result.ComputeResult.inp.InitTemperatureHotWater)
          ),
          unit: UNIT.CELSIUS_DEGREE
        }
      ]
    },
    {
      title: i18n._('results.simulationParams.inputData.geology'),
      rows: [
        {
          label: i18n._(
            'results.simulationParams.inputData.geology.soilHeatCapacity'
          ),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.inp.InitSoilVolumeHeatCapacity
            )
          ),
          unit: UNIT.JOULE_PER_KELVIN_PER_CUBIC_METER
        },
        {
          label: i18n._(
            'results.simulationParams.inputData.geology.soilThermalConductivity'
          ),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.inp.InitSoilThermalConductivity
            )
          ),
          precision: 2,
          unit: UNIT.WATT_PER_METER_KELVIN
        },
        {
          label: i18n._(
            'results.simulationParams.inputData.geology.groundTemperature'
          ),
          values: results.map((result) =>
            getSafeData(() => result.ComputeResult.inp.InitSoilTemperature10m)
          ),
          unit: UNIT.CELSIUS_DEGREE
        }
      ]
    },
    {
      title: i18n._('results.simulationParams.inputData.costs'),
      rows: [
        {
          label: i18n._(
            'results.simulationParams.inputData.costs.priceMeterProbeDrilling'
          ),
          values: results.map((result) =>
            getSafeData(() => result.ComputeResult.inp.InitBtesCostA)
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.simulationParams.inputData.costs.capexOtherHardware'
          ),
          values: results.map((result) =>
            getSafeData(() => result.ComputeResult.inp.InitCapexOther)
          ),
          unit: UNIT.EURO
        }
      ]
    },
    {
      title: i18n._('results.simulationParams.inputData.energyPricing'),
      rows: [
        {
          label: i18n._(
            'results.simulationParams.inputData.energyPricing.gasPricePerKilowattHour'
          ),
          values: results.map((result) =>
            getSafeData(() => result.ComputeResult.inp.InitGasPrice)
          ),
          unit: UNIT.EURO
        },
        {
          label: i18n._(
            'results.simulationParams.inputData.energyPricing.annualCceIncreasePerKilowattHour'
          ),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.inp.InitGasCarbonTaxIncrement
            )
          ),
          unit: UNIT.PERCENT
        }
      ]
    }
  ];
};

export const simuTemplate = (i18n, results) => {
  return {
    title: i18n._('results.simulationParams.simulation'),
    rows: [
      {
        label: i18n._('results.simulationParams.simulation.buildVersion'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.BuildVersion)
        )
      }
    ]
  };
};

export const detailedParamsTemplate = (i18n, results) => {
  return {
    title: i18n._('results.simulationParams.detailedParams'),
    rows: [
      {
        label: i18n._(
          'results.simulationParams.detailedParams.maxTotalThermalPower'
        ),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.PTotalThMax)
        ),
        unit: UNIT.KILOWATT
      },
      {
        label: i18n._(
          'results.simulationParams.detailedParams.maxAeroThermalPower'
        ),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.HPAPThMax)
        ),
        unit: UNIT.KILOWATT
      },
      {
        label: i18n._(
          'results.simulationParams.detailedParams.maxGeoThermalPower'
        ),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.HPGPThMax)
        ),
        unit: UNIT.KILOWATT
      },
      {
        label: i18n._('results.simulationParams.detailedParams.HPAPThNearMax'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.HPAPThNearMax)
        ),
        unit: UNIT.KILOWATT
      }
    ]
  };
};
