const {
  ST_EQUIPMENT,
  PV_EQUIPMENT,
  PV_MOUNTING_TYPE_EQUIPMENT,
  ITES_EQUIPMENT
} = require('../../constants/performance.constant');
const { UNIT } = require('../../constants/unit.constant');

const EXCHANGER = Object.freeze({
  InitLoopExchangerPinch: {
    key: 'InitLoopExchangerPinch',
    type: 'number',
    min: 0,
    max: 5,
    default: 1.5,
    unit: UNIT.CELSIUS_DEGREE
  },
  InitLoopExchangerU: {
    key: 'InitLoopExchangerU',
    type: 'number',
    min: 70,
    max: 1000,
    default: 100,
    unit: UNIT.WATT_PER_SQUARE_METER_KELVIN
  },
  InitLoopExchangerAirPinch: {
    key: 'InitLoopExchangerAirPinch',
    type: 'number',
    min: 1,
    max: 5,
    default: 2,
    unit: UNIT.CELSIUS_DEGREE
  },
  InitLoopExchangerAirU: {
    key: 'InitLoopExchangerAirU',
    type: 'number',
    min: 9,
    max: 30,
    default: 10,
    unit: UNIT.WATT_PER_SQUARE_METER_KELVIN
  },
  InitStationExchangerPinch: {
    key: 'InitStationExchangerPinch',
    type: 'number',
    min: 0,
    max: 5,
    default: 4,
    unit: UNIT.CELSIUS_DEGREE
  },
  InitStationColdExchangerPinch: {
    key: 'InitStationColdExchangerPinch',
    type: 'number',
    min: 0,
    max: 5,
    default: 1.5,
    unit: UNIT.CELSIUS_DEGREE
  },
  InitStationHotwaterExchangerPinch: {
    key: 'InitStationHotwaterExchangerPinch',
    type: 'number',
    min: 0,
    max: 5,
    default: 1.5,
    unit: UNIT.CELSIUS_DEGREE
  }
});

const getSTParams = (stModel = ST_EQUIPMENT.CUSTOM) => ({
  InitSolarThermalModel: {
    key: 'InitSolarThermalModel',
    default: ST_EQUIPMENT.CUSTOM.value,
    type: 'text',
    values: Object.values(ST_EQUIPMENT).map((equipment) => equipment.value),
    translateValuesIds: Object.values(ST_EQUIPMENT).map(
      (equipment) => equipment.labelId
    ) // TODO côté front
  },
  InitSolarSurfaceTilt: {
    key: 'InitSolarSurfaceTilt',
    type: 'number',
    min: 0,
    max: 90,
    default: 0,
    unit: UNIT.DEGREES
  },
  InitSolarSurfaceAzimuth: {
    key: 'InitSolarSurfaceAzimuth',
    type: 'number',
    min: -180,
    max: 180,
    default: 180,
    unit: UNIT.DEGREES
  },
  InitSolarThermalHeta0: {
    key: 'InitSolarThermalHeta0',
    type: 'number',
    min: 0,
    max: 1.5,
    default: stModel.data.InitSolarThermalHeta0
  },
  InitSolarThermalB1: {
    key: 'InitSolarThermalB1',
    type: 'number',
    min: 0,
    max: 20,
    default: stModel.data.InitSolarThermalB1,
    unit: UNIT.WATT_PER_SQUARE_METER_KELVIN
  },
  InitSolarThermalBu: {
    key: 'InitSolarThermalBu',
    type: 'number',
    min: 0,
    max: 1,
    default: stModel.data.InitSolarThermalBu,
    unit: UNIT.SECOND_PER_METER
  },
  InitSolarThermalEpsa: {
    key: 'InitSolarThermalEpsa',
    type: 'number',
    min: 0,
    max: 20,
    default: stModel.data.InitSolarThermalEpsa
  },
  InitSolarThermalB2: {
    key: 'InitSolarThermalB2',
    type: 'number',
    min: 0,
    max: 20,
    default: stModel.data.InitSolarThermalB2,
    unit: UNIT.WATT_SECOND_PER_CUBIC_METER_KELVIN
  },
  InitSolarThermalHeadlossMceMax: {
    key: 'InitSolarThermalHeadlossMceMax',
    type: 'number',
    min: 0,
    default: stModel.data.InitSolarThermalHeadlossMceMax,
    unit: UNIT.WATER_COLUMN_METER
  },
  InitSolarThermalFlowRate: {
    key: 'InitSolarThermalFlowRate',
    type: 'number',
    min: 0,
    max: 1,
    default: stModel.data.InitSolarThermalFlowRate,
    unit: UNIT.KILOGRAM_PER_SECOND_PER_SQUARE_METER
  },
  InitSolarDeltaTemp: {
    key: 'InitSolarDeltaTemp',
    type: 'number',
    default: stModel.data.InitSolarDeltaTemp,
    unit: UNIT.CELSIUS_DEGREE
  },
  InitSolarTemperatureMin: {
    key: 'InitSolarTemperatureMin',
    type: 'number',
    default: stModel.data.InitSolarTemperatureMin,
    unit: UNIT.CELSIUS_DEGREE
  },
  InitSolarInjectionRatio: {
    key: 'InitSolarInjectionRatio',
    type: 'number',
    min: 0,
    max: 1,
    default: 0
  }
});

const getPVParams = (pvModel = PV_EQUIPMENT.CUSTOM) => ({
  InitPhotovoltaicModel: {
    key: 'InitPhotovoltaicModel',
    default: PV_EQUIPMENT.CUSTOM.value,
    type: 'text',
    values: Object.values(PV_EQUIPMENT).map((equipment) => equipment.value),
    translateValuesIds: Object.values(PV_EQUIPMENT).map(
      (equipment) => equipment.labelId
    ) // TODO côté front
  },
  InitPVEfficiency: {
    key: 'InitPVEfficiency',
    type: 'number',
    min: 0,
    max: 100,
    default: pvModel.data.InitPVEfficiency,
    unit: UNIT.PERCENT
  },
  InitPVTemperatureCoefGammaModule: {
    key: 'InitPVTemperatureCoefGammaModule',
    type: 'number',
    min: -60,
    max: 0,
    default: pvModel.data.InitPVTemperatureCoefGammaModule,
    unit: UNIT.PERCENT_PER_KELVIN_DEGREES
  },
  InitPVDCLossesAgeModule: {
    key: 'InitPVDCLossesAgeModule',
    type: 'number',
    min: 0,
    max: 100,
    default: 0.1,
    unit: UNIT.PERCENT_PER_YEAR
  },
  InitPVInverterMaxPowerPerWc: {
    key: 'InitPVInverterMaxPowerPerWc',
    type: 'number',
    default: 1.1
  },
  InitPVInverterNominalEfficiency: {
    key: 'InitPVInverterNominalEfficiency',
    type: 'number',
    min: 0,
    max: 100,
    default: 0.95,
    unit: UNIT.PERCENT
  },
  InitPVMountingType: {
    key: 'InitPVMountingType',
    default: PV_MOUNTING_TYPE_EQUIPMENT.CLOSE_MOUNT.value,
    type: 'text',
    values: Object.values(PV_MOUNTING_TYPE_EQUIPMENT).map(
      (equipment) => equipment.value
    ),
    translateValuesIds: Object.values(PV_MOUNTING_TYPE_EQUIPMENT).map(
      (equipment) => equipment.labelId
    ) // TODO côté front
  }
});

const GAS = Object.freeze({
  InitGasEfficiency: {
    key: 'InitGasEfficiency',
    type: 'number',
    min: 0.01,
    max: 1,
    default: 0.9
  }
});

const HP = Object.freeze({
  InitStationCondTemperatureDelta: {
    key: 'InitStationCondTemperatureDelta',
    type: 'number',
    min: 3,
    max: 10,
    default: 5,
    unit: UNIT.CELSIUS_DEGREE
  },
  HPGFileID: {
    key: 'HPGFileID',
    default: '047e5004-cb27-11e9-8e12-8ba497065ab0'
  },
  HPAFileID: {
    key: 'HPAFileID',
    default: 'b0fa730a-9676-11ed-95b8-d05099d6ea72'
  },
  HPGFilename: {
    key: 'HPGFilename',
    default: 'COP-EER_Waterkotte_HeatPump_WaterWater_DS5235-5T_Checked-178'
  },
  HPAFilename: {
    key: 'HPAFilename',
    default: 'COP-EER_Rhoss_HeatPump_AirWater_THAETU-5520_ASDP1-less-points'
  },
  InitInjectionTemperatureDelta: {
    key: 'InitInjectionTemperatureDelta',
    type: 'number',
    min: 0,
    default: 8,
    unit: UNIT.CELSIUS_DEGREE
  },
  InitInjectionTemperatureMin: {
    key: 'InitInjectionTemperatureMin',
    type: 'number',
    min: 0,
    default: 20,
    unit: UNIT.CELSIUS_DEGREE
  },
  HPAPenaltyTemperatureMin: {
    key: 'HPAPenaltyTemperatureMin',
    type: 'number',
    min: -25,
    max: 0,
    default: -5,
    unit: UNIT.CELSIUS_DEGREE
  },
  HPAPenaltyTemperatureMax: {
    key: 'HPAPenaltyTemperatureMax',
    type: 'number',
    min: 0,
    max: 25,
    default: 5,
    unit: UNIT.CELSIUS_DEGREE
  },
  HPAPenaltyPercentageMax: {
    key: 'HPAPenaltyPercentageMax',
    type: 'number',
    min: 0,
    default: 30,
    unit: UNIT.PERCENT
  }
});

const ITES = Object.freeze({
  InitItesModel: {
    key: 'InitItesModel',
    default: ITES_EQUIPMENT.FAFCO.value,
    type: 'text',
    values: Object.values(ITES_EQUIPMENT).map((equipment) => equipment.value),
    translateValuesIds: Object.values(ITES_EQUIPMENT).map(
      (equipment) => equipment.labelId
    ) // TODO côté front
  },
  InitItesPumpConsumptionPercentage: {
    key: 'InitItesPumpConsumptionPercentage',
    type: 'number',
    min: 0,
    max: 100,
    default: 3,
    unit: UNIT.PERCENT
  },
  InitItesChargingTemperatureIn: {
    key: 'InitItesChargingTemperatureIn',
    type: 'number',
    min: -20,
    max: 1,
    default: -6,
    unit: UNIT.CELSIUS_DEGREE
  },
  InitItesChargingTemperatureOut: {
    key: 'InitItesChargingTemperatureOut',
    type: 'number',
    min: -20,
    max: 1,
    default: -2,
    unit: UNIT.CELSIUS_DEGREE
  },
  InitItesDischargingTemperatureIn: {
    key: 'InitItesDischargingTemperatureIn',
    type: 'number',
    min: 1,
    max: 20,
    default: 10,
    unit: UNIT.CELSIUS_DEGREE
  },
  InitItesDischargingTemperatureOut: {
    key: 'InitItesDischargingTemperatureOut',
    type: 'number',
    min: 1,
    max: 20,
    default: 5,
    unit: UNIT.CELSIUS_DEGREE
  }
});

const HEADLOSS = Object.freeze({
  InitPumpEfficiency: {
    key: 'InitPumpEfficiency',
    type: 'number',
    min: 0.01,
    max: 1,
    default: 0.32
  },
  InitExchangerHeadlossMceMax: {
    key: 'InitExchangerHeadlossMceMax',
    type: 'number',
    min: 0,
    default: 3,
    unit: UNIT.WATER_COLUMN_METER
  },
  InitBtesHeadlossLimit: {
    key: 'InitBtesHeadlossLimit',
    type: 'number',
    default: 8,
    unit: UNIT.WATER_COLUMN_METER
  },
  InitLoopConnectionLength: {
    key: 'InitLoopConnectionLength',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.METER
  }
});

const GEOCOOLING = Object.freeze({
  InitGeocoolingEnabled: {
    key: 'InitGeocoolingEnabled',
    type: 'boolean',
    default: false
  },
  InitGeocoolingDisableClim: {
    key: 'InitGeocoolingDisableClim',
    type: 'boolean',
    default: true
  },
  InitGeocoolingTempOutBtesMax: {
    key: 'InitGeocoolingTempOutBtesMax',
    type: 'number',
    min: 15,
    max: 22,
    default: 16,
    unit: UNIT.CELSIUS_DEGREE
  },
  InitGeocoolingPumpConsumptionPercent: {
    key: 'InitGeocoolingPumpConsumptionPercent',
    type: 'number',
    min: 0,
    max: 10,
    default: 5,
    unit: UNIT.PERCENT
  }
});

module.exports = {
  EXCHANGER,
  GAS,
  HP,
  ITES,
  HEADLOSS,
  GEOCOOLING,
  getSTParams,
  getPVParams
};
