const DEPARTMENT = Object.freeze({
  FR: {
    '01': {
      dep: 'Ain',
      region: 'Auvergne-Rhône-Alpes'
    },
    '02': {
      dep: 'Aisne',
      region: 'Hauts-de-France'
    },
    '03': {
      dep: 'Allier',
      region: 'Auvergne-Rhône-Alpes'
    },
    '04': {
      dep: 'Alpes-de-Haute-Provence',
      region: "Provence-Alpes-Côte d'Azur"
    },
    '05': {
      dep: 'Hautes-Alpes',
      region: "Provence-Alpes-Côte d'Azur"
    },
    '06': {
      dep: 'Alpes-Maritimes',
      region: "Provence-Alpes-Côte d'Azur"
    },
    '07': {
      dep: 'Ardèche',
      region: 'Auvergne-Rhône-Alpes'
    },
    '08': {
      dep: 'Ardennes',
      region: 'Grand Est'
    },
    '09': {
      dep: 'Ariège',
      region: 'Occitanie'
    },
    10: {
      dep: 'Aube',
      region: 'Grand Est'
    },
    11: {
      dep: 'Aude',
      region: 'Occitanie'
    },
    12: {
      dep: 'Aveyron',
      region: 'Occitanie'
    },
    13: {
      dep: 'Bouches-du-Rhône',
      region: "Provence-Alpes-Côte d'Azur"
    },
    14: {
      dep: 'Calvados',
      region: 'Normandie'
    },
    15: {
      dep: 'Cantal',
      region: 'Auvergne-Rhône-Alpes'
    },
    16: {
      dep: 'Charente',
      region: 'Nouvelle-Aquitaine'
    },
    17: {
      dep: 'Charente-Maritime',
      region: 'Nouvelle-Aquitaine'
    },
    18: {
      dep: 'Cher',
      region: 'Centre-Val de Loire'
    },
    19: {
      dep: 'Corrèze',
      region: 'Nouvelle-Aquitaine'
    },
    21: {
      dep: "Côte-d'Or",
      region: 'Bourgogne-Franche-Comté'
    },
    22: {
      dep: "Côtes-d'Armor",
      region: 'Bretagne'
    },
    23: {
      dep: 'Creuse',
      region: 'Nouvelle-Aquitaine'
    },
    24: {
      dep: 'Dordogne',
      region: 'Nouvelle-Aquitaine'
    },
    25: {
      dep: 'Doubs',
      region: 'Bourgogne-Franche-Comté'
    },
    26: {
      dep: 'Drôme',
      region: 'Auvergne-Rhône-Alpes'
    },
    27: {
      dep: 'Eure',
      region: 'Normandie'
    },
    28: {
      dep: 'Eure-et-Loir',
      region: 'Centre-Val de Loire'
    },
    29: {
      dep: 'Finistère',
      region: 'Bretagne'
    },
    30: {
      dep: 'Gard',
      region: 'Occitanie'
    },
    31: {
      dep: 'Haute-Garonne',
      region: 'Occitanie'
    },
    32: {
      dep: 'Gers',
      region: 'Occitanie'
    },
    33: {
      dep: 'Gironde',
      region: 'Nouvelle-Aquitaine'
    },
    34: {
      dep: 'Hérault',
      region: 'Occitanie'
    },
    35: {
      dep: 'Ille-et-Vilaine',
      region: 'Bretagne'
    },
    36: {
      dep: 'Indre',
      region: 'Centre-Val de Loire'
    },
    37: {
      dep: 'Indre-et-Loire',
      region: 'Centre-Val de Loire'
    },
    38: {
      dep: 'Isère',
      region: 'Auvergne-Rhône-Alpes'
    },
    39: {
      dep: 'Jura',
      region: 'Bourgogne-Franche-Comté'
    },
    40: {
      dep: 'Landes',
      region: 'Nouvelle-Aquitaine'
    },
    41: {
      dep: 'Loir-et-Cher',
      region: 'Centre-Val de Loire'
    },
    42: {
      dep: 'Loire',
      region: 'Auvergne-Rhône-Alpes'
    },
    43: {
      dep: 'Haute-Loire',
      region: 'Auvergne-Rhône-Alpes'
    },
    44: {
      dep: 'Loire-Atlantique',
      region: 'Pays de la Loire'
    },
    45: {
      dep: 'Loiret',
      region: 'Centre-Val de Loire'
    },
    46: {
      dep: 'Lot',
      region: 'Occitanie'
    },
    47: {
      dep: 'Lot-et-Garonne',
      region: 'Nouvelle-Aquitaine'
    },
    48: {
      dep: 'Lozère',
      region: 'Occitanie'
    },
    49: {
      dep: 'Maine-et-Loire',
      region: 'Pays de la Loire'
    },
    50: {
      dep: 'Manche',
      region: 'Normandie'
    },
    51: {
      dep: 'Marne',
      region: 'Grand Est'
    },
    52: {
      dep: 'Haute-Marne',
      region: 'Grand Est'
    },
    53: {
      dep: 'Mayenne',
      region: 'Pays de la Loire'
    },
    54: {
      dep: 'Meurthe-et-Moselle',
      region: 'Grand Est'
    },
    55: {
      dep: 'Meuse',
      region: 'Grand Est'
    },
    56: {
      dep: 'Morbihan',
      region: 'Bretagne'
    },
    57: {
      dep: 'Moselle',
      region: 'Grand Est'
    },
    58: {
      dep: 'Nièvre',
      region: 'Bourgogne-Franche-Comté'
    },
    59: {
      dep: 'Nord',
      region: 'Hauts-de-France'
    },
    60: {
      dep: 'Oise',
      region: 'Hauts-de-France'
    },
    61: {
      dep: 'Orne',
      region: 'Normandie'
    },
    62: {
      dep: 'Pas-de-Calais',
      region: 'Hauts-de-France'
    },
    63: {
      dep: 'Puy-de-Dôme',
      region: 'Auvergne-Rhône-Alpes'
    },
    64: {
      dep: 'Pyrénées-Atlantiques',
      region: 'Nouvelle-Aquitaine'
    },
    65: {
      dep: 'Hautes-Pyrénées',
      region: 'Occitanie'
    },
    66: {
      dep: 'Pyrénées-Orientales',
      region: 'Occitanie'
    },
    67: {
      dep: 'Bas-Rhin',
      region: 'Grand Est'
    },
    68: {
      dep: 'Haut-Rhin',
      region: 'Grand Est'
    },
    69: {
      dep: 'Rhône',
      region: 'Auvergne-Rhône-Alpes'
    },
    70: {
      dep: 'Haute-Saône',
      region: 'Bourgogne-Franche-Comté'
    },
    71: {
      dep: 'Saône-et-Loire',
      region: 'Bourgogne-Franche-Comté'
    },
    72: {
      dep: 'Sarthe',
      region: 'Pays de la Loire'
    },
    73: {
      dep: 'Savoie',
      region: 'Auvergne-Rhône-Alpes'
    },
    74: {
      dep: 'Haute-Savoie',
      region: 'Auvergne-Rhône-Alpes'
    },
    75: {
      dep: 'Paris',
      region: 'Île-de-France'
    },
    76: {
      dep: 'Seine-Maritime',
      region: 'Normandie'
    },
    77: {
      dep: 'Seine-et-Marne',
      region: 'Île-de-France'
    },
    78: {
      dep: 'Yvelines',
      region: 'Île-de-France'
    },
    79: {
      dep: 'Deux-Sèvres',
      region: 'Nouvelle-Aquitaine'
    },
    80: {
      dep: 'Somme',
      region: 'Hauts-de-France'
    },
    81: {
      dep: 'Tarn',
      region: 'Occitanie'
    },
    82: {
      dep: 'Tarn-et-Garonne',
      region: 'Occitanie'
    },
    83: {
      dep: 'Var',
      region: "Provence-Alpes-Côte d'Azur"
    },
    84: {
      dep: 'Vaucluse',
      region: "Provence-Alpes-Côte d'Azur"
    },
    85: {
      dep: 'Vendée',
      region: 'Pays de la Loire'
    },
    86: {
      dep: 'Vienne',
      region: 'Nouvelle-Aquitaine'
    },
    87: {
      dep: 'Haute-Vienne',
      region: 'Nouvelle-Aquitaine'
    },
    88: {
      dep: 'Vosges',
      region: 'Grand Est'
    },
    89: {
      dep: 'Yonne',
      region: 'Bourgogne-Franche-Comté'
    },
    90: {
      dep: 'Territoire de Belfort',
      region: 'Bourgogne-Franche-Comté'
    },
    91: {
      dep: 'Essonne',
      region: 'Île-de-France'
    },
    92: {
      dep: 'Hauts-de-Seine',
      region: 'Île-de-France'
    },
    93: {
      dep: 'Seine-Saint-Denis',
      region: 'Île-de-France'
    },
    94: {
      dep: 'Val-de-Marne',
      region: 'Île-de-France'
    },
    95: {
      dep: "Val-d'Oise",
      region: 'Île-de-France'
    },
    971: {
      dep: 'Guadeloupe',
      region: 'Guadeloupe'
    },
    972: {
      dep: 'Martinique',
      region: 'Martinique'
    },
    973: {
      dep: 'Guyane',
      region: 'Guyane'
    },
    974: {
      dep: 'La Réunion',
      region: 'La Réunion'
    },
    976: {
      dep: 'Mayotte',
      region: 'Mayotte'
    },
    '2A': {
      dep: 'Corse-du-Sud',
      region: 'Corse'
    },
    '2B': {
      dep: 'Haute-Corse',
      region: 'Corse'
    }
  }
});

module.exports = { DEPARTMENT };
