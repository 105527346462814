import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PROJECT_ENDPOINT } from '../../../../../server/constants/endpoint.constant';
import { updateParams } from '../../../api/project.api';
import CompanyContext from '../../../contexts/CompanyContext';
import PopupContext from '../../../contexts/PopupContext';
import ProjectContext from '../../../contexts/ProjectContext';

const useCost = () => {
  //#region [navigate]
  const navigate = useNavigate();
  //#endregion

  //#region [contexts]
  // TODO dans le cas où selectedCompany n'est utilisé que pour selectedCompany.id, utiliser plutôt const { companyId } = useParams();
  const { selectedCompany } = useContext(CompanyContext);
  const { project } = useContext(ProjectContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [tempProject, setTempProject] = useState(project); // TODO tempProject ne doit contenir que les informations liées à la page Coûts
  const [nbErrors, setNbErrors] = useState(0);
  //#endregion

  //#region [methods]
  const addError = () => setNbErrors((prevErrors) => prevErrors + 1);
  const removeError = () => setNbErrors((prevErrors) => prevErrors - 1);

  const handleParamChange = (key, value) => {
    const tempProjectCopy = { ...tempProject };
    tempProjectCopy[key] = value;
    setTempProject(tempProjectCopy);
  };

  const handleOtherValueChange = (key, value) => {
    const tempProjectCopy = { ...tempProject };
    tempProjectCopy.otherValues[key] = value;
    setTempProject(tempProjectCopy);
  };

  const handleTableValueChange = (paramKey, rowKey, value) => {
    const tempProjectCopy = { ...tempProject };
    tempProjectCopy.otherValues[paramKey] = {
      ...tempProjectCopy.otherValues[paramKey],
      [rowKey]: value
    };
    setTempProject(tempProjectCopy);
  };

  const handleSubmitClick = async () => {
    try {
      // TODO n'envoyer que les informations liées à la page Coûts
      await updateParams(tempProject, PROJECT_ENDPOINT.COST);
      navigate(
        `/company/${selectedCompany.id}/project/${project.AhsID}/compute`
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  return {
    tempProject,
    nbErrors,
    addError,
    removeError,
    handleParamChange,
    handleOtherValueChange,
    handleTableValueChange,
    handleSubmitClick
  };
};

export default useCost;
