import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import { cloneDeep } from 'lodash';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import Form from 'react-bootstrap/Form';
import {
  NAME,
  NEEDS,
  getColdTemperatureBackParam,
  getHeatTemperatureBackParam,
  getStationWaterTankHotTemperatureMinParam,
  getTemperatureColdParam,
  getTemperatureHeatParam,
  getTemperatureHotWaterBackParam,
  getTemperatureHotWaterParam
} from '../../../../../../server/models/design/description.model';
import DescriptionsPageContext from '../../../../contexts/DescriptionsPageContext';
import PopupContext from '../../../../contexts/PopupContext';
import wcImg from '../../../../image/weatherCompensation.png';
import { isObjNullOrEmpty } from '../../../../utils/data.utils';
import { getDefaultValue } from '../../../../utils/project.utils';
import Bloc from '../../../Bloc/Bloc';
import FormInput from '../../../Form/FormInput';
import SubstationTabs from '../SubstationTabs/SubstationTabs';
import WeatherSection from '../WeatherSection/WeatherSection';
import './DescriptionTab.css';

const DescriptionTab = ({ project, descIndex }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { setProjectParent, addErrorByDesc, removeErrorsByDesc } = useContext(
    DescriptionsPageContext
  );
  const { openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [gasValues, setGasValues] = useState({
    InitAppointGasChecked: false,
    InitReleveGasChecked: false,
    isInitAppointGasDisabled: false,
    isInitReleveGasDisabled: false
  });
  //#endregion

  //#region [effects]
  useEffect(() => {
    if (isObjNullOrEmpty(project)) return;
    const { InitGasEnabled, InitReleveEnabled } = project.otherValues;
    if (InitGasEnabled) {
      setGasValues({
        InitAppointGasChecked: !InitReleveEnabled,
        InitReleveGasChecked: InitReleveEnabled,
        isInitAppointGasDisabled: InitReleveEnabled,
        isInitReleveGasDisabled: !InitReleveEnabled
      });
    }
  }, [project?.AhsID]);
  //#endregion

  //#region [methods]
  const handleParamChange = (key, value) => {
    setProjectParent((projectParent) => {
      const projectParentCopy = cloneDeep(projectParent);
      if (project.AhsID === projectParent.AhsID) {
        projectParentCopy[key] = value;
      } else {
        projectParentCopy.children[descIndex - 1][key] = value;
      }
      return projectParentCopy;
    });
  };

  const handleSstParamChange = (sstIndex, key, value) => {
    setProjectParent((projectParent) => {
      const projectParentCopy = cloneDeep(projectParent);
      if (project.AhsID === projectParent.AhsID) {
        projectParentCopy.substations[sstIndex][key] = value;
      } else {
        projectParentCopy.children[descIndex - 1].substations[sstIndex][key] =
          value;
      }
      return projectParentCopy;
    });
  };

  const handleTempHeatBackChange = (value) => {
    const delta = project.substations[0]?.InitTemperatureHeat - value;
    handleSstParamChange(0, 'InitTemperatureHeatDelta', delta);
  };

  const handleTempColdBackChange = (value) => {
    const delta = value - project.substations[0]?.InitTemperatureCold;
    handleSstParamChange(0, 'InitStationEvapTemperatureDelta', delta);
  };

  const handleGasValueChange = (appointEnabled, releveEnabled) => {
    setProjectParent((projectParent) => {
      const projectParentCopy = cloneDeep(projectParent);
      if (project.AhsID === projectParent.AhsID) {
        projectParentCopy.otherValues.InitReleveEnabled = releveEnabled;
        projectParentCopy.otherValues.InitGasEnabled =
          appointEnabled || releveEnabled;
      } else {
        const childIndex = descIndex - 1;
        projectParentCopy.children[childIndex].otherValues.InitReleveEnabled =
          releveEnabled;
        projectParentCopy.children[childIndex].otherValues.InitGasEnabled =
          appointEnabled || releveEnabled;
      }
      return projectParentCopy;
    });
  };

  const handleGasChange = (key, value) => {
    const newGasValues = { ...gasValues };
    if (key === 'InitAppointGasChecked' && value) {
      newGasValues.InitAppointGasChecked = true;
      newGasValues.InitReleveGasChecked = false;
      newGasValues.isInitAppointGasDisabled = false;
      newGasValues.isInitReleveGasDisabled = true;
      handleGasValueChange(true, false);
    } else if (key === 'InitReleveGasChecked' && value) {
      newGasValues.InitAppointGasChecked = false;
      newGasValues.InitReleveGasChecked = true;
      newGasValues.isInitAppointGasDisabled = true;
      newGasValues.isInitReleveGasDisabled = false;
      handleGasValueChange(false, true);
    } else {
      newGasValues.InitAppointGasChecked = false;
      newGasValues.InitReleveGasChecked = false;
      newGasValues.isInitAppointGasDisabled = false;
      newGasValues.isInitReleveGasDisabled = false;
      handleGasValueChange(false, false);
    }
    setGasValues(newGasValues);
  };

  const handleOtherValueChange = (key, value) => {
    setProjectParent((projectParent) => {
      const projectParentCopy = cloneDeep(projectParent);
      if (key === 'ItesEnabled') {
        projectParentCopy.substations.forEach(
          (sst) => (sst.ItesEnabled = value)
        );
      }
      if (project.AhsID === projectParent.AhsID) {
        projectParentCopy.otherValues[key] = value;
      } else {
        projectParentCopy.children[descIndex - 1].otherValues[key] = value;
      }
      return projectParentCopy;
    });
  };

  const handleSstHeatNeedsChange = (value) => {
    setProjectParent((projectParent) => {
      const projectParentCopy = cloneDeep(projectParent);
      if (project.AhsID === projectParent.AhsID) {
        projectParentCopy.substations[0].InitStationHasHeatNeeds = value;
        projectParentCopy.otherValues.InitWaterLawIsUsed = false;
      } else {
        const childIndex = descIndex - 1;
        projectParentCopy.children[
          childIndex
        ].substations[0].InitStationHasHeatNeeds = value;
        projectParentCopy.children[
          childIndex
        ].otherValues.InitWaterLawIsUsed = false;
      }
      return projectParentCopy;
    });
  };
  //#endregion

  //#region [render]
  const { description } = project;
  const {
    InitStationHasHeatNeeds,
    InitTemperatureHeat,
    InitTemperatureHeatDelta,
    InitStationHasColdNeeds,
    InitTemperatureCold,
    InitStationEvapTemperatureDelta,
    InitStationHasHotWaterNeeds,
    InitTemperatureHotWater,
    InitTemperatureHotWaterBack
  } = project.substations[0];

  const {
    InitWaterLawIsUsed,
    InitStationTemperatureSetPoint,
    InitStationWaterTankHotTemperatureMin,
    InitBtesEnabled,
    InitLoopIsUsed,
    InitLoopWaterType,
    InitHPAInjectionEnabled,
    InitHPAHeatingEnabled,
    InitHPACoolingEnabled,
    ItesEnabled
  } = project.otherValues;

  const InitTemperatureHeatParam = getTemperatureHeatParam(
    InitStationHasHeatNeeds
  );
  const InitHeatTemperatureBackParam = getHeatTemperatureBackParam(
    InitStationHasHeatNeeds,
    InitTemperatureHeat
  );
  const InitStationWaterTankHotTemperatureMinParam =
    getStationWaterTankHotTemperatureMinParam(InitTemperatureHeat);
  const InitTemperatureColdParam = getTemperatureColdParam(
    InitStationHasColdNeeds
  );
  const InitColdTemperatureBackParam = getColdTemperatureBackParam(
    InitStationHasColdNeeds,
    InitTemperatureCold
  );
  const InitTemperatureHotWaterParam = getTemperatureHotWaterParam(
    InitStationHasHotWaterNeeds
  );
  const InitHotWaterTemperatureBackParam = getTemperatureHotWaterBackParam(
    InitStationHasHotWaterNeeds
  );

  return !isObjNullOrEmpty(project) ? (
    <Fragment>
      <Bloc title={i18n._('description.description')}>
        <FormInput
          value={description}
          onBlur={(value) => handleParamChange('description', value)}
          param={NAME.description}
          addError={() => addErrorByDesc(descIndex)}
          removeError={() => removeErrorsByDesc(descIndex, 1)}
        />
      </Bloc>
      <Bloc
        title={i18n._('description.projectNeeds')}
        className='description-bloc'
      >
        {/* --- Heat --- */}
        <div className='description-need-row'>
          <Form.Check
            type='switch'
            label={i18n._('description.InitStationHasHeatNeeds')}
            checked={InitStationHasHeatNeeds}
            className='description-need-toggle'
            onChange={(evt) => handleSstHeatNeedsChange(evt.target.checked)}
          />
          <div className='description-need-inputs'>
            <span className='form-input-label'>
              {i18n._('description.heatProdRegime')}
            </span>
            <div className='description-inputs-row'>
              <FormInput
                value={InitTemperatureHeat}
                disabled={!InitStationHasHeatNeeds}
                onBlur={(value) =>
                  handleSstParamChange(0, 'InitTemperatureHeat', value)
                }
                param={InitTemperatureHeatParam}
                addError={() => addErrorByDesc(descIndex)}
                removeError={() => removeErrorsByDesc(descIndex, 1)}
                unit
                className='description-input'
              />
              <FormInput
                value={InitTemperatureHeat - InitTemperatureHeatDelta}
                disabled={!InitStationHasHeatNeeds}
                onBlur={(value) => handleTempHeatBackChange(value)}
                param={InitHeatTemperatureBackParam}
                addError={() => addErrorByDesc(descIndex)}
                removeError={() => removeErrorsByDesc(descIndex, 1)}
                unit
                className='description-input'
              />
            </div>
            <span className='form-input-small-text'>
              {i18n._('description.heatProdRegimeDefault', {
                defaultHeat: InitTemperatureHeatParam.default,
                defaultHeatBack:
                  InitTemperatureHeatParam.default -
                  NEEDS.InitTemperatureHeatDelta.default
              })}
            </span>
            <div className='description-wc-row'>
              <Form.Check
                type='switch'
                label={i18n._('description.InitWaterLawIsUsed')}
                checked={InitWaterLawIsUsed}
                onChange={(evt) =>
                  handleOtherValueChange(
                    'InitWaterLawIsUsed',
                    evt.target.checked
                  )
                }
                disabled={!InitStationHasHeatNeeds}
              />
              <FontAwesomeIcon
                icon='circle-question'
                className='info-icon'
                onClick={() =>
                  openInfoModal(
                    i18n._('description.wcModalTitle'),
                    <img
                      src={wcImg}
                      alt='wcImg'
                      style={{ width: '90%', height: '90%' }}
                    />
                  )
                }
              />
            </div>
            <div className='description-labels-row'>
              <span>
                {i18n._('description.InitStationTemperatureSetPoint')}
              </span>
              <span>
                {i18n._('description.InitStationWaterTankHotTemperatureMin')}
              </span>
            </div>
            <div className='description-inputs-row'>
              <FormInput
                value={InitStationTemperatureSetPoint}
                param={NEEDS.InitStationTemperatureSetPoint}
                onBlur={(value) =>
                  handleOtherValueChange(
                    'InitStationTemperatureSetPoint',
                    value
                  )
                }
                addError={() => addErrorByDesc(descIndex)}
                removeError={() => removeErrorsByDesc(descIndex, 1)}
                unit
                disabled={!InitStationHasHeatNeeds || !InitWaterLawIsUsed}
                className='description-input'
              />
              <FormInput
                value={InitStationWaterTankHotTemperatureMin}
                param={InitStationWaterTankHotTemperatureMinParam}
                onBlur={(value) =>
                  handleOtherValueChange(
                    'InitStationWaterTankHotTemperatureMin',
                    value
                  )
                }
                addError={() => addErrorByDesc(descIndex)}
                removeError={() => removeErrorsByDesc(descIndex, 1)}
                unit
                disabled={!InitStationHasHeatNeeds || !InitWaterLawIsUsed}
                className='description-input'
              />
            </div>
            <div className='description-labels-row'>
              <span className='form-input-small-text'>
                {getDefaultValue(i18n, NEEDS.InitStationTemperatureSetPoint)}
              </span>
              <span className='form-input-small-text'>
                {i18n._('stationWaterTankHotTempMin.instructions', {
                  default: InitStationWaterTankHotTemperatureMinParam.default
                })}
              </span>
            </div>
          </div>
        </div>
        {/* --- Air conditioner --- */}
        <div className='description-need-row'>
          <div className='description-need-toggle'>
            <Form.Check
              type='switch'
              label={i18n._('description.InitStationHasColdNeeds')}
              checked={InitStationHasColdNeeds}
              className='description-need-toggle'
              onChange={(evt) =>
                handleSstParamChange(
                  0,
                  'InitStationHasColdNeeds',
                  evt.target.checked
                )
              }
            />
          </div>
          <div className='description-need-inputs'>
            <span className='form-input-label'>
              {i18n._('description.coldProdRegime')}
            </span>
            <div className='description-inputs-row'>
              <FormInput
                value={InitTemperatureCold}
                disabled={!InitStationHasColdNeeds}
                onBlur={(value) =>
                  handleSstParamChange(0, 'InitTemperatureCold', value)
                }
                param={InitTemperatureColdParam}
                addError={() => addErrorByDesc(descIndex)}
                removeError={() => removeErrorsByDesc(descIndex, 1)}
                unit
                className='description-input'
              />
              <FormInput
                value={InitTemperatureCold + InitStationEvapTemperatureDelta}
                disabled={!InitStationHasColdNeeds}
                onBlur={(value) => handleTempColdBackChange(value)}
                param={InitColdTemperatureBackParam}
                addError={() => addErrorByDesc(descIndex)}
                removeError={() => removeErrorsByDesc(descIndex, 1)}
                unit
                className='description-input'
              />
            </div>
            <span className='form-input-small-text'>
              {i18n._('description.coldProdRegimeDefault', {
                defaultCold: InitTemperatureColdParam.default,
                defaultColdBack:
                  InitTemperatureColdParam.default +
                  NEEDS.InitStationEvapTemperatureDelta.default
              })}
            </span>
          </div>
        </div>
        {/* --- Hot water --- */}
        <div className='description-need-row'>
          <div className='description-need-toggle'>
            <Form.Check
              type='switch'
              label={i18n._('description.InitStationHasHotWaterNeeds')}
              checked={InitStationHasHotWaterNeeds}
              className='description-need-toggle'
              onChange={(evt) =>
                handleSstParamChange(
                  0,
                  'InitStationHasHotWaterNeeds',
                  evt.target.checked
                )
              }
            />
          </div>
          <div className='description-need-inputs'>
            <span className='form-input-label'>
              {i18n._('description.ecsProdTemp')}
            </span>
            <div className='description-inputs-row'>
              <FormInput
                value={InitTemperatureHotWater}
                disabled={!InitStationHasHotWaterNeeds}
                onBlur={(value) =>
                  handleSstParamChange(0, 'InitTemperatureHotWater', value)
                }
                param={InitTemperatureHotWaterParam}
                addError={() => addErrorByDesc(descIndex)}
                removeError={() => removeErrorsByDesc(descIndex, 1)}
                unit
                className='description-input'
              />
              <FormInput
                value={InitTemperatureHotWaterBack}
                disabled={!InitStationHasHotWaterNeeds}
                onBlur={(value) =>
                  handleSstParamChange(0, 'InitTemperatureHotWaterBack', value)
                }
                param={InitHotWaterTemperatureBackParam}
                addError={() => addErrorByDesc(descIndex)}
                removeError={() => removeErrorsByDesc(descIndex, 1)}
                unit
                className='description-input'
              />
            </div>
            <span className='form-input-small-text'>
              {i18n._('description.ecsProdTempDefault', {
                defaultEcs: InitTemperatureHotWaterParam.default,
                defaultEcsBack: InitHotWaterTemperatureBackParam.default
              })}
            </span>
          </div>
        </div>
      </Bloc>
      <Bloc
        title={i18n._('description.projectDetails')}
        className='description-bloc'
      >
        <div className='description-detail'>
          <h2>{i18n._('description.btes')}</h2>
          <Form.Check
            type='switch'
            label={i18n._('description.InitBtesEnabled')}
            checked={!!InitBtesEnabled}
            onChange={(evt) =>
              handleOtherValueChange('InitBtesEnabled', evt.target.checked)
            }
          />
          <div className='description-detail-checkbx-wrapper'>
            <Form.Check
              type='checkbox'
              label={i18n._('description.InitLoopIsUsed')}
              checked={!!InitLoopIsUsed}
              onChange={(evt) =>
                handleOtherValueChange('InitLoopIsUsed', evt.target.checked)
              }
              disabled={!InitBtesEnabled}
            />
            <Form.Check
              type='checkbox'
              label={i18n._('description.InitLoopWaterType')}
              checked={InitLoopWaterType === 'B'}
              onChange={(evt) =>
                handleOtherValueChange(
                  'InitLoopWaterType',
                  evt.target.checked ? 'B' : 'W'
                )
              }
              disabled={!InitBtesEnabled}
            />
            <Form.Check
              type='checkbox'
              label={i18n._('description.InitHPAInjectionEnabled')}
              checked={!!InitHPAInjectionEnabled}
              onChange={(evt) =>
                handleOtherValueChange(
                  'InitHPAInjectionEnabled',
                  evt.target.checked
                )
              }
              disabled={!InitBtesEnabled}
            />
          </div>
        </div>
        <div className='description-detail'>
          <h2>{i18n._('description.heat')}</h2>
          <span className='bold'>
            {i18n._('description.heatProductionSolutions')}
          </span>
          <div className='description-detail-checkbx-wrapper'>
            <Form.Check
              type='checkbox'
              label={i18n._('description.InitAppointGasChecked')}
              checked={gasValues.InitAppointGasChecked}
              onChange={(evt) =>
                handleGasChange('InitAppointGasChecked', evt.target.checked)
              }
              disabled={gasValues.isInitAppointGasDisabled}
            />
            <Form.Check
              type='checkbox'
              label={i18n._('description.InitReleveGasChecked')}
              checked={gasValues.InitReleveGasChecked}
              onChange={(evt) =>
                handleGasChange('InitReleveGasChecked', evt.target.checked)
              }
              disabled={gasValues.isInitReleveGasDisabled}
            />
            <Form.Check
              type='checkbox'
              label={i18n._('description.hpa')}
              checked={!!InitHPAHeatingEnabled}
              onChange={(evt) =>
                handleOtherValueChange(
                  'InitHPAHeatingEnabled',
                  evt.target.checked
                )
              }
            />
          </div>
        </div>
        <div className='description-detail'>
          <h2>{i18n._('description.airConditioner')}</h2>
          <span className='bold'>
            {i18n._('description.coldProductionSolutions')}
          </span>
          <div className='description-detail-checkbx-wrapper'>
            <Form.Check
              type='checkbox'
              label={i18n._('description.hpa')}
              checked={!!InitHPACoolingEnabled}
              onChange={(evt) =>
                handleOtherValueChange(
                  'InitHPACoolingEnabled',
                  evt.target.checked
                )
              }
            />
            <Form.Check
              type='checkbox'
              label={i18n._('description.ItesEnabled')}
              checked={!!ItesEnabled}
              onChange={(evt) =>
                handleOtherValueChange('ItesEnabled', evt.target.checked)
              }
            />
          </div>
        </div>
      </Bloc>
      <SubstationTabs project={project} descIndex={descIndex} />
      <WeatherSection project={project} descIndex={descIndex} />
    </Fragment>
  ) : null;
  //#endregion
};

export default DescriptionTab;
