// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compare-expandable-table tbody tr:first-of-type .table-label>span {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 10px;
}

.compare-expandable-table tbody tr:first-of-type .table-label>span>svg {
    color: var(--primary-color);
    background-color: white;
    font-size: 20px;
}`, "",{"version":3,"sources":["webpack://./src/components/CompareResultsExpandableTable/CompareResultsExpandableTable.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,yBAAyB;QACrB,sBAAsB;YAClB,mBAAmB;IAC3B,SAAS;AACb;;AAEA;IACI,2BAA2B;IAC3B,uBAAuB;IACvB,eAAe;AACnB","sourcesContent":[".compare-expandable-table tbody tr:first-of-type .table-label>span {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    gap: 10px;\n}\n\n.compare-expandable-table tbody tr:first-of-type .table-label>span>svg {\n    color: var(--primary-color);\n    background-color: white;\n    font-size: 20px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
