import React from 'react';
import { Navigate } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

const RequireAdmin = ({ children, redirectTo }) => {
  //#region [auth]
  const { user } = useAuth();
  //#endregion

  //#region [render]
  return user.IS_SUPERADMIN ? children : <Navigate to={redirectTo} />;
  //#endregion
};

export default RequireAdmin;
