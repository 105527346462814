import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Button } from 'react-bootstrap';
import { WEATHER_COLS } from '../../../../../../server/constants/weatherCol.constant';
import { fetchWeatherFile } from '../../../../api/project.api';
import DescriptionsPageContext from '../../../../contexts/DescriptionsPageContext';
import PopupContext from '../../../../contexts/PopupContext';
import {
  checkForFileError,
  downloadFile,
  parseFileName
} from '../../../../utils/file.utils';
import WeatherModal from '../WeatherModal';
import './WeatherSection.css';

export const WeatherSection = ({ project, descIndex }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { setProjectParent, addErrorByDesc, removeErrorsByDesc } = useContext(
    DescriptionsPageContext
  );
  const { openInfoModal, openToast, openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [filename, setFilename] = useState();
  const [isFileMissing, setIsFileMissing] = useState(false);
  //#endregion

  //#region [refs]
  const inputOpenFileRef = useRef();
  //#endregion

  //#region [effects]
  useEffect(() => {
    if (!project.ahsWeatherFileOriginalName && !project.weatherFile) {
      addErrorByDesc(descIndex);
      setIsFileMissing(true);
    } else if (project.weatherFile) {
      setFilename(project.weatherFile.name);
    } else {
      setFilename(project.ahsWeatherFileOriginalName);
    }
  }, [project.ahsWeatherFileOriginalName, project.weatherFile]);
  //#endregion

  //#region [memos]
  const weatherModal = useMemo(() => {
    return WeatherModal(i18n);
  }, []);
  //#endregion

  //#region [methods]
  const handleFileChange = (evt) => {
    try {
      const csvReader = new FileReader();
      const file = evt.target.files[0];
      if (!file) return;

      csvReader.readAsText(file);
      const { fileExt } = parseFileName(file.name);
      csvReader.onload = (event) => {
        try {
          const error = checkForFileError(
            i18n,
            event.target.result,
            fileExt,
            WEATHER_COLS
          );
          if (error) {
            console.error(error);
            openToast(i18n._('weather.error'), error);
          } else {
            setFilename(file.name);
            changeWeatherFile(file);
            if (isFileMissing) {
              removeErrorsByDesc(descIndex, 1);
              setIsFileMissing(false);
            }
          }
        } catch (err) {
          console.error(err);
          openErrorToast(err);
        }
      };
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const changeWeatherFile = (file) => {
    setProjectParent((projectParent) => {
      const projectParentCopy = cloneDeep(projectParent);
      if (project.AhsID === projectParent.AhsID) {
        projectParentCopy.weatherFile = file;
      } else {
        projectParentCopy.children[descIndex - 1].weatherFile = file;
      }
      return projectParentCopy;
    });
  };

  const handleDlClick = async () => {
    try {
      let file;
      if (project.weatherFile) {
        file = project.weatherFile;
      } else {
        file = await fetchWeatherFile(project.AhsID);
      }
      downloadFile(file.name, new Blob([file], { type: 'text/csv' }));
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [states]
  return (
    <div className='description-file-bloc'>
      <h3>{i18n._('projectNeeds.meteoSTD')}</h3>
      <div className='description-file-children'>
        <div className='weather-section description-file-children'>
          <p className='description-file-label'>
            {i18n._('projectNeeds.fileMeteoSTD')}
            <FontAwesomeIcon
              icon='circle-question'
              className='info-icon'
              onClick={() =>
                openInfoModal(weatherModal.title, weatherModal.body)
              }
            />
          </p>
          <div className='description-file-btns'>
            <input
              ref={inputOpenFileRef}
              type='file'
              accept='.csv, .try'
              style={{ display: 'none' }}
              onChange={handleFileChange}
            />
            <Button
              variant='primary'
              onClick={() => inputOpenFileRef.current.click()}
            >
              <FontAwesomeIcon icon='upload' />
              {i18n._('browse')}
            </Button>
            {project.AhsID && (
              <Button
                variant='outline-secondary'
                onClick={handleDlClick}
                disabled={!filename}
              >
                <FontAwesomeIcon icon='download' />
                {i18n._('download')}
              </Button>
            )}
            <div className='description-file-csv-body'>
              <div className='csv-file'>
                <FontAwesomeIcon icon='file-csv' />
                {isFileMissing ? (
                  <span className='description-file-missing'>
                    {i18n._('projectNeeds.noWeatherFile')}
                  </span>
                ) : (
                  filename
                )}
              </div>
              <span className='csv-format'>
                {i18n._('projectNeeds.csvFormat')}
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
  //#endregion
};

export default WeatherSection;
