import { useLingui } from '@lingui/react';
import React, {
  Fragment,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { Alert } from 'react-bootstrap';
import { COMPUTE_TYPE } from '../../../../../../../../server/constants/constraint.constant';
import { PV_EQUIPMENT } from '../../../../../../../../server/constants/performance.constant';
import {
  COMMON_CONSTRAINT,
  SIMU,
  getBalanceDuration
} from '../../../../../../../../server/models/design/constraint.model';
import {
  fetchNextCalculationData,
  fetchResultsByType
} from '../../../../../../api/project.api';
import FormInput from '../../../../../../components/Form/FormInput';
import FormSelect from '../../../../../../components/Form/FormSelect';
import ResultSelect from '../../../../../../components/ResultSelect/ResultSelect';
import PopupContext from '../../../../../../contexts/PopupContext';
import ProjectContext from '../../../../../../contexts/ProjectContext';
import SimulationContext from '../../../../../../contexts/SimulationContext';
import useAuth from '../../../../../../hooks/useAuth';
import { setCalculationGlobalParams } from '../../../../../../utils/compute.utils';
import {
  isArrNullOrEmpty,
  isObjNullOrEmpty
} from '../../../../../../utils/data.utils';
import CalculationForm from '../../CalculationForm/CalculationForm';
import { default as KCPVModal } from '../../KilowattCretePVModal';
import SimuDescriptionSelect from '../../SimuDescriptionSelect';
import SubstationInputs from '../../SubstationInputs/SubstationsInputs';
import './SimulationForm.css';

const SimulationForm = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [auth]
  const { user } = useAuth();
  //#endregion

  //#region [contexts]
  const { project } = useContext(ProjectContext);
  const { openErrorToast, openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [groupedResults, setGroupedResults] = useState([]);
  const [selectedResultIndexes, setSelectedResultIndexes] = useState([0, 0]);
  const [calculationData, setCalculationData] = useState();
  const [areParamsChanged, setAreParamsChanged] = useState(false);
  const [nbErrors, setNbErrors] = useState(0);
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        await handleResultsRefresh();
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, []);
  //#endregion

  //#region [memos]
  const kilowattCretePVModal = useMemo(() => {
    return KCPVModal(i18n);
  }, []);

  const balanceDuration = useMemo(() => {
    if (!calculationData) return;
    const { InitYearsSimulations } = calculationData.constraints.inp;
    return InitYearsSimulations > 20
      ? getBalanceDuration()
      : getBalanceDuration(InitYearsSimulations);
  }, [calculationData?.constraints?.inp?.InitYearsSimulations]);

  const isQItesMaxDisabled = useMemo(() => {
    if (!calculationData) return;
    return calculationData.constraints.params.BtesLength !== 0;
  }, [calculationData?.constraints?.params?.BtesLength]);

  const isKiloWattCretePVInputDisabled = useMemo(() => {
    if (!calculationData) return;
    const { SolarThermalSurface } = calculationData.constraints.params;
    return (
      SolarThermalSurface > 0 &&
      project.otherValues.InitPhotovoltaicModel === PV_EQUIPMENT.DUALSUN.value
    );
  }, [
    project.otherValues.InitPhotovoltaicModel,
    calculationData?.constraints?.params?.SolarThermalSurface
  ]);
  //#endregion

  //#region [methods]
  const addError = () => {
    setNbErrors((prevErrors) => prevErrors + 1);
  };

  const removeError = () => {
    setNbErrors((prevErrors) => prevErrors - 1);
  };

  const setConstraint = (param, constraint) => {
    setCalculationData((data) => {
      const dataCopy = { ...data };
      dataCopy.constraints[param.path][param.key] = constraint;
      return dataCopy;
    });
    setAreParamsChanged(true);
  };

  const fetchSimuResults = async () => {
    try {
      const groups = await fetchResultsByType(
        project.AhsID,
        COMPUTE_TYPE.SIMU,
        true
      );
      setGroupedResults(groups);
      return groups;
    } catch (err) {
      throw err;
    }
  };

  const fetchNextCalcData = async (computeId) => {
    try {
      let data = await fetchNextCalculationData(
        project.AhsID,
        computeId,
        COMPUTE_TYPE.SIMU
      );
      data = setCalculationGlobalParams(data, user.UserID, user.UserEmail);
      setCalculationData(data);
    } catch (err) {
      throw err;
    }
  };

  const handleResultsRefresh = async () => {
    try {
      const groups = await fetchSimuResults();
      await fetchNextCalcData(groups[0]?.computes[0]?.ComputeID);
    } catch (err) {
      throw err;
    }
  };

  const handleResultChange = async (data) => {
    try {
      setSelectedResultIndexes(data.indexes);
      await fetchNextCalcData(
        groupedResults[data.indexes[0]].computes[data.indexes[1]].ComputeID
      );
      setAreParamsChanged(false);
      setNbErrors(0);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  if (!isObjNullOrEmpty(calculationData)) {
    const {
      BtesLength,
      BtesProbeDepth,
      BtesProbeDistance,
      T2final_Btes,
      HPGHeatBtesPowerMax,
      HPGColdBtesPowerMax,
      HPAPowerMax,
      SolarThermalSurface,
      KiloWattCretePV,
      QItesMax
    } = calculationData.constraints.params;
    const { InitYearsSimulations, InitBalanceDuration, InitGasPowerMax } =
      calculationData.constraints.inp;
    return (
      <SimulationContext.Provider
        value={{
          calculationData,
          setCalculationData,
          setAreParamsChanged,
          setConstraint,
          addError,
          removeError
        }}
      >
        <CalculationForm
          calculationData={calculationData}
          nbErrors={nbErrors}
          onResultsRefresh={handleResultsRefresh}
        >
          {!isArrNullOrEmpty(groupedResults) && (
            <Fragment>
              <ResultSelect
                onResultChange={handleResultChange}
                groupedResults={groupedResults}
                selectedResultIndexes={selectedResultIndexes}
              />
              {areParamsChanged && (
                <Alert variant='warning'>
                  {i18n._('compute.simu.paramsChanged')}
                </Alert>
              )}
            </Fragment>
          )}
          <FormInput
            label={i18n._('compute.InitYearsSimulations')}
            value={InitYearsSimulations}
            param={COMMON_CONSTRAINT.InitYearsSimulations}
            onBlur={(value) => {
              setConstraint(COMMON_CONSTRAINT.InitYearsSimulations, value);
            }}
            addError={addError}
            removeError={removeError}
            labelLeft
            className='compute-input'
          />
          <FormInput
            label={i18n._('compute.InitBalanceDuration')}
            value={InitBalanceDuration}
            param={balanceDuration}
            onBlur={(value) => {
              setConstraint(balanceDuration, value);
            }}
            addError={addError}
            removeError={removeError}
            labelLeft
            className='compute-input'
          />
          <SimuDescriptionSelect />
          <FormInput
            label={i18n._('compute.btesLength')}
            value={BtesLength}
            param={SIMU.BtesLength}
            onBlur={(value) => {
              setConstraint(SIMU.BtesLength, value);
            }}
            addError={addError}
            removeError={removeError}
            labelLeft
            className='compute-input'
          />
          <FormSelect
            label={i18n._('compute.btesProbeDepth')}
            value={BtesProbeDepth}
            param={SIMU.BtesProbeDepth}
            onChange={(value) => {
              setConstraint(SIMU.BtesProbeDepth, value);
            }}
            labelLeft
            className='compute-input'
          />
          <FormSelect
            label={i18n._('compute.btesProbeDistance')}
            value={BtesProbeDistance}
            param={SIMU.BtesProbeDistance}
            onChange={(value) => {
              setConstraint(SIMU.BtesProbeDistance, value);
            }}
            labelLeft
            className='compute-input'
          />
          <FormInput
            label={i18n._('compute.btesT2Final')}
            value={T2final_Btes}
            param={SIMU.T2final_Btes}
            onBlur={(value) => {
              setConstraint(SIMU.T2final_Btes, value);
            }}
            addError={addError}
            removeError={removeError}
            labelLeft
            className='compute-input'
          />
          <FormInput
            label={i18n._('compute.hpgHeatBtesPowerMax', {
              tempHeat: project.substations[0].InitTemperatureHeat
            })}
            value={HPGHeatBtesPowerMax}
            param={SIMU.HPGHeatBtesPowerMax}
            onBlur={(value) => {
              setConstraint(SIMU.HPGHeatBtesPowerMax, value);
            }}
            addError={addError}
            removeError={removeError}
            labelLeft
            className='compute-input'
          />
          <FormInput
            label={i18n._('compute.hpgColdBtesPowerMax', {
              tempCold: project.substations[0].InitTemperatureCold
            })}
            value={HPGColdBtesPowerMax}
            param={SIMU.HPGColdBtesPowerMax}
            onBlur={(value) => {
              setConstraint(SIMU.HPGColdBtesPowerMax, value);
            }}
            addError={addError}
            removeError={removeError}
            labelLeft
            className='compute-input'
          />
          <FormInput
            label={i18n._('compute.hpaPowerMax')}
            value={HPAPowerMax}
            param={SIMU.HPAPowerMax}
            onBlur={(value) => {
              setConstraint(SIMU.HPAPowerMax, value);
            }}
            addError={addError}
            removeError={removeError}
            labelLeft
            className='compute-input'
          />
          <FormInput
            label={i18n._('compute.initGasPowerMax')}
            value={InitGasPowerMax}
            param={SIMU.InitGasPowerMax}
            onBlur={(value) => {
              setConstraint(SIMU.InitGasPowerMax, value);
            }}
            addError={addError}
            removeError={removeError}
            labelLeft
            className='compute-input'
          />
          <FormInput
            label={i18n._('compute.solarThermalSurface')}
            value={SolarThermalSurface}
            param={SIMU.SolarThermalSurface}
            onBlur={(value) => {
              setConstraint(SIMU.SolarThermalSurface, value);
            }}
            addError={addError}
            removeError={removeError}
            labelLeft
            className='compute-input'
          />
          <FormInput
            label={i18n._('compute.kiloWattCretePV')}
            value={KiloWattCretePV}
            param={SIMU.KiloWattCretePV}
            onBlur={(value) => {
              setConstraint(SIMU.KiloWattCretePV, value);
            }}
            addError={addError}
            removeError={removeError}
            labelLeft
            disabled={isKiloWattCretePVInputDisabled}
            showInfoIcon
            onInfoClick={() =>
              openInfoModal(
                kilowattCretePVModal.title,
                kilowattCretePVModal.body
              )
            }
            className='compute-input'
          />
          <FormInput
            label={i18n._('compute.qItesMax')}
            value={QItesMax}
            param={SIMU.QItesMax}
            onBlur={(value) => {
              setConstraint(SIMU.QItesMax, value);
            }}
            addError={addError}
            removeError={removeError}
            labelLeft
            disabled={isQItesMaxDisabled}
            showInfoIcon
            onInfoClick={() =>
              openInfoModal(
                i18n._('compute.qItesMax'),
                i18n._('compute.qItesMax.help')
              )
            }
            className='compute-input'
          />
          <SubstationInputs
            label={i18n._('compute.hpgPowerMaxPerSST')}
            param={SIMU.HPGPowerMaxPerSST}
          />
        </CalculationForm>
      </SimulationContext.Provider>
    );
  } else {
    return null;
  }
  //#endregion
};

export default SimulationForm;
