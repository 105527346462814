const { WEATHER_COLS } = require('../constants/weatherCol.constant');
const { convertToRealType } = require('./string.utils');

const _parseCsvIntoColumns = (content) => {
  const contentNormalized = content.replace(/,/g, '.').replace(/  +/g, ' ');
  const split = contentNormalized.split('\n');
  const columns = [];
  split.forEach((line, index1) => {
    if (line.trim() === '') return;
    const delimiters = [';', '\t'];
    let lineSplits;
    for (const delimiter of delimiters) {
      if (line.includes(delimiter)) {
        lineSplits = line.split(delimiter);
        break;
      }
    }
    lineSplits.forEach((cell, index2) => {
      if (index1 === 0) {
        columns.push([cell]);
      } else {
        columns[index2].push(cell);
      }
    });
  });
  return columns;
};
const ToCelsius = (value) => '' + Math.round(parseFloat(value)) / 10;
const ToWattPerM2 = (value) => '' + Math.round((parseFloat(value) * 100) / 36);
const tryColSizes = [
  { size: 3, colName: WEATHER_COLS.STATION_ID.name },
  {
    size: 4,
    colName: WEATHER_COLS.OUTSIDE_TEMPERATURE.name,
    convertTo: ToCelsius
  },
  {
    size: 4,
    colName: WEATHER_COLS.GLOBAL_RADIATION.name,
    convertTo: ToWattPerM2
  },
  {
    size: 4,
    colName: WEATHER_COLS.DIFFUSE_RADIATION.name,
    convertTo: ToWattPerM2
  },
  {
    size: 4,
    colName: WEATHER_COLS.NORMAL_DIRECT_RADIATION.name,
    convertTo: ToWattPerM2
  },
  { size: 4, colName: WEATHER_COLS.SUNSHINE_DURATION.name },
  { size: 3, colName: WEATHER_COLS.RELATIVE_HUMIDITY.name },
  { size: 3, colName: WEATHER_COLS.WIND_SPEED.name },
  { size: 2, colName: WEATHER_COLS.MONTH.name },
  { size: 2, colName: WEATHER_COLS.DAY.name },
  { size: 2, colName: WEATHER_COLS.HOUR.name }
];

const _parseTryIntoColumns = (content) => {
  const lines = content.split('\n');
  const columns = tryColSizes.map((elt) => [elt.colName]);
  lines.forEach((line) => {
    if (line.trim() === '') return;
    let posInLine = 0;
    tryColSizes.forEach(({ size, convertTo }, index) => {
      const value = line.slice(posInLine, posInLine + size).trim();
      columns[index].push(convertTo ? convertTo(value) : value);
      posInLine += size;
    });
  });
  return columns;
};

const parseIntoColumns = (content, type) => {
  const decodedContent = decodeFileContent(content);
  if (type === 'try') return _parseTryIntoColumns(decodedContent);
  if (type === 'csv') return _parseCsvIntoColumns(decodedContent);
  throw new Error(
    `file of Type "${type}" can not be parsed, it must either be a "csv" or "try"`
  );
};

const formatWithColumnName = (columns) => {
  const hasColNames = columns.every(
    (elt) => typeof convertToRealType(elt[0]) === 'string'
  );
  let colNames;
  if (hasColNames) {
    colNames = columns.map((column) => column.shift().replace('\r', ''));
  } else {
    colNames = columns.map(() => '');
  }
  return columns.map((col, index) => ({ colName: colNames[index], data: col }));
};

const decodeFileContent = (buf) => {
  let data = buf.toString('utf16le');
  if (data.charCodeAt(0) > 65000) data = data.substr(1);
  if (data.substr(0, 5) !== 'CRYPT') return buf.toString('utf-8');
  return data
    .split('')
    .slice(7)
    .map((c) => {
      if (c === '\r' || c === '\n') return c;
      return String.fromCharCode(65535 - c.charCodeAt(0));
    })
    .join('');
};

module.exports = {
  parseIntoColumns,
  formatWithColumnName
};
