const { UNIT } = require('./unit.constant');

const WEATHER_COLS = Object.freeze({
  HOUR: {
    mandatory: false,
    name: 'HOUR',
    min: 1,
    max: 24,
    type: 'number'
  },
  STATION_ID: {
    mandatory: false,
    name: 'STATION_ID',
    error: 'errorStationId',
    type: 'text'
  },
  OUTSIDE_TEMPERATURE: {
    mandatory: true,
    name: 'OUTSIDE_TEMPERATURE',
    unit: UNIT.CELSIUS_DEGREE,
    min: -70,
    max: 70,
    type: 'number'
  },
  GLOBAL_RADIATION: {
    mandatory: false,
    name: 'GLOBAL_RADIATION',
    unit: UNIT.WATT_HOUR_PER_SQUARE_METER,
    min: 0,
    max: 2000,
    type: 'number'
  },
  DIFFUSE_RADIATION: {
    mandatory: false,
    name: 'DIFFUSE_RADIATION',
    unit: UNIT.WATT_HOUR_PER_SQUARE_METER,
    min: 0,
    max: 850,
    accepted: ['E'],
    type: 'number'
  },
  NORMAL_DIRECT_RADIATION: {
    mandatory: false,
    name: 'NORMAL_DIRECT_RADIATION',
    unit: UNIT.WATT_HOUR_PER_SQUARE_METER,
    min: 0,
    max: 1700,
    accepted: ['E'],
    type: 'number'
  },
  SUNSHINE_DURATION: {
    mandatory: false,
    name: 'SUNSHINE_DURATION',
    unit: UNIT.MINUTES,
    min: 0,
    max: 1440,
    accepted: ['E'],
    type: 'number'
  },
  RELATIVE_HUMIDITY: {
    mandatory: true,
    name: 'RELATIVE_HUMIDITY',
    unit: UNIT.PERCENT,
    min: 0,
    max: 110,
    accepted: ['E'],
    type: 'number'
  },
  WIND_SPEED: {
    mandatory: true,
    name: 'WIND_SPEED',
    unit: UNIT.METER_PER_SECOND,
    min: 0,
    max: 40,
    type: 'number'
  },
  MONTH: {
    mandatory: false,
    name: 'MONTH',
    min: 1,
    max: 12,
    type: 'number'
  },
  DAY: {
    mandatory: false,
    name: 'DAY',
    min: 1,
    max: 31,
    type: 'number'
  }
});

module.exports = { WEATHER_COLS };
