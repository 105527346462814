import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import CompareResultsTable from '../../../../../components/CompareResultsTable/CompareResultsTable';
import Section from '../../../../../components/Section/Section.jsx';
import ResultContext from '../../../../../contexts/ResultContext.jsx';
import SstTable from './components/SstTable.jsx';
import { btesSolarTotalTemplates } from './templates.js';

const SizingSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { selectedResult, comparedResults } = useContext(ResultContext);
  //#endregion

  //#region [memos]
  const BSTTemplates = useMemo(() => {
    return btesSolarTotalTemplates(i18n, comparedResults);
  }, [comparedResults]);
  //#endregion

  //#region [render]
  const { substations } = selectedResult;
  return (
    <Section title={i18n._('results.sizing')} open>
      {substations.length > 1 &&
        substations.map((sst, sstIndex) => (
          <SstTable
            key={'sizing_sst_' + sst}
            substation={sst}
            sstIndex={sstIndex}
          />
        ))}
      <Section
        title={
          substations.length > 1
            ? i18n._('totalProject')
            : i18n._('totalProjectBySst', { sst: substations[0] })
        }
        level={2}
        open
      >
        {BSTTemplates.map((template, index) => (
          <CompareResultsTable
            level={3}
            template={template}
            key={'sizing_btes_template_' + index}
          />
        ))}
      </Section>
    </Section>
  );
  //#endregion
};

export default React.memo(SizingSection);
