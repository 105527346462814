const { TYPE, USAGE } = require('../../constants/ahs.constant');
const { COUNTRY } = require('../../constants/country.constant');
const { UNIT } = require('../../constants/unit.constant');

const GENERAL = Object.freeze({
  AhsName: {
    key: 'AhsName',
    type: 'text',
    required: true,
    default: null,
    maxLength: 255
  },
  AhsDescription: {
    key: 'AhsDescription',
    type: 'text',
    default: null,
    maxLength: 800
  },
  InitBuildingSurface: {
    key: 'InitBuildingSurface',
    type: 'number',
    required: true,
    min: 100,
    max: 1000000,
    default: null,
    unit: UNIT.SQUARE_METER
  },
  AhsType: {
    key: 'AhsType',
    required: true,
    default: TYPE.CONSTRUCTION,
    type: 'radio',
    values: Object.values(TYPE),
    translateValuesIds: Object.values(TYPE).map(
      (type) => `type.${type.toLowerCase()}`
    ) // TODO côté front
  },
  AhsMainUsage: {
    key: 'AhsMainUsage',
    required: true,
    default: USAGE.TERTIARY,
    type: 'text',
    values: Object.values(USAGE),
    translateValuesIds: Object.values(USAGE).map(
      (usage) => `usage.${usage.toLowerCase()}`
    ) // TODO côté front
  },
  ahsImageFileName: {
    key: 'ahsImageFileName',
    default: null
  }
});

const LOCATION = Object.freeze({
  AhsAddress1: {
    key: 'AhsAddress1',
    type: 'text',
    required: true,
    minLength: 5,
    default: null,
    maxLength: 255
  },
  AhsZipCode: {
    key: 'AhsZipCode',
    type: 'text',
    required: true,
    pattern: /^[0-9]{5}$/,
    example: '92012',
    default: null
  },
  AhsCity: {
    key: 'AhsCity',
    type: 'text',
    required: true,
    minLength: 3,
    default: null,
    maxLength: 255
  },
  AhsCountry: {
    key: 'AhsCountry',
    required: true,
    default: 'FR',
    values: Object.keys(COUNTRY),
    translateValuesIds: Object.keys(COUNTRY).map(
      (country) => `country.${country}`
    ) // TODO côté front
  },
  InitLatitude: {
    key: 'InitLatitude',
    type: 'text',
    required: true,
    pattern:
      /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,6})?))$/,
    example: '48.8333',
    default: null
  },
  InitLongitude: {
    key: 'InitLongitude',
    type: 'text',
    required: true,
    pattern:
      /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,6})?))$/,
    example: '2.25',
    default: null
  },
  InitAltitude: {
    key: 'InitAltitude',
    type: 'number',
    required: true,
    min: 0,
    max: 8850,
    default: 0,
    unit: UNIT.METER
  }
});

const ALL_GENERAL_PARAMS = { ...GENERAL, ...LOCATION };

module.exports = { GENERAL, LOCATION, ALL_GENERAL_PARAMS };
