import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import OptimizationContext from '../../../../contexts/OptimizationContext';
import { isNull } from '../../../../utils/data.utils';
import {
  getInputErrorCode,
  getMinMaxErrorCode
} from '../../../../utils/error.utils';

const MinMaxInputs = ({ label, param, onInfoClick, disabled }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { calculationData, setBounds, addError, removeError } =
    useContext(OptimizationContext);
  //#endregion

  //#region [states]
  const [minError, setMinError] = useState();
  const [maxError, setMaxError] = useState();
  const [minClassName, setMinClassName] = useState('');
  const [maxClassName, setMaxClassName] = useState('');
  //#endregion

  //#region [refs]
  const inputMinRef = useRef();
  const inputMaxRef = useRef();
  //#endregion

  //#region [effects]
  useEffect(() => {
    if (!minError) return;
    ReactTooltip.show(inputMinRef.current);
  }, [minError]);

  useEffect(() => {
    if (!maxError) return;
    ReactTooltip.show(inputMaxRef.current);
  }, [maxError]);

  useEffect(() => {
    if (
      !param ||
      !inputMinRef ||
      !inputMinRef.current ||
      !inputMaxRef ||
      !inputMaxRef.current
    )
      return;

    const minValue = calculationData.constraints.bounds[0][param.key];
    if (inputMinRef?.current?.value != minValue) {
      inputMinRef.current.value = minValue;
    }

    const maxValue = calculationData.constraints.bounds[1][param.key];
    if (inputMaxRef?.current?.value != maxValue) {
      inputMaxRef.current.value = maxValue;
    }

    resetErrors();
    if (disabled || checkForErrors()) return;
    manageMinMaxChanges(minValue, maxValue);
  }, [
    param,
    disabled,
    calculationData.constraints.bounds[0][param.key],
    calculationData.constraints.bounds[1][param.key]
  ]);
  //#endregion

  //#region [methods]
  const resetErrors = () => {
    if (!minError && !maxError) return;
    removeError();
    setMinError(() => null);
    setMaxError(() => null);
  };

  const manageMinError = (errorCode) => {
    if (!errorCode) return;
    addError();
    setMinError(() => errorCode);
    setMinClassName('form-input-error');
  };

  const manageMaxError = (errorCode) => {
    if (!errorCode) return;
    addError();
    setMaxError(() => errorCode);
    setMaxClassName('form-input-error');
  };

  const manageMinMaxError = (errorCode) => {
    if (!errorCode) return;
    addError();
    setMinError(() => errorCode);
    setMaxError(() => errorCode);
    setMinClassName('form-input-error');
    setMaxClassName('form-input-error');
  };

  const checkForErrors = () => {
    const minValue = +inputMinRef.current.value;
    const maxValue = +inputMaxRef.current.value;

    const errorCode1 = getInputErrorCode(minValue, param, false);
    manageMinError(errorCode1);
    if (errorCode1) return true;

    const errorCode2 = getInputErrorCode(maxValue, param, false);
    manageMaxError(errorCode2);
    if (errorCode2) return true;

    const errorCode3 = getMinMaxErrorCode(minValue, maxValue);
    manageMinMaxError(errorCode3);
    if (errorCode3) return true;

    return false;
  };

  const manageMinMaxChanges = (min, max) => {
    const isEmpty = (val) => isNull(val) || val === '';
    const manageChange = (val, defaultParamValue, setClassName) => {
      setClassName(
        (isEmpty(val) && isEmpty(defaultParamValue)) || val == defaultParamValue
          ? ''
          : 'form-input-changed'
      );
    };
    manageChange(min, param.default[0], setMinClassName);
    manageChange(max, param.default[1], setMaxClassName);
  };

  const handleInputBlur = () => {
    resetErrors();
    if (checkForErrors()) return;
    const minValue = +inputMinRef.current.value;
    const maxValue = +inputMaxRef.current.value;
    setBounds(param.key, [minValue, maxValue]);
    manageMinMaxChanges(minValue, maxValue);
  };
  //#endregion

  //#region [render]
  const inputMinId = `input_min_${param.key}`;
  const inputMaxId = `input_max_${param.key}`;
  return (
    <Row>
      <Col className='opti-inputs-label' xs={6}>
        {label}
        {onInfoClick && (
          <FontAwesomeIcon
            icon='circle-question'
            className='info-icon'
            onClick={onInfoClick}
          />
        )}
      </Col>
      <Col xs={3}>
        <Form.Control
          className={`${minClassName} form-input`}
          type={param.type}
          disabled={disabled}
          onBlur={handleInputBlur}
          defaultValue={
            !isNull(calculationData.constraints.bounds[0][param.key])
              ? calculationData.constraints.bounds[0][param.key]
              : ''
          }
          data-tip
          data-for={inputMinId}
          ref={inputMinRef}
        />
        {minError && (
          <ReactTooltip
            id={inputMinId}
            className='error-tooltip'
            delayHide={3000}
            effect='solid'
            place='bottom'
          >
            {i18n._(minError.translateId, minError.translateParams)}
          </ReactTooltip>
        )}
      </Col>
      <Col xs={3}>
        <Form.Control
          className={`${maxClassName} form-input`}
          type={param.type}
          disabled={disabled}
          onBlur={handleInputBlur}
          defaultValue={
            !isNull(calculationData.constraints.bounds[1][param.key])
              ? calculationData.constraints.bounds[1][param.key]
              : ''
          }
          data-tip
          data-for={inputMaxId}
          ref={inputMaxRef}
        />
        {maxError && (
          <ReactTooltip
            id={inputMaxId}
            className='error-tooltip'
            delayHide={3000}
            effect='solid'
            place='bottom'
          >
            {i18n._(maxError.translateId, maxError.translateParams)}
          </ReactTooltip>
        )}
      </Col>
    </Row>
  );
  //#endregion
};

export default MinMaxInputs;
