const { UNIT } = require('./unit.constant');

const STD_COLS = Object.freeze({
  TIME_CODE: {
    mandatory: true,
    unit: null,
    min: 1,
    max: 8760,
    isNeed: false,
    name: 'TIME_CODE',
    type: 'number'
  },
  HEAT_NEED: {
    mandatory: true,
    unit: UNIT.KILOWATT,
    range: null,
    isNeed: true,
    name: 'HEAT_NEED',
    min: 0,
    type: 'number'
  },
  COLD_NEED: {
    mandatory: true,
    unit: UNIT.KILOWATT,
    range: null,
    isNeed: true,
    name: 'COLD_NEED',
    min: 0,
    type: 'number'
  },
  HOTWATER_NEED: {
    mandatory: true,
    unit: UNIT.KILOWATT,
    range: null,
    isNeed: true,
    name: 'HOTWATER_NEED',
    min: 0,
    type: 'number'
  },
  ELECTRICITY_PRICE: {
    mandatory: true,
    unit: UNIT.EURO_PER_KILOWATT,
    range: null,
    isNeed: false,
    name: 'ELECTRICITY_PRICE',
    min: 0,
    type: 'number'
  },
  PHOTOVOLTAIC_PRODUCTION: {
    mandatory: false,
    unit: UNIT.KILOWATT_PER_SQUARE_METER,
    range: null,
    isNeed: false,
    name: 'PHOTOVOLTAIC_PRODUCTION',
    type: 'number'
  },
  SOLAR_THERMAL_PRODUCTION: {
    mandatory: false,
    unit: UNIT.KILOWATT_PER_SQUARE_METER,
    range: null,
    isNeed: false,
    name: 'SOLAR_THERMAL_PRODUCTION',
    type: 'number'
  },
  ELECTRICITY_CONSUMPTION: {
    mandatory: false,
    unit: UNIT.KILOWATT_HOUR_ELECTRIC,
    range: null,
    isNeed: false,
    name: 'ELECTRICITY_CONSUMPTION',
    type: 'number'
  },
  ELECTRICITY_PRICE_PV_PROJECT: {
    mandatory: false,
    unit: UNIT.EURO_PER_KILOWATT_HOUR_ELECTRIC,
    range: null,
    isNeed: false,
    name: 'ELECTRICITY_PRICE_PV_PROJECT',
    type: 'number'
  },
  ELECTRICITY_PRICE_PV_NETWORK: {
    mandatory: false,
    unit: UNIT.EURO_PER_KILOWATT_HOUR_ELECTRIC,
    range: null,
    isNeed: false,
    name: 'ELECTRICITY_PRICE_PV_NETWORK',
    type: 'number'
  }
});

module.exports = { STD_COLS };
