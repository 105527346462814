import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import LinesChart from '../../../../../../../components/Chart/LinesChart';
import { MODE } from '../../../../../../../constants/plot.constant';
import ProjectContext from '../../../../../../../contexts/ProjectContext';
import { COP_EER_CHART_COLORS } from '../../../../../../../styles/colors/copEer.color';

const COPEERPerYearLinesChart = ({ result, substation }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { project } = useContext(ProjectContext);
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    const copEerPerYear = substation
      ? !result.ComputeResult.summary.PerSub?.CopEerPerYear[substation]
        ? null
        : result.ComputeResult.summary.PerSub.CopEerPerYear[substation]
      : !result.ComputeResult.summary.COPEERPerYear
      ? null
      : result.ComputeResult.summary.COPEERPerYear;
    if (!copEerPerYear) return null;

    const { InitYearsSimulations } = result.ComputeResult.inp;
    const x = new Array(InitYearsSimulations + 1).fill(0).map((_, i) => i + 1);
    return Object.entries(copEerPerYear).map(([copEerType, values]) => ({
      x,
      y: values,
      name: i18n._(`results.energyAndCarbon.copEerPerYearTable.${copEerType}`),
      line: { color: COP_EER_CHART_COLORS[copEerType] },
      mode: MODE.LINES_MARKERS
    }));
  }, [result.ComputeID, substation]);
  //#endregion

  //#region [render]
  return data ? (
    <LinesChart
      data={data}
      title={i18n._(
        'results.energyAndCarbon.energyBalance.copEerPerYearLinesChart.title',
        { years: result.ComputeResult.inp.InitYearsSimulations }
      )}
      filename={i18n._('plotly.filename', {
        projectName: project.AhsName,
        title: i18n._(
          'results.energyAndCarbon.energyBalance.copEerPerYearLinesChart.title'
        )
      })}
    />
  ) : null;
  //#endregion
};

export default COPEERPerYearLinesChart;
