import React from 'react';
import './Bloc.css';

const Bloc = ({ title, children, className, style }) => {
  return (
    <div className={className}>
      <div className='bloc' style={style}>
        <h3 className='bloc-title'>{title}</h3>
        <div className='bloc-children'>{children}</div>
      </div>
    </div>
  );
};

export default Bloc;
