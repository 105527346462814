import React from 'react';
import './OptiSection.css';

export const OptiSection = ({ title, children }) => {
  return (
    <div className='opti-section'>
      <h5 className='opti-section-title'>{title}</h5>
      {children}
    </div>
  );
};
