// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.opti-dim-section-header {
    font-weight: 500;
    font-size: 15px;
    text-align: center;
}

.opti-dim-section .col-3 {
    padding-left: 10px;
}`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/components/ComputeForm/optimization/sections/OptiSizingSection/OptiSizingSection.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;AACtB","sourcesContent":[".opti-dim-section-header {\n    font-weight: 500;\n    font-size: 15px;\n    text-align: center;\n}\n\n.opti-dim-section .col-3 {\n    padding-left: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
