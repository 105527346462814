// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-table th {
    color: white;
    padding: 5px;
    text-align: center;
}

.result-table-title {
    font-weight: 500;
    font-size: 17px;
    margin: 0;
}

.result-table-details {
    font-size: 14px;
    width: 100%;
    font-weight: 400;
    margin: 5px 0 0 0;
}

.compare-results-table th:nth-of-type(1) {
    background-color: transparent;
    width: 35%;
}

.compare-results-table th:nth-of-type(2n) {
    background-color: var(--primary-color);
}

.compare-results-table th:nth-of-type(2n+1):not(:nth-of-type(1)) {
    background-color: #838996;
}

.compare-results-table th:not(:nth-of-type(1)):not(:nth-of-type(2)) {
    border-left: 1px solid var(--primary-color);
}

.compare-table-value {
    text-align: right;
    border-left: 1px solid var(--primary-color);
    padding: 5px;
    white-space: normal;
    overflow-wrap: break-word;
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}

`, "",{"version":3,"sources":["webpack://./src/components/CompareResultsTable/CompareResultsTable.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,SAAS;AACb;;AAEA;IACI,eAAe;IACf,WAAW;IACX,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,6BAA6B;IAC7B,UAAU;AACd;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,2CAA2C;AAC/C;;AAEA;IACI,iBAAiB;IACjB,2CAA2C;IAC3C,YAAY;IACZ,mBAAmB;IACnB,yBAAyB;IACzB,qBAAqB;QACjB,iBAAiB;YACb,aAAa;AACzB","sourcesContent":[".results-table th {\n    color: white;\n    padding: 5px;\n    text-align: center;\n}\n\n.result-table-title {\n    font-weight: 500;\n    font-size: 17px;\n    margin: 0;\n}\n\n.result-table-details {\n    font-size: 14px;\n    width: 100%;\n    font-weight: 400;\n    margin: 5px 0 0 0;\n}\n\n.compare-results-table th:nth-of-type(1) {\n    background-color: transparent;\n    width: 35%;\n}\n\n.compare-results-table th:nth-of-type(2n) {\n    background-color: var(--primary-color);\n}\n\n.compare-results-table th:nth-of-type(2n+1):not(:nth-of-type(1)) {\n    background-color: #838996;\n}\n\n.compare-results-table th:not(:nth-of-type(1)):not(:nth-of-type(2)) {\n    border-left: 1px solid var(--primary-color);\n}\n\n.compare-table-value {\n    text-align: right;\n    border-left: 1px solid var(--primary-color);\n    padding: 5px;\n    white-space: normal;\n    overflow-wrap: break-word;\n    -webkit-hyphens: auto;\n        -ms-hyphens: auto;\n            hyphens: auto;\n}\n\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
