const styles = () => ({
  singleValue: (defaultStyles) => ({
    ...defaultStyles,
    display: 'flex',
    alignItems: 'center',
    width: 'fit-content',
    gap: '20px'
  }),
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    backgroundColor:
      state.isFocused || state.isSelected ? 'rgba(226,223,235, 0.4)' : 'white',
    color: 'black',
    ':active': {
      ...defaultStyles[':active'],
      backgroundColor: 'rgba(226,223,235, 0.7)'
    }
  }),

  groupHeading: () => ({
    flex: '1',
    textOverflow: 'ellipsis',
    whiteSpace: 'noWrap',
    overflow: 'hidden',
    fontSize: '14px',
    color: '#6C757D',
    textTransform: 'uppercase'
  }),
  group: () => ({ marginBottom: '6px' })
});

export default styles;
