import { library } from '@fortawesome/fontawesome-svg-core';
import { fab } from '@fortawesome/free-brands-svg-icons';
import {
  faCalendar,
  faFaceRollingEyes
} from '@fortawesome/free-regular-svg-icons';
import {
  faArrowDown,
  faArrowLeft,
  faArrowRight,
  faBars,
  faCalculator,
  faCaretDown,
  faCaretRight,
  faCaretSquareDown,
  faCaretSquareRight,
  faChartLine,
  faCheck,
  faCheckCircle,
  faChevronLeft,
  faChevronRight,
  faCircleArrowLeft,
  faCircleArrowRight,
  faCircleDot,
  faCircleInfo,
  faCircleQuestion,
  faComment,
  faDownload,
  faExclamationCircle,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFaceFrown,
  faFileCsv,
  faFloppyDisk,
  faListCheck,
  faMagnifyingGlass,
  faMinusCircle,
  faPenToSquare,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faPowerOff,
  faSadCry,
  faSearchLocation,
  faStopwatch,
  faTable,
  faTrashAlt,
  faTrashCanArrowUp,
  faUpload,
  faUser,
  faUserGroup,
  faUserShield,
  faWrench,
  faXmark
} from '@fortawesome/free-solid-svg-icons';

library.add(
  fab,
  faMinusCircle,
  faPlus,
  faPlusCircle,
  faPlusSquare,
  faTrashAlt,
  faArrowLeft,
  faCircleQuestion,
  faFileCsv,
  faExclamationCircle,
  faBars,
  faPowerOff,
  faArrowRight,
  faExclamationTriangle,
  faCheckCircle,
  faPenToSquare,
  faArrowDown,
  faCaretDown,
  faCaretRight,
  faCheck,
  faCaretSquareDown,
  faCaretSquareRight,
  faChartLine,
  faCalendar,
  faCalculator,
  faXmark,
  faFaceFrown,
  faComment,
  faSearchLocation,
  faMagnifyingGlass,
  faChevronLeft,
  faChevronRight,
  faTrashCanArrowUp,
  faCircleArrowLeft,
  faCircleArrowRight,
  faStopwatch,
  faEye,
  faWrench,
  faSadCry,
  faFloppyDisk,
  faTable,
  faDownload,
  faUpload,
  faUserGroup,
  faUserShield,
  faUser,
  faFaceRollingEyes,
  faCircleInfo,
  faListCheck,
  faCircleDot,
  faEyeSlash
);
