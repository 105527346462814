export const OPEX_TYPES_CHART_COLORS = Object.freeze({
  OpexElecSupply: '#5491E3',
  OpexPVSaved: '#895ADE',
  OpexPVSoldToProject: '#9878C9',
  OpexPVSoldToGrid: '#CF6FA1',
  OpexElecTransportAndTaxes: '#DE6E81',
  OpexGasSupply: '#DC9055',
  OpexGasCarbonTax: '#EFCD53',
  OpexOperationAndMaintenance: '#68B87D',
  OpexGaPerf: '#65CFB1',
  OpexOther: '#58B9C6',
  OpexPerYear: '#804817'
});
