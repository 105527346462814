import React from 'react';

const kilowattCretePVModal = (i18n) => ({
  title: i18n._('compute.kiloWattCretePV'),
  body: (
    <div>
      {i18n._('compute.kiloWattCretePV.help1')}
      <br />
      <br />
      <p style={{ textAlign: 'center' }}>
        {i18n._('compute.kiloWattCretePV.help2')}
      </p>
    </div>
  )
});

export default kilowattCretePVModal;
