// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compute-page {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 2%;
}

@media (max-width: 1007px) {
    .compute-page {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
    }
}`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/ComputePage.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,OAAO;AACX;;AAEA;IACI;QACI,4BAA4B;QAC5B,6BAA6B;YACzB,0BAA0B;gBACtB,sBAAsB;IAClC;AACJ","sourcesContent":[".compute-page {\n    width: 100%;\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    gap: 2%;\n}\n\n@media (max-width: 1007px) {\n    .compute-page {\n        -webkit-box-orient: vertical;\n        -webkit-box-direction: normal;\n            -ms-flex-direction: column;\n                flex-direction: column;\n    }\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
