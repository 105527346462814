import React from 'react';

const defaultState = {
  calculationData: undefined,
  setCalculationData: () => {},
  setConstraint: () => {},
  setBounds: () => {},
  setAreParamsChanged: () => {},
  addError: () => {},
  removeError: () => {}
};

const OptimizationContext = React.createContext(defaultState);
export default OptimizationContext;
