import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useState } from 'react';
import { getTableValue } from '../../../../../../../../../utils/compute.utils';
import './StdTable.css';

const StdTable = ({ template }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [isSummaryDataVisible, setIsSummaryDataVisible] = useState(false);
  //#endregion

  //#region [render]
  return (
    <table className='template-table'>
      <thead>
        <tr>
          <th></th>
          {template.descriptions.map((description, i) => (
            <th
              key={'std_th_desc_' + i}
              className='multi-desc-th ellipsis'
              title={description || i18n._('description', { index: i + 1 })}
            >
              {description || i18n._('description', { index: i + 1 })}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        <tr className='std-table-first-row'>
          <td
            className='table-label'
            onClick={() => setIsSummaryDataVisible(!isSummaryDataVisible)}
          >
            <span>
              {i18n._('description.STD')}
              <FontAwesomeIcon
                icon={
                  isSummaryDataVisible
                    ? 'caret-square-down'
                    : 'caret-square-right'
                }
              />
            </span>
          </td>
          {template.files.map((file, i) => (
            <td
              key={'std_td_filename_' + i}
              title={file}
              className='multi-desc-table-value'
            >
              {file}
            </td>
          ))}
        </tr>
        {template.rows.map((row, rowIndex) => (
          <tr
            className={isSummaryDataVisible ? '' : 'hidden'}
            key={'std_tr_' + rowIndex}
          >
            <td className='table-label'>{i18n._(row.labelId)}</td>
            {row.values.map((value, colIndex) => (
              <td
                key={'description_td_' + rowIndex + '_' + colIndex}
                className='multi-desc-table-value'
              >
                {getTableValue(i18n, value, row.unit, row.translateValue)}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
  //#endregion
};

export default StdTable;
