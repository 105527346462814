import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import { GEOCOOLING } from '../../../../../../server/models/design/performance.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import PerformancePageContext from '../../../../contexts/PerformancePageContext';
import { getDefaultValue } from '../../../../utils/project.utils';

const GeocoolingBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleOtherValueChange } =
    useContext(PerformancePageContext);
  //#endregion

  //#region [render]
  const {
    InitGeocoolingEnabled,
    InitGeocoolingDisableClim,
    InitGeocoolingTempOutBtesMax,
    InitGeocoolingPumpConsumptionPercent
  } = tempProject.otherValues;

  return (
    <Bloc title={i18n._('performance.geocooling')}>
      <Row>
        <Col>
          <Form.Check
            type='switch'
            label={i18n._('performance.InitGeocoolingEnabled')}
            checked={InitGeocoolingEnabled}
            onChange={(evt) =>
              handleOtherValueChange(
                'InitGeocoolingEnabled',
                evt.target.checked
              )
            }
          />
        </Col>
        <Col>
          <Form.Check
            type='switch'
            label={i18n._('performance.InitGeocoolingDisableClim')}
            checked={InitGeocoolingDisableClim}
            onChange={(evt) =>
              handleOtherValueChange(
                'InitGeocoolingDisableClim',
                evt.target.checked
              )
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('performance.InitGeocoolingTempOutBtesMax')}
            value={InitGeocoolingTempOutBtesMax}
            param={GEOCOOLING.InitGeocoolingTempOutBtesMax}
            onBlur={(value) =>
              handleOtherValueChange('InitGeocoolingTempOutBtesMax', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(
              i18n,
              GEOCOOLING.InitGeocoolingTempOutBtesMax
            )}
            unit
            disabled={!InitGeocoolingEnabled}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('performance.InitGeocoolingPumpConsumptionPercent')}
            value={InitGeocoolingPumpConsumptionPercent}
            param={GEOCOOLING.InitGeocoolingPumpConsumptionPercent}
            onBlur={(value) =>
              handleOtherValueChange(
                'InitGeocoolingPumpConsumptionPercent',
                value
              )
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(
              i18n,
              GEOCOOLING.InitGeocoolingPumpConsumptionPercent
            )}
            unit
            disabled={!InitGeocoolingDisableClim}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default GeocoolingBloc;
