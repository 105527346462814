import CostPage from './cost/CostPage';
import DescriptionsPage from './descriptions/DescriptionsPage';
import GeneralPage from './general/GeneralPage';
import GeologyPage from './geology/GeologyPage';
import PerformancePage from './performance/PerformancePage';

const designPages = [
  {
    id: 'general',
    component: GeneralPage
  },
  {
    id: 'description',
    component: DescriptionsPage
  },
  {
    id: 'geology',
    component: GeologyPage
  },
  {
    id: 'costs',
    component: CostPage
  },
  {
    id: 'performance',
    component: PerformancePage
  }
];
export default designPages;
