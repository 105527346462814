import {
  PV_EQUIPMENT,
  ST_EQUIPMENT
} from '../../../../../../../../../server/constants/performance.constant';
import {
  EXCHANGER,
  GEOCOOLING,
  HEADLOSS,
  HP,
  ITES,
  getPVParams,
  getSTParams
} from '../../../../../../../../../server/models/design/performance.model';
import {
  getSafeData,
  roundNumber
} from '../../../../../../../utils/data.utils';

const getModel = (models, value) => {
  return models.find((model) => model.value === value);
};

export const perfTemplates = (i18n, project) => {
  const { InitSolarThermalModel, InitPhotovoltaicModel } = project.otherValues;
  const pvModel = getModel(Object.values(PV_EQUIPMENT), InitPhotovoltaicModel);
  const stModel = getModel(Object.values(ST_EQUIPMENT), InitSolarThermalModel);
  const PV = getPVParams(pvModel);
  const ST = getSTParams(stModel);
  return [
    {
      title: i18n._('performance.heatPumps'),
      rows: [
        {
          label: i18n._('performance.HPGFilename'),
          value: project.otherValues.HPGFilename
        },
        {
          label: i18n._('performance.HPAFilename'),
          value: project.otherValues.HPAFilename
        },
        {
          label: i18n._('performance.InitInjectionTemperatureDelta'),
          value: getSafeData(
            () => project.otherValues.InitInjectionTemperatureDelta
          ),
          unit: HP.InitInjectionTemperatureDelta.unit
        }
      ]
    },
    {
      title: i18n._('performance.gasBoiler'),
      rows: [
        {
          label: i18n._('performance.InitGasEfficiency'),
          value: getSafeData(() => project.otherValues.InitGasEfficiency)
        }
      ]
    },
    {
      title: i18n._('performance.exchangers'),
      rows: [
        {
          label: i18n._('performance.InitLoopExchangerPinch'),
          value: getSafeData(() => project.InitLoopExchangerPinch),
          unit: EXCHANGER.InitLoopExchangerPinch.unit
        },
        {
          label: i18n._('performance.InitLoopExchangerU'),
          value: getSafeData(() => project.InitLoopExchangerU),
          unit: EXCHANGER.InitLoopExchangerU.unit
        },
        {
          label: i18n._('performance.InitLoopExchangerAirPinch'),
          value: getSafeData(() => project.InitLoopExchangerAirPinch),
          unit: EXCHANGER.InitLoopExchangerAirPinch.unit
        },
        {
          label: i18n._('performance.InitLoopExchangerAirU'),
          value: getSafeData(() => project.InitLoopExchangerAirU),
          unit: EXCHANGER.InitLoopExchangerAirU.unit
        },
        {
          label: i18n._('performance.InitStationExchangerPinch'),
          value: getSafeData(() => project.InitStationExchangerPinch),
          unit: EXCHANGER.InitStationExchangerPinch.unit
        },
        {
          label: i18n._('performance.InitStationColdExchangerPinch'),
          value: getSafeData(() => project.InitStationColdExchangerPinch),
          unit: EXCHANGER.InitStationColdExchangerPinch.unit
        },
        {
          label: i18n._('performance.InitStationHotwaterExchangerPinch'),
          value: getSafeData(() => project.InitStationHotwaterExchangerPinch),
          unit: EXCHANGER.InitStationHotwaterExchangerPinch.unit
        }
      ]
    },
    {
      title: i18n._('performance.weightLoss'),
      rows: [
        {
          label: i18n._('performance.InitPumpEfficiency'),
          value: getSafeData(() => project.otherValues.InitPumpEfficiency)
        },
        {
          label: i18n._('performance.InitExchangerHeadlossMceMax'),
          value: getSafeData(
            () => project.otherValues.InitExchangerHeadlossMceMax
          ),
          unit: HEADLOSS.InitExchangerHeadlossMceMax.unit
        },
        {
          label: i18n._('performance.InitBtesHeadlossLimit'),
          value: getSafeData(() => project.otherValues.InitBtesHeadlossLimit),
          unit: HEADLOSS.InitBtesHeadlossLimit.unit
        },
        {
          label: i18n._('performance.InitLoopConnectionLength'),
          value: getSafeData(
            () => project.otherValues.InitLoopConnectionLength
          ),
          unit: HEADLOSS.InitLoopConnectionLength.unit
        }
      ]
    },
    {
      title: i18n._('performance.geocooling'),
      rows: [
        {
          label: i18n._('performance.InitGeocoolingEnabled'),
          value: getSafeData(() => project.otherValues.InitGeocoolingEnabled),
          translateValue: true
        },
        {
          label: i18n._('performance.InitGeocoolingPumpConsumptionPercent'),
          value: getSafeData(
            () => project.otherValues.InitGeocoolingPumpConsumptionPercent
          ),
          unit: GEOCOOLING.InitGeocoolingPumpConsumptionPercent.unit
        }
      ]
    },
    {
      title: i18n._('performance.solarThermal'),
      rows: [
        {
          label: i18n._('performance.InitSolarSurfaceTilt'),
          value: getSafeData(() => project.otherValues.InitSolarSurfaceTilt),
          unit: ST.InitSolarSurfaceTilt.unit
        },
        {
          label: i18n._('performance.InitSolarSurfaceAzimuth'),
          value: getSafeData(() => project.otherValues.InitSolarSurfaceAzimuth),
          unit: ST.InitSolarSurfaceAzimuth.unit
        },
        {
          label: i18n._('performance.InitSolarThermalHeta0'),
          value: getSafeData(() => project.otherValues.InitSolarThermalHeta0)
        },
        {
          label: i18n._('performance.InitSolarThermalBu'),
          value: getSafeData(() => project.otherValues.InitSolarThermalBu),
          unit: ST.InitSolarThermalBu.unit
        },
        {
          label: i18n._('performance.InitSolarThermalB1'),
          value: getSafeData(() => project.otherValues.InitSolarThermalB1),
          unit: ST.InitSolarThermalB1.unit
        },
        {
          label: i18n._('performance.InitSolarThermalB2'),
          value: getSafeData(() => project.otherValues.InitSolarThermalB2),
          unit: ST.InitSolarThermalB2.unit
        },
        {
          label: i18n._('performance.InitSolarThermalEpsa'),
          value: getSafeData(() => project.otherValues.InitSolarThermalEpsa)
        },
        {
          label: i18n._('performance.InitSolarThermalFlowRate'),
          value: getSafeData(
            () => project.otherValues.InitSolarThermalFlowRate
          ),
          unit: ST.InitSolarThermalFlowRate.unit
        },
        {
          label: i18n._('performance.InitSolarDeltaTemp'),
          value: getSafeData(() => project.otherValues.InitSolarDeltaTemp),
          unit: ST.InitSolarDeltaTemp.unit
        },
        {
          label: i18n._('performance.InitSolarTemperatureMin'),
          value: getSafeData(() => project.otherValues.InitSolarTemperatureMin),
          unit: ST.InitSolarTemperatureMin.unit
        }
      ]
    },
    {
      title: i18n._('performance.photovoltaic'),
      rows: [
        {
          label: i18n._('performance.InitPhotovoltaicModel'),
          value: getSafeData(() => project.otherValues.InitPhotovoltaicModel)
        },
        {
          label: i18n._('performance.InitPVEfficiency'),
          value: roundNumber(project.otherValues.InitPVEfficiency * 100, 4),
          unit: PV.InitPVEfficiency.unit
        },
        {
          label: i18n._('performance.InitPVDCLossesAgeModule'),
          value: roundNumber(
            project.otherValues.InitPVDCLossesAgeModule * 100,
            4
          ),
          unit: PV.InitPVDCLossesAgeModule.unit
        },
        {
          label: i18n._('performance.InitPVTemperatureCoefGammaModule'),
          value: roundNumber(
            project.otherValues.InitPVTemperatureCoefGammaModule * 100,
            4
          ),
          unit: PV.InitPVTemperatureCoefGammaModule.unit
        },
        {
          label: i18n._('performance.InitPVInverterMaxPowerPerWc'),
          value: getSafeData(
            () => project.otherValues.InitPVInverterMaxPowerPerWc
          ),
          unit: PV.InitPVInverterMaxPowerPerWc.unit
        },
        {
          label: i18n._('performance.InitPVInverterNominalEfficiency'),
          value: roundNumber(
            project.otherValues.InitPVInverterNominalEfficiency * 100,
            4
          )
        },
        {
          label: i18n._('performance.InitSolarSurfaceTilt'),
          value: getSafeData(() => project.otherValues.InitSolarSurfaceTilt),
          unit: ST.InitSolarSurfaceTilt.unit
        },
        {
          label: i18n._('performance.InitSolarSurfaceAzimuth'),
          value: getSafeData(() => project.otherValues.InitSolarSurfaceAzimuth),
          unit: ST.InitSolarSurfaceAzimuth.unit
        }
      ]
    },
    {
      title: i18n._('performance.coldStorage'),
      rows: [
        {
          label: i18n._('performance.ites'),
          value: getSafeData(() => project.otherValues.InitItesModel)
        },
        {
          label: i18n._('performance.InitItesPumpConsumptionPercentage'),
          value: getSafeData(
            () => project.otherValues.InitItesPumpConsumptionPercentage
          ),
          unit: ITES.InitItesPumpConsumptionPercentage.unit
        },
        {
          label: i18n._('performance.InitItesChargingTemperatureIn'),
          value: getSafeData(
            () => project.otherValues.InitItesChargingTemperatureIn
          ),
          unit: ITES.InitItesChargingTemperatureIn.unit
        },
        {
          label: i18n._('performance.InitItesDischargingTemperatureIn'),
          value: getSafeData(
            () => project.otherValues.InitItesDischargingTemperatureIn
          ),
          unit: ITES.InitItesDischargingTemperatureIn.unit
        },
        {
          label: i18n._('performance.InitItesChargingTemperatureOut'),
          value: getSafeData(
            () => project.otherValues.InitItesChargingTemperatureOut
          ),
          unit: ITES.InitItesChargingTemperatureOut.unit
        },
        {
          label: i18n._('performance.InitItesDischargingTemperatureOut'),
          value: getSafeData(
            () => project.otherValues.InitItesDischargingTemperatureOut
          ),
          unit: ITES.InitItesDischargingTemperatureOut.unit
        }
      ]
    }
  ];
};
