import React from 'react';

const defaultState = {
  tempProject: undefined,
  nbErrors: 0,
  addError: () => {},
  removeError: () => {},
  setTempProject: () => {},
  handleParamChange: () => {},
  handleOtherValueChange: () => {},
  handleSSTParamChange: () => {},
  handleSubmitClick: async () => {}
};

const PerformancePageContext = React.createContext(defaultState);
export default PerformancePageContext;
