import React from 'react';

const defaultState = {
  calculationData: undefined,
  addError: () => {},
  removeError: () => {},
  setCalculationData: () => {},
  setAreParamsChanged: () => {}
};

const SimulationContext = React.createContext(defaultState);
export default SimulationContext;
