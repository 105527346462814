import React, { useContext, useEffect, useState } from 'react';
import { Outlet, useNavigate, useParams } from 'react-router-dom';
import { fetchCompany } from '../../api/company.api';
import CompanyContext from '../../contexts/CompanyContext';
import PopupContext from '../../contexts/PopupContext';

const CompanyPage = () => {
  //#region [router]
  const { companyId } = useParams();
  const navigate = useNavigate();
  //#endregion

  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [selectedCompany, setSelectedCompany] = useState();
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        const company = await fetchCompany(companyId);
        setSelectedCompany(() => ({ id: companyId, name: company.CoName }));
      } catch (err) {
        console.error(err);
        if (err.response?.status === 404) {
          navigate('/notfound');
        } else {
          openErrorToast(err);
        }
      }
    })();
  }, [companyId]);
  //#endregion

  //#region [render]
  return selectedCompany ? (
    <CompanyContext.Provider value={{ selectedCompany }}>
      <Outlet />
    </CompanyContext.Provider>
  ) : null;
  //#endregion
};

export default CompanyPage;
