import { useLingui } from '@lingui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import {
  ACCESS_KEY,
  COMPANY_KEY
} from '../../../../server/constants/storage.constant';
import { sendMailToResetPwd } from '../../api/auth.api';
import PwdInput from '../../components/PwdInput/PwdInput';
import Toast from '../../components/Toast/Toast';
import useAuth from '../../hooks/useAuth';
import logo from '../../image/logo.svg';
import './LoginPage.css';

const LoginPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [auth]
  const { handleLogin } = useAuth();
  //#endregion

  //#region [states]
  const [lostPwdView, setLostPwdView] = useState(false);
  const [emailSent, setEmailSent] = useState(false);
  const [error, setError] = useState();
  //#endregion

  //#region [refs]
  const rememberIdsRef = useRef();
  const emailInputRef = useRef();
  const pwdInputRef = useRef();
  const lostPwdEmailInputRef = useRef();
  //endregion

  //#region [effects]
  useEffect(() => {
    localStorage.removeItem(ACCESS_KEY);
    sessionStorage.removeItem(ACCESS_KEY);
    localStorage.removeItem(COMPANY_KEY);
    sessionStorage.removeItem(COMPANY_KEY);
  }, []);
  //#endregion

  //#region [methods]
  const handleLoginFormSubmit = async (evt) => {
    evt.preventDefault();
    try {
      await handleLogin(
        emailInputRef.current.value,
        pwdInputRef.current.value,
        rememberIdsRef.current.checked
      );
    } catch (err) {
      console.error(err);
      setError({
        title: i18n._('error.connect.title'),
        body: i18n._('error.connect.body')
      });
    }
  };

  const handleLostPwdFormSubmit = async (evt) => {
    evt.preventDefault();
    try {
      await sendMailToResetPwd(lostPwdEmailInputRef.current.value, i18n.locale);
      setEmailSent(true);
    } catch (err) {
      console.error(err);
      setError({
        title: i18n._('error.lostPwd.title'),
        body: i18n._('error.lostPwd.body')
      });
    }
  };
  //#endregion

  //#region [render]
  return (
    <div className='login-body'>
      <div className='login-left-body'>
        <img src={logo} alt='logo' className='login-logo' />
        {i18n._('login.optimizedDesign')}
      </div>
      <div className='login-right-body'>
        <Form
          className={!lostPwdView ? 'login-form' : 'hidden'}
          onSubmit={handleLoginFormSubmit}
        >
          <h1>{i18n._('login.loginToDesign')}</h1>
          <Form.Group>
            <Form.Label className='form-input-label'>
              {i18n._('login.email.label')}
            </Form.Label>
            <Form.Control
              placeholder={i18n._('login.email.placeholder')}
              className='login-form-input'
              ref={emailInputRef}
            />
          </Form.Group>
          <PwdInput
            label={i18n._('login.pwd.label')}
            placeholder={i18n._('login.pwd.placeholder')}
            ref={pwdInputRef}
          />
          <Form.Check
            type='checkbox'
            id='rememberCheckbox'
            label={i18n._('login.rememberMe')}
            ref={rememberIdsRef}
          />
          <span
            className='login-form-forgot-pwd'
            onClick={() => setLostPwdView(true)}
          >
            {i18n._('login.forgotPwd')}
          </span>
          <Button type='submit' variant='primary'>
            {i18n._('login.logIn')}
          </Button>
        </Form>
        <Form
          className={lostPwdView ? 'login-form' : 'hidden'}
          onSubmit={handleLostPwdFormSubmit}
        >
          <h1>{i18n._('login.forgotPwd')}</h1>
          {i18n._('login.forgotPwd.instructions')}
          <Form.Group className='login-form-group' controlId='lostPwdEmail'>
            <Form.Label className='login-form-label'>
              {i18n._('login.email.label')}
            </Form.Label>
            <Form.Control
              placeholder={i18n._('login.email.placeholder')}
              className='login-form-input'
              ref={lostPwdEmailInputRef}
            />
          </Form.Group>
          <div className='login-btns'>
            <Button onClick={() => setLostPwdView(false)} variant='secondary'>
              {i18n._('login.forgotPwd.cancel')}
            </Button>
            <Button type='submit' variant='primary'>
              {i18n._('login.send')}
            </Button>
          </div>
          {emailSent && (
            <span className='login-email-sent'>
              {i18n._('login.forgotPwd.emailSent')}
            </span>
          )}
        </Form>
      </div>
      <Toast
        isOpen={!!error}
        level='danger'
        header={error?.title}
        body={error?.body}
        onClose={() => setError(null)}
      />
    </div>
  );
  //#endregion
};

export default LoginPage;
