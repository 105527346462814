import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ITES } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import CostPageContext from '../../../../contexts/CostPageContext';
import { getDefaultValue } from '../../../../utils/project.utils';

const ItesBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleParamChange } =
    useContext(CostPageContext);
  //#endregion

  //#region [render]
  const { InitItesCostA, InitItesCostB } = tempProject;
  return (
    <Bloc title={i18n._('cost.ites')}>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitItesCostA')}
            value={InitItesCostA}
            param={ITES.InitItesCostA}
            onBlur={(value) => handleParamChange('InitItesCostA', value)}
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, ITES.InitItesCostA)}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitItesCostB')}
            value={InitItesCostB}
            param={ITES.InitItesCostB}
            onBlur={(value) => handleParamChange('InitItesCostB', value)}
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, ITES.InitItesCostB)}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default ItesBloc;
