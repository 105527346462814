import { ACCESS_KEY } from '../../../server/constants/storage.constant';
import { logout } from '../api/auth.api';
import axios from '../conf/axios.conf';

export const setupInterceptors = (navigate) => {
  axios.interceptors.response.use(
    (response) => {
      const newAccessToken = response.headers.authorization?.split(' ')[1];
      if (newAccessToken) {
        localStorage.getItem(ACCESS_KEY)
          ? localStorage.setItem(ACCESS_KEY, newAccessToken)
          : sessionStorage.setItem(ACCESS_KEY, newAccessToken);
      }
      return response;
    },
    // AXIOS ERROR HANDLING
    async (error) => {
      console.error(error);
      if (error.response?.status === 401) {
        await logout();
        navigate('/login');
      }
      throw error;
    }
  );
};
