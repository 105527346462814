// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.detailed-btn-dl {
    font-size: 14px;
    margin-top: 10px;
}

.detailed-select-body {
    font-size: 14px;
    margin: 15px 0px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}

.detailed-select {
    width: 120px;
    margin-left: 15px;
    font-size: 13px;
}

.detailed-instructions {
    font-style: italic;
    font-size: 13px;
}

.detailed-grid {
    width: 700px;
    height: 400px;
}

.InovuaReactDataGrid--theme-default-light {
    font-size: 12px !important;
}

.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__content {
    font-size: 12px !important;
    font-weight: 300 !important;
    text-align: center;
    padding: 0 !important;
}

.InovuaReactDataGrid__cell .InovuaReactDataGrid__cell__content {
    text-align: center;
    padding: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/design/result/sections/simulationParams/components/DetailedResults/DetailedResults.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,yBAAyB;QACrB,sBAAsB;YAClB,mBAAmB;AAC/B;;AAEA;IACI,YAAY;IACZ,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,aAAa;AACjB;;AAEA;IACI,0BAA0B;AAC9B;;AAEA;IACI,0BAA0B;IAC1B,2BAA2B;IAC3B,kBAAkB;IAClB,qBAAqB;AACzB;;AAEA;IACI,kBAAkB;IAClB,UAAU;AACd","sourcesContent":[".detailed-btn-dl {\n    font-size: 14px;\n    margin-top: 10px;\n}\n\n.detailed-select-body {\n    font-size: 14px;\n    margin: 15px 0px;\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n}\n\n.detailed-select {\n    width: 120px;\n    margin-left: 15px;\n    font-size: 13px;\n}\n\n.detailed-instructions {\n    font-style: italic;\n    font-size: 13px;\n}\n\n.detailed-grid {\n    width: 700px;\n    height: 400px;\n}\n\n.InovuaReactDataGrid--theme-default-light {\n    font-size: 12px !important;\n}\n\n.InovuaReactDataGrid--theme-default-light .InovuaReactDataGrid__column-header__content {\n    font-size: 12px !important;\n    font-weight: 300 !important;\n    text-align: center;\n    padding: 0 !important;\n}\n\n.InovuaReactDataGrid__cell .InovuaReactDataGrid__cell__content {\n    text-align: center;\n    padding: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
