import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Button } from 'react-bootstrap';
import { ENGINE_ERROR } from '../../../../../../../server/constants/error.constant';
import { fetchEngineData } from '../../../../../api/calculation.api';
import PopupContext from '../../../../../contexts/PopupContext';
import { downloadFile } from '../../../../../utils/file.utils';
import './ErrorCard.css';

const EngineErrorCard = ({ error }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const handleDlEngineDataClick = async () => {
    try {
      const { code, calculationId, projectId } = error;
      const engineData = await fetchEngineData(calculationId, projectId);
      const filename = i18n._('calculation.engineError.filename', {
        code,
        projectId
      });
      downloadFile(
        `${filename}.json`,
        new Blob([JSON.stringify(engineData)], {
          type: 'text/json'
        })
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const { code, message } = error;
  return (
    <div className='error-card'>
      <div className='error-card-title'>
        <FontAwesomeIcon icon='face-frown' />
        <span>{i18n._('calculation.error')}</span>
        {code && <span>{i18n._('calculation.error.code', { code })}</span>}
        <Button variant='outline-secondary' onClick={handleDlEngineDataClick}>
          <FontAwesomeIcon icon='download' />
          {i18n._('calculation.engineData.dl')}
        </Button>
      </div>
      <div className='error-card-bottom'>
        {code && ENGINE_ERROR[code] ? (
          <div className='error-card-engine'>
            {ENGINE_ERROR[code].message && (
              <span className='error-card-message'>
                <FontAwesomeIcon icon='circle-dot' />
                {i18n._(`calculation.error.${code}.message`)}
              </span>
            )}
            {ENGINE_ERROR[code].causes &&
              ENGINE_ERROR[code].causes.map((cause, i) => (
                <div
                  className='error-card-causes-actions'
                  key={'error_card_cause_' + i + 1}
                >
                  <span>
                    <FontAwesomeIcon icon='xmark' />
                    {i18n._(`calculation.error.${code}.cause${i + 1}`)}
                  </span>
                  {new Array(cause.actions).fill(null).map((_, j) => (
                    <span key={'error_card_action_' + j + 1}>
                      <FontAwesomeIcon icon='wrench' />
                      {i18n._(
                        `calculation.error.${code}.cause${i + 1}.action${j + 1}`
                      )}
                    </span>
                  ))}
                </div>
              ))}
          </div>
        ) : (
          <span>
            <FontAwesomeIcon icon='xmark' />
            {message}
          </span>
        )}
      </div>
    </div>
  );
  //#endregion
};

export default React.memo(EngineErrorCard);
