// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sst-inputs-rows {
    width: 40%;
}

.sst-inputs-row {
    gap: 10px;
}

.sst-inputs-col {
    padding: 0;
    -ms-flex-item-align: center;
        align-self: center;
}

.sst-inputs-name {
    text-align: center;
    width: 100%;
    max-width: 320px;
}`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/components/SubstationInputs/SubstationsInputs.css"],"names":[],"mappings":"AAAA;IACI,UAAU;AACd;;AAEA;IACI,SAAS;AACb;;AAEA;IACI,UAAU;IACV,2BAA2B;QACvB,kBAAkB;AAC1B;;AAEA;IACI,kBAAkB;IAClB,WAAW;IACX,gBAAgB;AACpB","sourcesContent":[".sst-inputs-rows {\n    width: 40%;\n}\n\n.sst-inputs-row {\n    gap: 10px;\n}\n\n.sst-inputs-col {\n    padding: 0;\n    -ms-flex-item-align: center;\n        align-self: center;\n}\n\n.sst-inputs-name {\n    text-align: center;\n    width: 100%;\n    max-width: 320px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
