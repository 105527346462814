import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import useAuth from '../../hooks/useAuth';
import { COMPANY_KEY } from '../../../../server/constants/storage.constant';

const RedirectToProjects = () => {
  //#region [router]
  const location = useLocation();
  //#endregion

  //#region [auth]
  const { user } = useAuth();
  //#endregion

  //#region [render]
  const companyId =
    location?.state?.companyId ??
    localStorage.getItem(COMPANY_KEY) ??
    user.UserCoID;
  return <Navigate to={`company/${companyId}/projects?active=true`} replace />;
  //#endregion
};

export default RedirectToProjects;
