import { useLingui } from '@lingui/react';
import React, { useMemo, useState } from 'react';
import { Form } from 'react-bootstrap';
import {
  NB_HOURS_PER_YEAR,
  RESULTS_DETAILS_EMPTY_ARRAY_ONE_YEAR
} from '../../../../../../../../server/constants/date.constant';
import { BTES_TEMP } from '../../../../../../../../server/constants/result.constant';
import LinesChart from '../../../../../../components/Chart/LinesChart';
import { MODE } from '../../../../../../constants/plot.constant';
import { DETAILED_RESULTS_CHART_COLORS } from '../../../../../../styles/colors/detailedResult.color';

const BtesTemperatureLinesChartPerYear = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [selectedYear, setSelectedYear] = useState(0);
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    if (!result.details) return null;
    const start = selectedYear * NB_HOURS_PER_YEAR;
    const end = (selectedYear + 1) * NB_HOURS_PER_YEAR;
    return Object.keys(BTES_TEMP).map((key) => ({
      x: RESULTS_DETAILS_EMPTY_ARRAY_ONE_YEAR.map(
        (_, i) => selectedYear * NB_HOURS_PER_YEAR + i
      ),
      y: result.details[key].slice(start, end),
      name: i18n._(BTES_TEMP[key].translateId),
      line: { color: DETAILED_RESULTS_CHART_COLORS[key] },
      mode: MODE.LINES
    }));
  }, [result.details, selectedYear]);
  //#endregion

  //#region [render]
  const { InitYearsSimulations } = result.ComputeResult.inp;
  return data ? (
    <div>
      <Form.Select
        value={selectedYear}
        onChange={(evt) => setSelectedYear(evt.target.value)}
        style={{ width: '160px', marginTop: '15px' }}
      >
        {new Array(InitYearsSimulations).fill(0).map((_, index) => (
          <option key={'btesTemperatureLinesChart_' + index} value={index}>
            {i18n._('results.year', { year: index + 1 })}
          </option>
        ))}
      </Form.Select>
      <LinesChart
        data={data}
        title={i18n._('results.year', { year: parseInt(selectedYear) + 1 })}
      />
    </div>
  ) : null;
  //#endregion
};

export default BtesTemperatureLinesChartPerYear;
