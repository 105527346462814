const parseFileName = (fileNameAndType) => {
  const fileNameSplit = fileNameAndType.split('.');
  const fileExt = fileNameSplit.pop().toLowerCase();
  let fileNameWoExt = fileNameSplit.join('.');
  return { fileNameWoExt, fileExt };
};

function convertToRealType(data, type) {
  let formatedData;
  if (data === 'true') {
    formatedData = true;
  } else if (data === 'false') {
    formatedData = false;
  } else if (
    (data === '1' || data === 1 || data === '0' || data === 0) &&
    typeof type === 'boolean'
  ) {
    formatedData = !!+data;
  } else if (!isNaN(data) && typeof data !== 'boolean' && data !== '') {
    formatedData = +data;
  } else formatedData = data;
  return formatedData;
}

function getStringifiedJSON(obj) {
  if (!obj) return null;
  if (typeof obj === 'string') return obj;
  return JSON.stringify(obj);
}

function getParsedJSON(str) {
  if (!str) return null;
  if (typeof str !== 'string') return str;
  return JSON.parse(str);
}

module.exports = {
  parseFileName,
  convertToRealType,
  getStringifiedJSON,
  getParsedJSON
};
