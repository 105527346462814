import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { GAS_OPEX } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import FormSelect from '../../../../components/Form/FormSelect';
import CostPageContext from '../../../../contexts/CostPageContext';
import PopupContext from '../../../../contexts/PopupContext';
import {
  getGasEvols,
  getNextCompassYear
} from '../../../../utils/compass.utils';
import { getDefaultValue } from '../../../../utils/project.utils';

const GasBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const {
    tempProject,
    addError,
    removeError,
    handleParamChange,
    handleOtherValueChange
  } = useContext(CostPageContext);
  const { openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [memos]
  const evols = useMemo(() => {
    return getGasEvols(i18n).join(' / ');
  }, []);
  //#endregion

  //#region [render]
  const { InitGasPriceSubscription, InitGasPrice, InitGasPriceEvol } =
    tempProject;
  const { InitGasPriceEvolMethod } = tempProject.otherValues;
  return (
    <Bloc title={i18n._('cost.gas')}>
      <Row>
        <Col>
          <FormSelect
            label={i18n._('cost.InitGasPriceEvolMethod')}
            value={InitGasPriceEvolMethod}
            param={GAS_OPEX.InitGasPriceEvolMethod}
            onChange={(value) =>
              handleOtherValueChange('InitGasPriceEvolMethod', value)
            }
            onInfoClick={() =>
              openInfoModal(
                i18n._('gasPriceEvolMethod.title'),
                i18n._('gasPriceEvolMethod.body', { evols })
              )
            }
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitGasPriceSubscription')}
            value={InitGasPriceSubscription}
            param={GAS_OPEX.InitGasPriceSubscription}
            onBlur={(value) =>
              handleParamChange('InitGasPriceSubscription', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              GAS_OPEX.InitGasPriceSubscription
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitGasPrice')}
            value={InitGasPrice}
            param={GAS_OPEX.InitGasPrice}
            onBlur={(value) => handleParamChange('InitGasPrice', value)}
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, GAS_OPEX.InitGasPrice)}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitGasPriceEvol')}
            value={InitGasPriceEvol}
            param={GAS_OPEX.InitGasPriceEvol}
            onBlur={(value) => handleParamChange('InitGasPriceEvol', value)}
            addError={addError}
            removeError={removeError}
            unit
            bottomText={i18n._(
              `cost.InitElecPriceEvol.bottom.${InitGasPriceEvolMethod}`,
              {
                year: getNextCompassYear(),
                default: GAS_OPEX.InitGasPriceEvol.default
              }
            )}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default GasBloc;
