import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { getDefaultValue } from '../../utils/project.utils';
import FormInput from '../Form/FormInput';
import './EditTable.css';

const EditTable = ({
  titles,
  values,
  params,
  onBlur,
  addError,
  removeError
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const tableValues = useMemo(() => {
    const newTableValues = [];
    const parsedValues =
      typeof values !== 'string' ? values : JSON.parse(values);
    const nbCols = parsedValues.length;
    const nbRows = Object.keys(parsedValues[0]).length;
    for (let i = 0; i < nbRows; ++i) {
      const rowValues = [Object.keys(parsedValues[0])[i]];
      for (let j = 0; j < nbCols; ++j) {
        rowValues.push(Object.values(parsedValues[j])[i]);
      }
      newTableValues.push(rowValues);
    }
    return newTableValues;
  }, [values]);

  const extendedParams = useMemo(() => {
    const newExtendedParams = {};
    params.forEach((param) => {
      newExtendedParams[param.key] = [];
      Object.entries(param.default).forEach(([key, value]) => {
        newExtendedParams[param.key].push({
          key: param.key + '_' + key,
          type: 'number',
          min: param.min,
          max: param.max,
          unit: param.unit,
          default: value
        });
      });
    });
    return newExtendedParams;
  }, [params]);
  //#endregion

  //#region [render]
  return (
    <table className='custom-table edit-table'>
      <thead>
        <tr>
          {titles.map((title) => (
            <th key={'edit_table_th_' + title}>{title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableValues.map((rowValues, rowIndex) => (
          <tr key={'edit_table_tr_' + rowValues[0][0] + '_' + rowIndex}>
            {rowValues.map((value, colIndex) => (
              <td key={'edit_table_td_' + value + '_' + colIndex}>
                {colIndex === 0 ? (
                  <span>{value}</span>
                ) : (
                  <FormInput
                    param={extendedParams[params[colIndex - 1].key][rowIndex]}
                    value={value}
                    addError={addError}
                    removeError={removeError}
                    onBlur={(val) =>
                      onBlur(params[colIndex - 1].key, rowValues[0], val)
                    }
                    bottomText={getDefaultValue(
                      i18n,
                      extendedParams[params[colIndex - 1].key][rowIndex]
                    )}
                  />
                )}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
  //#endregion
};

export default EditTable;
