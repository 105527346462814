import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import SectionTitle from '../SectionTitle';
import './Section.css';

const Section = ({ title, children, editPath, open, level }) => {
  //#region [states]
  const [isOpen, setIsOpen] = useState(open);
  //#endregion

  //#region [render]
  return !level || level === 1 ? (
    <div className='section-body'>
      <div className='section-title-row-container'>
        <div
          className='section-title-container'
          onClick={() => setIsOpen(!isOpen)}
        >
          <div className='section-title-container-right'>
            <FontAwesomeIcon icon={isOpen ? 'caret-down' : 'caret-right'} />
          </div>
          <span className='section-title'>{title}</span>
        </div>
        {editPath && (
          <Link className='edit-link' to={editPath}>
            <FontAwesomeIcon icon='pen-to-square' />
          </Link>
        )}
      </div>
      <div className={isOpen ? 'section-content' : 'hidden'}>{children}</div>
    </div>
  ) : (
    <div>
      <div className='subsection-title' onClick={() => setIsOpen(!isOpen)}>
        <SectionTitle title={title} level={level} />
        <FontAwesomeIcon
          icon={isOpen ? 'caret-down' : 'caret-right'}
          style={{
            fontSize: level === 2 ? '23px' : level === 3 ? '20px' : '17px'
          }}
        />
      </div>
      <div className={isOpen ? '' : 'hidden'}>{children}</div>
    </div>
  );
  //#endregion
};

export default Section;
