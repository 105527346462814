import React, { useMemo } from 'react';
import { OTHER_PER_SUB_KEYS } from '../../../../../../../../../server/constants/perSub.constant';
import Table from '../../../../../../../components/Table/Table';
import {
  formatValue,
  getSafeData,
  isObjNullOrEmpty
} from '../../../../../../../utils/data.utils';
import './OtherPerSubTable.css';

const OtherPerSubTable = ({ result }) => {
  //#region [memos]
  const data = useMemo(() => {
    const { PerSub } = result.ComputeResult.summary;
    const { substations } = result;
    const otherPerSubKeys = OTHER_PER_SUB_KEYS.filter(
      (key) => !isObjNullOrEmpty(PerSub[key])
    );
    const cols = [otherPerSubKeys];
    substations.forEach((sst) => {
      const newCol = new Array(otherPerSubKeys.length);
      otherPerSubKeys.forEach((key, index) => {
        newCol[index] = formatValue(getSafeData(() => PerSub[key][sst]));
      });
      cols.push(newCol);
    });
    return {
      titles: ['Variables', ...substations],
      cols
    };
  }, [result.ComputeID]);
  //#endregion

  //#region [render]
  return <Table titles={data.titles} cols={data.cols} />;
  //#endregion
};

export default OtherPerSubTable;
