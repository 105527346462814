const TYPE = Object.freeze({
  CONSTRUCTION: 'CONSTRUCTION',
  RENOVATION: 'RENOVATION'
});

const USAGE = Object.freeze({
  TERTIARY: 'TERTIARY',
  HOUSING: 'HOUSING',
  HOTEL: 'HOTEL',
  WAREHOUSE: 'WAREHOUSE'
});

module.exports = { TYPE, USAGE };
