import {
  COMPUTE_TYPE,
  REFERENCE_SOLUTION
} from '../../../server/constants/constraint.constant';
import { UNIT } from '../../../server/constants/unit.constant';
import { OPTIONS_GOAL } from '../../../server/models/design/constraint.model';
import {
  formatValueWithUnit,
  isArrNullOrEmpty,
  isNull,
  isObjNullOrEmpty
} from './data.utils';
import { formatDateAndHour } from './date.utils';
import { getComputeDescriptionIndex } from './project.utils';

export const getTableValue = (i18n, value, unit, translateValue, precision) => {
  return isNull(value)
    ? i18n._('noValue')
    : translateValue
    ? i18n._('compute.value.' + value)
    : formatValueWithUnit(i18n, value, unit, precision);
};

export const getSubstations = (summary) => {
  return Object.keys(
    Object.values(summary.PerSub).find((perSubObj) => {
      if (typeof perSubObj === 'object') return !isObjNullOrEmpty(perSubObj);
      return false;
    })
  );
};

export const getResultNameData = (result) => {
  const data = {};
  const inpKeys = [
    'InitSimulationCalcul',
    'InitConstraintEnabled',
    'InitConstraintENR',
    'InitConstraintEnergyFinale',
    'InitConstraintCO2kg',
    'InitConstraintHpg',
    'InitConstraintGasPart',
    'InitConstraintEnergyPrimary'
  ];
  const summaryKeys = [
    'PartENR',
    'EnergyFinalPercent',
    'CO2Percent',
    'PartHpgTfp',
    'GasPart',
    'EnergyPrimaryPercent'
  ];
  const resultKeys = ['created_at', 'Comment', 'ProjectID'];
  inpKeys.forEach((key) => (data[key] = result.ComputeResult.inp[key]));
  summaryKeys.forEach((key) => (data[key] = result.ComputeResult.summary[key]));
  resultKeys.forEach((key) => (data[key] = result[key]));
  return data;
};

export const getResultNameByNameData = (
  i18n,
  projectParent,
  resultNameData
) => {
  const name = [];
  const { InitSimulationCalcul, created_at, Comment, ProjectID } =
    resultNameData;

  // on ajoute au nom du résultat le type...
  if (!isNull(InitSimulationCalcul)) {
    name.push(
      InitSimulationCalcul
        ? i18n._('computeType.simu')
        : i18n._('computeType.opti')
    );
  }

  // ...la date
  name.push(formatDateAndHour(i18n, created_at));

  // ... la description
  name.push(getResultDescription(i18n, projectParent, ProjectID));

  // ...l'objectif
  const goal = getResultGoalName(i18n, resultNameData);
  if (goal) name.push(goal);

  // ... le commentaire
  if (Comment && Comment.trim().length !== 0) {
    name.push(Comment);
  }

  return name.join(i18n._('result.name.separator'));
};

export const getResultDescription = (i18n, projectParent, ProjectID) => {
  const descriptionIndex = getComputeDescriptionIndex(projectParent, ProjectID);
  let description;
  switch (descriptionIndex) {
    case -1:
      description = i18n._('description.deleted');
      break;
    case 0:
      description =
        projectParent.description || i18n._('description', { index: 1 });
      break;
    default:
      description =
        projectParent.children[descriptionIndex - 1].description ||
        i18n._('description', { index: descriptionIndex + 1 });
  }
  return description;
};

export const getCompareResultName = (i18n, comment, resultIndex) => {
  const name = [i18n._('result', { index: resultIndex + 1 })];
  if (comment) name.push(comment);
  return name.join(i18n._('result.name.separator'));
};

export const getSingleValueName = (
  i18n,
  projectParent,
  group,
  resultNameData
) => {
  try {
    const name = [];
    const pushIfExist = (part) => {
      if (part) name.push(part);
    };
    pushIfExist(getGroupHeadingName(i18n, projectParent, group));
    name.push(
      getResultGoalName(i18n, resultNameData) ?? i18n._('result.name.noGoal')
    );
    pushIfExist(getResultComment(resultNameData));
    return name.join(i18n._('result.name.separator'));
  } catch (err) {
    throw err;
  }
};

export const getGroupHeadingName = (i18n, projectParent, group) => {
  try {
    const name = [];
    // on ajoute au nom du résultat le type...
    name.push(
      group.type === COMPUTE_TYPE.SIMU
        ? i18n._('computeType.simu')
        : i18n._('computeType.opti')
    );

    // ...la date
    name.push(formatDateAndHour(i18n, group.date));

    // ... la description
    name.push(getResultDescription(i18n, projectParent, group.descriptionId));

    return name.join(i18n._('result.name.separator'));
  } catch (err) {
    throw err;
  }
};

export const getOptionName = (i18n, resultNameData) => {
  const name = [];
  const pushIfExist = (part) => {
    if (part) name.push(part);
  };
  name.push(
    getResultGoalName(i18n, resultNameData) ?? i18n._('result.name.noGoal')
  );
  pushIfExist(getResultComment(resultNameData));
  return name.join(i18n._('result.name.separator'));
};

export const getResultGoalName = (i18n, resultNameData) => {
  const {
    InitSimulationCalcul,
    InitConstraintEnabled,
    PartENR,
    EnergyFinalPercent,
    CO2Percent,
    GasPart,
    PartHpgTfp,
    EnergyPrimaryPercent
  } = resultNameData;

  if (InitSimulationCalcul || !InitConstraintEnabled) return null;
  const constraints = {
    InitConstraintENR: PartENR,
    InitConstraintEnergyFinale: EnergyFinalPercent,
    InitConstraintCO2kg: CO2Percent,
    InitConstraintHpg: PartHpgTfp,
    InitConstraintGasPart: GasPart,
    InitConstraintEnergyPrimary: EnergyPrimaryPercent
  };
  const constraintKey = Object.keys(constraints).find(
    (key) => resultNameData[key]
  );
  return constraintKey
    ? i18n._(`result.name.goal.${constraintKey}`, {
        value: formatValueWithUnit(
          i18n,
          constraints[constraintKey],
          UNIT.PERCENT
        )
      })
    : null;
};

export const getResultComment = (result) => {
  return result.Comment && result.Comment.trim().length !== 0
    ? result.Comment
    : '';
};

export const setCalculationGlobalParams = (
  calculationData,
  userId,
  userEmail
) => {
  calculationData.userId = userId;
  calculationData.userEmail = userEmail;
  calculationData.path = location.host;
  calculationData.engineId = undefined;
  return calculationData;
};

export const getSelectedGoalParam = (inp) => {
  if (!inp.InitConstraintReference || !inp.InitConstraintEnabled) return null;
  const goalSelected = OPTIONS_GOAL.filter((param) => inp[param.key]);
  return !isArrNullOrEmpty(goalSelected) ? goalSelected[0] : null;
};

export const getSummaryRefPath = (inp) => {
  return inp.InitReferenceSolution === REFERENCE_SOLUTION.GAS_CHILLER
    ? 'summaryRefGas'
    : 'summaryRefHpa';
};

export const getResultIndexes = (groups, computeId) => {
  for (let i = 0; i < groups.length; ++i) {
    for (let j = 0; j < groups[i].computes.length; ++j) {
      if (groups[i].computes[j].ComputeID === computeId) {
        return [i, j];
      }
    }
  }
  return [-1, -1];
};
