import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import {
  NB_HOURS_PER_YEAR,
  NB_MILLISECONDS_PER_HOUR
} from '../../../../../../../../../server/constants/date.constant';
import { DETAILED_RESULTS_SST_KEYS } from '../../../../../../../../../server/constants/result.constant';
import { SOURCE } from '../../../../../../../../../server/constants/source.constant';
import LinesChart from '../../../../../../../components/Chart/LinesChart';
import { MODE } from '../../../../../../../constants/plot.constant';
import { DETAILED_RESULTS_SST_COLORS } from '../../../../../../../styles/colors/detailedResult.color';
import { SOURCES_CHART_COLORS } from '../../../../../../../styles/colors/source.color';
import {
  formatValue,
  isArrNullOrEmpty,
  isObjNullOrEmpty
} from '../../../../../../../utils/data.utils';
import './PowerLoadCurve.css';

const PowerLoadCurveSst = ({ result, substation, need, sorted }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memo]
  const data = useMemo(() => {
    if (isObjNullOrEmpty(result.details)) return null;
    const newData = [];

    // on récupère les clés des différentes sources
    const sstSrcKeys = Object.keys(DETAILED_RESULTS_SST_KEYS[need].sources)
      .filter((key) => result.details[`${substation}_${key}`])
      .map((key) => `${substation}_${key}`);
    if (isArrNullOrEmpty(sstSrcKeys)) return null;

    // on récupère la clé du total
    const totalKey = Object.keys(DETAILED_RESULTS_SST_KEYS[need].total).find(
      (key) => result.details[`${substation}_${key}`]
    );
    if (!totalKey) return null;

    // l'abcisse = nombre d'heures par année
    const x = new Array(NB_HOURS_PER_YEAR).fill(0).map((_, index) => index);

    // utils
    const getSrc = (sstSrcKey) => {
      return Object.values(SOURCE).find((source) =>
        sstSrcKey
          .substring(substation.length, sstSrcKey.length)
          .toLowerCase()
          .includes(source)
      );
    };

    const getArea = (y, srcY, src, fill) => {
      if (!sorted) {
        let timestamp = new Date(new Date().getFullYear(), 0, 0).getTime();
        for (let i = 0; i < NB_HOURS_PER_YEAR; ++i) {
          timestamp += NB_MILLISECONDS_PER_HOUR;
          x[i] = new Date(timestamp).toISOString();
        }
      }
      return {
        x,
        y,
        mode: MODE.NONE,
        fill,
        fillcolor: SOURCES_CHART_COLORS[src],
        name: i18n._(`source.${src}`),
        text: formatValue(srcY, 2),
        hovertemplate: '%{text}'
      };
    };

    // la courbe représentant la couverture des besoins
    let totalValues = result.details[`${substation}_${totalKey}`]
      .splice(0, NB_HOURS_PER_YEAR)
      .map((total, hour) => ({ total, hour }));

    // si c'est une monotone alors on la trie par valeurs décroissantes
    if (sorted) totalValues = totalValues.sort((a, b) => b.total - a.total);

    const totalY = totalValues.map((value) => value.total);
    const name = sorted
      ? i18n._('results.energyAndCarbon.powerLoadCurve.name')
      : i18n._('results.energyAndCarbon.yearNeedsCurve.total.name', {
          need: i18n._(`need.${need}`)
        });
    newData.push({
      x,
      y: totalY,
      mode: MODE.LINES,
      name,
      line: {
        color: DETAILED_RESULTS_SST_COLORS[totalKey],
        width: sorted ? 2 : 0.5
      }
    });

    // les autres courbes
    /**
     * Ordre de somme des aires
        - Chauffage : (TFP + HPG) → HPSolaireThermique→ HPA → Gaz
        - ECS : (TFP + HPG) → HPSolaireThermique→ HPA → Gaz
        - Climatisation : (TFP + HPG) → HPA → ITES
     */
    let tempTotalValuesY;
    const tfpSstSrcKey = sstSrcKeys.find(
      (sstSrcKey) => getSrc(sstSrcKey) === SOURCE.TFP
    );
    sstSrcKeys
      .filter((sstSrcKey) => getSrc(sstSrcKey) !== SOURCE.TFP)
      .forEach((sstSrcKey, i) => {
        const source = getSrc(sstSrcKey);
        const valuesY = new Array(totalValues.length);
        const srcY = new Array(totalValues.length);
        if (source === SOURCE.HPG && tfpSstSrcKey) {
          // TFP est compris dans HPG
          totalValues.forEach((value, j) => {
            // le total temporaire de toutes les valeurs à l'heure "value.hour"
            valuesY[j] =
              (tempTotalValuesY ? tempTotalValuesY[j] : 0) +
              result.details[sstSrcKey][value.hour] +
              result.details[tfpSstSrcKey][value.hour];

            // la vraie valeur à l'heure "value.hour"
            srcY[j] =
              result.details[sstSrcKey][value.hour] +
              result.details[tfpSstSrcKey][value.hour];
          });
        } else {
          totalValues.forEach((value, j) => {
            // le total temporaire de toutes les valeurs à l'heure "value.hour"
            valuesY[j] =
              (tempTotalValuesY ? tempTotalValuesY[j] : 0) +
              result.details[sstSrcKey][value.hour];

            // la vraie valeur à l'heure "value.hour"
            srcY[j] = result.details[sstSrcKey][value.hour];
          });
        }
        tempTotalValuesY = [...valuesY];
        newData.push(
          getArea(valuesY, srcY, source, i === 0 ? 'tozeroy' : 'tonexty')
        );
      });
    return newData;
  }, [result.details, substation]);
  //#endregion

  //#region [render]
  let titleId;
  let layout = {
    margin: { b: 70 },
    xaxis: undefined,
    yaxis: {
      title: {
        text: i18n._('results.energyAndCarbon.powerLoadCurve.yaxis'),
        standoff: 30,
        showlegend: true
      }
    }
  };

  if (sorted) {
    titleId = 'results.energyAndCarbon.powerLoadCurve.need';
    layout.xaxis = {
      title: i18n._('results.energyAndCarbon.powerLoadCurve.xaxis')
    };
  } else {
    titleId = 'results.energyAndCarbon.yearNeedsCoverageCurve.need';
    layout.xaxis = {
      tickformat: '%b',
      dtick: 'M1'
    };
  }
  const title = i18n._(titleId, {
    need: i18n._(`need.${need}`).toLowerCase()
  });
  return data ? (
    <LinesChart
      data={data}
      layout={layout}
      title={title}
      filename={i18n._('results.energyAndCarbon.powerLoadCurveSst.filename', {
        result: result.ComputeName,
        sst: substation,
        title
      })}
    />
  ) : (
    <Alert variant='warning' className='no-power-load'>
      {i18n._('results.energyAndCarbon.noPowerLoadCurve')}
    </Alert>
  );
  //#endregion
};

export default PowerLoadCurveSst;
