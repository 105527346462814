// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toast {
    position: fixed;
    top: 10px;
    right: 10px;
    padding: 10px 15px;
    background-color: white;
    width: 350px;
    z-index: 200;
}

.toast .toast-header {
    background-color: white;
    font-size: 17px;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
    border-bottom: none;
    padding: 0 0 5px 0;
}

.toast .toast-body {
    background-color: white;
    font-size: 15px;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    border-top: 1px solid rgb(220, 220, 225);
    padding: 8px 0 0 0;
}

.toast-square {
    width: 22px;
    height: 22px;
    border-radius: 5px;
    -webkit-box-shadow: 0 0 0 1px rgba(211, 211, 211, 0.6);
            box-shadow: 0 0 0 1px rgba(211, 211, 211, 0.6);
    margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/Toast/Toast.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,SAAS;IACT,WAAW;IACX,kBAAkB;IAClB,uBAAuB;IACvB,YAAY;IACZ,YAAY;AAChB;;AAEA;IACI,uBAAuB;IACvB,eAAe;IACf,4BAA4B;IAC5B,6BAA6B;IAC7B,mBAAmB;IACnB,kBAAkB;AACtB;;AAEA;IACI,uBAAuB;IACvB,eAAe;IACf,+BAA+B;IAC/B,gCAAgC;IAChC,wCAAwC;IACxC,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,sDAAsD;YAC9C,8CAA8C;IACtD,kBAAkB;AACtB","sourcesContent":[".toast {\n    position: fixed;\n    top: 10px;\n    right: 10px;\n    padding: 10px 15px;\n    background-color: white;\n    width: 350px;\n    z-index: 200;\n}\n\n.toast .toast-header {\n    background-color: white;\n    font-size: 17px;\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n    border-bottom: none;\n    padding: 0 0 5px 0;\n}\n\n.toast .toast-body {\n    background-color: white;\n    font-size: 15px;\n    border-bottom-left-radius: 10px;\n    border-bottom-right-radius: 10px;\n    border-top: 1px solid rgb(220, 220, 225);\n    padding: 8px 0 0 0;\n}\n\n.toast-square {\n    width: 22px;\n    height: 22px;\n    border-radius: 5px;\n    -webkit-box-shadow: 0 0 0 1px rgba(211, 211, 211, 0.6);\n            box-shadow: 0 0 0 1px rgba(211, 211, 211, 0.6);\n    margin-right: 10px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
