import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ST_EQUIPMENT } from '../../../../../../server/constants/performance.constant';
import { getSTParams } from '../../../../../../server/models/design/performance.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import FormSelect from '../../../../components/Form/FormSelect';
import PerformancePageContext from '../../../../contexts/PerformancePageContext';
import PopupContext from '../../../../contexts/PopupContext';
import azimuthImg from '../../../../image/azimuth.png';
import { getDefaultValue } from '../../../../utils/project.utils';

const SolarThermalBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const {
    tempProject,
    addError,
    removeError,
    setTempProject,
    handleOtherValueChange
  } = useContext(PerformancePageContext);
  const { openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const getModel = (models, value) => {
    return models.find((model) => model.value === value);
  };

  const handleSolarThermalModelChange = (modelValue) => {
    const { InitPhotovoltaicModel } = tempProject.otherValues;
    const stModel = getModel(Object.values(ST_EQUIPMENT), modelValue);
    if (!stModel.data) return;
    if (InitPhotovoltaicModel !== stModel.linkedPv) {
      handleOtherValueChange('InitPhotovoltaicModel', stModel.linkedPv);
    }
    Object.entries(stModel.data).forEach(([key, value]) => {
      tempProject.otherValues[key] = value;
    });
    tempProject.otherValues.InitSolarThermalModel = modelValue;
    setTempProject(() => ({ ...tempProject }));
  };
  //#endregion

  //#region [render]
  const {
    InitSolarThermalModel,
    InitSolarSurfaceTilt,
    InitSolarSurfaceAzimuth,
    InitSolarThermalHeta0,
    InitSolarThermalB1,
    InitSolarThermalBu,
    InitSolarThermalEpsa,
    InitSolarThermalB2,
    InitSolarThermalHeadlossMceMax,
    InitSolarThermalFlowRate,
    InitSolarDeltaTemp,
    InitSolarTemperatureMin,
    InitSolarInjectionRatio
  } = tempProject.otherValues;

  const stModel = getModel(Object.values(ST_EQUIPMENT), InitSolarThermalModel);
  const { isCombined } = stModel;
  const ST = getSTParams(stModel);
  const disabledOptions = Object.values(ST_EQUIPMENT)
    .filter((st) => st.disabled)
    .map((st) => st.value);

  return (
    <Bloc title={i18n._('performance.solarThermal')}>
      <div className='performance-row'>
        <FormSelect
          label={i18n._('performance.InitSolarThermalModel')}
          value={InitSolarThermalModel}
          param={ST.InitSolarThermalModel}
          onChange={handleSolarThermalModelChange}
          disabledOptions={disabledOptions}
        />
        {isCombined && (
          <span className='performance-combined'>
            <FontAwesomeIcon icon='exclamation-circle' />
            {i18n._('performance.combined.st')}
          </span>
        )}
      </div>
      <Row>
        <Col>
          <FormInput
            label={i18n._('performance.InitSolarSurfaceTilt')}
            value={InitSolarSurfaceTilt}
            param={ST.InitSolarSurfaceTilt}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarSurfaceTilt', value)
            }
            addError={addError}
            removeError={removeError}
            topText={i18n._('performance.topSTInitSolarSurfaceTilt')}
            bottomText={getDefaultValue(i18n, ST.InitSolarSurfaceTilt)}
            unit
          />
        </Col>
        <Col className='performance-azimuth-col'>
          <FormInput
            label={i18n._('performance.InitSolarSurfaceAzimuth')}
            value={InitSolarSurfaceAzimuth}
            param={ST.InitSolarSurfaceAzimuth}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarSurfaceAzimuth', value)
            }
            addError={addError}
            removeError={removeError}
            topText={i18n._('performance.topSTInitSolarSurfaceAzimuth')}
            bottomText={getDefaultValue(i18n, ST.InitSolarSurfaceAzimuth)}
            unit
            showInfoIcon
            onInfoClick={() =>
              openInfoModal(
                i18n._('performance.azimuthModalTitle'),
                <img
                  src={azimuthImg}
                  alt='azimuthImg'
                  style={{ width: '90%', height: '90%' }}
                />
              )
            }
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('performance.InitSolarThermalHeta0')}
            value={InitSolarThermalHeta0}
            param={ST.InitSolarThermalHeta0}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarThermalHeta0', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(i18n, ST.InitSolarThermalHeta0)}
            disabled={InitSolarThermalModel !== ST_EQUIPMENT.CUSTOM.value}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('performance.InitSolarThermalB1')}
            value={InitSolarThermalB1}
            param={ST.InitSolarThermalB1}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarThermalB1', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(i18n, ST.InitSolarThermalB1)}
            disabled={InitSolarThermalModel !== ST_EQUIPMENT.CUSTOM.value}
            unit
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('performance.InitSolarThermalBu')}
            value={InitSolarThermalBu}
            param={ST.InitSolarThermalBu}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarThermalBu', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(i18n, ST.InitSolarThermalBu)}
            disabled={InitSolarThermalModel !== ST_EQUIPMENT.CUSTOM.value}
            unit
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('performance.InitSolarThermalEpsa')}
            value={InitSolarThermalEpsa}
            param={ST.InitSolarThermalEpsa}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarThermalEpsa', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(i18n, ST.InitSolarThermalEpsa)}
            disabled={InitSolarThermalModel !== ST_EQUIPMENT.CUSTOM.value}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('performance.InitSolarThermalB2')}
            value={InitSolarThermalB2}
            param={ST.InitSolarThermalB2}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarThermalB2', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(i18n, ST.InitSolarThermalB2)}
            disabled={InitSolarThermalModel !== ST_EQUIPMENT.CUSTOM.value}
            unit
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('performance.InitSolarThermalHeadlossMceMax')}
            value={InitSolarThermalHeadlossMceMax}
            param={ST.InitSolarThermalHeadlossMceMax}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarThermalHeadlossMceMax', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(
              i18n,
              ST.InitSolarThermalHeadlossMceMax
            )}
            disabled={InitSolarThermalModel !== ST_EQUIPMENT.CUSTOM.value}
            unit
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('performance.InitSolarThermalFlowRate')}
            value={InitSolarThermalFlowRate}
            param={ST.InitSolarThermalFlowRate}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarThermalFlowRate', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(i18n, ST.InitSolarThermalFlowRate)}
            unit
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('performance.InitSolarDeltaTemp')}
            value={InitSolarDeltaTemp}
            param={ST.InitSolarDeltaTemp}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarDeltaTemp', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(i18n, ST.InitSolarDeltaTemp)}
            unit
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormInput
            label={i18n._('performance.InitSolarTemperatureMin')}
            value={InitSolarTemperatureMin}
            param={ST.InitSolarTemperatureMin}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarTemperatureMin', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(i18n, ST.InitSolarTemperatureMin)}
            unit
          />
        </Col>
        <Col xs={6}>
          <FormInput
            label={i18n._('performance.InitSolarInjectionRatio')}
            value={InitSolarInjectionRatio}
            param={ST.InitSolarInjectionRatio}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarInjectionRatio', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(i18n, ST.InitSolarInjectionRatio)}
            unit
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default SolarThermalBloc;
