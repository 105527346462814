const LANGUAGE_KEY = 'language';
const ADMIN_KEY = 'AdminMode';
const ACCESS_KEY = 'access';
const REFRESH_KEY = 'refreshToken';
const COMPANY_KEY = 'company';

module.exports = {
  ADMIN_KEY,
  LANGUAGE_KEY,
  ACCESS_KEY,
  REFRESH_KEY,
  COMPANY_KEY
};
