// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.opaque-layer {
    position: absolute;
    background-color: rgba(255, 255, 255, 0.5);
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 99;
}`, "",{"version":3,"sources":["webpack://./src/components/OpaqueLayer/OpaqueLayer.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,0CAA0C;IAC1C,MAAM;IACN,SAAS;IACT,OAAO;IACP,QAAQ;IACR,WAAW;AACf","sourcesContent":[".opaque-layer {\n    position: absolute;\n    background-color: rgba(255, 255, 255, 0.5);\n    top: 0;\n    bottom: 0;\n    left: 0;\n    right: 0;\n    z-index: 99;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
