import { useLingui } from '@lingui/react';
import React, { Fragment, useContext, useMemo } from 'react';
import { NEED } from '../../../../../../../server/constants/need.constant.js';
import AccentaVsRefTable from '../../../../../components/AccentaVsRefTable/AccentaVsRefTable.jsx';
import CompareResultsCards from '../../../../../components/CompareResultsCards/CompareResultsCards.jsx';
import CompareResultsElements from '../../../../../components/CompareResultsElements/CompareResultsElements.jsx';
import CompareResultsExpandableTable from '../../../../../components/CompareResultsExpandableTable/CompareResultsExpandableTable.jsx';
import CompareResultsTable from '../../../../../components/CompareResultsTable/CompareResultsTable.jsx';
import ExpandableTable from '../../../../../components/ExpandableTable/ExpandableTable.jsx';
import Section from '../../../../../components/Section/Section.jsx';
import SectionTitle from '../../../../../components/SectionTitle.jsx';
import ResultContext from '../../../../../contexts/ResultContext.jsx';
import ConsumptionTable from './components/ConsumptionTable.jsx';
import EnergyCarbonGoalAlert from './components/EnergyCarbonGoalAlert.jsx';
import EnergyCarbonSubstationTable from './components/EnergyCarbonSubstationTable.jsx';
import PowerLoadCurveTotal from './components/PowerLoadCurve/PowerLoadCurveTotal.jsx';
import SubstationNeedsChart from './components/SubstationNeedsChart.jsx';
import COPEERPerYearLinesChart from './components/heatpumps/COPEERPerYearLinesChart';
import COPEERPerYearTable from './components/heatpumps/COPEERPerYearTable.jsx';
import COPsDistributionTable from './components/heatpumps/COPsDistributionTable.jsx';
import EnergyConsumedDistributionTable from './components/heatpumps/EnergyConsumedDistributionTable.jsx';
import EnergyProducedDistributionPieChart from './components/heatpumps/EnergyProducedDistributionPieChart/EnergyProducedDistributionPieChart.jsx';
import EnergyProducedDistributionTable from './components/heatpumps/EnergyProducedDistributionTable.jsx';
import PVEnergyDistributionPieChart from './components/photovoltaïc/PVEnergyDistributionPieChart.jsx';
import PVEnergyTable from './components/photovoltaïc/PVEnergyTable.jsx';
import {
  stdTemplate,
  temperedLoopTemplate,
  totalByGazTemplate,
  totalCarbonYieldTemplate,
  totalColdTemplate,
  totalEnergyNeedsTemplates,
  totalEnergyYieldTemplate,
  totalHeatTemplate,
  totalHotwaterTemplate,
  totalPVTemplate
} from './templates.js';

const EnergyCarbonSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { selectedResult, comparedResults, loadDetails } =
    useContext(ResultContext);
  //#endregion

  //#region [memos]
  const STDTemplate = useMemo(() => {
    const { substations } = selectedResult;
    return stdTemplate(i18n, comparedResults, substations[0]);
  }, [comparedResults]);

  const TLTemplate = useMemo(() => {
    return temperedLoopTemplate(i18n, comparedResults);
  }, [comparedResults]);

  const TBGTemplate = useMemo(() => {
    return totalByGazTemplate(i18n, comparedResults);
  }, [comparedResults]);

  const THTemplate = useMemo(() => {
    return totalHeatTemplate(i18n, comparedResults);
  }, [comparedResults]);

  const TCTemplate = useMemo(() => {
    return totalColdTemplate(i18n, comparedResults);
  }, [comparedResults]);

  const THWTemplate = useMemo(() => {
    return totalHotwaterTemplate(i18n, comparedResults);
  }, [comparedResults]);

  const TENTemplates = useMemo(() => {
    return totalEnergyNeedsTemplates(i18n, comparedResults);
  }, [comparedResults]);

  const TPVTemplate = useMemo(() => {
    return totalPVTemplate(i18n, comparedResults);
  }, [comparedResults]);

  const TEYTemplate = useMemo(() => {
    return totalEnergyYieldTemplate(i18n, comparedResults);
  }, [comparedResults]);

  const TCYTemplate = useMemo(() => {
    return totalCarbonYieldTemplate(i18n, comparedResults);
  }, [comparedResults]);
  //#endregion

  //#region [render]
  const { substations } = selectedResult;
  return (
    <Section title={i18n._('results.energyAndCarbon')} open>
      <SectionTitle title={i18n._('results.energyAndCarbon.goals')} level={2} />
      <CompareResultsElements element={EnergyCarbonGoalAlert} />
      {substations.length > 1 &&
        substations.map((substation, index) => (
          <EnergyCarbonSubstationTable
            key={'energyAndCarbon_' + substation + '_' + index}
            substation={substation}
            sstIndex={index}
          />
        ))}
      {substations.length > 1 && (
        <CompareResultsTable level={2} template={TLTemplate} />
      )}
      <Section
        title={
          substations.length > 1
            ? i18n._('totalProject')
            : i18n._('totalProjectBySst', {
                sst: substations[0]
              })
        }
        level={2}
        open
      >
        {substations.length === 1 && (
          <Fragment>
            <CompareResultsExpandableTable level={3} template={STDTemplate} />
            <ExpandableTable>
              <CompareResultsCards
                title={i18n._('results.energyAndCarbon.sst.needsChart.title')}
                onOpen={loadDetails}
                element={SubstationNeedsChart}
                elementProps={{ substation: substations[0] }}
              />
            </ExpandableTable>
          </Fragment>
        )}
        <CompareResultsTable template={THTemplate} level={3} />
        <ExpandableTable>
          <CompareResultsCards
            title={i18n._('results.energyAndCarbon.powerLoadCurve')}
            onOpen={loadDetails}
            element={PowerLoadCurveTotal}
            elementProps={{ need: NEED.HEAT, sorted: true }}
          />
          <CompareResultsCards
            title={i18n._('results.energyAndCarbon.yearNeedsCoverageCurve')}
            onOpen={loadDetails}
            element={PowerLoadCurveTotal}
            elementProps={{ need: NEED.HEAT }}
          />
        </ExpandableTable>
        <CompareResultsTable template={TCTemplate} level={3} />
        <ExpandableTable>
          <CompareResultsCards
            title={i18n._('results.energyAndCarbon.powerLoadCurve')}
            onOpen={loadDetails}
            element={PowerLoadCurveTotal}
            elementProps={{ need: NEED.COLD, sorted: true }}
          />
          <CompareResultsCards
            title={i18n._('results.energyAndCarbon.yearNeedsCoverageCurve')}
            onOpen={loadDetails}
            element={PowerLoadCurveTotal}
            elementProps={{ need: NEED.COLD }}
          />
        </ExpandableTable>
        <CompareResultsTable template={THWTemplate} level={3} />
        <ExpandableTable>
          <CompareResultsCards
            title={i18n._('results.energyAndCarbon.powerLoadCurve')}
            onOpen={loadDetails}
            element={PowerLoadCurveTotal}
            elementProps={{ need: NEED.HOTWATER, sorted: true }}
          />
          <CompareResultsCards
            title={i18n._('results.energyAndCarbon.yearNeedsCoverageCurve')}
            onOpen={loadDetails}
            element={PowerLoadCurveTotal}
            elementProps={{ need: NEED.HOTWATER }}
          />
        </ExpandableTable>
        {TENTemplates.map((template, index) => (
          <CompareResultsTable
            template={template}
            key={'energyAndCarbon_energyNeedsTotal_template_' + index}
            level={3}
          />
        ))}
        <ExpandableTable>
          <CompareResultsCards
            title={i18n._(
              'results.energyAndCarbon.total.energySource.consumptionTable.elec.title'
            )}
            element={ConsumptionTable}
            elementProps={{
              consumptionKey: 'Elec',
              verticalTitle: i18n._(
                'results.energyAndCarbon.total.energySource.consumptionTable.elec'
              )
            }}
          />
        </ExpandableTable>
        <CompareResultsTable template={TBGTemplate} />
        <ExpandableTable>
          <CompareResultsCards
            title={i18n._(
              'results.energyAndCarbon.total.energySource.consumptionTable.gas.title'
            )}
            element={ConsumptionTable}
            elementProps={{
              consumptionKey: 'Gas',
              verticalTitle: i18n._(
                'results.energyAndCarbon.total.energySource.consumptionTable.gas'
              )
            }}
          />
        </ExpandableTable>
        <ExpandableTable
          level={3}
          title={i18n._('results.energyAndCarbon.energyBalance')}
        >
          <CompareResultsCards
            title={i18n._(
              'results.energyAndCarbon.energyBalance.energyProducedDistributionPieChart.title'
            )}
            element={EnergyProducedDistributionPieChart}
            open
          />
          <CompareResultsCards
            title={i18n._(
              'results.energyAndCarbon.energyBalance.productionsTable.title'
            )}
            element={EnergyProducedDistributionTable}
          />
          <CompareResultsCards
            title={i18n._(
              'results.energyAndCarbon.energyBalance.consumptionsTable.title'
            )}
            element={EnergyConsumedDistributionTable}
          />
          <CompareResultsCards
            title={i18n._(
              'results.energyAndCarbon.energyBalance.copsTable.title'
            )}
            element={COPsDistributionTable}
          />
          <CompareResultsCards
            title={i18n._(
              'results.energyAndCarbon.energyBalance.copEerPerYearTable.title'
            )}
            element={COPEERPerYearTable}
          />
          <CompareResultsCards
            title={i18n._(
              'results.energyAndCarbon.energyBalance.copEerPerYearLinesChart.title'
            )}
            element={COPEERPerYearLinesChart}
          />
        </ExpandableTable>
        <CompareResultsTable level={3} template={TPVTemplate} />
        <ExpandableTable>
          <CompareResultsCards
            title={i18n._(
              'results.energyAndCarbon.total.pv.pvEnergyDistributionPieChart.title'
            )}
            element={PVEnergyDistributionPieChart}
          />
          <CompareResultsCards
            title={i18n._(
              'results.energyAndCarbon.total.pv.pvEnergyTable.title'
            )}
            element={PVEnergyTable}
          />
        </ExpandableTable>
        <SectionTitle
          level={3}
          title={i18n._('results.energyAndCarbon.energyYield')}
        />
        <AccentaVsRefTable template={TEYTemplate} />
        <SectionTitle
          level={3}
          title={i18n._('results.energyAndCarbon.carbonYield')}
        />
        <AccentaVsRefTable template={TCYTemplate} />
      </Section>
    </Section>
  );
  //#endregion
};

export default EnergyCarbonSection;
