import dateFormat from 'dateformat';
import {
  HOURS_BY_MONTH,
  MONTHS,
  NB_HOURS_PER_YEAR
} from '../../../server/constants/date.constant';

export const getHoursInterval = (month, year) => {
  const monthIndex = MONTHS.findIndex((m) => m === month);
  let hoursSinceJanuary = 0;

  for (let i = 0; i < monthIndex; i++) {
    const currentMonth = MONTHS[i];
    hoursSinceJanuary += HOURS_BY_MONTH[currentMonth];
  }

  const startHour = hoursSinceJanuary + year * NB_HOURS_PER_YEAR + 1;
  const endHour = startHour + HOURS_BY_MONTH[month];

  return [startHour, endHour];
};

export const formatDate = (date, language) => {
  if (!date) return '';
  return date.toLocaleString(language, {
    dateStyle: 'short'
  });
};

export const formatHour = (date, language) => {
  if (!date) return '';
  return date.toLocaleString(language, {
    timeStyle: 'short'
  });
};

export const formatDateAndHour = (i18n, date) => {
  return dateFormat(date, i18n._('formatDateAndHour'));
};
