import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { EXCHANGER } from '../../../../../../server/models/design/performance.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import PerformancePageContext from '../../../../contexts/PerformancePageContext';
import { getDefaultValue } from '../../../../utils/project.utils';

const ExchangerBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleParamChange } = useContext(
    PerformancePageContext
  );
  //#endregion

  //#region [render]
  const {
    InitLoopExchangerPinch,
    InitLoopExchangerU,
    InitLoopExchangerAirPinch,
    InitLoopExchangerAirU,
    InitStationExchangerPinch,
    InitStationColdExchangerPinch,
    InitStationHotwaterExchangerPinch
  } = tempProject;

  return (
    <Bloc title={i18n._('performance.exchangers')}>
      <Row>
        <Col>
          <FormInput
            label={i18n._('performance.InitLoopExchangerPinch')}
            value={InitLoopExchangerPinch}
            param={EXCHANGER.InitLoopExchangerPinch}
            onBlur={(value) =>
              handleParamChange('InitLoopExchangerPinch', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(i18n, EXCHANGER.InitLoopExchangerPinch)}
            unit
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('performance.InitLoopExchangerU')}
            value={InitLoopExchangerU}
            param={EXCHANGER.InitLoopExchangerU}
            onBlur={(value) => handleParamChange('InitLoopExchangerU', value)}
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(i18n, EXCHANGER.InitLoopExchangerU)}
            unit
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('performance.InitLoopExchangerAirPinch')}
            value={InitLoopExchangerAirPinch}
            param={EXCHANGER.InitLoopExchangerAirPinch}
            onBlur={(value) =>
              handleParamChange('InitLoopExchangerAirPinch', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(
              i18n,
              EXCHANGER.InitLoopExchangerAirPinch
            )}
            unit
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('performance.InitLoopExchangerAirU')}
            value={InitLoopExchangerAirU}
            param={EXCHANGER.InitLoopExchangerAirU}
            onBlur={(value) =>
              handleParamChange('InitLoopExchangerAirU', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(i18n, EXCHANGER.InitLoopExchangerAirU)}
            unit
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('performance.InitStationExchangerPinch')}
            value={InitStationExchangerPinch}
            param={EXCHANGER.InitStationExchangerPinch}
            onBlur={(value) =>
              handleParamChange('InitStationExchangerPinch', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(
              i18n,
              EXCHANGER.InitStationExchangerPinch
            )}
            unit
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('performance.InitStationColdExchangerPinch')}
            value={InitStationColdExchangerPinch}
            param={EXCHANGER.InitStationColdExchangerPinch}
            onBlur={(value) =>
              handleParamChange('InitStationColdExchangerPinch', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(
              i18n,
              EXCHANGER.InitStationColdExchangerPinch
            )}
            unit
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormInput
            label={i18n._('performance.InitStationHotwaterExchangerPinch')}
            value={InitStationHotwaterExchangerPinch}
            param={EXCHANGER.InitStationHotwaterExchangerPinch}
            onBlur={(value) =>
              handleParamChange('InitStationHotwaterExchangerPinch', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(
              i18n,
              EXCHANGER.InitStationHotwaterExchangerPinch
            )}
            unit
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default ExchangerBloc;
