export const BASIC = Object.freeze([
  '#024f7d',
  '#f58231',
  '#911eb4',
  '#42d4f4',
  '#f032e6',
  '#bfef45',
  '#9A6324',
  '#469990',
  '#dcbeff',
  '#000000',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000'
]);
