import { COMPUTE_TYPE } from '../../../server/constants/constraint.constant';
import axios from '../conf/axios.conf';

//#region [GET]
export const fetchEmptyChild = async (projectParentId) => {
  try {
    const url = `/project/${projectParentId}/emptyChild`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchChildCopy = async (projectCopy) => {
  try {
    if (!projectCopy.AhsID) return projectCopy;
    const url = `/project/${projectCopy.AhsID}/childCopy`;
    const response = await axios.get(url);
    const csvType = { type: 'text/csv' };

    const { weatherFile, ahsWeatherFileOriginalName } = response.data;
    if (weatherFile) {
      const blob = new Blob([weatherFile], csvType);
      response.data.weatherFile = new File(
        [blob],
        ahsWeatherFileOriginalName,
        csvType
      );
    }

    response.data.substations
      .filter((sst) => sst.stdFile)
      .forEach((sst) => {
        const blob = new Blob([sst.stdFile], csvType);
        sst.stdFile = new File([blob], sst.filesStdOriginalName, csvType);
      });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchProject = async (projectId, withChildren) => {
  try {
    const url = `/project/${projectId}`;
    const response = await axios.get(url, {
      params: {
        withChildren
      }
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchResults = async (projectId, withChildren) => {
  try {
    return fetchResultsByType(projectId, COMPUTE_TYPE.SIMU, withChildren);
  } catch (err) {
    throw err;
  }
};

export const fetchResultsByType = async (
  projectId,
  calculationType,
  withChildren
) => {
  try {
    const url = `/project/${projectId}/results`;
    const response = await axios.get(url, {
      params: {
        calculationType,
        withChildren
      }
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchCurrentCalculation = async (projectId) => {
  try {
    const url = `/project/${projectId}/calculation`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchWeatherFile = async (projectId) => {
  try {
    const url = `/project/${projectId}/weather`;
    const response = await axios.get(url);
    const weatherFile = response.data;
    const filename = decodeURI(
      response.headers['content-disposition'].split('filename=')[1]
    );
    const csvType = { type: 'text/csv' };
    const blob = new Blob([weatherFile], csvType);
    return new File([blob], filename, csvType);
  } catch (err) {
    throw err;
  }
};

export const fetchNextCalculationData = async (
  projectParentId,
  computeId,
  type
) => {
  try {
    const url = `/project/${projectParentId}/nextCalculationData`;
    const response = await axios.get(url, {
      params: {
        computeId,
        type
      }
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchComputeIdByTag = async (projectId, tag) => {
  try {
    const response = await axios.get(`/project/${projectId}/tag/${tag}`);
    return response.data.computeId;
  } catch (err) {
    throw err;
  }
};

export const fetchLastResultId = async (projectId) => {
  try {
    const response = await axios.get(`/project/${projectId}/lastResultId`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const checkForMissingData = async (projectId) => {
  try {
    const response = await axios.get(`/project/${projectId}/missingData`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchLastCalculationError = async (projectId) => {
  try {
    const response = await axios.get(
      `/project/${projectId}/lastCalculationError`
    );
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [POST]
export const updateDescriptionsParams = async (projectParent) => {
  try {
    const url = '/project/descriptions';
    const response = await axios.post(url, projectParent);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const createCalculation = async (projectParentId, calculationData) => {
  try {
    const url = `/project/${projectParentId}/calculation`;
    await axios.post(url, calculationData);
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [PUT]
export const updateParams = async (project, endpoint) => {
  try {
    const url = `/project/${project.AhsID}/${endpoint}`;
    await axios.put(url, project);
  } catch (err) {
    throw err;
  }
};

export const updateInputs = async (projectId, inputs) => {
  try {
    const url = `/project/${projectId}/inputs`;
    await axios.put(url, inputs);
  } catch (err) {
    throw err;
  }
};

export const restoreProject = async (projectId) => {
  try {
    await axios.put(`/project/${projectId}/restore`);
  } catch (err) {
    throw err;
  }
};

export const cancelCalculation = async (projectParentId) => {
  try {
    const url = `/project/${projectParentId}/cancelCalculation`;
    const response = await axios.put(url);
    return response.data.calculationId;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [DELETE]
export const deleteProject = async (projectId) => {
  try {
    if (!projectId) return;
    await axios.delete(`/project/${projectId}`);
  } catch (err) {
    throw err;
  }
};
//#endregion
