import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { LANGUAGE } from '../../../../server/constants/language.constant';
import { LANGUAGE_KEY } from '../../../../server/constants/storage.constant';
import useAuth from '../../hooks/useAuth';
import logo from '../../image/logo.svg';
import './Header.css';

const Header = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  const { companyId } = useParams();
  //#endregion

  //#region [auth]
  const { user, handleLogout } = useAuth();
  //#endregion

  //#region [methods]
  const handleLanguageClick = (lang) => {
    i18n.activate(lang);
    localStorage.setItem(LANGUAGE_KEY, lang);
  };
  //#endregion

  //#region [render]
  const loadFlagImg = (language) => require(`../../image/flag-${language}.png`);
  return user ? (
    <div className='header'>
      <div className='header-left-body'>
        <NavLink to='/' state={{ companyId }}>
          <img src={logo} alt='logo' className='header-logo' />
        </NavLink>
      </div>
      <div className='header-right-body'>
        {user.IS_SUPERADMIN && (
          <FontAwesomeIcon
            icon='user-group'
            className='header-fa-icon'
            onClick={() =>
              navigate(
                companyId
                  ? `/company/${companyId}/users`
                  : `/company/${user.UserCoID}/users`
              )
            }
          />
        )}
        <Dropdown drop='down-centered'>
          <Dropdown.Toggle className='header-dropdown-toggle'>
            <img
              src={loadFlagImg(i18n.locale)}
              alt='current-flag'
              className='header-img-icon lang-selected'
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className='header-dropdown-language'>
            {Object.values(LANGUAGE).map((lang) => (
              <Dropdown.Item
                onClick={() => handleLanguageClick(lang)}
                key={'language_' + lang}
              >
                <img
                  src={loadFlagImg(lang)}
                  alt={`flag-${lang}`}
                  className='header-img-icon'
                />
                <span>{i18n._(`language.${lang}`)}</span>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>
        <FontAwesomeIcon
          icon='power-off'
          className='header-fa-icon'
          onClick={handleLogout}
        />
      </div>
    </div>
  ) : null;
  //#endregion
};

export default Header;
