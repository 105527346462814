import { useLingui } from '@lingui/react';
import React, { useLayoutEffect } from 'react';
import { Button } from 'react-bootstrap';
import Bloc from '../../../components/Bloc/Bloc';
import CostPageContext from '../../../contexts/CostPageContext';
import ElecBloc from '../cost/blocs/ElecBloc';
import './CostPage.css';
import BtesBloc from './blocs/BtesBloc';
import ElecLotBloc from './blocs/ElecLotBloc';
import GasBloc from './blocs/GasBloc';
import GasBoilerBloc from './blocs/GasBoilerBloc';
import HpaBloc from './blocs/HpaBloc';
import HpgBloc from './blocs/HpgBloc';
import HydraulicLotBloc from './blocs/HydraulicLotBloc';
import InjectionBloc from './blocs/InjectionBloc';
import ItesBloc from './blocs/ItesBloc';
import OtherCapexBloc from './blocs/OtherCapexBloc';
import PhotovoltaicBloc from './blocs/PhotovoltaicBloc';
import ReferenceOtherOpex from './blocs/ReferenceOtherOpex';
import SolarThermalBloc from './blocs/SolarThermalBloc';
import SolutionOtherOpexBloc from './blocs/SolutionOtherOpexBloc';
import WaterTankColdBloc from './blocs/WaterTankColdBloc';
import WaterTankHotBloc from './blocs/WaterTankHotBloc';
import WaterTankHotwaterBloc from './blocs/WaterTankHotwaterBloc';
import useCost from './useCost';

const CostPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [logic]
  const {
    tempProject,
    nbErrors,
    addError,
    removeError,
    handleParamChange,
    handleOtherValueChange,
    handleTableValueChange,
    handleSubmitClick
  } = useCost();
  //#endregion

  //#region [effects]
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion

  //#region [render]
  return (
    <CostPageContext.Provider
      value={{
        tempProject,
        nbErrors,
        addError,
        removeError,
        handleParamChange,
        handleOtherValueChange,
        handleTableValueChange,
        handleSubmitClick
      }}
    >
      <div className='cost-page'>
        <Bloc title={i18n._('capex.hypotheses')}>
          <BtesBloc />
          <HpgBloc />
          <HpaBloc />
          <GasBoilerBloc />
          <WaterTankHotBloc />
          <WaterTankColdBloc />
          <WaterTankHotwaterBloc />
          <InjectionBloc />
          <SolarThermalBloc />
          <PhotovoltaicBloc />
          <ItesBloc />
          <HydraulicLotBloc />
          <ElecLotBloc />
          <OtherCapexBloc />
        </Bloc>
        <Bloc title={i18n._('opex.hypotheses')}>
          <ElecBloc />
          <GasBloc />
          <SolutionOtherOpexBloc />
          <ReferenceOtherOpex />
        </Bloc>
        <div className='cost-btns'>
          <Button
            variant='primary'
            onClick={handleSubmitClick}
            disabled={nbErrors > 0}
          >
            {i18n._('save')}
          </Button>
        </div>
      </div>
    </CostPageContext.Provider>
  );
  //#endregion
};

export default CostPage;
