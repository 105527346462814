import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { setupInterceptors } from '../utils/axios.utils';

const Navigate = () => {
  //#region [navigate]
  const navigate = useNavigate();
  //#endregion

  //#region [states]
  const [ran, setRan] = useState(false);
  //#endregion

  //#region [render]
  if (!ran) {
    setupInterceptors(navigate);
    setRan(true);
  }
  return <></>;
  //#endregion
};

export default Navigate;
