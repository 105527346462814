const { STD_COLS } = require('../constants/stdCol.constant');
const { parseIntoColumns } = require('./csv.utils');

function getSummaryData(stdFile, ext) {
  try {
    const summaryData = {};
    const columns = parseIntoColumns(stdFile, ext);
    const needs = Object.values(STD_COLS).filter((value) => value.isNeed);
    needs.forEach((need) => {
      const columnNeed = columns.find(
        (col) =>
          col[0]
            .trim()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '') === need.name
      );
      let sum = 0;
      let max = Number(columnNeed[1]);
      columnNeed.forEach((valueStr, rowIndex) => {
        if (rowIndex > 0) {
          const valueNb = Number(valueStr);
          sum += valueNb;
          max = valueNb > max ? valueNb : max;
        }
      });
      summaryData[need.name] = { SUM: sum, MAX: max };
    });
    return summaryData;
  } catch (error) {
    throw error;
  }
}

module.exports = { getSummaryData };
