const { USAGE } = require('../../constants/ahs.constant');
const { UNIT } = require('../../constants/unit.constant');

const getTemperatureHeatParam = (hasHeatNeeds) => ({
  key: 'InitTemperatureHeat',
  min: 25,
  max: 80,
  type: 'number',
  required: hasHeatNeeds,
  default: 45,
  unit: UNIT.CELSIUS_DEGREE
});

const getHeatTemperatureBackParam = (hasHeatNeeds, temperatureHeat = 45) => ({
  key: 'InitTemperatureHeatBack',
  min: temperatureHeat - 40,
  max: temperatureHeat - 3,
  type: 'number',
  required: hasHeatNeeds,
  default: 40,
  unit: UNIT.CELSIUS_DEGREE
});

const getTemperatureColdParam = (hasColdNeeds) => ({
  key: 'InitTemperatureCold',
  min: -5,
  max: 20,
  type: 'number',
  required: hasColdNeeds,
  default: 8,
  unit: UNIT.CELSIUS_DEGREE
});

const getColdTemperatureBackParam = (hasColdNeeds, temperatureCold = 8) => ({
  key: 'InitTemperatureColdBack',
  min: temperatureCold + 3,
  max: temperatureCold + 20,
  type: 'number',
  required: hasColdNeeds,
  default: 12,
  unit: UNIT.CELSIUS_DEGREE
});

const getTemperatureHotWaterParam = (hasHotWaterNeeds) => ({
  key: 'InitTemperatureHotWater',
  min: 40,
  max: 75,
  type: 'number',
  required: hasHotWaterNeeds,
  default: 60,
  unit: UNIT.CELSIUS_DEGREE
});

const getTemperatureHotWaterBackParam = (hasHotWaterNeeds) => ({
  key: 'InitTemperatureHotWaterBack',
  min: 4,
  max: 40,
  type: 'number',
  required: hasHotWaterNeeds,
  default: 12,
  unit: UNIT.CELSIUS_DEGREE
});

const getStationWaterTankHotTemperatureMinParam = (tempHeat = 45) => ({
  key: 'InitStationWaterTankHotTemperatureMin',
  type: 'number',
  required: false,
  min: 24,
  max: tempHeat,
  default: 30,
  unit: UNIT.CELSIUS_DEGREE
});

const NAME = Object.freeze({
  description: {
    key: 'description',
    type: 'text',
    pattern: /^.{3,}$/,
    example: 'Description',
    default: null,
    maxLength: 255
  }
});

const NEEDS = Object.freeze({
  InitStationHasHeatNeeds: {
    key: 'InitStationHasHeatNeeds',
    type: 'boolean',
    default: true
  },
  InitTemperatureHeatDelta: {
    key: 'InitTemperatureHeatDelta',
    default: 5
  },
  InitWaterLawIsUsed: {
    key: 'InitWaterLawIsUsed',
    type: 'boolean',
    default: false
  },
  InitStationTemperatureSetPoint: {
    key: 'InitStationTemperatureSetPoint',
    type: 'number',
    required: false,
    min: 10,
    max: 30,
    default: 19,
    unit: UNIT.CELSIUS_DEGREE
  },
  InitStationHasColdNeeds: {
    key: 'InitStationHasColdNeeds',
    type: 'boolean',
    default: true
  },
  InitStationEvapTemperatureDelta: {
    key: 'InitStationEvapTemperatureDelta',
    default: 4
  },
  InitStationHasHotWaterNeeds: {
    key: 'InitStationHasHotWaterNeeds',
    type: 'boolean',
    default: true
  }
});

const DETAILS = Object.freeze({
  InitBtesEnabled: {
    key: 'InitBtesEnabled',
    type: 'boolean',
    default: true
  },
  InitLoopIsUsed: {
    key: 'InitLoopIsUsed',
    type: 'boolean',
    default: false
  },
  InitLoopWaterType: {
    key: 'InitLoopWaterType',
    default: 'B',
    values: ['W', 'B']
  },
  InitHPAInjectionEnabled: {
    key: 'InitHPAInjectionEnabled',
    type: 'boolean',
    default: false
  },
  InitGasEnabled: {
    key: 'InitGasEnabled',
    type: 'boolean',
    default: false
  },
  InitReleveEnabled: {
    key: 'InitReleveEnabled',
    type: 'boolean',
    default: false
  },
  InitHPAHeatingEnabled: {
    key: 'InitHPAHeatingEnabled',
    type: 'boolean',
    default: true
  },
  InitHPACoolingEnabled: {
    key: 'InitHPAHeatingEnabled',
    type: 'boolean',
    default: true
  },
  ItesEnabled: {
    key: 'ItesEnabled',
    type: 'boolean',
    default: false
  }
});

const SST = Object.freeze({
  InitBuildingSurface: {
    key: 'InitStationBuildingSurface', // le préfixe "Station" sert à différencier cette clé avec la clé "InitBuildingSurface"
    min: 100,
    max: 1000000,
    type: 'number',
    required: true,
    default: null,
    unit: UNIT.SQUARE_METER
  },
  InitStationName: {
    key: 'InitStationName',
    type: 'text',
    required: true,
    default: null,
    maxLength: 255
  },
  InitStationUse: {
    key: 'InitStationUse',
    required: true,
    default: USAGE.TERTIARY,
    type: 'text',
    values: Object.values(USAGE),
    translateValuesIds: Object.values(USAGE).map(
      (usage) => `usage.${usage.toLowerCase()}`
    ) // TODO côté front
  },
  InitStationOrder: {
    key: 'InitStationOrder',
    default: 1
  },
  filesStdOriginalName: {
    key: 'filesStdOriginalName',
    default: null
  }
});

const WEATHER = Object.freeze({
  ahsWeatherFileOriginalName: {
    key: 'ahsWeatherFileOriginalName',
    required: true,
    default: null
  }
});

const ALL_DESCRIPTION_PARAMS = (
  hasHeatNeeds,
  hasColdNeeds,
  hasHotWaterNeeds
) => ({
  InitTemperatureHeat: { ...getTemperatureHeatParam(hasHeatNeeds) },
  InitTemperatureHeatBack: { ...getHeatTemperatureBackParam(hasHeatNeeds) },
  InitTemperatureCold: { ...getTemperatureColdParam(hasColdNeeds) },
  InitTemperatureColdBack: { ...getColdTemperatureBackParam(hasColdNeeds) },
  InitTemperatureHotWater: { ...getTemperatureHotWaterParam(hasHotWaterNeeds) },
  InitTemperatureHotWaterBack: {
    ...getTemperatureHotWaterBackParam(hasHotWaterNeeds)
  },
  InitStationWaterTankHotTemperatureMin: {
    ...getStationWaterTankHotTemperatureMinParam()
  },
  ...DETAILS,
  ...NAME,
  ...NEEDS,
  ...SST,
  ...WEATHER
});

module.exports = {
  DETAILS,
  NAME,
  NEEDS,
  SST,
  WEATHER,
  getTemperatureHeatParam,
  getHeatTemperatureBackParam,
  getTemperatureColdParam,
  getColdTemperatureBackParam,
  getTemperatureHotWaterParam,
  getTemperatureHotWaterBackParam,
  getStationWaterTankHotTemperatureMinParam,
  ALL_DESCRIPTION_PARAMS
};
