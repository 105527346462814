import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PV_EQUIPMENT } from '../../../../../../../../../../server/constants/performance.constant';
import { OPTI } from '../../../../../../../../../../server/models/design/constraint.model';
import OptimizationContext from '../../../../../../../../contexts/OptimizationContext';
import PopupContext from '../../../../../../../../contexts/PopupContext';
import ProjectContext from '../../../../../../../../contexts/ProjectContext';
import { default as KCPVModal } from '../../../../KilowattCretePVModal';
import MinMaxInputs from '../../../../MinMaxInputs';
import MinMaxSelects from '../../../../MinMaxSelects';
import { OptiSection } from '../../../../OptiSection/OptiSection';
import './OptiSizingSection.css';

const OptiSizingSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { project } = useContext(ProjectContext);
  const { calculationData } = useContext(OptimizationContext);
  const { openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [memos]
  const kilowattCretePVModal = useMemo(() => {
    return KCPVModal(i18n);
  }, []);

  const isQItesMaxDisabled = useMemo(() => {
    return calculationData.constraints.bounds[1].BtesLength !== 0;
  }, [calculationData.constraints.bounds[1].BtesLength]);

  const isKiloWattCretePVInputDisabled = useMemo(() => {
    const { SolarThermalSurface } = calculationData.constraints.bounds[1];
    const { InitPhotovoltaicModel } = project.otherValues;
    return (
      SolarThermalSurface > 0 &&
      InitPhotovoltaicModel === PV_EQUIPMENT.DUALSUN.value
    );
  }, [
    project.otherValues.InitPhotovoltaicModel,
    calculationData.constraints.bounds[1].SolarThermalSurface
  ]);
  //#endregion

  //#region [render]
  return (
    <OptiSection title={i18n._('compute.opti.sizingParams')}>
      <div className='opti-section opti-dim-section'>
        <Row>
          <Col xs={6}></Col>
          <Col className='opti-dim-section-header' xs={3}>
            {i18n._('compute.opti.min')}
          </Col>
          <Col className='opti-dim-section-header' xs={3}>
            {i18n._('compute.opti.max')}
          </Col>
        </Row>
        <MinMaxInputs
          label={i18n._('compute.btesLength')}
          param={OPTI.BtesLength}
        />
        <MinMaxSelects
          label={i18n._('compute.btesProbeDepth')}
          param={OPTI.BtesProbeDepth}
        />
        <MinMaxSelects
          label={i18n._('compute.btesProbeDistance')}
          param={OPTI.BtesProbeDistance}
        />
        <MinMaxInputs
          label={i18n._('compute.btesT2Final')}
          param={OPTI.T2final_Btes}
        />
        <MinMaxInputs
          label={i18n._('compute.hpgHeatBtesPowerMax', {
            tempHeat: project.substations[0].InitTemperatureHeat
          })}
          param={OPTI.HPGHeatBtesPowerMax}
        />
        <MinMaxInputs
          label={i18n._('compute.hpgColdBtesPowerMax', {
            tempCold: project.substations[0].InitTemperatureCold
          })}
          param={OPTI.HPGColdBtesPowerMax}
        />
        <MinMaxInputs
          label={i18n._('compute.hpaPowerMax')}
          param={OPTI.HPAPowerMax}
        />
        <MinMaxInputs
          label={i18n._('compute.solarThermalSurface')}
          param={OPTI.SolarThermalSurface}
        />
        <MinMaxInputs
          label={i18n._('compute.kiloWattCretePV')}
          param={OPTI.KiloWattCretePV}
          onInfoClick={() =>
            openInfoModal(kilowattCretePVModal.title, kilowattCretePVModal.body)
          }
          disabled={isKiloWattCretePVInputDisabled}
        />
        <MinMaxInputs
          label={i18n._('compute.qItesMax')}
          param={OPTI.QItesMax}
          onInfoClick={() =>
            openInfoModal(
              i18n._('compute.qItesMax'),
              i18n._('compute.qItesMax.help')
            )
          }
          disabled={isQItesMaxDisabled}
        />
      </div>
    </OptiSection>
  );
  //#endregion
};

export default OptiSizingSection;
