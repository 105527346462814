// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.std-table-first-row {
    cursor: pointer;
}

.std-table-first-row>.table-label>span>svg {
    color: var(--primary-color);
    background-color: white;
    margin-left: 5px;
    font-size: 18px;
}`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/components/ProjectSummary/sections/description/components/StdTable/StdTable.css"],"names":[],"mappings":"AAAA;IACI,eAAe;AACnB;;AAEA;IACI,2BAA2B;IAC3B,uBAAuB;IACvB,gBAAgB;IAChB,eAAe;AACnB","sourcesContent":[".std-table-first-row {\n    cursor: pointer;\n}\n\n.std-table-first-row>.table-label>span>svg {\n    color: var(--primary-color);\n    background-color: white;\n    margin-left: 5px;\n    font-size: 18px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
