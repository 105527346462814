import React from 'react';
import { Toast as RBToast } from 'react-bootstrap';
import { MODAL_COLORS } from '../../styles/colors/modal.color';
import './Toast.css';

const Toast = ({ isOpen, level, header, body, onClose }) => {
  //#region [render]
  return (
    <RBToast
      onClose={async () => {
        await onClose();
      }}
      className='toast'
      show={isOpen}
      delay={6500}
      autohide
    >
      <RBToast.Header closeButton={false}>
        <div
          className='toast-square'
          style={{ backgroundColor: MODAL_COLORS[level] ?? 'black' }}
        />
        {header}
      </RBToast.Header>
      <RBToast.Body>{body}</RBToast.Body>
    </RBToast>
  );
  //#endregion
};

export default Toast;
