import Sockette from 'sockette';
import { isProd } from '../../../server/utils/env.utils';

class Ws {
  constructor() {
    this.existingSockets = {};
  }

  send(room, data) {
    if (!(room in this.existingSockets)) {
      if (!isProd()) console.log('not connected to room', room);
      return;
    }
    const json = JSON.stringify(data);
    this.existingSockets[room].send(json);
  }

  connect(room, messageHandler) {
    if (this.existingSockets[room]) return;
    this.existingSockets[room] = new Sockette(
      (location.protocol === 'http:' ? 'ws://' : 'wss://') +
        `${global.location.host}/api-v1?room=${room}`,
      {
        timeout: 5e3,
        maxAttempts: 10,
        onmessage: async (msg) => {
          await messageHandler(JSON.parse(msg.data));
        },
        onerror: (code) => console.error('ws connect error:', code),
        onopen: () => {
          if (!isProd()) console.log('websocket connected to room:', room);
        },
        onclose: () => {
          if (!isProd()) console.log('websocket disconnected from room:', room);
        }
      }
    );
  }

  disconnect(room) {
    if (!this.existingSockets[room]) return;
    this.existingSockets[room].close();
    delete this.existingSockets[room];
  }
}

export default new Ws();
