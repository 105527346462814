import { useLingui } from '@lingui/react';
import React, { useContext, useRef, useState } from 'react';
import ResultContext from '../../contexts/ResultContext';
import { getTableValue } from '../../utils/compute.utils';
import SectionTitle from '../SectionTitle';
import './CompareResultsTable.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Overlay, Tooltip } from 'react-bootstrap';

const CompareResultsTable = ({ template, level }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { comparedResults } = useContext(ResultContext);
  //#endregion

  //#region [states]
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  //#endregion

  //#region [refs]
  const iconRef = useRef(null);
  //#endregion

  //#region [render]
  return template ? (
    <div>
      {template.title && <SectionTitle level={level} title={template.title} />}
      <table className='template-table results-table compare-results-table'>
        {comparedResults.length > 1 && (
          <thead>
            <tr>
              <th></th>
              {comparedResults.map((result, i) => (
                <th key={'th_result_' + i} title={result.ComputeName}>
                  <p className='result-table-title'>
                    {i18n._('result', { index: i + 1 })}
                  </p>
                  {result.Comment && (
                    <p className='result-table-details ellipsis'>
                      {result.Comment}
                    </p>
                  )}
                </th>
              ))}
            </tr>
          </thead>
        )}
        <tbody>
          {template.rows.map((row, rowIndex) => (
            <tr
              key={'tr_result_' + rowIndex}
              className={comparedResults.length > 1 ? '' : 'table-tr'}
            >
              <td className='table-label'>
                {row.label}
                {row.tooltipInfo && (
                  <>
                    <FontAwesomeIcon
                      icon={'circle-info'}
                      className='info-icon'
                      onMouseOver={() => setShowInfoTooltip(true)}
                      onMouseOut={() => setShowInfoTooltip(false)}
                      ref={iconRef}
                    />
                    <Overlay
                      target={iconRef.current}
                      show={showInfoTooltip}
                      placement='right'
                    >
                      {(props) => (
                        <Tooltip
                          id='form-input-info-tooltip'
                          {...props}
                          className='accenta-ref-table-tooltip'
                        >
                          {row.tooltipInfo}
                        </Tooltip>
                      )}
                    </Overlay>
                  </>
                )}
              </td>

              {row.values.map((value, colIndex) => {
                const tableValue = getTableValue(
                  i18n,
                  value,
                  row.unit,
                  row.translateValue,
                  row.precision
                );
                return (
                  <td
                    key={'td_result_' + rowIndex + '_' + colIndex}
                    title={tableValue}
                    className={`${
                      row.valuesClassNames ? row.valuesClassNames[colIndex] : ''
                    } ${
                      comparedResults.length > 1
                        ? 'compare-table-value'
                        : 'table-value'
                    }`}
                  >
                    {tableValue}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  ) : null;
  //#endregion
};

export default CompareResultsTable;
