import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useEffect, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import {
  useAsyncDebounce,
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable
} from 'react-table';
import './ProjectsList.css';

const COLS_WIDTHS = ['auto', 'auto', '150px', '20%', '120px'];
const DIVIDER = 118;

const ProjectsList = ({ projects, columns, hiddenColumns }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [react-table]
  const {
    headerGroups,
    page,
    state,
    setGlobalFilter,
    canPreviousPage,
    canNextPage,
    pageOptions,
    nextPage,
    previousPage,
    setPageSize,
    setHiddenColumns,
    getTableProps,
    getTableBodyProps,
    prepareRow
  } = useTable(
    {
      columns,
      data: projects,
      initialState: {
        sortBy: [
          {
            id: 'updated_at',
            desc: false
          }
        ]
      }
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  //#endregion

  //#region [states]
  const [searchValue, setSearchValue] = useState(state.globalFilter);
  const [nbItems, setNbItems] = useState(
    parseInt(window.innerHeight / DIVIDER)
  );
  //#endregion

  //#region [effects]
  useEffect(() => {
    const debounce = (fn, ms) => {
      let timer;
      return () => {
        clearTimeout(timer);
        timer = setTimeout(() => {
          timer = null;
          fn.apply(this, arguments);
        }, ms);
      };
    };

    const debounceHandleResize = debounce(function handleResize() {
      setNbItems(parseInt(window.innerHeight / DIVIDER));
      setHiddenColumns(window.innerWidth < 1007 ? hiddenColumns : []);
    }, 300);

    window.addEventListener('resize', debounceHandleResize);
    return () => window.removeEventListener('resize', debounceHandleResize);
  }, []);

  useEffect(() => {
    setPageSize(nbItems);
  }, [nbItems]);
  //#endregion

  //#region [render]
  return (
    <div>
      <div className='projects-input-body'>
        <FontAwesomeIcon icon='magnifying-glass' />
        <Form.Control
          type='text'
          placeholder={i18n._('projects.search')}
          value={searchValue || ''}
          onChange={(evt) => {
            setSearchValue(evt.target.value);
            onChange(evt.target.value);
          }}
          className='projects-input'
        />
      </div>
      <table className='projects-table' {...getTableProps()} border='1'>
        <thead className='projects-table-thead'>
          {headerGroups.map((headerGroup) => (
            <tr {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, i) => (
                <th
                  className='projects-table-th'
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  style={{ width: COLS_WIDTHS[i] }}
                >
                  {column.render('Header')}
                  <span>
                    {column.isSorted ? (column.isSortedDesc ? ' ▼' : ' ▲') : ''}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody className='projects-table-body' {...getTableBodyProps()}>
          {page.map((row) => {
            prepareRow(row);
            return (
              <tr className='projects-table-tr' {...row.getRowProps()}>
                {row.cells.map((cell, i) => {
                  return (
                    <td
                      className='projects-table-td'
                      style={{
                        width: COLS_WIDTHS[i]
                      }}
                      {...cell.getCellProps()}
                    >
                      {cell.render('Cell')}
                    </td>
                  );
                })}
              </tr>
            );
          })}
        </tbody>
      </table>
      <div className='pagination'>
        <span className='pagination-page-index'>
          {i18n._('pagination', {
            pageIndex: state.pageIndex + 1,
            nbPages: pageOptions.length
          })}
        </span>
        <div className='pagination-btns'>
          <Button
            variant='secondary'
            onClick={() => previousPage()}
            disabled={!canPreviousPage}
            className='pagination-btn'
          >
            <FontAwesomeIcon icon='chevron-left' />
          </Button>
          <Button
            variant='secondary'
            onClick={() => nextPage()}
            disabled={!canNextPage}
            className='pagination-btn'
          >
            <FontAwesomeIcon icon='chevron-right' />
          </Button>
        </div>
      </div>
    </div>
  );
  //#endregion
};

export default ProjectsList;
