const {
  INIT_OBJECTIVE,
  REFERENCE_SOLUTION
} = require('../../constants/constraint.constant');
const { UNIT } = require('../../constants/unit.constant');
const { isNull } = require('../../utils/data.utils');

const COMMON_CONSTRAINT = Object.freeze({
  InitSimulationCalcul: {
    key: 'InitSimulationCalcul',
    type: 'boolean',
    default: undefined
  },
  InitYearsSimulations: {
    key: 'InitYearsSimulations',
    path: 'inp',
    min: 1,
    max: 30,
    type: 'number',
    required: true,
    default: 20,
    unit: UNIT.YEAR
  }
});

const SIMU = Object.freeze({
  BtesLength: {
    key: 'BtesLength',
    path: 'params',
    min: 0,
    max: 100000,
    type: 'number',
    required: true,
    default: 0,
    unit: UNIT.METER
  },
  BtesProbeDepth: {
    key: 'BtesProbeDepth',
    path: 'params',
    values: [50, 75, 100, 150, 200, 250, 300, 400, 600, 800],
    type: 'number',
    required: true,
    default: 100,
    unit: UNIT.METER
  },
  BtesProbeDistance: {
    key: 'BtesProbeDistance',
    path: 'params',
    values: [3, 6, 8, 10, 12, 15, 20, 24, 30],
    type: 'number',
    required: true,
    default: 10,
    unit: UNIT.METER
  },
  T2final_Btes: {
    key: 'T2final_Btes',
    path: 'params',
    min: 3,
    max: 34,
    type: 'number',
    required: true,
    default: 10,
    unit: UNIT.CELSIUS_DEGREE
  },
  HPGHeatBtesPowerMax: {
    key: 'HPGHeatBtesPowerMax',
    path: 'params',
    min: 0,
    max: 1000000,
    type: 'number',
    required: true,
    default: 0,
    unit: UNIT.KILOWATT
  },
  HPGColdBtesPowerMax: {
    key: 'HPGColdBtesPowerMax',
    path: 'params',
    min: 0,
    max: 1000000,
    type: 'number',
    required: true,
    default: 0,
    unit: UNIT.KILOWATT
  },
  HPAPowerMax: {
    key: 'HPAPowerMax',
    path: 'params',
    min: 0,
    max: 1000000,
    type: 'number',
    required: true,
    default: 0,
    unit: UNIT.KILOWATT
  },
  InitGasPowerMax: {
    key: 'InitGasPowerMax',
    path: 'inp',
    min: 0,
    max: 1000000,
    type: 'number',
    default: 0,
    unit: UNIT.KILOWATT
  },
  SolarThermalSurface: {
    key: 'SolarThermalSurface',
    path: 'params',
    min: 0,
    max: 1000000,
    type: 'number',
    default: 0,
    unit: UNIT.SQUARE_METER
  },
  KiloWattCretePV: {
    key: 'KiloWattCretePV',
    path: 'params',
    min: 0,
    max: 1000000,
    type: 'number',
    default: 0,
    unit: UNIT.KILOWATT_PEAK
  },
  QItesMax: {
    key: 'QItesMax',
    path: 'params',
    min: 0,
    max: 1000000,
    type: 'number',
    default: 0,
    unit: UNIT.KILOWATT_HOUR
  },
  HPGPowerMaxPerSST: {
    key: 'HPGPowerMaxPerSST',
    path: 'params',
    min: 0,
    max: 1000000,
    type: 'number',
    default: 0,
    unit: UNIT.KILOWATT
  }
});

const getBalanceDuration = (max) => ({
  key: 'InitBalanceDuration',
  path: 'inp',
  min: 1,
  max: isNull(max) ? 20 : max,
  type: 'number',
  required: true,
  default: 20,
  unit: UNIT.YEAR
});

const OPTI = Object.freeze({
  InitObjective: {
    key: 'InitObjective',
    path: 'inp',
    type: 'text',
    values: Object.values(INIT_OBJECTIVE),
    translateValuesIds: ['opti.capexAndOpex', 'opti.tri'],
    required: true,
    default: INIT_OBJECTIVE.CAPEX_OPEX
  },
  InitReferenceSolution: {
    key: 'InitReferenceSolution',
    path: 'inp',
    type: 'text',
    values: Object.values(REFERENCE_SOLUTION),
    translateValuesIds: ['opti.gas', 'opti.hpa'],
    required: true,
    default: REFERENCE_SOLUTION.GAS_CHILLER
  },
  BtesLength: {
    key: 'BtesLength',
    path: 'bounds',
    min: 0,
    max: 100000,
    type: 'number',
    required: true,
    default: [0, 10000]
  },
  BtesProbeDepth: {
    key: 'BtesProbeDepth',
    path: 'bounds',
    values: [50, 75, 100, 150, 200, 250, 300, 400, 600, 800],
    required: true,
    default: [100, 200]
  },
  BtesProbeDistance: {
    key: 'BtesProbeDistance',
    path: 'bounds',
    values: [3, 6, 8, 10, 12, 15, 20, 24, 30],
    required: true,
    default: [8, 12]
  },
  T2final_Btes: {
    key: 'T2final_Btes',
    path: 'bounds',
    min: 3,
    max: 34,
    type: 'number',
    required: true,
    default: [8, 15]
  },
  HPGHeatBtesPowerMax: {
    key: 'HPGHeatBtesPowerMax',
    path: 'bounds',
    min: 0,
    max: 1000000,
    type: 'number',
    required: true,
    default: [0, 700]
  },
  HPGColdBtesPowerMax: {
    key: 'HPGColdBtesPowerMax',
    path: 'bounds',
    min: 0,
    max: 1000000,
    type: 'number',
    required: true,
    default: [0, 700]
  },
  HPAPowerMax: {
    key: 'HPAPowerMax',
    path: 'bounds',
    min: 0,
    max: 1000000,
    type: 'number',
    required: true,
    default: [0, 0]
  },
  SolarThermalSurface: {
    key: 'SolarThermalSurface',
    path: 'bounds',
    min: 0,
    max: 1000000,
    type: 'number',
    required: true,
    default: [0, 0]
  },
  KiloWattCretePV: {
    key: 'KiloWattCretePV',
    path: 'bounds',
    min: 0,
    max: 1000000,
    type: 'number',
    default: [0, 0]
  },
  QItesMax: {
    key: 'QItesMax',
    path: 'bounds',
    min: 0,
    max: 1000000,
    type: 'number',
    default: [0, 0]
  },
  FuncEvaluations: {
    key: 'FuncEvaluations',
    path: 'inp',
    min: 0,
    max: Infinity,
    type: 'number',
    required: true,
    default: 2500
  },
  ConfigurableIterations: {
    key: 'configurableIterations',
    min: 1,
    max: Infinity,
    type: 'number',
    required: true,
    default: 3000
  }
});

const GOAL = {
  InitConstraintNone: {
    key: 'InitConstraintNone',
    path: null,
    InitConstraintEnabled: false
  },
  InitConstraintEnabled: {
    key: 'InitConstraintEnabled',
    default: false
  },
  InitConstraintReference: {
    key: 'InitConstraintReference',
    path: 'inp',
    min: 0,
    max: Infinity,
    type: 'number',
    default: 0
  },
  InitConstraintEnergyPrimary: {
    key: 'InitConstraintEnergyPrimary',
    path: 'inp',
    InitConstraintEnabled: true,
    ref: true,
    unit: UNIT.PRIMARY_ENERGY_KILOWATT_HOUR,
    min: -10,
    max: 100,
    type: 'number',
    default: 0
  },
  InitConstraintEnergyFinale: {
    key: 'InitConstraintEnergyFinale',
    path: 'inp',
    InitConstraintEnabled: true,
    ref: true,
    unit: UNIT.KILOWATT_HOUR_PER_YEAR,
    min: -10,
    max: 100,
    type: 'number',
    default: 0
  },
  InitConstraintCO2kg: {
    key: 'InitConstraintCO2kg',
    path: 'inp',
    InitConstraintEnabled: true,
    ref: true,
    unit: UNIT.KILOGRAM_CO2EQ_PER_YEAR,
    min: -10,
    max: 100,
    type: 'number',
    default: 0
  },
  InitConstraintENR: {
    key: 'InitConstraintENR',
    path: 'inp',
    InitConstraintEnabled: true,
    ref: false,
    unit: null,
    min: -10,
    max: 100,
    type: 'number',
    default: 0
  },
  InitConstraintHpg: {
    key: 'InitConstraintHpg',
    path: 'inp',
    InitConstraintEnabled: true,
    ref: false,
    unit: null,
    min: 0,
    max: 100,
    type: 'number',
    default: 0
  },
  InitConstraintGasPart: {
    key: 'InitConstraintGasPart',
    path: 'inp',
    InitConstraintEnabled: true,
    ref: false,
    unit: null,
    min: -10,
    max: 100,
    type: 'number',
    default: 0
  },
  InitConstraintNbOptim: {
    key: 'InitConstraintNbOptim',
    path: 'inp',
    min: 1,
    max: 5,
    default: 1
  }
};

const OPTIONS_GOAL = Object.freeze(
  Object.values(GOAL).filter((param) => !isNull(param.InitConstraintEnabled))
);

module.exports = {
  COMMON_CONSTRAINT,
  SIMU,
  OPTI,
  GOAL,
  OPTIONS_GOAL,
  getBalanceDuration
};
