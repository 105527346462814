import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import ProjectContext from '../../../../../contexts/ProjectContext';
import { getDescriptionById } from '../../../../../utils/project.utils';

const MissingDataErrorCard = ({ missingData }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { project } = useContext(ProjectContext);
  //#endregion

  //#region [render]
  return (
    <div className='error-card'>
      <div className='error-card-title'>
        <FontAwesomeIcon icon='list-check' />
        <span>{i18n._('missingData.error')}</span>
      </div>
      {missingData.general && (
        <div className='missing-data-row'>
          <FontAwesomeIcon icon='xmark' />
          <span className='missing-data-page'>{i18n._('page.general')}</span>
          {missingData.general.map((key) => (
            <span className='missing-data-param' key={'missing_general_' + key}>
              <FontAwesomeIcon icon='circle-dot' />
              {i18n._(`general.${key}`)}
            </span>
          ))}
        </div>
      )}
      {missingData.descriptions && (
        <div>
          <div className='missing-data-row'>
            <FontAwesomeIcon icon='xmark' />
            <span className='missing-data-page'>
              {i18n._('page.description')}
            </span>
          </div>
          {Object.keys(missingData.descriptions).map((projectId, index) => {
            const description = getDescriptionById(i18n, project, projectId);
            return (
              <div
                className='missing-data-row'
                key={'missing_description_' + index}
              >
                <span className='missing-data-description'>{description}</span>
                {missingData.descriptions[projectId].map((key) => (
                  <span
                    className='missing-data-param'
                    key={'missing_description_' + index + '_' + key}
                  >
                    <FontAwesomeIcon icon='circle-dot' />
                    {i18n._(`description.${key}`)}
                  </span>
                ))}
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
  //#endregion
};

export default React.memo(MissingDataErrorCard);
