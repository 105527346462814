const COMPUTE_STATUS = Object.freeze({
  ERROR: 0,
  PENDING: 1,
  IN_PROGRESS: 2,
  CANCELED: 3,
  SUCCESS: 100
});

const MAX_COST = 999999999999999;

module.exports = {
  COMPUTE_STATUS,
  MAX_COST
};
