import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ELEC_CAPEX } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import CostPageContext from '../../../../contexts/CostPageContext';
import { getDefaultValue } from '../../../../utils/project.utils';

const ElecLotBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleParamChange } =
    useContext(CostPageContext);
  //#endregion

  //#region [render]
  const { InitCapexLotElecA, InitCapexLotElecB } = tempProject;
  return (
    <Bloc title={i18n._('cost.elecLot')}>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitCapexLotElecA')}
            value={InitCapexLotElecA}
            param={ELEC_CAPEX.InitCapexLotElecA}
            onBlur={(value) => handleParamChange('InitCapexLotElecA', value)}
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, ELEC_CAPEX.InitCapexLotElecA)}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitCapexLotElecB')}
            value={InitCapexLotElecB}
            param={ELEC_CAPEX.InitCapexLotElecB}
            onBlur={(value) => handleParamChange('InitCapexLotElecB', value)}
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, ELEC_CAPEX.InitCapexLotElecB)}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default ElecLotBloc;
