const { GENERAL, LOCATION } = require('./design/general.model');
const {
  DETAILS,
  NAME,
  NEEDS,
  SST,
  WEATHER,
  getTemperatureHeatParam,
  getTemperatureColdParam,
  getTemperatureHotWaterParam,
  getTemperatureHotWaterBackParam,
  getStationWaterTankHotTemperatureMinParam
} = require('./design/description.model');
const {
  EXCHANGER,
  GAS: PERF_GAS,
  HP,
  ITES: PERF_ITES,
  HEADLOSS,
  GEOCOOLING,
  getPVParams,
  getSTParams
} = require('./design/performance.model');
const {
  BTES: GEO_BTES,
  SOIL,
  getBTESTestProbeLengthParam
} = require('./design/geology.model');
const {
  BTES,
  HPG,
  HPA,
  GAS,
  WATERTANK_HOT,
  WATERTANK_COLD,
  WATERTANK_HOTWATER,
  INJECTION,
  SOLAR,
  PV,
  ITES,
  HYDRAULIC,
  ELEC_CAPEX,
  OTHER_CAPEX,
  ELEC_OPEX,
  GAS_OPEX,
  OTHER_OPEX_ACCENTA,
  OTHER_OPEX_REF,
  getInitBtesCostAParam,
  getInitBtesCostBParam
} = require('./design/cost.model');
const { STD_COLS } = require('../constants/stdCol.constant');

const PERF_PV = getPVParams();
const PERF_ST = getSTParams();
const EMPTY_PROJECT = {
  AhsID: null,
  AhsCoID: null,
  deleted_at: null,
  created_at: null,
  updated_at: null,
  ParentAhsID: null,
  children: [],
  CalculationID: null,
  GeneralDesignDate: null,
  LastComputeDate: null,
  LastComputeUserID: null,
  AhsAddress1: LOCATION.AhsAddress1.default,
  AhsCity: LOCATION.AhsCity.default,
  AhsCountry: LOCATION.AhsCountry.default,
  AhsDescription: GENERAL.AhsDescription.default,
  ahsImageFileName: GENERAL.ahsImageFileName.default,
  AhsMainUsage: GENERAL.AhsMainUsage.default,
  AhsName: GENERAL.AhsName.default,
  AhsType: GENERAL.AhsType.default,
  ahsWeatherFileOriginalName: WEATHER.ahsWeatherFileOriginalName.default,
  AhsZipCode: LOCATION.AhsZipCode.default,
  description: NAME.description.default,
  InitAltitude: LOCATION.InitAltitude.default,
  InitBtesTestProbeLength: getBTESTestProbeLengthParam().default,
  InitBtesTemperatureMin: GEO_BTES.InitBtesTemperatureMin.default,
  InitBtesTemperatureMax: GEO_BTES.InitBtesTemperatureMax.default,
  InitBuildingSurface: GENERAL.InitBuildingSurface.default,
  InitBtesCostA: getInitBtesCostAParam().default,
  InitBtesCostB: getInitBtesCostBParam().default,
  InitGasPrice: GAS_OPEX.InitGasPrice.default,
  InitGasPriceSubscription: GAS_OPEX.InitGasPriceSubscription.default,
  InitGasPriceEvol: GAS_OPEX.InitGasPriceEvol.default,
  InitItesCostA: ITES.InitItesCostA.default,
  InitItesCostB: ITES.InitItesCostB.default,
  InitCapexLotElecA: ELEC_CAPEX.InitCapexLotElecA.default,
  InitCapexLotElecB: ELEC_CAPEX.InitCapexLotElecB.default,
  InitLatitude: LOCATION.InitLatitude.default,
  InitLongitude: LOCATION.InitLongitude.default,
  InitLoopExchangerPinch: EXCHANGER.InitLoopExchangerPinch.default,
  InitLoopExchangerU: EXCHANGER.InitLoopExchangerU.default,
  InitLoopExchangerAirPinch: EXCHANGER.InitLoopExchangerAirPinch.default,
  InitLoopExchangerAirU: EXCHANGER.InitLoopExchangerAirU.default,
  InitPVModuleCostA: PV.InitPVModuleCostA.default,
  InitPVModuleCostB: PV.InitPVModuleCostB.default,
  InitPVModuleSupportCostA: PV.InitPVModuleSupportCostA.default,
  InitPVModuleSupportCostB: PV.InitPVModuleSupportCostB.default,
  InitPVInverterCostA: PV.InitPVInverterCostA.default,
  InitPVInverterCostB: PV.InitPVInverterCostB.default,
  InitSoilThermalConductivity: SOIL.InitSoilThermalConductivity.default,
  InitSoilVolumeHeatCapacity: SOIL.InitSoilVolumeHeatCapacity.default,
  InitSoilTemperature10m: SOIL.InitSoilTemperature10m.default,
  InitStationExchangerPinch: EXCHANGER.InitStationExchangerPinch.default,
  InitStationColdExchangerPinch:
    EXCHANGER.InitStationColdExchangerPinch.default,
  InitStationHotwaterExchangerPinch:
    EXCHANGER.InitStationHotwaterExchangerPinch.default,
  otherValues: {
    InitBtesEnabled: DETAILS.InitBtesEnabled.default,
    InitHPACoolingEnabled: DETAILS.InitHPACoolingEnabled.default,
    InitHPAHeatingEnabled: DETAILS.InitHPAHeatingEnabled.default,
    InitHPAInjectionEnabled: DETAILS.InitHPAInjectionEnabled.default,
    InitLoopIsUsed: DETAILS.InitLoopIsUsed.default,
    InitLoopWaterType: DETAILS.InitLoopWaterType.default,
    ItesEnabled: DETAILS.ItesEnabled.default,

    InitWaterLawIsUsed: NEEDS.InitWaterLawIsUsed.default,
    InitStationWaterTankHotTemperatureMin:
      getStationWaterTankHotTemperatureMinParam().default,
    InitStationTemperatureSetPoint:
      NEEDS.InitStationTemperatureSetPoint.default,
    InitGasEnabled: DETAILS.InitGasEnabled.default,
    InitReleveEnabled: DETAILS.InitReleveEnabled.default,
    InitGasEfficiency: PERF_GAS.InitGasEfficiency.default,
    InitSolarThermalBu: PERF_ST.InitSolarThermalBu.default,
    InitSolarThermalB1: PERF_ST.InitSolarThermalB1.default,
    InitSolarThermalB2: PERF_ST.InitSolarThermalB2.default,
    InitSolarThermalEpsa: PERF_ST.InitSolarThermalEpsa.default,
    InitSolarThermalFlowRate: PERF_ST.InitSolarThermalFlowRate.default,
    InitSolarThermalHeta0: PERF_ST.InitSolarThermalHeta0.default,
    InitSolarThermalModel: PERF_ST.InitSolarThermalModel.default,
    InitSolarThermalS: 3,
    InitSolarThermalHeadlossMceMax:
      PERF_ST.InitSolarThermalHeadlossMceMax.default,
    InitPumpEfficiency: HEADLOSS.InitPumpEfficiency.default,
    InitExchangerHeadlossMceMax: HEADLOSS.InitExchangerHeadlossMceMax.default,
    InitBtesHeadlossLimit: HEADLOSS.InitBtesHeadlossLimit.default,
    InitLoopConnectionLength: HEADLOSS.InitLoopConnectionLength.default,
    InitItesModel: PERF_ITES.InitItesModel.default,
    InitItesPumpConsumptionPercentage:
      PERF_ITES.InitItesPumpConsumptionPercentage.default,
    InitItesChargingTemperatureIn:
      PERF_ITES.InitItesChargingTemperatureIn.default,
    InitItesChargingTemperatureOut:
      PERF_ITES.InitItesChargingTemperatureOut.default,
    InitItesDischargingTemperatureIn:
      PERF_ITES.InitItesDischargingTemperatureIn.default,
    InitItesDischargingTemperatureOut:
      PERF_ITES.InitItesDischargingTemperatureOut.default,
    InitSolarDeltaTemp: PERF_ST.InitSolarDeltaTemp.default,
    InitSolarTemperatureMin: PERF_ST.InitSolarTemperatureMin.default,
    InitSolarSurfaceTilt: PERF_ST.InitSolarSurfaceTilt.default,
    InitSolarSurfaceAzimuth: PERF_ST.InitSolarSurfaceAzimuth.default,
    InitPhotovoltaicModel: PERF_PV.InitPhotovoltaicModel.default,
    InitPVEfficiency: PERF_PV.InitPVEfficiency.default,
    InitPVTemperatureCoefGammaModule:
      PERF_PV.InitPVTemperatureCoefGammaModule.default,
    InitPVInverterMaxPowerPerWc: PERF_PV.InitPVInverterMaxPowerPerWc.default,
    InitPVDCLossesAgeModule: PERF_PV.InitPVDCLossesAgeModule.default,
    InitPVInverterNominalEfficiency:
      PERF_PV.InitPVInverterNominalEfficiency.default,
    InitPVMountingType: PERF_PV.InitPVMountingType.default,
    HPGFileID: HP.HPGFileID.default,
    HPGFilename: HP.HPGFilename.default,
    HPAFileID: HP.HPAFileID.default,
    HPAFilename: HP.HPAFilename.default,
    InitBtesProbeResistance: GEO_BTES.InitBtesProbeResistance.default,
    InitCapexOther: OTHER_CAPEX.InitCapexOther.default,
    InitOpexGaPerf: OTHER_OPEX_ACCENTA.InitOpexGaPerf.default,
    InitOpexGaPerfEvol: OTHER_OPEX_ACCENTA.InitOpexGaPerfEvol.default,
    InitOpexMaint: OTHER_OPEX_ACCENTA.InitOpexMaint.default,
    InitOpexMaintEvol: OTHER_OPEX_ACCENTA.InitOpexMaintEvol.default,
    InitOpexOther: OTHER_OPEX_ACCENTA.InitOpexOther.default,
    InitOpexOtherEvol: OTHER_OPEX_ACCENTA.InitOpexOtherEvol.default,
    InitRefOpexGaPerf: OTHER_OPEX_REF.InitRefOpexGaPerf.default,
    InitRefOpexGaPerfEvol: OTHER_OPEX_REF.InitRefOpexGaPerfEvol.default,
    InitRefOpexMaint: OTHER_OPEX_REF.InitRefOpexMaint.default,
    InitRefOpexMaintEvol: OTHER_OPEX_REF.InitRefOpexMaintEvol.default,
    InitRefOpexOther: OTHER_OPEX_REF.InitRefOpexOther.default,
    InitRefOpexOtherEvol: OTHER_OPEX_REF.InitRefOpexOtherEvol.default,
    InitElecPriceEvolMethod: ELEC_OPEX.InitElecPriceEvolMethod.default,
    InitElecPriceEvol: ELEC_OPEX.InitElecPriceEvol.default,
    InitPVElecToNetworkPriceEvol:
      ELEC_OPEX.InitPVElecToNetworkPriceEvol.default,
    InitPVElecToProjectPriceEvol:
      ELEC_OPEX.InitPVElecToProjectPriceEvol.default,
    InitGasPriceEvolMethod: GAS_OPEX.InitGasPriceEvolMethod.default,
    InitBtesTemperatureDelta: GEO_BTES.InitBtesTemperatureDelta.default,
    YearsT2Final: GEO_BTES.YearsT2Final.default,
    InitBtesMaxWidth: GEO_BTES.InitBtesMaxWidth.default,
    InitBtesProbeInternalDiameter:
      GEO_BTES.InitBtesProbeInternalDiameter.default,
    InitMOEBtesMargin: OTHER_CAPEX.InitMOEBtesMargin.default,
    InitMOEMargin: OTHER_CAPEX.InitMOEMargin.default,
    InitIntegrationBtesMargin: OTHER_CAPEX.InitIntegrationBtesMargin.default,
    InitIntegrationMargin: OTHER_CAPEX.InitIntegrationMargin.default,
    InitYearsBeforeStart: OTHER_OPEX_ACCENTA.InitYearsBeforeStart.default,
    InitSolarInjectionRatio: PERF_ST.InitSolarInjectionRatio.default,
    InitInjectionTemperatureDelta: HP.InitInjectionTemperatureDelta.default,
    InitInjectionTemperatureMin: HP.InitInjectionTemperatureMin.default,
    HPAPenaltyTemperatureMin: HP.HPAPenaltyTemperatureMin.default,
    HPAPenaltyTemperatureMax: HP.HPAPenaltyTemperatureMax.default,
    HPAPenaltyPercentageMax: HP.HPAPenaltyPercentageMax.default,
    InitGeocoolingEnabled: GEOCOOLING.InitGeocoolingEnabled.default,
    InitGeocoolingDisableClim: GEOCOOLING.InitGeocoolingDisableClim.default,
    InitGeocoolingTempOutBtesMax:
      GEOCOOLING.InitGeocoolingTempOutBtesMax.default,
    InitGeocoolingPumpConsumptionPercent:
      GEOCOOLING.InitGeocoolingPumpConsumptionPercent.default,
    InitBtesDiscountDict: BTES.InitBtesDiscountDict.default,
    InitHPGCost: HPG.InitHPGCost.default,
    InitHPACostHeat: HPA.InitHPACostHeat.default,
    InitHPACostHeatCold: HPA.InitHPACostHeatCold.default,
    InitCapexGas: GAS.InitCapexGas.default,
    InitWaterTankHotCostA: WATERTANK_HOT.InitWaterTankHotCostA.default,
    InitWaterTankHotCostB: WATERTANK_HOT.InitWaterTankHotCostB.default,
    InitWaterTankColdCostA: WATERTANK_COLD.InitWaterTankColdCostA.default,
    InitWaterTankColdCostB: WATERTANK_COLD.InitWaterTankColdCostB.default,
    InitWaterTankColdExchangerCostA:
      WATERTANK_COLD.InitWaterTankColdExchangerCostA.default,
    InitWaterTankColdExchangerCostB:
      WATERTANK_COLD.InitWaterTankColdExchangerCostB.default,
    InitWaterTankColdPumpCostA:
      WATERTANK_COLD.InitWaterTankColdPumpCostA.default,
    InitWaterTankColdPumpCostB:
      WATERTANK_COLD.InitWaterTankColdPumpCostB.default,
    InitWaterTankHotwaterCostA:
      WATERTANK_HOTWATER.InitWaterTankHotwaterCostA.default,
    InitWaterTankHotwaterCostB:
      WATERTANK_HOTWATER.InitWaterTankHotwaterCostB.default,
    InitWaterTankHotwaterExchangerCostA:
      WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostA.default,
    InitWaterTankHotwaterExchangerCostB:
      WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostB.default,
    InitWaterTankHotwaterPumpCostA:
      WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostA.default,
    InitWaterTankHotwaterPumpCostB:
      WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostB.default,
    InitInjectionExchangerCostA: INJECTION.InitInjectionExchangerCostA.default,
    InitInjectionExchangerCostB: INJECTION.InitInjectionExchangerCostB.default,
    InitInjectionPumpCostA: INJECTION.InitInjectionPumpCostA.default,
    InitInjectionPumpCostB: INJECTION.InitInjectionPumpCostB.default,
    InitSolarThermalPanelCostA: SOLAR.InitSolarThermalPanelCostA.default,
    InitSolarThermalVanneCost: SOLAR.InitSolarThermalVanneCost.default,
    InitSolarThermalPumpCostA: SOLAR.InitSolarThermalPumpCostA.default,
    InitSolarThermalPumpCostB: SOLAR.InitSolarThermalPumpCostB.default,
    InitCapexHydraulicMargin: HYDRAULIC.InitCapexHydraulicMargin.default
  },
  substations: [
    {
      InitStationID: null,
      AhsID: null,
      created_at: null,
      updated_at: null,
      deleted_at: null,
      ParentInitStationID: null,
      InitStationOrder: SST.InitStationOrder.default,
      filesStdOriginalName: SST.filesStdOriginalName.default,
      InitStationHasHeatNeeds: NEEDS.InitStationHasHeatNeeds.default,
      InitTemperatureHeat: getTemperatureHeatParam(
        NEEDS.InitStationHasHeatNeeds.default
      ).default,
      InitTemperatureHeatDelta: NEEDS.InitTemperatureHeatDelta.default,
      InitStationHasColdNeeds: NEEDS.InitStationHasColdNeeds.default,
      InitTemperatureCold: getTemperatureColdParam(
        NEEDS.InitStationHasColdNeeds
      ).default,
      InitStationEvapTemperatureDelta:
        NEEDS.InitStationEvapTemperatureDelta.default,
      InitStationCondTemperatureDelta:
        HP.InitStationCondTemperatureDelta.default,
      InitStationHasHotWaterNeeds: NEEDS.InitStationHasHotWaterNeeds.default,
      InitTemperatureHotWater: getTemperatureHotWaterParam(
        NEEDS.InitStationHasHotWaterNeeds.default
      ).default,
      InitTemperatureHotWaterBack: getTemperatureHotWaterBackParam(
        NEEDS.InitStationHasHotWaterNeeds.default
      ).default,
      InitBuildingSurface: SST.InitBuildingSurface.default,
      InitStationName: SST.InitStationName.default,
      InitStationUse: SST.InitStationUse.default,
      ItesEnabled: DETAILS.ItesEnabled.default,
      SummaryData: {
        [STD_COLS.HEAT_NEED.name]: { SUM: 0, MAX: 0 },
        [STD_COLS.COLD_NEED.name]: { SUM: 0, MAX: 0 },
        [STD_COLS.HOTWATER_NEED.name]: { SUM: 0, MAX: 0 }
      }
    }
  ]
};

module.exports = { EMPTY_PROJECT };
