import React, { useMemo } from 'react';
import './Table.css';

const Table = ({ titles, cols, className }) => {
  //#region [memos]
  const values = useMemo(() => {
    const newValues = [];
    for (let i = 0; i < cols[0].length; ++i) {
      newValues[i] = [];
      cols.forEach((colValues) => {
        newValues[i].push(colValues[i]);
      });
    }
    return newValues;
  }, [cols]);
  //#endregion

  //#region [render]
  return (
    <table className={`${className} custom-table`}>
      <thead>
        <tr>
          {titles.map((title) => (
            <th key={'table_th_' + title}>{title}</th>
          ))}
        </tr>
      </thead>
      <tbody>
        {values.map((rowValues, rowIndex) => (
          <tr key={'table_tr_' + rowValues[0][0] + '_' + rowIndex}>
            {rowValues.map((value, colIndex) => (
              <td key={'table_td_' + value + '_' + colIndex}>{value}</td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  );
  //#endregion
};

export default React.memo(Table);
