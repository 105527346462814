import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Button, Modal as RBModal } from 'react-bootstrap';
import { MODAL_COLORS } from '../../styles/colors/modal.color';
import './Modal.css';

const Modal = ({ isOpen, level, header, body, action, onClose }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [methods]
  const handleActionClick = async () => {
    try {
      await action();
      onClose();
    } catch (err) {
      throw err;
    }
  };
  //#endregion

  //#region [render]
  return (
    <RBModal show={isOpen} onHide={onClose} size='lg' className='custom-modal'>
      <RBModal.Header
        closeButton
        style={{ color: MODAL_COLORS[level] ?? 'black' }}
      >
        <span>{header}</span>
        {action && <FontAwesomeIcon icon='exclamation-triangle' />}
      </RBModal.Header>
      <RBModal.Body>{body}</RBModal.Body>
      {action && (
        <RBModal.Footer>
          <Button variant='secondary' onClick={onClose}>
            {i18n._('cancel')}
          </Button>
          <Button variant='primary' onClick={handleActionClick}>
            {i18n._('confirm')}
          </Button>
        </RBModal.Footer>
      )}
    </RBModal>
  );
  //#endregion
};

export default React.memo(Modal);
