import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import { Col, Row } from 'react-bootstrap';
import { ELEC_OPEX } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import FormSelect from '../../../../components/Form/FormSelect';
import CostPageContext from '../../../../contexts/CostPageContext';
import PopupContext from '../../../../contexts/PopupContext';
import {
  getElecEvols,
  getNextCompassYear
} from '../../../../utils/compass.utils';
import { getDefaultValue } from '../../../../utils/project.utils';

const ElecBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleOtherValueChange } =
    useContext(CostPageContext);
  const { openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [memos]
  const evols = useMemo(() => {
    return getElecEvols(i18n).join(' / ');
  }, []);
  //#endregion

  //#region [render]
  const {
    InitElecPriceEvolMethod,
    InitElecPriceEvol,
    InitPVElecToNetworkPriceEvol,
    InitPVElecToProjectPriceEvol
  } = tempProject.otherValues;

  return (
    <Bloc title={i18n._('cost.elec')}>
      <Row>
        <Col>
          <FormSelect
            label={i18n._('cost.InitElecPriceEvolMethod')}
            value={InitElecPriceEvolMethod}
            param={ELEC_OPEX.InitElecPriceEvolMethod}
            onChange={(value) =>
              handleOtherValueChange('InitElecPriceEvolMethod', value)
            }
            onInfoClick={() =>
              openInfoModal(
                i18n._('elecPriceEvolMethod.title'),
                i18n._('elecPriceEvolMethod.body', { evols })
              )
            }
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitElecPriceEvol')}
            value={InitElecPriceEvol}
            param={ELEC_OPEX.InitElecPriceEvol}
            onBlur={(value) =>
              handleOtherValueChange('InitElecPriceEvol', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={i18n._(
              `cost.InitElecPriceEvol.bottom.${InitElecPriceEvolMethod}`,
              {
                year: getNextCompassYear(),
                default: ELEC_OPEX.InitElecPriceEvol.default
              }
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitPVElecToNetworkPriceEvol')}
            value={InitPVElecToNetworkPriceEvol}
            param={ELEC_OPEX.InitPVElecToNetworkPriceEvol}
            onBlur={(value) =>
              handleOtherValueChange('InitPVElecToNetworkPriceEvol', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              ELEC_OPEX.InitPVElecToNetworkPriceEvol
            )}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitPVElecToProjectPriceEvol')}
            value={InitPVElecToProjectPriceEvol}
            param={ELEC_OPEX.InitPVElecToProjectPriceEvol}
            onBlur={(value) =>
              handleOtherValueChange('InitPVElecToProjectPriceEvol', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              ELEC_OPEX.InitPVElecToProjectPriceEvol
            )}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default ElecBloc;
