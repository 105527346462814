import { parseIntoColumns } from '../../../server/utils/csv.utils';
import { isArrNullOrEmpty, isNull } from './data.utils';

export const parseFileName = (fileNameAndType) => {
  const fileNameSplit = fileNameAndType.split('.');
  const fileExt = fileNameSplit.pop().toLowerCase();
  const fileNameWoExt = fileNameSplit.join('.');
  return { fileNameWoExt, fileExt };
};

export const downloadFile = (fileName, objectURL) => {
  const downloadLink = document.createElement('a');
  downloadLink.download = fileName;
  const url = URL.createObjectURL(objectURL);
  downloadLink.href = url;
  downloadLink.click();
  URL.revokeObjectURL(url);
};

export const checkForFileError = (i18n, file, ext, refCols) => {
  try {
    if (ext !== 'csv') return i18n._('file.error.ext', { ext });
    const columns = parseIntoColumns(file, ext);
    const currentColNames = columns.map((col) =>
      col[0]
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
    );
    const refColNames = Object.values(refCols).map((col) => col.name);

    // missing columns
    const mandatoryCols = Object.values(refCols)
      .filter((obj) => obj.mandatory)
      .map((col) => col.name);
    const missingColumns = mandatoryCols.filter(
      (mandatoryCol) => !currentColNames.includes(mandatoryCol)
    );
    if (!isArrNullOrEmpty(missingColumns)) {
      return i18n._('file.error.missingColumns', {
        columns: missingColumns.join(', ')
      });
    }

    // unknown columns
    const unknownColumns = currentColNames.filter(
      (colName) => !refColNames.includes(colName)
    );
    if (!isArrNullOrEmpty(unknownColumns)) {
      return i18n._('file.error.unknownColumns', {
        columns: unknownColumns.join(', ')
      });
    }

    // other error
    const nbRows = columns[0].length;
    let error;
    for (let col = 0; col < columns.length; col++) {
      const columnName = columns[col][0]
        .trim()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '');
      const currentRefCol = Object.values(refCols).find(
        (refCol) => refCol.name === columnName
      );

      for (let row = 1; row < nbRows; row++) {
        const value = columns[col][row];
        if (isNull(value) || value.trim() === '') {
          // la valeur est nulle
          error = i18n._('file.error.null', {
            name: columnName,
            row
          });
          break;
        }

        if (currentRefCol.type === 'number') {
          if (isFinite(value)) {
            // la valeur est un nombre...
            if (!isNull(currentRefCol.min) && value < currentRefCol.min) {
              // ... inférieur au min accepté
              error = i18n._('file.error.min', {
                name: columnName,
                row,
                value,
                min: currentRefCol.min
              });
              break;
            } else if (
              !isNull(currentRefCol.max) &&
              value > currentRefCol.max
            ) {
              // ... supérieur au max accepté
              error = i18n._('file.error.max', {
                name: columnName,
                row,
                value,
                max: currentRefCol.max
              });
              break;
            }
          } else if (
            !currentRefCol.accepted ||
            (currentRefCol.accepted && !currentRefCol.accepted.includes(value))
          ) {
            // la valeur n'est pas un caractère valide
            error = i18n._('file.error.invalid', {
              name: columnName,
              row,
              value
            });
            break;
          }
        }
      }
    }
    return error;
  } catch (err) {
    return i18n._('file.error.unknown', { error: err.message });
  }
};
