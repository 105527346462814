import {
  ACCESS_KEY,
  COMPANY_KEY
} from '../../../server/constants/storage.constant';
import axios from '../conf/axios.conf';

//#region [GET]
export const fetchUserIdByResetPwdToken = async (token) => {
  try {
    const url = `/auth/resetPwd/${token}/user`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [POST]
export const login = async (email, pwd) => {
  try {
    const body = { email, pwd };
    const response = await axios.post('/auth/login', body);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const logout = async () => {
  try {
    const response = await axios.post(
      '/auth/logout',
      {},
      { withCredentials: true }
    );
    localStorage.removeItem(ACCESS_KEY);
    sessionStorage.removeItem(ACCESS_KEY);
    localStorage.removeItem(COMPANY_KEY);
    sessionStorage.removeItem(COMPANY_KEY);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [PUT]
export const sendMailToResetPwd = async (email, language) => {
  try {
    const body = { email, language };
    const response = await axios.put('/auth/resetPwd/token', body);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const resetUserPwd = async (userId, pwd, token) => {
  try {
    const body = { userId, pwd, token };
    const response = await axios.put('/auth/resetPwd/pwd', body);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion
