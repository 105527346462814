const {
  ELEC_PRICE_EVOL_METHOD,
  GAS_PRICE_EVOL_METHOD,
  BTES_COST
} = require('../../constants/cost.constant');
const { UNIT } = require('../../constants/unit.constant');
const { DEPARTMENT } = require('../../constants/department.constant');

const getInitBtesCostAParam = (country, department) => {
  if (!BTES_COST[country] || !BTES_COST[country][department]) {
    country = 'FR';
    department = '01';
  }
  return {
    key: 'InitBtesCostA',
    type: 'number',
    min: 5,
    max: 200,
    default: BTES_COST[country][department].InitBtesCostA,
    department: DEPARTMENT[country][department].dep,
    unit: UNIT.EURO_PER_METER
  };
};

const getInitBtesCostBParam = (country, department) => {
  if (!BTES_COST[country] || !BTES_COST[country][department]) {
    country = 'FR';
    department = '01';
  }

  return {
    key: 'InitBtesCostB',
    type: 'number',
    min: 0,
    max: 20000,
    default: BTES_COST[country][department].InitBtesCostB,
    department: DEPARTMENT[country][department].dep,
    unit: UNIT.EURO
  };
};

const BTES = Object.freeze({
  InitBtesDiscountDict: {
    key: 'InitBtesDiscountDict',
    type: 'table',
    min: 0,
    max: 100,
    default: { 2000: 0, 5000: 6, 10000: 9, 20000: 10 },
    unit: UNIT.PERCENT
  }
});

const HPG = Object.freeze({
  InitHPGCost: {
    key: 'InitHPGCost',
    type: 'table',
    min: 0,
    default: { 0: 9100, 50: 364, 150: 227.5, 550: 127.4 },
    unit: UNIT.EURO
  }
});

const HPA = Object.freeze({
  InitHPACostHeat: {
    key: 'InitHPACostHeat',
    type: 'table',
    min: 0,
    default: { 0: 9425, 50: 377, 150: 236, 750: 132 },
    unit: UNIT.EURO
  },
  InitHPACostHeatCold: {
    key: 'InitHPACostHeatCold',
    type: 'table',
    min: 0,
    default: { 0: 11375, 50: 455, 150: 284.375, 750: 159.25 },
    unit: UNIT.EURO
  }
});

const GAS = Object.freeze({
  InitCapexGas: {
    key: 'InitCapexGas',
    type: 'table',
    min: 0,
    default: { 0: 5000, 50: 800, 200: 300, 750: 50 },
    unit: UNIT.EURO
  }
});

const WATERTANK_HOT = Object.freeze({
  InitWaterTankHotCostA: {
    key: 'InitWaterTankHotCostA',
    type: 'number',
    min: 0,
    default: 700,
    unit: UNIT.EURO_PER_CUBIC_METER
  },
  InitWaterTankHotCostB: {
    key: 'InitWaterTankHotCostB',
    type: 'number',
    min: 0,
    default: 2000,
    unit: UNIT.EURO
  }
});

const WATERTANK_COLD = Object.freeze({
  InitWaterTankColdCostA: {
    key: 'InitWaterTankColdCostA',
    type: 'number',
    min: 0,
    default: 1500,
    unit: UNIT.EURO_PER_CUBIC_METER
  },
  InitWaterTankColdCostB: {
    key: 'InitWaterTankColdCostB',
    type: 'number',
    min: 0,
    default: 3000,
    unit: UNIT.EURO
  },
  InitWaterTankColdExchangerCostA: {
    key: 'InitWaterTankColdExchangerCostA',
    type: 'number',
    min: 0,
    default: 10,
    unit: UNIT.EURO_PER_KILOWATT
  },
  InitWaterTankColdExchangerCostB: {
    key: 'InitWaterTankColdExchangerCostB',
    type: 'number',
    min: 0,
    default: 1500,
    unit: UNIT.EURO
  },
  InitWaterTankColdPumpCostA: {
    key: 'InitWaterTankColdPumpCostA',
    type: 'number',
    min: 0,
    default: 100,
    unit: UNIT.EURO_PER_CUBIC_METER_PER_HOUR
  },
  InitWaterTankColdPumpCostB: {
    key: 'InitWaterTankColdPumpCostB',
    type: 'number',
    min: 0,
    default: 1500,
    unit: UNIT.EURO
  }
});

const WATERTANK_HOTWATER = Object.freeze({
  InitWaterTankHotwaterCostA: {
    key: 'InitWaterTankHotwaterCostA',
    type: 'number',
    min: 0,
    default: 1500,
    unit: UNIT.EURO_PER_CUBIC_METER
  },
  InitWaterTankHotwaterCostB: {
    key: 'InitWaterTankHotwaterCostB',
    type: 'number',
    min: 0,
    default: 3000,
    unit: UNIT.EURO
  },
  InitWaterTankHotwaterExchangerCostA: {
    key: 'InitWaterTankHotwaterExchangerCostA',
    type: 'number',
    min: 0,
    default: 10,
    unit: UNIT.EURO_PER_KILOWATT
  },
  InitWaterTankHotwaterExchangerCostB: {
    key: 'InitWaterTankHotwaterExchangerCostB',
    type: 'number',
    min: 0,
    default: 1500,
    unit: UNIT.EURO
  },
  InitWaterTankHotwaterPumpCostA: {
    key: 'InitWaterTankHotwaterPumpCostA',
    type: 'number',
    min: 0,
    default: 100,
    unit: UNIT.EURO_PER_CUBIC_METER_PER_HOUR
  },
  InitWaterTankHotwaterPumpCostB: {
    key: 'InitWaterTankHotwaterPumpCostB',
    type: 'number',
    min: 0,
    default: 1500,
    unit: UNIT.EURO
  }
});

const INJECTION = Object.freeze({
  InitInjectionExchangerCostA: {
    key: 'InitInjectionExchangerCostA',
    type: 'number',
    min: 0,
    default: 10,
    unit: UNIT.EURO_PER_KILOWATT
  },
  InitInjectionExchangerCostB: {
    key: 'InitInjectionExchangerCostB',
    type: 'number',
    min: 0,
    default: 1000,
    unit: UNIT.EURO
  },
  InitInjectionPumpCostA: {
    key: 'InitInjectionPumpCostA',
    type: 'number',
    min: 0,
    default: 20,
    unit: UNIT.EURO_PER_CUBIC_METER_PER_HOUR
  },
  InitInjectionPumpCostB: {
    key: 'InitInjectionPumpCostB',
    type: 'number',
    min: 0,
    default: 1500,
    unit: UNIT.EURO
  }
});

const SOLAR = Object.freeze({
  InitSolarThermalPanelCostA: {
    key: 'InitSolarThermalPanelCostA',
    type: 'number',
    min: 0,
    default: 350,
    unit: UNIT.EURO_PER_SQUARE_METER
  },
  InitSolarThermalVanneCost: {
    key: 'InitSolarThermalVanneCost',
    type: 'number',
    min: 0,
    default: 3000,
    unit: UNIT.EURO
  },
  InitSolarThermalPumpCostA: {
    key: 'InitSolarThermalPumpCostA',
    type: 'number',
    min: 0,
    default: 100,
    unit: UNIT.EURO_PER_CUBIC_METER_PER_HOUR
  },
  InitSolarThermalPumpCostB: {
    key: 'InitSolarThermalPumpCostB',
    type: 'number',
    min: 0,
    default: 1000,
    unit: UNIT.EURO
  }
});

const PV = Object.freeze({
  InitPVModuleCostA: {
    key: 'InitPVModuleCostA',
    type: 'number',
    min: 0,
    default: 56.1,
    unit: UNIT.EURO_PER_SQUARE_METER
  },
  InitPVModuleCostB: {
    key: 'InitPVModuleCostB',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO
  },
  InitPVModuleSupportCostA: {
    key: 'InitPVModuleSupportCostA',
    type: 'number',
    min: 0,
    default: 150.7,
    unit: UNIT.EURO_PER_SQUARE_METER
  },
  InitPVModuleSupportCostB: {
    key: 'InitPVModuleSupportCostB',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO
  },
  InitPVInverterCostA: {
    key: 'InitPVInverterCostA',
    type: 'number',
    min: 0,
    default: 82.5,
    unit: UNIT.EURO_PER_KILOWATT
  },
  InitPVInverterCostB: {
    key: 'InitPVInverterCostB',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO
  }
});

const ITES = Object.freeze({
  InitItesCostA: {
    key: 'InitItesCostA',
    type: 'number',
    min: 0,
    default: 30,
    unit: UNIT.EURO_PER_KILOWATT_THERMAL
  },
  InitItesCostB: {
    key: 'InitItesCostB',
    type: 'number',
    min: 0,
    default: 7500,
    unit: UNIT.EURO
  }
});

const HYDRAULIC = Object.freeze({
  InitCapexHydraulicMargin: {
    key: 'InitCapexHydraulicMargin',
    type: 'number',
    min: 0,
    max: 100,
    default: 0.2,
    unit: UNIT.PERCENT
  }
});

const ELEC_CAPEX = Object.freeze({
  InitCapexLotElecA: {
    key: 'InitCapexLotElecA',
    type: 'number',
    min: 0,
    default: 60,
    unit: UNIT.EURO_PER_KILOVOLT_AMPERE
  },
  InitCapexLotElecB: {
    key: 'InitCapexLotElecB',
    type: 'number',
    min: 0,
    default: 9000,
    unit: UNIT.EURO
  }
});

const ELEC_OPEX = Object.freeze({
  InitElecPriceEvolMethod: {
    key: 'InitElecPriceEvolMethod',
    type: 'text',
    default: ELEC_PRICE_EVOL_METHOD.MANUAL,
    values: Object.values(ELEC_PRICE_EVOL_METHOD),
    translateValuesIds: Object.values(ELEC_PRICE_EVOL_METHOD).map(
      (type) => `elecEvolMethod.${type}`
    ) // TODO côté front
  },
  InitElecPriceEvol: {
    key: 'InitElecPriceEvol',
    type: 'number',
    min: -1000,
    max: 1000,
    default: 3,
    unit: UNIT.PERCENT_PER_YEAR
  },
  InitPVElecToNetworkPriceEvol: {
    key: 'InitPVElecToNetworkPriceEvol',
    type: 'number',
    min: 0,
    max: 100,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR
  },
  InitPVElecToProjectPriceEvol: {
    key: 'InitPVElecToProjectPriceEvol',
    type: 'number',
    min: 0,
    max: 100,
    default: 3,
    unit: UNIT.PERCENT_PER_YEAR
  }
});

const GAS_OPEX = Object.freeze({
  InitGasPriceSubscription: {
    key: 'InitGasPriceSubscription',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO
  },
  InitGasPrice: {
    key: 'InitGasPrice',
    type: 'number',
    min: 0,
    max: 0.3,
    default: 0.06,
    unit: UNIT.EURO_PER_KILOWATT_HOUR
  },
  InitGasPriceEvolMethod: {
    key: 'InitGasPriceEvolMethod',
    type: 'text',
    default: GAS_PRICE_EVOL_METHOD.MANUAL,
    values: Object.values(GAS_PRICE_EVOL_METHOD),
    translateValuesIds: Object.values(GAS_PRICE_EVOL_METHOD).map(
      (type) => `gasEvolMethod.${type}`
    ) // TODO côté front
  },
  InitGasPriceEvol: {
    key: 'InitGasPriceEvol',
    type: 'number',
    min: -1000,
    max: 1000,
    default: 3,
    unit: UNIT.PERCENT_PER_YEAR
  }
});

const OTHER_CAPEX = Object.freeze({
  InitMOEBtesMargin: {
    key: 'InitMOEBtesMargin',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR
  },
  InitMOEMargin: {
    key: 'InitMOEMargin',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR
  },
  InitIntegrationBtesMargin: {
    key: 'InitIntegrationBtesMargin',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR
  },
  InitIntegrationMargin: {
    key: 'InitIntegrationMargin',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR
  },
  InitCapexOther: {
    key: 'InitCapexOther',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO
  }
});

const OTHER_OPEX_ACCENTA = Object.freeze({
  InitOpexGaPerf: {
    key: 'InitOpexGaPerf',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO_PER_YEAR
  },
  InitOpexGaPerfEvol: {
    key: 'InitOpexGaPerfEvol',
    type: 'number',
    min: 0,
    max: 100,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR
  },
  InitOpexMaint: {
    key: 'InitOpexMaint',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO_PER_YEAR
  },
  InitOpexMaintEvol: {
    key: 'InitOpexMaintEvol',
    type: 'number',
    min: 0,
    max: 100,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR
  },
  InitOpexOther: {
    key: 'InitOpexOther',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO_PER_YEAR
  },
  InitOpexOtherEvol: {
    key: 'InitOpexOtherEvol',
    type: 'number',
    min: 0,
    max: 100,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR
  },
  InitYearsBeforeStart: {
    key: 'InitYearsBeforeStart',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.YEAR
  }
});

const OTHER_OPEX_REF = Object.freeze({
  InitRefOpexGaPerf: {
    key: 'InitRefOpexGaPerf',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO_PER_YEAR
  },
  InitRefOpexGaPerfEvol: {
    key: 'InitRefOpexGaPerfEvol',
    type: 'number',
    min: 0,
    max: 100,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR
  },
  InitRefOpexMaint: {
    key: 'InitRefOpexMaint',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO_PER_YEAR
  },
  InitRefOpexMaintEvol: {
    key: 'InitRefOpexMaintEvol',
    type: 'number',
    min: 0,
    max: 100,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR
  },
  InitRefOpexOther: {
    key: 'InitRefOpexOther',
    type: 'number',
    min: 0,
    default: 0,
    unit: UNIT.EURO_PER_YEAR
  },
  InitRefOpexOtherEvol: {
    key: 'InitRefOpexOtherEvol',
    type: 'number',
    min: 0,
    max: 100,
    default: 0,
    unit: UNIT.PERCENT_PER_YEAR
  }
});

module.exports = {
  BTES,
  HPG,
  HPA,
  GAS,
  WATERTANK_HOT,
  WATERTANK_COLD,
  WATERTANK_HOTWATER,
  INJECTION,
  SOLAR,
  PV,
  ITES,
  HYDRAULIC,
  ELEC_CAPEX,
  OTHER_CAPEX,
  ELEC_OPEX,
  GAS_OPEX,
  OTHER_OPEX_ACCENTA,
  OTHER_OPEX_REF,
  getInitBtesCostAParam,
  getInitBtesCostBParam
};
