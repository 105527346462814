import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { SOLAR } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import CostPageContext from '../../../../contexts/CostPageContext';
import { getDefaultValue } from '../../../../utils/project.utils';

const SolarThermalBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleOtherValueChange } =
    useContext(CostPageContext);
  //#endregion

  //#region [render]
  const {
    InitSolarThermalPanelCostA,
    InitSolarThermalVanneCost,
    InitSolarThermalPumpCostA,
    InitSolarThermalPumpCostB
  } = tempProject.otherValues;
  return (
    <Bloc title={i18n._('cost.solarThermal')}>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitSolarThermalPanelCostA')}
            value={InitSolarThermalPanelCostA}
            param={SOLAR.InitSolarThermalPanelCostA}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarThermalPanelCostA', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, SOLAR.InitSolarThermalPanelCostA)}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitSolarThermalVanneCost')}
            value={InitSolarThermalVanneCost}
            param={SOLAR.InitSolarThermalVanneCost}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarThermalVanneCost', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, SOLAR.InitSolarThermalVanneCost)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitSolarThermalPumpCostA')}
            value={InitSolarThermalPumpCostA}
            param={SOLAR.InitSolarThermalPumpCostA}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarThermalPumpCostA', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, SOLAR.InitSolarThermalPumpCostA)}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitSolarThermalPumpCostB')}
            value={InitSolarThermalPumpCostB}
            param={SOLAR.InitSolarThermalPumpCostB}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarThermalPumpCostB', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, SOLAR.InitSolarThermalPumpCostB)}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default SolarThermalBloc;
