import { STD_COLS } from '../../../../../../../server/constants/stdCol.constant';
import { UNIT } from '../../../../../../../server/constants/unit.constant';
import {
  getSelectedGoalParam,
  getSummaryRefPath
} from '../../../../../utils/compute.utils';
import { getSafeData } from '../../../../../utils/data.utils';

export const stdTemplate = (i18n, results, substation) => {
  const getDataPerSst = (result) =>
    result.summaryDataPerSst.find(
      (data) => data.InitStationName === substation
    );

  const getSTDFile = (result) => getDataPerSst(result)?.filesStdOriginalName;

  const getSummaryDataValues = (need, param) => {
    return results.map((result) => {
      const data = getDataPerSst(result);
      return getSafeData(() => data?.SummaryData[need][param]);
    });
  };

  return {
    title: i18n._('results.energyAndCarbon.sst.needsSummary'),
    fixedRow: {
      label: i18n._('results.energyAndCarbon.sst.std'),
      values: results.map((result) => getSafeData(() => getSTDFile(result)))
    },
    expandableRows: [
      {
        label: i18n._('std.heat.sum'),
        values: getSummaryDataValues(STD_COLS.HEAT_NEED.name, 'SUM'),
        unit: UNIT.KILOWATT_HOUR_PER_YEAR
      },
      {
        label: i18n._('std.heat.max'),
        values: getSummaryDataValues(STD_COLS.HEAT_NEED.name, 'MAX'),
        unit: STD_COLS.HEAT_NEED.unit
      },
      {
        label: i18n._('std.cold.sum'),
        values: getSummaryDataValues(STD_COLS.COLD_NEED.name, 'SUM'),
        unit: UNIT.KILOWATT_HOUR_PER_YEAR
      },
      {
        label: i18n._('std.cold.max'),
        values: getSummaryDataValues(STD_COLS.COLD_NEED.name, 'MAX'),
        unit: STD_COLS.HEAT_NEED.unit
      },
      {
        label: i18n._('std.hotwater.sum'),
        values: getSummaryDataValues(STD_COLS.HOTWATER_NEED.name, 'SUM'),
        unit: UNIT.KILOWATT_HOUR_PER_YEAR
      },
      {
        label: i18n._('std.hotwater.max'),
        values: getSummaryDataValues(STD_COLS.HOTWATER_NEED.name, 'MAX'),
        unit: STD_COLS.HEAT_NEED.unit
      }
    ]
  };
};

export const sstHeatTemplate = (i18n, results, sst) => {
  return {
    title: i18n._('results.energyAndCarbon.sst.heat'),
    rows: [
      {
        label: i18n._('results.energyAndCarbon.sst.heat.EnergyNeeds'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.PerSub.Heat[sst])
        ),
        unit: UNIT.KILOWATT_HOUR_THERMAL
      },
      {
        label: i18n._('results.energyAndCarbon.sst.heat.SurfaceNeeds'),
        values: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.PerSub.HeatSurface[sst]
          )
        ),
        unit: UNIT.KILOWATT_HOUR_THERMAL
      },
      {
        label: i18n._('results.energyAndCarbon.sst.heat.Cop'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.PerSub.HeatCOP[sst])
        ),
        precision: 2
      },
      {
        label: i18n._(
          'results.energyAndCarbon.sst.heat.ElectricityConsumption'
        ),
        values: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.PerSub.HeatElectricity[sst]
          )
        ),
        unit: UNIT.KILOWATT_HOUR_ELECTRIC
      },
      {
        label: i18n._('results.energyAndCarbon.sst.heat.GasConsumption'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.PerSub.HeatGas[sst])
        ),
        unit: UNIT.KILOWATT_HOUR
      }
    ]
  };
};

export const sstColdTemplate = (i18n, results, sst) => {
  return {
    title: i18n._('results.energyAndCarbon.sst.cold'),
    rows: [
      {
        label: i18n._('results.energyAndCarbon.sst.cold.EnergyNeeds'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.PerSub.Cold[sst])
        ),
        unit: UNIT.KILOWATT_HOUR_THERMAL
      },
      {
        label: i18n._('results.energyAndCarbon.sst.cold.SurfaceNeeds'),
        values: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.PerSub.ColdSurface[sst]
          )
        ),
        unit: UNIT.KILOWATT_HOUR_THERMAL
      },
      {
        label: i18n._('results.energyAndCarbon.sst.cold.Eer'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.PerSub.ColdEER[sst])
        ),
        precision: 2
      },
      {
        label: i18n._(
          'results.energyAndCarbon.sst.cold.ElectricityConsumption'
        ),
        values: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.PerSub.ColdElectricity[sst]
          )
        ),
        unit: UNIT.KILOWATT_HOUR_ELECTRIC
      }
    ]
  };
};

export const sstHotwaterTemplate = (i18n, results, sst) => {
  return {
    title: i18n._('results.energyAndCarbon.sst.hotWater'),
    rows: [
      {
        label: i18n._('results.energyAndCarbon.sst.hotWater.EnergyNeeds'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.PerSub.Hotwater[sst])
        ),
        unit: UNIT.KILOWATT_HOUR_THERMAL
      },
      {
        label: i18n._('results.energyAndCarbon.sst.hotWater.SurfaceNeeds'),
        values: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.PerSub.HotwaterSurface[sst]
          )
        ),
        unit: UNIT.KILOWATT_HOUR_THERMAL
      },
      {
        label: i18n._('results.energyAndCarbon.sst.hotWater.Cop'),
        values: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.PerSub.HotwaterCOP[sst]
          )
        ),
        precision: 2
      },
      {
        label: i18n._(
          'results.energyAndCarbon.sst.hotWater.ElectricityConsumption'
        ),
        values: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.PerSub.HotwaterElectricity[sst]
          )
        ),
        unit: UNIT.KILOWATT_HOUR_ELECTRIC
      },
      {
        label: i18n._('results.energyAndCarbon.sst.hotWater.GasConsumption'),
        values: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.PerSub.HotwaterGas[sst]
          )
        ),
        unit: UNIT.KILOWATT_HOUR
      }
    ]
  };
};

export const temperedLoopTemplate = (i18n, results) => ({
  title: i18n._('results.energyAndCarbon.temperedLoop'),
  rows: [
    {
      label: i18n._(
        'results.energyAndCarbon.temperedLoop.tlElectricityConsumption'
      ),
      values: results.map((result) =>
        getSafeData(() => result.ComputeResult.summary.WHeadLossLoopAverage)
      ),
      unit: UNIT.KILOWATT_HOUR_ELECTRIC
    }
  ]
});

export const totalHeatTemplate = (i18n, results) => {
  return {
    title: i18n._('results.energyAndCarbon.total.heat'),
    rows: [
      {
        label: i18n._('results.energyAndCarbon.total.heat.EnergyNeeds'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.TotalHeat)
        ),
        unit: UNIT.KILOWATT_HOUR_THERMAL
      },
      {
        label: i18n._('results.energyAndCarbon.total.heat.SurfaceNeeds'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.TotalHeatSurface)
        ),
        unit: UNIT.KILOWATT_HOUR_THERMAL
      },
      {
        label: i18n._('results.energyAndCarbon.total.heat.Cop'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.TotalHeatCOP)
        ),
        precision: 2
      },
      {
        label: i18n._(
          'results.energyAndCarbon.total.heat.ElectricityConsumption'
        ),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.TotalHeatElectricity)
        ),
        unit: UNIT.KILOWATT_HOUR_ELECTRIC
      },
      {
        label: i18n._('results.energyAndCarbon.total.heat.GasConsumption'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.TotalHeatGas)
        ),
        unit: UNIT.KILOWATT_HOUR
      }
    ]
  };
};

export const totalColdTemplate = (i18n, results) => {
  return {
    title: i18n._('results.energyAndCarbon.total.cold'),
    rows: [
      {
        label: i18n._('results.energyAndCarbon.total.cold.EnergyNeeds'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.TotalCold)
        ),
        unit: UNIT.KILOWATT_HOUR_THERMAL
      },
      {
        label: i18n._('results.energyAndCarbon.total.cold.SurfaceNeeds'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.TotalColdSurface)
        ),
        unit: UNIT.KILOWATT_HOUR_THERMAL
      },
      {
        label: i18n._('results.energyAndCarbon.total.cold.Eer'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.TotalColdEER)
        ),
        precision: 2
      },
      {
        label: i18n._(
          'results.energyAndCarbon.total.cold.ElectricityConsumption'
        ),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.TotalColdElectricity)
        ),
        unit: UNIT.KILOWATT_HOUR_ELECTRIC
      }
    ]
  };
};

export const totalHotwaterTemplate = (i18n, results) => {
  return {
    title: i18n._('results.energyAndCarbon.total.hotWater'),
    rows: [
      {
        label: i18n._('results.energyAndCarbon.total.hotWater.EnergyNeeds'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.TotalHotwater)
        ),
        unit: UNIT.KILOWATT_HOUR_THERMAL
      },
      {
        label: i18n._('results.energyAndCarbon.total.hotWater.SurfaceNeeds'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.TotalHotwaterSurface)
        ),
        unit: UNIT.KILOWATT_HOUR_THERMAL
      },
      {
        label: i18n._('results.energyAndCarbon.total.hotWater.Cop'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.TotalHotwaterCOP)
        ),
        precision: 2
      },
      {
        label: i18n._(
          'results.energyAndCarbon.total.hotWater.ElectricityConsumption'
        ),
        values: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.TotalHotwaterElectricity
          )
        ),
        unit: UNIT.KILOWATT_HOUR_ELECTRIC
      },
      {
        label: i18n._('results.energyAndCarbon.total.hotWater.GasConsumption'),
        values: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.TotalHotwaterGas)
        ),
        unit: UNIT.KILOWATT_HOUR
      }
    ]
  };
};

export const totalEnergyNeedsTemplates = (i18n, results) => {
  return [
    {
      title: i18n._('results.energyAndCarbon.total.solarThermal'),
      rows: [
        {
          label: i18n._(
            'results.energyAndCarbon.total.solarThermal.productionPerPanel'
          ),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary
                  .QSolarThermalProdPerSquareMeterAverage
            )
          ),
          unit: UNIT.KILOWATT_HOUR_PER_SQUARE_METER
        },
        {
          label: i18n._(
            'results.energyAndCarbon.total.solarThermal.energyInjectiedIntoBTESPerPanel'
          ),
          values: results.map((result) =>
            getSafeData(
              () =>
                result.ComputeResult.summary
                  .QSolarThermalInjPerSquareMeterAverage
            )
          ),
          unit: UNIT.KILOWATT_HOUR_PER_SQUARE_METER
        }
      ]
    },
    {
      title: i18n._('results.energyAndCarbon.total.energySource'),
      rows: [
        {
          label: i18n._(
            'results.energyAndCarbon.total.energySource.electricityConsumptionAverage'
          ),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.ElectricityConsoAverage
            )
          ),
          unit: UNIT.KILOWATT_HOUR_ELECTRIC
        },
        {
          label: i18n._(
            'results.energyAndCarbon.total.energySource.circulationPumpsConsumptionAverage'
          ),
          values: results.map((result) =>
            getSafeData(
              () => result.ComputeResult.summary.CirculationPumpsConsoAverage
            )
          ),
          unit: UNIT.KILOWATT_HOUR_ELECTRIC
        }
      ]
    }
  ];
};

export const totalByGazTemplate = (i18n, results) => ({
  rows: [
    {
      label: i18n._(
        'results.energyAndCarbon.total.energySource.gasConsumptionAverage'
      ),
      values: results.map((result) =>
        getSafeData(() => result.ComputeResult.summary.GasConsoAverage)
      ),
      unit: UNIT.KILOWATT_HOUR
    }
  ]
});

export const totalPVTemplate = (i18n, results) => ({
  title: i18n._('results.energyAndCarbon.total.pv'),
  rows: [
    {
      label: i18n._(
        'results.energyAndCarbon.total.pv.pvElectricEnergyProduced'
      ),
      values: results.map((result) =>
        getSafeData(() => result.ComputeResult.summary.TotalPVProduced)
      ),
      unit: UNIT.KILOWATT_HOUR_ELECTRIC
    },
    {
      label: i18n._(
        'results.energyAndCarbon.total.pv.pvElectricEnergySelfConsumed'
      ),
      values: results.map((result) =>
        getSafeData(() => result.ComputeResult.summary.TotalPVProduced)
      ),
      unit: UNIT.KILOWATT_HOUR_ELECTRIC
    },
    {
      label: i18n._(
        'results.energyAndCarbon.total.pv.pvElectricEnergySoldNetwork'
      ),
      values: results.map((result) =>
        getSafeData(() => result.ComputeResult.summary.TotalPVSold)
      ),
      unit: UNIT.KILOWATT_HOUR_ELECTRIC
    }
  ]
});

export const totalEnergyYieldTemplate = (i18n, results) => {
  return {
    rows: [
      {
        label: i18n._(
          'results.energyAndCarbon.energyYield.primaryEnergyConsumption',
          { years: results[0].ComputeResult.inp.InitYearsSimulations }
        ),
        refValues: results.map((result) => {
          const { inp } = result.ComputeResult;
          const summaryPath = getSummaryRefPath(inp);
          const selectedGoal = getSelectedGoalParam(inp);
          return !selectedGoal ||
            selectedGoal.key === 'InitConstraintEnergyPrimary'
            ? getSafeData(() => result.ComputeResult[summaryPath].EnergyPrimary)
            : null;
        }),
        accentaValues: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.EnergyPrimary)
        ),
        unit: UNIT.KILOWATT_HOUR
      },
      {
        label: i18n._(
          'results.energyAndCarbon.energyYield.finalEnergyConsumption',
          { years: results[0].ComputeResult.inp.InitYearsSimulations }
        ),
        refValues: results.map((result) => {
          const { inp } = result.ComputeResult;
          const summaryPath = getSummaryRefPath(inp);
          const selectedGoal = getSelectedGoalParam(inp);
          return !selectedGoal ||
            selectedGoal.key === 'InitConstraintEnergyFinale'
            ? getSafeData(() => result.ComputeResult[summaryPath].EnergyFinal)
            : null;
        }),
        accentaValues: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.EnergyFinal)
        ),
        unit: UNIT.KILOWATT_HOUR
      },
      {
        label: i18n._('results.energyAndCarbon.energyYield.injectionScop'),
        refValues: results.map((result) => {
          const { inp } = result.ComputeResult;
          const summaryPath = getSummaryRefPath(inp);
          return !getSelectedGoalParam(inp)
            ? getSafeData(
                () => result.ComputeResult[summaryPath].COPs.Injection.Mean
              )
            : null;
        }),
        accentaValues: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.COPs.Injection.Mean)
        ),
        precision: 2
      },
      {
        label: i18n._('results.energyAndCarbon.energyYield.globalCop'),
        tooltipInfo: i18n._(
          'results.energyAndCarbon.energyYield.globalCop.info'
        ),
        refValues: results.map((result) => {
          const { inp } = result.ComputeResult;
          const summaryPath = getSummaryRefPath(inp);
          return !getSelectedGoalParam(inp)
            ? getSafeData(
                () => result.ComputeResult[summaryPath].COPs.Global.Mean
              )
            : null;
        }),
        accentaValues: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.COPs.Global.Mean)
        ),
        precision: 2
      },
      {
        label: i18n._('results.energyAndCarbon.energyYield.overallYield'),
        refValues: results.map((result) => {
          const { inp } = result.ComputeResult;
          const summaryPath = getSummaryRefPath(inp);
          return !getSelectedGoalParam(inp)
            ? getSafeData(() => result.ComputeResult[summaryPath].OverallYield)
            : null;
        }),
        accentaValues: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.OverallYield)
        ),
        unit: UNIT.PERCENT
      },
      {
        label: i18n._('results.energyAndCarbon.energyYield.enrPart'),
        refValues: results.map((result) => {
          const { inp } = result.ComputeResult;
          const summaryPath = getSummaryRefPath(inp);
          return !getSelectedGoalParam(inp)
            ? getSafeData(() => result.ComputeResult[summaryPath].PartENR)
            : null;
        }),
        accentaValues: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.PartENR)
        ),
        unit: UNIT.PERCENT
      },
      {
        label: i18n._('results.energyAndCarbon.energyYield.hpgPart'),
        refValues: results.map((result) => {
          const { inp } = result.ComputeResult;
          const summaryPath = getSummaryRefPath(inp);
          return !getSelectedGoalParam(inp)
            ? getSafeData(() => result.ComputeResult[summaryPath].PartHpgTfp)
            : null;
        }),
        accentaValues: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.PartHpgTfp)
        ),
        unit: UNIT.PERCENT
      },
      {
        label: i18n._('results.energyAndCarbon.energyYield.gasPart'),
        refValues: results.map((result) => {
          const { inp } = result.ComputeResult;
          const summaryPath = getSummaryRefPath(inp);
          return !getSelectedGoalParam(inp)
            ? getSafeData(() => result.ComputeResult[summaryPath].GasPart)
            : null;
        }),
        accentaValues: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.GasPart)
        ),
        unit: UNIT.PERCENT
      }
    ]
  };
};

export const totalCarbonYieldTemplate = (i18n, results) => {
  return {
    rows: [
      {
        label: i18n._('results.energyAndCarbon.carbonYield.co2Emissions', {
          years: results[0].ComputeResult.inp.InitYearsSimulations
        }),
        refValues: results.map((result) => {
          const { inp } = result.ComputeResult;
          const summaryPath = getSummaryRefPath(inp);
          const selectedGoal = getSelectedGoalParam(inp);
          return !selectedGoal || selectedGoal.key === 'InitConstraintCO2kg'
            ? getSafeData(() => result.ComputeResult[summaryPath].CO2kg)
            : null;
        }),
        accentaValues: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.CO2kg)
        ),
        unit: UNIT.KILOGRAM
      },
      {
        label: i18n._(
          'results.energyAndCarbon.carbonYield.co2EmissionsPerYear'
        ),
        refValues: results.map((result) => {
          const { inp } = result.ComputeResult;
          const summaryPath = getSummaryRefPath(inp);
          const selectedGoal = getSelectedGoalParam(inp);
          return !selectedGoal || selectedGoal.key === 'InitConstraintCO2kg'
            ? getSafeData(() => result.ComputeResult[summaryPath].CO2kgPerYear)
            : null;
        }),
        accentaValues: results.map((result) =>
          getSafeData(() => result.ComputeResult.summary.CO2kgPerYear)
        ),
        unit: UNIT.KILOGRAM
      },
      {
        label: i18n._(
          'results.energyAndCarbon.carbonYield.co2EmissionsPerSquareMeterPerYear'
        ),
        refValues: results.map((result) => {
          const { inp } = result.ComputeResult;
          const summaryPath = getSummaryRefPath(inp);
          const selectedGoal = getSelectedGoalParam(inp);
          return !selectedGoal || selectedGoal.key === 'InitConstraintCO2kg'
            ? getSafeData(
                () =>
                  result.ComputeResult[summaryPath].CO2gPerSquareMeterPerYear
              )
            : null;
        }),
        accentaValues: results.map((result) =>
          getSafeData(
            () => result.ComputeResult.summary.CO2gPerSquareMeterPerYear
          )
        ),
        unit: UNIT.GRAM_PER_SQUARE_METER
      }
    ]
  };
};
