import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { HEADLOSS } from '../../../../../../server/models/design/performance.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import PerformancePageContext from '../../../../contexts/PerformancePageContext';
import { getDefaultValue } from '../../../../utils/project.utils';

const WeightLossBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleOtherValueChange } =
    useContext(PerformancePageContext);
  //#endregion

  //#region [render]
  const {
    InitPumpEfficiency,
    InitExchangerHeadlossMceMax,
    InitBtesHeadlossLimit,
    InitLoopConnectionLength
  } = tempProject.otherValues;

  return (
    <Bloc title={i18n._('performance.weightLoss')}>
      <Row>
        <Col>
          <FormInput
            label={i18n._('performance.InitPumpEfficiency')}
            value={InitPumpEfficiency}
            param={HEADLOSS.InitPumpEfficiency}
            onBlur={(value) =>
              handleOtherValueChange('InitPumpEfficiency', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(i18n, HEADLOSS.InitPumpEfficiency)}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('performance.InitExchangerHeadlossMceMax')}
            value={InitExchangerHeadlossMceMax}
            param={HEADLOSS.InitExchangerHeadlossMceMax}
            onBlur={(value) =>
              handleOtherValueChange('InitExchangerHeadlossMceMax', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(
              i18n,
              HEADLOSS.InitExchangerHeadlossMceMax
            )}
            unit
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('performance.InitBtesHeadlossLimit')}
            value={InitBtesHeadlossLimit}
            param={HEADLOSS.InitBtesHeadlossLimit}
            onBlur={(value) =>
              handleOtherValueChange('InitBtesHeadlossLimit', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(i18n, HEADLOSS.InitBtesHeadlossLimit)}
            unit
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('performance.InitLoopConnectionLength')}
            value={InitLoopConnectionLength}
            param={HEADLOSS.InitLoopConnectionLength}
            onBlur={(value) =>
              handleOtherValueChange('InitLoopConnectionLength', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(
              i18n,
              HEADLOSS.InitLoopConnectionLength
            )}
            unit
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default WeightLossBloc;
