import { MODE } from '../constants/plot.constant';
import { isArrNullOrEmpty } from './data.utils';

const getHeatingLines = (i18n, hpData, source) => {
  return hpData
    .filter((hp) => hp.Source === source)
    .filter((hp) => hp.Mode === 'Heating')
    .reduce((acc, val) => {
      const { Tcond, Tevap, COP_EER_EN14511 } = val;
      const key = Tcond.toString();
      if (!acc[key])
        acc[key] = {
          x: [Tevap],
          y: [COP_EER_EN14511],
          name: i18n._('performance.heating.legend', { key }),
          mode: MODE.LINES_MARKERS
        };
      else {
        acc[key].x.push(Tevap);
        acc[key].y.push(COP_EER_EN14511);
      }
      return acc;
    }, {});
};

const getCoolingLines = (i18n, hpData) => {
  return hpData
    .filter((hp) => hp.Source === 'A')
    .filter((hp) => hp.Mode === 'Cooling')
    .reduce((acc, val) => {
      const { Tcond, Tevap, COP_EER_EN14511 } = val;
      const key = Tevap.toString();
      if (!acc[key])
        acc[key] = {
          x: [Tcond],
          y: [COP_EER_EN14511],
          name: i18n._('performance.cooling.legend', { key }),
          mode: MODE.LINES_MARKERS
        };
      else {
        acc[key].x.push(Tcond);
        acc[key].y.push(COP_EER_EN14511);
      }
      return acc;
    }, {});
};

export const getHPGData = (i18n, hpData) => {
  if (isArrNullOrEmpty(hpData)) return [];
  return {
    B: {
      Heating: getHeatingLines(i18n, hpData, 'B')
    },
    W: {
      Heating: getHeatingLines(i18n, hpData, 'W')
    }
  };
};

export const getHPAData = (i18n, hpData) => {
  if (isArrNullOrEmpty(hpData)) return [];
  return {
    Heating: getHeatingLines(i18n, hpData, 'A'),
    Cooling: getCoolingLines(i18n, hpData)
  };
};

export const getShortHPName = (hp) => {
  const hpNameDetails = hp.split('_');
  const detailsIndexesToDelete = [0, 3]; // les index des mots à supprimer dans le nom de la PAC
  return hpNameDetails
    .filter((_, index) => !detailsIndexesToDelete.includes(index))
    .join('_');
};
