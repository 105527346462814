import React, { Fragment } from 'react';
import { STD_COLS } from '../../../../../server/constants/stdCol.constant';
import { isNull } from '../../../utils/data.utils';

const STDModal = (i18n) => ({
  title: i18n._('projectNeeds.STDDesc.title'),
  body: (
    <Fragment>
      <p>{i18n._('projectNeeds.STDDesc.acceptedFiles')}</p>
      <div className='description-file-modal-hl' />
      {Object.entries(STD_COLS).map(([stdColName, stdColObj], colIndex) => {
        let text = [stdColName];
        if (!isNull(stdColObj.min)) {
          text.push(
            i18n._('projectNeeds.modal.min', {
              min: stdColObj.min
            })
          );
        }
        if (!isNull(stdColObj.max)) {
          text.push(
            i18n._('projectNeeds.modal.max', {
              max: stdColObj.max
            })
          );
        }
        if (stdColObj.unit) {
          text.push(
            i18n._('projectNeeds.modal.unit', {
              unit: i18n._(`unit.${stdColObj.unit}`)
            })
          );
        }
        if (!stdColObj.mandatory) {
          text.push(i18n._('projectNeeds.modal.optional'));
        }
        return (
          <p key={'substationNeeds_' + stdColName + '_' + colIndex}>
            {text.join(', ')}
          </p>
        );
      })}
    </Fragment>
  )
});

export default STDModal;
