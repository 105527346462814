import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { INJECTION } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import CostPageContext from '../../../../contexts/CostPageContext';
import { getDefaultValue } from '../../../../utils/project.utils';

const InjectionBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleOtherValueChange } =
    useContext(CostPageContext);
  //#endregion

  //#region [render]
  const {
    InitInjectionExchangerCostA,
    InitInjectionExchangerCostB,
    InitInjectionPumpCostA,
    InitInjectionPumpCostB
  } = tempProject.otherValues;
  return (
    <Bloc title={i18n._('cost.injection')}>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitInjectionExchangerCostA')}
            value={InitInjectionExchangerCostA}
            param={INJECTION.InitInjectionExchangerCostA}
            onBlur={(value) =>
              handleOtherValueChange('InitInjectionExchangerCostA', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              INJECTION.InitInjectionExchangerCostA
            )}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitInjectionExchangerCostB')}
            value={InitInjectionExchangerCostB}
            param={INJECTION.InitInjectionExchangerCostB}
            onBlur={(value) =>
              handleOtherValueChange('InitInjectionExchangerCostB', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              INJECTION.InitInjectionExchangerCostB
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitInjectionPumpCostA')}
            value={InitInjectionPumpCostA}
            param={INJECTION.InitInjectionPumpCostA}
            onBlur={(value) =>
              handleOtherValueChange('InitInjectionPumpCostA', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, INJECTION.InitInjectionPumpCostA)}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitInjectionPumpCostB')}
            value={InitInjectionPumpCostB}
            param={INJECTION.InitInjectionPumpCostB}
            onBlur={(value) =>
              handleOtherValueChange('InitInjectionPumpCostB', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, INJECTION.InitInjectionPumpCostB)}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default InjectionBloc;
