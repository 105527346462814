import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { UNIT } from '../../../../../../server/constants/unit.constant';
import { HYDRAULIC } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import CostPageContext from '../../../../contexts/CostPageContext';
import { roundNumber } from '../../../../utils/data.utils';

const HydraulicLotBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleOtherValueChange } =
    useContext(CostPageContext);
  //#endregion

  //#region [render]
  const { InitCapexHydraulicMargin } = tempProject.otherValues;

  return (
    <Bloc title={i18n._('cost.hydraulicLot')}>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitCapexHydraulicMargin')}
            value={roundNumber(InitCapexHydraulicMargin * 100, 4)}
            param={HYDRAULIC.InitCapexHydraulicMargin}
            onBlur={(value) =>
              handleOtherValueChange('InitCapexHydraulicMargin', value / 100)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={i18n._('param.defaultValues', {
              value: roundNumber(
                HYDRAULIC.InitCapexHydraulicMargin.default * 100,
                4
              ),
              unit: UNIT.PERCENT
            })}
            showInfoIcon
            infoIcon='circle-info'
            infoTooltip={i18n._('cost.hydraulicLot.help')}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default HydraulicLotBloc;
