// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-params-inputs-table {
    width: 100%;
    table-layout: fixed;
}

.results-params-inputs-table tr {
    font-size: 14px;
}

.results-params-inputs-table tr:first-of-type td:first-of-type {
    border-top-left-radius: 8px;
}

.results-params-inputs-table tr:first-of-type td:last-of-type {
    border-top-right-radius: 8px;
}

.results-params-inputs-table tr:last-of-type td:first-of-type {
    border-bottom-left-radius: 8px;
}

.results-params-inputs-table tr:last-of-type td:last-of-type {
    border-bottom-right-radius: 8px;
}

.results-params-inputs-table tr td:first-of-type {
    padding-left: 10px;
}

.results-params-inputs-table tr td:last-of-type {
    text-align: end;
    padding-right: 10px;
}

.results-params-inputs-table tr:nth-of-type(2n) {
    background-color: rgb(209, 209, 209);
    color: var(--dark-grey);
}

.results-params-inputs-table tr:nth-of-type(2n+1) {
    background-color: var(--light-blue);
    color: rgb(49, 49, 49);
}

.results-params-inputs-btn {
    font-size: 14px;
    margin: 5px 0 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/design/result/sections/simulationParams/components/RecursiveTable/InputsParamsTable.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;AACvB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,2BAA2B;AAC/B;;AAEA;IACI,4BAA4B;AAChC;;AAEA;IACI,8BAA8B;AAClC;;AAEA;IACI,+BAA+B;AACnC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,eAAe;IACf,mBAAmB;AACvB;;AAEA;IACI,oCAAoC;IACpC,uBAAuB;AAC3B;;AAEA;IACI,mCAAmC;IACnC,sBAAsB;AAC1B;;AAEA;IACI,eAAe;IACf,oBAAoB;AACxB","sourcesContent":[".results-params-inputs-table {\n    width: 100%;\n    table-layout: fixed;\n}\n\n.results-params-inputs-table tr {\n    font-size: 14px;\n}\n\n.results-params-inputs-table tr:first-of-type td:first-of-type {\n    border-top-left-radius: 8px;\n}\n\n.results-params-inputs-table tr:first-of-type td:last-of-type {\n    border-top-right-radius: 8px;\n}\n\n.results-params-inputs-table tr:last-of-type td:first-of-type {\n    border-bottom-left-radius: 8px;\n}\n\n.results-params-inputs-table tr:last-of-type td:last-of-type {\n    border-bottom-right-radius: 8px;\n}\n\n.results-params-inputs-table tr td:first-of-type {\n    padding-left: 10px;\n}\n\n.results-params-inputs-table tr td:last-of-type {\n    text-align: end;\n    padding-right: 10px;\n}\n\n.results-params-inputs-table tr:nth-of-type(2n) {\n    background-color: rgb(209, 209, 209);\n    color: var(--dark-grey);\n}\n\n.results-params-inputs-table tr:nth-of-type(2n+1) {\n    background-color: var(--light-blue);\n    color: rgb(49, 49, 49);\n}\n\n.results-params-inputs-btn {\n    font-size: 14px;\n    margin: 5px 0 10px 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
