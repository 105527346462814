import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { OTHER_CAPEX } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import CostPageContext from '../../../../contexts/CostPageContext';
import { getDefaultValue } from '../../../../utils/project.utils';

const OtherCapexBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleOtherValueChange } =
    useContext(CostPageContext);
  //#endregion

  //#region [render]
  const {
    InitMOEBtesMargin,
    InitMOEMargin,
    InitIntegrationBtesMargin,
    InitIntegrationMargin,
    InitCapexOther
  } = tempProject.otherValues;
  return (
    <Bloc title={i18n._('cost.otherCapex')} style={{ borderBottom: 'none' }}>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitMOEBtesMargin')}
            value={InitMOEBtesMargin}
            param={OTHER_CAPEX.InitMOEBtesMargin}
            onBlur={(value) =>
              handleOtherValueChange('InitMOEBtesMargin', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, OTHER_CAPEX.InitMOEBtesMargin)}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitMOEMargin')}
            value={InitMOEMargin}
            param={OTHER_CAPEX.InitMOEMargin}
            onBlur={(value) => handleOtherValueChange('InitMOEMargin', value)}
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, OTHER_CAPEX.InitMOEMargin)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitIntegrationBtesMargin')}
            value={InitIntegrationBtesMargin}
            param={OTHER_CAPEX.InitIntegrationBtesMargin}
            onBlur={(value) =>
              handleOtherValueChange('InitIntegrationBtesMargin', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_CAPEX.InitIntegrationBtesMargin
            )}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitIntegrationMargin')}
            value={InitIntegrationMargin}
            param={OTHER_CAPEX.InitIntegrationMargin}
            onBlur={(value) =>
              handleOtherValueChange('InitIntegrationMargin', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_CAPEX.InitIntegrationMargin
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormInput
            label={i18n._('cost.InitCapexOther')}
            value={InitCapexOther}
            param={OTHER_CAPEX.InitCapexOther}
            onBlur={(value) => handleOtherValueChange('InitCapexOther', value)}
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, OTHER_CAPEX.InitCapexOther)}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default OtherCapexBloc;
