import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import { UNIT } from '../../../../../../../../../server/constants/unit.constant';
import DoughnutPieChart from '../../../../../../../components/Chart/DoughnutPieChart';
import ProjectContext from '../../../../../../../contexts/ProjectContext';
import { PV_USES_CHART_COLORS } from '../../../../../../../styles/colors/pvUse.color';
import { formatValueWithUnit } from '../../../../../../../utils/data.utils';

const PVEnergyDistributionPieChart = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { project } = useContext(ProjectContext);
  //#endregion

  //#region [memos]
  const chart = useMemo(() => {
    if (!result.ComputeResult.summary.PVRecapStruct) return null;
    const { PVRecapStruct } = result.ComputeResult.summary;
    const pvEntries = Object.entries(PVRecapStruct).filter(
      (key) => key !== 'Total' && PVRecapStruct[key] !== 0
    );
    const newLabels = pvEntries.map((entry) =>
      i18n._(`pv.use.${entry[0].toLowerCase()}`)
    );
    const newColors = pvEntries.map(
      (entry) => PV_USES_CHART_COLORS[entry[0].toLowerCase()]
    );
    const newTotal = formatValueWithUnit(
      i18n,
      PVRecapStruct.Total,
      UNIT.KILOWATT_HOUR_ELECTRIC
    );
    return {
      data: {
        values: pvEntries.map((entry) => entry[1]),
        labels: newLabels,
        colors: newColors
      },
      layout: {
        total: newTotal
      }
    };
  }, [result.ComputeID]);
  //#endregion

  //#region [render]
  return chart ? (
    <DoughnutPieChart
      data={chart.data}
      layout={chart.layout}
      title={i18n._('pvEnergyDistributionPieChart.title', {
        years: result.ComputeResult.inp.InitYearsSimulations
      })}
      filename={i18n._('plotly.filename', {
        projectName: project.AhsName,
        title: i18n._('pvEnergyDistributionPieChart.title', {
          years: result.ComputeResult.inp.InitYearsSimulations
        })
      })}
    />
  ) : null;
  //#endregion
};

export default PVEnergyDistributionPieChart;
