// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.substation-tabs-warning-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 5px;
    font-size: 13px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin-bottom: 10px;
}

.substation-tabs-warning-body>svg {
    font-size: 18px;
    color: var(--warning-color);
}`, "",{"version":3,"sources":["webpack://./src/components/DescriptionsTabs/components/SubstationTabs/SubstationTabs.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,QAAQ;IACR,eAAe;IACf,yBAAyB;QACrB,sBAAsB;YAClB,mBAAmB;IAC3B,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,2BAA2B;AAC/B","sourcesContent":[".substation-tabs-warning-body {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    gap: 5px;\n    font-size: 13px;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    margin-bottom: 10px;\n}\n\n.substation-tabs-warning-body>svg {\n    font-size: 18px;\n    color: var(--warning-color);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
