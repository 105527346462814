import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Button, Collapse, Form, Spinner } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select';
import { MAX_RESULTS_FOR_COMPARE } from '../../../../../../../server/constants/result.constant';
import CompanyContext from '../../../../../contexts/CompanyContext';
import ProjectContext from '../../../../../contexts/ProjectContext';
import ResultContext from '../../../../../contexts/ResultContext';
import useScrollPosition from '../../../../../hooks/useScrollPosition';
import styles from '../../../../../styles/react-select.style';
import {
  getGroupHeadingName,
  getOptionName,
  getSingleValueName
} from '../../../../../utils/compute.utils';
import DeleteModal from '../DeleteModal/DeleteModal';
import './CompareResultSelect.css';

const CompareResultSelect = ({
  addResultToCompare,
  removeResultFromCompare
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  const { project } = useContext(ProjectContext);
  const { isLoading, groupedResults, selectedResult, comparedResults } =
    useContext(ResultContext);
  //#endregion

  //#region [states]
  const [isOpen, setIsOpen] = useState(true);
  // groupedOptions pourrait être remplacé par un useMemo et être régi par des règles + simples que celles dessous (pas de useEffect pour set le GroupedOptions et aucun setGroupedOptions)
  // cependant, si on passe par un useMemo un glitch apparaît à chaque fois que j'intéragis avec le select
  const [groupedOptions, setGroupedOptions] = useState([]);
  const [isSelectOpen, setIsSelectOpen] = useState();
  const [isModalOpen, setIsModalOpen] = useState(false);
  //#endregion

  //#region [refs]
  const cardRef = useRef();
  //#endregion

  //#region [effects]
  useEffect(() => {
    setGroupedOptions(() => {
      return groupedResults.map((group, groupIndex) => ({
        label: getGroupHeadingName(i18n, project, group),
        options: group.computes.map((result, resultIndex) => ({
          value: result.ComputeID,
          label: getOptionName(i18n, result),
          projectTag: result.tags.project,
          feasibilityTag: result.tags.feasibility,
          contractCommitmentTag: result.tags.contractCommitment,
          groupLabel: getSingleValueName(i18n, project, group, result),
          indexes: [groupIndex, resultIndex],
          checked: comparedResults.find(
            (res) => res.ComputeID === result.ComputeID
          )
        }))
      }));
    });
  }, [groupedResults]);
  //#endregion

  //#region [custom hooks]
  useScrollPosition(
    ({ prevPos, currPos }) => {
      if (prevPos.y === currPos.y) return;
      if (currPos.y < 130 && isOpen) {
        setIsOpen(false);
      } else if (currPos.y >= 130 && !isOpen) {
        setIsOpen(true);
      }
    },
    [isOpen],
    cardRef,
    null,
    400
  );
  //#endregion

  //#region [memos]
  const selectedOption = useMemo(() => {
    let newSelectedOption;
    for (const group of groupedOptions) {
      const option = group.options.find(
        (option) => option.value === selectedResult.ComputeID
      );
      if (option) {
        newSelectedOption = option;
        break;
      }
    }
    return newSelectedOption;
  }, [selectedResult.ComputeID, groupedOptions]);

  const customStyles = useMemo(() => styles(), []);
  //#endregion

  //#region [methods]
  const handleCheckboxClick = (resultId, checked) => {
    setGroupedOptions((groups) => {
      for (const group of groups) {
        const option = group.options.find(
          (option) => option.value === resultId
        );
        if (option) {
          option.checked = checked;
          break;
        }
      }
      return [...groups];
    });
    checked ? addResultToCompare(resultId) : removeResultFromCompare(resultId);
    setIsSelectOpen(true);
  };

  const handleInputChange = (_, { action, prevInputValue }) => {
    if (action === 'menu-close' && !prevInputValue) {
      setIsSelectOpen(undefined);
    }
    return prevInputValue;
  };

  const handleOptionClick = (resultId) => {
    setGroupedOptions((groups) => {
      return groups.map((group) => ({
        ...group,
        options: group.options.map((option) => ({ ...option, checked: false }))
      }));
    });
    navigate(
      `/company/${selectedCompany.id}/project/${project.AhsID}/results/${resultId}`
    );
  };

  const handleCompareClick = (options) => {
    const baseResultId = options[0].value;
    const compareResultsIds = [];
    let url = `/company/${selectedCompany.id}/project/${project.AhsID}/results/${baseResultId}`;
    if (options.length > 1) {
      for (let i = 1; i < options.length; ++i) {
        compareResultsIds.push(options[i].value);
      }
      url += `?compare=${compareResultsIds.join(',')}`;
    }
    setGroupedOptions((groups) => {
      return groups.map((group) => ({
        ...group,
        options: group.options.map((option) => ({
          ...option,
          checked: compareResultsIds.includes(option.value)
        }))
      }));
    });
    navigate(url);
  };
  //#endregion

  //#region [select components]
  const SingleValue = ({ ...props }) => {
    const content = useMemo(() => {
      return (
        <components.SingleValue {...props}>
          <span
            title={props.data.groupLabel}
            className='results-single-value ellipsis'
          >
            {props.data.groupLabel}
          </span>
          <span className='results-select-tags'>
            {props.data.feasibilityTag && (
              <Badge pill className='feasibility-badge'>
                {i18n._('tag.feasibility')}
              </Badge>
            )}
            {props.data.projectTag && (
              <Badge pill className='project-badge'>
                {i18n._('tag.project')}
              </Badge>
            )}
            {props.data.contractCommitmentTag && (
              <Badge pill className='contract-commitment-badge'>
                {i18n._('tag.contractCommitment')}
              </Badge>
            )}
          </span>
        </components.SingleValue>
      );
    }, [
      props.data.groupLabel,
      props.data.feasibilityTag,
      props.data.projectTag,
      props.data.contractCommitmentTag
    ]);
    return content;
  };

  const GroupHeading = ({ ...props }) => {
    const content = useMemo(() => {
      return (
        <div className='group-header'>
          <components.GroupHeading {...props} />
          <Button
            variant='outline-secondary'
            onClick={() => handleCompareClick(props.data.options)}
          >
            <FontAwesomeIcon icon='table' />
            {i18n._('results.select.compare')}
          </Button>
        </div>
      );
    }, [props.data.label]);
    return content;
  };

  const Option = ({ ...props }) => {
    const content = useMemo(() => {
      return (
        <components.Option {...props}>
          <div className='result-page-option'>
            <Form.Check
              type='checkbox'
              onChange={(evt) =>
                handleCheckboxClick(props.data.value, evt.target.checked)
              }
              checked={props.data.checked}
              className={
                props.data.value !== selectedResult.ComputeID
                  ? 'result-checkbox'
                  : 'checkbox-hidden'
              }
              disabled={
                comparedResults.length === MAX_RESULTS_FOR_COMPARE &&
                !props.data.checked
              }
            />
            <div
              className='result-option'
              onClick={() => handleOptionClick(props.data.value)}
            >
              <span
                className='result-option-label ellipsis'
                title={props.data.label}
              >
                {props.data.label}
              </span>
              <div className='results-select-tags'>
                {props.data.feasibilityTag && (
                  <Badge pill className='feasibility-badge'>
                    {i18n._('tag.feasibility')}
                  </Badge>
                )}
                {props.data.projectTag && (
                  <Badge pill className='project-badge'>
                    {i18n._('tag.project')}
                  </Badge>
                )}
                {props.data.contractCommitmentTag && (
                  <Badge pill className='contract-commitment-badge'>
                    {i18n._('tag.contractCommitment')}
                  </Badge>
                )}
              </div>
            </div>
          </div>
        </components.Option>
      );
    }, [
      props.data.value,
      props.data.label,
      props.data.checked,
      props.data.feasibilityTag,
      props.data.projectTag,
      props.data.contractCommitmentTag,
      props.isSelected,
      props.isFocused,
      comparedResults.length
    ]);

    return content;
  };
  //#endregion

  //#region [render]
  return (
    <div className='results-select-card' ref={cardRef}>
      <Collapse in={isOpen}>
        <div>
          <p className='results-select-label'>
            {i18n._('results.select', { max: MAX_RESULTS_FOR_COMPARE - 1 })}
          </p>
        </div>
      </Collapse>
      <div className='results-select-body'>
        <Select
          value={selectedOption}
          options={groupedOptions}
          components={{ SingleValue, GroupHeading, Option }}
          styles={customStyles}
          isSearchable={false}
          menuIsOpen={isSelectOpen}
          onInputChange={handleInputChange}
          className='results-select'
        />
        <FontAwesomeIcon
          icon='trash-alt'
          onClick={() => setIsModalOpen(true)}
        />
        {isLoading && <Spinner animation='border' variant='light' />}
      </div>
      <DeleteModal
        groupedResults={groupedResults}
        selectedResultId={selectedResult.ComputeID}
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
  //#endregion
};

export default CompareResultSelect;
