import {
  BTES,
  SOIL
} from '../../../../../../../../../server/models/design/geology.model';
import { getSafeData } from '../../../../../../../utils/data.utils';

export const geoTemplates = (i18n, project) => [
  {
    title: i18n._('geology.soil'),
    rows: [
      {
        label: i18n._('geology.InitSoilThermalConductivity'),
        value: getSafeData(() => project.InitSoilThermalConductivity),
        unit: SOIL.InitSoilThermalConductivity.unit
      },
      {
        label: i18n._('geology.InitSoilVolumeHeatCapacity'),
        value: getSafeData(() => project.InitSoilVolumeHeatCapacity),
        unit: SOIL.InitSoilVolumeHeatCapacity.unit
      },
      {
        label: i18n._('geology.InitSoilTemperature10m'),
        value: getSafeData(() => project.InitSoilTemperature10m),
        unit: SOIL.InitSoilTemperature10m.unit
      }
    ]
  },
  {
    title: i18n._('geology.btes'),
    rows: [
      {
        label: i18n._('geology.InitBtesTemperatureMin'),
        value: getSafeData(() => project.InitBtesTemperatureMin),
        unit: BTES.InitBtesTemperatureMin.unit
      },
      {
        label: i18n._('geology.InitBtesTemperatureMax'),
        value: getSafeData(() => project.InitBtesTemperatureMax),
        unit: BTES.InitBtesTemperatureMax.unit
      },
      {
        label: i18n._('geology.YearsT2Final'),
        value: getSafeData(() => project.otherValues.YearsT2Final),
        unit: BTES.YearsT2Final.unit
      },
      {
        label: i18n._('geology.InitBtesProbeResistance'),
        value: getSafeData(() => project.otherValues.InitBtesProbeResistance),
        unit: BTES.InitBtesProbeResistance.unit
      }
    ]
  }
];
