// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.breadcrumb {
    background-color: var(--app-bg-color);
    padding: 0;
    margin-bottom: 15px;
}

.breadcrumb .breadcrumb-item a:not(.active) {
    color: var(--primary-color);
}`, "",{"version":3,"sources":["webpack://./src/components/Breadcrumb/Breadcrumb.css"],"names":[],"mappings":"AAAA;IACI,qCAAqC;IACrC,UAAU;IACV,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;AAC/B","sourcesContent":[".breadcrumb {\n    background-color: var(--app-bg-color);\n    padding: 0;\n    margin-bottom: 15px;\n}\n\n.breadcrumb .breadcrumb-item a:not(.active) {\n    color: var(--primary-color);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
