import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Form } from 'react-bootstrap';
import { Link, useNavigate } from 'react-router-dom';
import { COMPANY_KEY } from '../../../../server/constants/storage.constant';
import { fetchUsers } from '../../api/company.api';
import CompanyContext from '../../contexts/CompanyContext';
import PopupContext from '../../contexts/PopupContext';
import useAuth from '../../hooks/useAuth';
import './UsersPage.css';
import UsersTable from './components/UsersTable/UsersTable';

const UsersPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [auth]
  const { user } = useAuth();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [users, setUsers] = useState([]);
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        const users = await fetchUsers(selectedCompany.id);
        setUsers(users);
      } catch (err) {
        console.error(err);
        if (err.response?.status === 404) {
          navigate('/notfound');
        } else {
          openErrorToast(err);
        }
      }
    })();
  }, [selectedCompany.id]);
  //#endregion

  //#region [methods]
  const handleCompanyChange = (evt) => {
    localStorage.setItem(COMPANY_KEY, evt.target.value);
    navigate(`/company/${evt.target.value}/users`);
  };
  //#endregion

  //#region [render]
  return (
    <div className='users-page'>
      <div className='users-top-row'>
        <div className='users-top-row-left'>
          <div className='users-company-select'>
            <span>{i18n._('company')}</span>
            <Form.Select
              aria-label='select company'
              onChange={handleCompanyChange}
              value={selectedCompany.id}
            >
              {user.companies.map((company) => (
                <option key={'company_' + company.CoID} value={company.CoID}>
                  {company.CoName}
                </option>
              ))}
            </Form.Select>
          </div>
        </div>
        <Link
          to={`/company/${selectedCompany.id}/new-user`}
          className='users-new'
        >
          {i18n._('newUser')} <FontAwesomeIcon icon='plus' />
        </Link>
      </div>
      {users && <UsersTable users={users} />}
    </div>
  );
  //#endregion
};

export default UsersPage;
