// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.multi-desc-th {
    color: white;
    font-weight: 500;
    font-size: 17px;
    padding: 5px;
    text-align: center;
}

.multi-desc-th:nth-of-type(1) {
    background-color: transparent
}

.multi-desc-th:nth-of-type(2n) {
    background-color: var(--primary-color);
}

.multi-desc-th:nth-of-type(2n+1) {
    background-color: var(--dark-grey);
}

.multi-desc-th:last-of-type {
    border-top-right-radius: 10px;
}

.multi-desc-table-value {
    text-align: center;
    border-left: 1px solid rgb(61, 61, 61);
    padding: 5px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/components/ProjectSummary/sections/description/components/MultiDescTable/MultiDescTable.css"],"names":[],"mappings":"AAAA;IACI,YAAY;IACZ,gBAAgB;IAChB,eAAe;IACf,YAAY;IACZ,kBAAkB;AACtB;;AAEA;IACI;AACJ;;AAEA;IACI,sCAAsC;AAC1C;;AAEA;IACI,kCAAkC;AACtC;;AAEA;IACI,6BAA6B;AACjC;;AAEA;IACI,kBAAkB;IAClB,sCAAsC;IACtC,YAAY;IACZ,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".multi-desc-th {\n    color: white;\n    font-weight: 500;\n    font-size: 17px;\n    padding: 5px;\n    text-align: center;\n}\n\n.multi-desc-th:nth-of-type(1) {\n    background-color: transparent\n}\n\n.multi-desc-th:nth-of-type(2n) {\n    background-color: var(--primary-color);\n}\n\n.multi-desc-th:nth-of-type(2n+1) {\n    background-color: var(--dark-grey);\n}\n\n.multi-desc-th:last-of-type {\n    border-top-right-radius: 10px;\n}\n\n.multi-desc-table-value {\n    text-align: center;\n    border-left: 1px solid rgb(61, 61, 61);\n    padding: 5px;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    overflow: hidden;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
