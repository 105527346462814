import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import { Badge, Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import Select, { components } from 'react-select';
import CompanyContext from '../../contexts/CompanyContext';
import ProjectContext from '../../contexts/ProjectContext';
import styles from '../../styles/react-select.style';
import {
  getGroupHeadingName,
  getOptionName,
  getSingleValueName
} from '../../utils/compute.utils';
import './ResultSelect.css';

const ResultSelect = ({
  onResultChange,
  groupedResults,
  selectedResultIndexes
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  const { project } = useContext(ProjectContext);
  //#endregion

  //#region [methods]
  const handleCompareClick = (options) => {
    const baseResultId = options[0].value;
    const compareResults = [];
    let url = `/company/${selectedCompany.id}/project/${project.AhsID}/results/${baseResultId}`;
    if (options.length > 1) {
      for (let i = 1; i < options.length; ++i) {
        compareResults.push(options[i].value);
      }
      url += `?compare=${compareResults.join(',')}`;
    }
    navigate(url);
  };
  //#endregion

  //#region [memos]
  const groupedOptions = useMemo(() => {
    return groupedResults.map((group, groupIndex) => ({
      label: getGroupHeadingName(i18n, project, group),
      options: group.computes.map((result, resultIndex) => ({
        value: result.ComputeID,
        label: getOptionName(i18n, result),
        projectTag: result.tags.project,
        feasibilityTag: result.tags.feasibility,
        contractCommitmentTag: result.tags.contractCommitment,
        groupLabel: getSingleValueName(i18n, project, group, result),
        indexes: [groupIndex, resultIndex]
      }))
    }));
  }, [groupedResults]);

  const customStyles = useMemo(() => styles(), []);
  //#endregion

  //#region [select components]
  const SingleValue = ({ ...props }) => {
    const content = useMemo(() => {
      return (
        <components.SingleValue {...props}>
          <span
            title={props.data.groupLabel}
            className='results-single-value ellipsis'
          >
            {props.data.groupLabel}
          </span>
          <span className='results-select-tags'>
            {props.data.feasibilityTag && (
              <Badge pill className='feasibility-badge'>
                {i18n._('tag.feasibility')}
              </Badge>
            )}
            {props.data.projectTag && (
              <Badge pill className='project-badge'>
                {i18n._('tag.project')}
              </Badge>
            )}
            {props.data.contractCommitmentTag && (
              <Badge pill className='contract-commitment-badge'>
                {i18n._('tag.contractCommitment')}
              </Badge>
            )}
          </span>
        </components.SingleValue>
      );
    }, [
      props.data.groupLabel,
      props.data.feasibilityTag,
      props.data.projectTag,
      props.data.contractCommitmentTag
    ]);
    return content;
  };

  const GroupHeading = ({ ...props }) => {
    const content = useMemo(() => {
      return (
        <div className='group-header'>
          <components.GroupHeading {...props} />
          <Button
            variant='outline-secondary'
            onClick={() => handleCompareClick(props.data.options)}
          >
            <FontAwesomeIcon icon='table' />
            {i18n._('results.select.compare')}
          </Button>
        </div>
      );
    }, [props.data.label, props.data.options]);
    return content;
  };

  const Option = ({ ...props }) => {
    const content = useMemo(() => {
      return (
        <components.Option {...props}>
          <div
            className='result-option'
            onClick={async () => await onResultChange(props.data)}
          >
            <span
              className='result-option-label ellipsis'
              title={props.data.label}
            >
              {props.data.label}
            </span>
            {props.data.feasibilityTag && (
              <Badge pill className='feasibility-badge'>
                {i18n._('tag.feasibility')}
              </Badge>
            )}
            {props.data.projectTag && (
              <Badge pill className='project-badge'>
                {i18n._('tag.project')}
              </Badge>
            )}
            {props.data.contractCommitmentTag && (
              <Badge pill className='contract-commitment-badge'>
                {i18n._('tag.contractCommitment')}
              </Badge>
            )}
          </div>
        </components.Option>
      );
    }, [
      props.data.value,
      props.data.label,
      props.data.feasibilityTag,
      props.data.projectTag,
      props.data.contractCommitmentTag,
      props.data.indexes,
      props.isSelected,
      props.isFocused
    ]);
    return content;
  };
  //#endregion

  //#region [render]
  return (
    <Select
      value={
        groupedOptions[selectedResultIndexes[0]].options[
          selectedResultIndexes[1]
        ]
      }
      options={groupedOptions}
      components={{ SingleValue, GroupHeading, Option }}
      styles={customStyles}
      isSearchable={false}
      className='results-select'
    />
  );
  //#endregion
};

export default ResultSelect;
