import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import TwoWayTable from '../../../../../../components/TwoWayTable/TwoWayTable';
import { formatValue } from '../../../../../../utils/data.utils';

const ConsumptionTable = ({ result, consumptionKey, verticalTitle }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    if (!result.ComputeResult.summary.ConsumptionPerYear?.[consumptionKey])
      return null;
    const length = result.ComputeResult.inp.InitYearsSimulations;
    const horizontalTitles = Array.from({ length }, (_, i) =>
      i18n._('results.year', {
        year: i + 1
      })
    );
    const values = [
      Array.from({ length }, (_, i) =>
        formatValue(
          result.ComputeResult.summary.ConsumptionPerYear[consumptionKey][i]
        )
      )
    ];
    return {
      horizontalTitles,
      values
    };
  }, [result.ComputeID, consumptionKey]);
  //#endregion

  //#region [render]
  return data ? (
    <TwoWayTable
      horizontalTitles={data.horizontalTitles}
      verticalTitles={[verticalTitle]}
      values={data.values}
    />
  ) : (
    <Alert variant='warning'>{i18n._('results.noData')}</Alert>
  );
  //#endregion
};

export default ConsumptionTable;
