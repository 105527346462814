import { UNIT } from '../../../../../../../../../server/constants/unit.constant';
import {
  NEEDS,
  SST,
  getStationWaterTankHotTemperatureMinParam,
  getTemperatureColdParam,
  getTemperatureHeatParam,
  getTemperatureHotWaterParam
} from '../../../../../../../../../server/models/design/description.model';
import { getSafeData } from '../../../../../../../utils/data.utils';

export const multiDescTemplate = (projectAndChildren) => ({
  descriptions: projectAndChildren.map((project) => project.description),
  rows: [
    {
      labelId: 'description.temperatureHeat',
      values: projectAndChildren.map((project) =>
        getSafeData(() => project.substations[0].InitTemperatureHeat)
      ),
      unit: getTemperatureHeatParam().unit
    },
    {
      labelId: 'description.isWeatherCompensationEnabled',
      values: projectAndChildren.map((project) =>
        getSafeData(() => project.otherValues.InitWaterLawIsUsed)
      ),
      translateValue: true
    },
    {
      labelId: 'description.InitStationTemperatureSetPoint',
      values: projectAndChildren.map((project) =>
        getSafeData(() => project.otherValues.InitWaterLawIsUsed)
          ? getSafeData(
              () => project.otherValues.InitStationTemperatureSetPoint
            )
          : null
      ),
      unit: NEEDS.InitStationTemperatureSetPoint.unit
    },
    {
      labelId: 'description.InitStationWaterTankHotTemperatureMin',
      values: projectAndChildren.map((project) =>
        getSafeData(() => project.otherValues.InitWaterLawIsUsed)
          ? getSafeData(
              () => project.otherValues.InitStationWaterTankHotTemperatureMin
            )
          : null
      ),
      unit: getStationWaterTankHotTemperatureMinParam().unit
    },
    {
      labelId: 'description.temperatureCold',
      values: projectAndChildren.map((project) =>
        getSafeData(() => project.substations[0].InitTemperatureCold)
      ),
      unit: getTemperatureColdParam().unit
    },
    {
      labelId: 'description.temperatureHotwater',
      values: projectAndChildren.map((project) =>
        getSafeData(() => project.substations[0].InitTemperatureHotWater)
      ),
      unit: getTemperatureHotWaterParam().unit
    },
    {
      labelId: 'description.InitLoopIsUsed',
      values: projectAndChildren.map((project) =>
        getSafeData(() => project.otherValues.InitLoopIsUsed)
      ),
      translateValue: true
    },
    {
      labelId: 'description.heatProductionSolutions',
      values: projectAndChildren.map((project) => {
        const isInitGasEnabled = getSafeData(
          () => project.otherValues.InitGasEnabled
        );
        const isInitReleveEnabled = getSafeData(
          () => project.otherValues.InitReleveEnabled
        );
        const isInitHPAHeatingEnabled = getSafeData(
          () => project.otherValues.InitHPAHeatingEnabled
        );

        if (!isInitHPAHeatingEnabled && !isInitGasEnabled) return 'NONE';

        let translateValueId = '';
        if (isInitHPAHeatingEnabled) {
          translateValueId += 'hpa';
        }

        if (isInitGasEnabled) {
          if (isInitHPAHeatingEnabled) translateValueId += '.';
          translateValueId += isInitReleveEnabled
            ? 'InitReleveGasEnabled'
            : 'InitAppointGasEnabled';
        }

        return translateValueId;
      }),
      translateValue: true
    },
    {
      labelId: 'description.coldProductionSolutions',
      values: projectAndChildren.map((project) => {
        const isInitHPACoolingEnabled = getSafeData(
          () => project.otherValues.InitHPACoolingEnabled
        );
        const isItesEnabled = getSafeData(
          () => project.otherValues.ItesEnabled
        );

        if (isInitHPACoolingEnabled && isItesEnabled) return 'ItesEnabled';
        if (isInitHPACoolingEnabled && !isItesEnabled) return 'hpa';
        return 'NONE';
      }),
      translateValue: true
    },
    {
      labelId: 'description.InitHPAInjectionEnabled',
      values: projectAndChildren.map((project) =>
        getSafeData(() => project.otherValues.InitHPAInjectionEnabled)
      ),
      translateValue: true
    }
  ]
});

export const sstTemplate = (i18n, substation) => {
  const { InitStationName, InitStationUse, InitBuildingSurface } = substation;
  return {
    title: InitStationName,
    rows: [
      {
        label: i18n._('description.InitStationUse'),
        value: InitStationUse,
        translateValue: true
      },
      {
        label: i18n._('description.InitBuildingSurface'),
        value: InitBuildingSurface,
        unit: SST.InitBuildingSurface.unit
      }
    ]
  };
};

export const stdTemplate = (projectAndChildren, sstIndex) => ({
  descriptions: projectAndChildren.map((project) => project.description),
  files: projectAndChildren.map((project) =>
    getSafeData(() => project.substations[sstIndex].filesStdOriginalName)
  ),
  rows: [
    {
      labelId: 'std.heat.sum',
      values: projectAndChildren.map((project) =>
        getSafeData(
          () => project.substations[sstIndex].SummaryData.HEAT_NEED.SUM
        )
      ),
      unit: UNIT.KILOWATT_HOUR_PER_YEAR
    },
    {
      labelId: 'std.heat.max',
      values: projectAndChildren.map((project) =>
        getSafeData(
          () => project.substations[sstIndex].SummaryData.HEAT_NEED.MAX
        )
      ),
      unit: UNIT.KILOWATT
    },
    {
      labelId: 'std.cold.sum',
      values: projectAndChildren.map((project) =>
        getSafeData(
          () => project.substations[sstIndex].SummaryData.COLD_NEED.SUM
        )
      ),
      unit: UNIT.KILOWATT_HOUR_PER_YEAR
    },
    {
      labelId: 'std.cold.max',
      values: projectAndChildren.map((project) =>
        getSafeData(
          () => project.substations[sstIndex].SummaryData.COLD_NEED.MAX
        )
      ),
      unit: UNIT.KILOWATT
    },
    {
      labelId: 'std.hotwater.sum',
      values: projectAndChildren.map((project) =>
        getSafeData(
          () => project.substations[sstIndex].SummaryData.HOTWATER_NEED.SUM
        )
      ),
      unit: UNIT.KILOWATT_HOUR_PER_YEAR
    },
    {
      labelId: 'std.hotwater.max',
      values: projectAndChildren.map((project) =>
        getSafeData(
          () => project.substations[sstIndex].SummaryData.HOTWATER_NEED.MAX
        )
      ),
      unit: UNIT.KILOWATT
    }
  ]
});
