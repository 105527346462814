import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import CompareResultsCards from '../../../../../components/CompareResultsCards/CompareResultsCards.jsx';
import CompareResultsTable from '../../../../../components/CompareResultsTable/CompareResultsTable.jsx';
import ExpandableTable from '../../../../../components/ExpandableTable/ExpandableTable';
import Section from '../../../../../components/Section/Section';
import ResultContext from '../../../../../contexts/ResultContext';
import BtesEnergyBalanceTable from './components/BtesEnergyBalanceTable';
import BtesTemperatureLinesChart from './components/BtesTemperatureLinesChart';
import BtesTemperatureLinesChartPerYear from './components/BtesTemperatureLinesChartPerYear';
import {
  btesEnergybalanceTemplate,
  btesEvolutionTemplate,
  btesParametersTemplate
} from './templates';

const GeomodelingSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { comparedResults, loadDetails } = useContext(ResultContext);
  //#endregion

  //#region [memos]
  const BEBTemplate = useMemo(() => {
    return btesEnergybalanceTemplate(i18n, comparedResults);
  }, [comparedResults]);

  const BETemplate = useMemo(() => {
    return btesEvolutionTemplate(i18n, comparedResults);
  }, [comparedResults]);

  const BPTemplate = useMemo(() => {
    return btesParametersTemplate(i18n, comparedResults);
  }, [comparedResults]);
  //#endregion

  //#region [render]
  return (
    <Section title={i18n._('results.geomodeling')} open>
      <ExpandableTable
        level={2}
        title={i18n._('results.geomodeling.btesEnergybalance')}
      >
        <CompareResultsCards
          title={i18n._('results.geomodeling.btesEnergybalance.table.title')}
          element={BtesEnergyBalanceTable}
        />
      </ExpandableTable>
      <CompareResultsTable level={2} template={BEBTemplate} />
      <CompareResultsTable level={2} template={BETemplate} />
      <ExpandableTable>
        <CompareResultsCards
          title={i18n._(
            'results.geomodeling.btesEvolution.btesTempLinesChartPerYear.title'
          )}
          element={BtesTemperatureLinesChartPerYear}
          onOpen={loadDetails}
        />
        <CompareResultsCards
          title={i18n._(
            'results.geomodeling.btesEvolution.btesTempLinesChart.title'
          )}
          element={BtesTemperatureLinesChart}
          onOpen={loadDetails}
        />
      </ExpandableTable>
      <CompareResultsTable level={2} template={BPTemplate} />
    </Section>
  );
  //#endregion
};

export default GeomodelingSection;
