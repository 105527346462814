import { useLingui } from '@lingui/react';
import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Header from '../../components/Header/Header';
import Modal from '../../components/Modal/Modal';
import Toast from '../../components/Toast/Toast';
import PopupContext from '../../contexts/PopupContext';

const RootPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [toast, setToast] = useState();
  const [modal, setModal] = useState();
  //#endregion

  //#region [methods]
  const openToast = (title, body, level = 'danger') => {
    setToast({ title, body, level });
  };

  const openErrorToast = (err) => {
    const status = err.response?.status || err.status;
    const title = status ? i18n._('statusError', { status }) : i18n._('error');
    const body =
      err.response?.data?.message ||
      err.response?.data?.error ||
      err.message ||
      i18n._('error.unknown');
    openToast(title, body);
  };

  const openInfoModal = (title, body) => {
    setModal({ title, body });
  };

  const openConfirmModal = (title, body, level, action) => {
    setModal({
      title,
      body,
      level,
      action
    });
  };
  //#endregion

  //#region [render]
  return (
    <div>
      <PopupContext.Provider
        value={{
          openToast,
          openErrorToast,
          openInfoModal,
          openConfirmModal
        }}
      >
        <Header />
        <Outlet />
      </PopupContext.Provider>
      <Modal
        isOpen={!!modal}
        level={modal?.level}
        header={modal?.title}
        body={modal?.body}
        action={modal?.action}
        onClose={() => setModal(null)}
      />
      <Toast
        isOpen={!!toast}
        level={toast?.level}
        header={toast?.title}
        body={toast?.body}
        onClose={() => setToast(null)}
      />
    </div>
  );
  //#endregion
};

export default RootPage;
