import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import ReactTooltip from 'react-tooltip';
import OptimizationContext from '../../../../contexts/OptimizationContext';
import { isNull } from '../../../../utils/data.utils';
import { getMinMaxErrorCode } from '../../../../utils/error.utils';

const MinMaxSelects = ({ label, param }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { calculationData, setBounds, addError, removeError } =
    useContext(OptimizationContext);
  //#endregion

  //#region [states]
  const [minValue, setMinValue] = useState();
  const [maxValue, setMaxValue] = useState();
  const [minError, setMinError] = useState();
  const [maxError, setMaxError] = useState();
  const [minClassName, setMinClassName] = useState('');
  const [maxClassName, setMaxClassName] = useState('');
  //#endregion

  //#region [refs]
  const inputMinRef = useRef();
  const inputMaxRef = useRef();
  //#endregion

  //#region [effects]
  useEffect(() => {
    if (!minError) return;
    ReactTooltip.show(inputMinRef.current);
  }, [minError]);

  useEffect(() => {
    if (!maxError) return;
    ReactTooltip.show(inputMaxRef.current);
  }, [maxError]);

  useEffect(() => {
    const minValue = calculationData.constraints.bounds[0][param.key];
    setMinValue(() => +minValue);

    const maxValue = calculationData.constraints.bounds[1][param.key];
    setMaxValue(() => +maxValue);

    manageMinMaxChanges(minValue, maxValue);
  }, [
    calculationData.constraints.bounds[0][param.key],
    calculationData.constraints.bounds[1][param.key]
  ]);
  //#endregion

  //#region [methods]
  const resetErrors = () => {
    if (!minError && !maxError) return;
    removeError();
    setMinError(() => null);
    setMaxError(() => null);
  };

  const manageMinMaxError = (errorCode) => {
    if (!errorCode) return;
    addError();
    setMinError(() => errorCode);
    setMaxError(() => errorCode);
    setMinClassName('form-input-error');
    setMaxClassName('form-input-error');
  };

  const manageMinMaxChanges = (min, max) => {
    const isEmpty = (val) => isNull(val) || val === '';
    const manageChange = (val, defaultParamValue, setClassName) => {
      setClassName(
        (isEmpty(val) && isEmpty(defaultParamValue)) || val == defaultParamValue
          ? ''
          : 'form-input-changed'
      );
    };
    manageChange(min, param.default[0], setMinClassName);
    manageChange(max, param.default[1], setMaxClassName);
  };

  const handleInputChange = (min, max) => {
    resetErrors();
    setMinValue(() => min);
    setMaxValue(() => max);

    const errorCode = getMinMaxErrorCode(min, max);
    manageMinMaxError(errorCode);
    if (errorCode) return;

    setBounds(param.key, [min, max]);
    manageMinMaxChanges(min, max);
  };
  //#endregion

  //#region [render]
  const inputMinId = `input_min_${param.key}`;
  const inputMaxId = `input_max_${param.key}`;
  return (
    <Row>
      <Col className='opti-inputs-label' xs={6}>
        {label}
      </Col>
      <Col xs={3}>
        <Form.Select
          className={`${minClassName} form-input`}
          onChange={(evt) => handleInputChange(+evt.target.value, +maxValue)}
          value={!isNull(minValue) ? minValue : ''}
          data-tip
          data-for={inputMinId}
          ref={inputMinRef}
        >
          {param.values.map((value) => (
            <option key={param.key + '_min_value_' + value} value={value}>
              {value}
            </option>
          ))}
        </Form.Select>
        {minError && (
          <ReactTooltip
            id={inputMinId}
            className='error-tooltip'
            delayHide={3000}
            effect='solid'
            place='bottom'
          >
            {i18n._(minError.translateId, minError.translateParams)}
          </ReactTooltip>
        )}
      </Col>
      <Col xs={3}>
        <Form.Select
          className={`${maxClassName} form-input`}
          onChange={(evt) => handleInputChange(+minValue, +evt.target.value)}
          value={!isNull(maxValue) ? maxValue : ''}
          data-tip
          data-for={inputMaxId}
          ref={inputMaxRef}
        >
          {param.values.map((value) => (
            <option key={param.key + '_max_value_' + value} value={value}>
              {value}
            </option>
          ))}
        </Form.Select>
        {maxError && (
          <ReactTooltip
            id={inputMaxId}
            className='error-tooltip'
            delayHide={3000}
            effect='solid'
            place='bottom'
          >
            {i18n._(maxError.translateId, maxError.translateParams)}
          </ReactTooltip>
        )}
      </Col>
    </Row>
  );
  //#endregion
};

export default MinMaxSelects;
