import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState
} from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchProjects } from '../../../api/company.api';
import { restoreProject } from '../../../api/project.api';
import ProjectsList from '../../../components/ProjectsList/ProjectsList';
import CompanyContext from '../../../contexts/CompanyContext';
import PopupContext from '../../../contexts/PopupContext';
import defaultProjectImg from '../../../image/default_project.png';
import { formatDate, formatHour } from '../../../utils/date.utils';

const DeletedProjects = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  const { openConfirmModal, openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [deletedProjects, setDeletedProjects] = useState([]);
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        await getProjects();
      } catch (err) {
        console.error(err);
        if (err.response?.status === 404) {
          navigate('/notfound');
        } else {
          openErrorToast(err);
        }
      }
    })();
  }, [selectedCompany.id, getProjects]);
  //#endregion

  //#region [callbacks]
  const getProjects = useCallback(async () => {
    try {
      const projects = await fetchProjects(selectedCompany.id, false);
      setDeletedProjects(projects);
    } catch (err) {
      throw err;
    }
  }, [selectedCompany.id]);
  //#endregion

  //#region [methods]
  const handleRestoreProject = async (projectIdToRestore) => {
    try {
      await restoreProject(projectIdToRestore);
      await getProjects();
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [memos]
  const columns = useMemo(
    () => [
      {
        id: 'AhsName',
        accessor: 'AhsName',
        Header: () => (
          <span className='projects-table-name-header'>
            {i18n._('projects.header.name')}
          </span>
        ),
        Cell: (props) => {
          const { AhsName, ahsImageFileName } = props.row.original;
          const imgSrc = ahsImageFileName
            ? `/api-v1/images/${encodeURI(ahsImageFileName)}`
            : defaultProjectImg;

          return (
            <div className='projects-table-name-body'>
              <img
                className={`projects-table-img ${
                  ahsImageFileName ? 'projects-table-rounded-img' : ''
                } `}
                src={imgSrc}
                alt='projectImg'
              />
              <div
                className='projects-table-name two-lines-max'
                title={AhsName}
              >
                {AhsName}
              </div>
            </div>
          );
        }
      },
      {
        id: 'AhsDescription',
        accessor: 'AhsDescription',
        Header: () => <span>{i18n._('projects.header.description')}</span>,
        Cell: (props) => {
          const { AhsDescription } = props.row.original;
          return (
            <div className='two-lines-max' title={AhsDescription}>
              {AhsDescription}
            </div>
          );
        }
      },
      {
        id: 'updated_at',
        accessor: 'updated_at',
        Header: () => <span>{i18n._('projects.header.update')}</span>,
        Cell: (props) => {
          const { updated_at } = props.row.original;
          return <div>{formatDate(new Date(updated_at), i18n.language)}</div>;
        },
        sortType: (a, b) =>
          new Date(b.original.updated_at) - new Date(a.original.updated_at)
      },
      {
        id: 'lastCompute',
        accessor: 'LastComputeDate',
        Header: () => <span>{i18n._('projects.header.lastCompute')}</span>,
        Cell: (props) => {
          const { LastComputeDate, lastComputeUserName } = props.row.original;
          let lastCompute;
          if (LastComputeDate) {
            const date = new Date(LastComputeDate);
            lastCompute = i18n._('projects.lastCompute', {
              username:
                lastComputeUserName === '_user_ _deleted_'
                  ? i18n._('projects.userDeleted')
                  : lastComputeUserName,
              date: formatDate(date, i18n.language),
              hour: formatHour(date, i18n.language)
            });
          }
          return (
            <div className='two-lines-max' title={lastCompute}>
              {lastCompute}
            </div>
          );
        }
      },
      {
        id: 'actions',
        Header: () => <span>{i18n._('projects.header.actions')}</span>,
        Cell: (props) => {
          const { AhsID } = props.row.original;
          return (
            <div className='projects-table-actions'>
              <FontAwesomeIcon
                icon='trash-can-arrow-up'
                className='projects-table-actions-restore'
                onClick={() =>
                  openConfirmModal(
                    i18n._('projects.restoreProject.title'),
                    i18n._('projects.restoreProject.body'),
                    'warning',
                    async () => await handleRestoreProject(AhsID)
                  )
                }
                title={i18n._('projects.action.restore')}
              />
            </div>
          );
        },
        isSorted: false
      }
    ],
    []
  );
  //#endregion

  //#region [render]
  return <ProjectsList projects={deletedProjects} columns={columns} />;
  //#endregion
};

export default DeletedProjects;
