import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { OTHER_OPEX_ACCENTA } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import CostPageContext from '../../../../contexts/CostPageContext';
import { getDefaultValue } from '../../../../utils/project.utils';

const SolutionOtherOpexBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleOtherValueChange } =
    useContext(CostPageContext);
  //#endregion

  //#region [render]
  const {
    InitOpexGaPerf,
    InitOpexGaPerfEvol,
    InitOpexMaint,
    InitOpexMaintEvol,
    InitOpexOther,
    InitOpexOtherEvol,
    InitYearsBeforeStart
  } = tempProject.otherValues;
  return (
    <Bloc title={i18n._('cost.solutionOtherOpex')}>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitOpexGaPerf')}
            value={InitOpexGaPerf}
            param={OTHER_OPEX_ACCENTA.InitOpexGaPerf}
            onBlur={(value) => handleOtherValueChange('InitOpexGaPerf', value)}
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_OPEX_ACCENTA.InitOpexGaPerf
            )}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitOpexGaPerfEvol')}
            value={InitOpexGaPerfEvol}
            param={OTHER_OPEX_ACCENTA.InitOpexGaPerfEvol}
            onBlur={(value) =>
              handleOtherValueChange('InitOpexGaPerfEvol', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_OPEX_ACCENTA.InitOpexGaPerfEvol
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitOpexMaint')}
            value={InitOpexMaint}
            param={OTHER_OPEX_ACCENTA.InitOpexMaint}
            onBlur={(value) => handleOtherValueChange('InitOpexMaint', value)}
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, OTHER_OPEX_ACCENTA.InitOpexMaint)}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitOpexMaintEvol')}
            value={InitOpexMaintEvol}
            param={OTHER_OPEX_ACCENTA.InitOpexMaintEvol}
            onBlur={(value) =>
              handleOtherValueChange('InitOpexMaintEvol', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_OPEX_ACCENTA.InitOpexMaintEvol
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitOpexOther')}
            value={InitOpexOther}
            param={OTHER_OPEX_ACCENTA.InitOpexOther}
            onBlur={(value) => handleOtherValueChange('InitOpexOther', value)}
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, OTHER_OPEX_ACCENTA.InitOpexOther)}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitOpexOtherEvol')}
            value={InitOpexOtherEvol}
            param={OTHER_OPEX_ACCENTA.InitOpexOtherEvol}
            onBlur={(value) =>
              handleOtherValueChange('InitOpexOtherEvol', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_OPEX_ACCENTA.InitOpexOtherEvol
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormInput
            label={i18n._('cost.InitYearsBeforeStart')}
            value={InitYearsBeforeStart}
            param={OTHER_OPEX_ACCENTA.InitYearsBeforeStart}
            onBlur={(value) =>
              handleOtherValueChange('InitYearsBeforeStart', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_OPEX_ACCENTA.InitYearsBeforeStart
            )}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default SolutionOtherOpexBloc;
