const ST_EQUIPMENT = Object.freeze({
  FAFCO: {
    labelId: 'stModel.fafco',
    value: 'FAFCO 1N',
    linkedPv: 'CUSTOM',
    data: {
      InitSolarThermalB1: 12.58,
      InitSolarThermalB2: 3.59,
      InitSolarThermalBu: 0.044,
      InitSolarThermalEpsa: 0.85,
      InitSolarThermalFlowRate: 0.02,
      InitSolarThermalHeta0: 0.887,
      InitSolarThermalS: 3,
      InitSolarThermalHeadlossMceMax: 0.09
    }
  },
  HELOIPAC: {
    labelId: 'stModel.heliopac',
    value: 'Heliopac Solerpool',
    linkedPv: 'CUSTOM',
    data: {
      InitSolarThermalB1: 7.61,
      InitSolarThermalB2: 16.23,
      InitSolarThermalBu: 0.052,
      InitSolarThermalEpsa: 0.85,
      InitSolarThermalFlowRate: 0.04,
      InitSolarThermalHeta0: 1.11,
      InitSolarThermalS: 3,
      InitSolarThermalHeadlossMceMax: 0.09
    }
  },
  EUROVIA: {
    labelId: 'stModel.eurovia',
    value: 'EUROVIA POWER ROAD',
    linkedPv: 'CUSTOM',
    data: {
      InitSolarThermalB1: 6.553,
      InitSolarThermalB2: 0.0676,
      InitSolarThermalBu: 0.199,
      InitSolarThermalEpsa: 0.85,
      InitSolarThermalFlowRate: 0.04,
      InitSolarThermalHeta0: 0.2,
      InitSolarThermalS: 19.2,
      InitSolarThermalHeadlossMceMax: 0.09,
      InitSolarTemperatureMin: 1,
      InitSolarDeltaTemp: 4
    }
  },
  DUALSUN: {
    // Plus utilisé : il est affiché en front car présent dans des projets, mais n'est plus sélectionnable.
    labelId: 'stModel.dualsun',
    value: 'DUALSUN SPRING',
    linkedPv: 'DUALSUN SPRING',
    data: {
      InitSolarThermalB1: 11.02,
      InitSolarThermalB2: 4.802,
      InitSolarThermalBu: 0.0192,
      InitSolarThermalEpsa: 0.62,
      InitSolarThermalFlowRate: 0.02,
      InitSolarThermalHeta0: 0.53,
      InitSolarThermalS: 1.65,
      InitSolarThermalHeadlossMceMax: 0.09,
      InitSolarTemperatureMin: -3,
      InitSolarDeltaTemp: 4
    },
    disabled: true
  },
  DUALSUN_SPRING4_NON_INSULATED: {
    labelId: 'stModel.dualsun.spring4.nonInsulated',
    value: 'DUALSUN SPRING4 not insulated',
    linkedPv: 'DUALSUN SPRING4',
    data: {
      InitSolarThermalB1: 13,
      InitSolarThermalB2: 5.6,
      InitSolarThermalBu: 0.0394,
      InitSolarThermalEpsa: 0.62,
      InitSolarThermalFlowRate: 0.014,
      InitSolarThermalHeta0: 0.508,
      InitSolarThermalS: 1.95,
      InitSolarThermalHeadlossMceMax: 0.026,
      InitSolarTemperatureMin: -3,
      InitSolarDeltaTemp: 4
    }
  },
  DUALSUN_SPRING4_WITH_FINS: {
    labelId: 'stModel.dualsun.spring4.withFins',
    value: 'DUALSUN SPRING4 with fins',
    linkedPv: 'DUALSUN SPRING4',
    data: {
      InitSolarThermalB1: 15.5,
      InitSolarThermalB2: 7.3,
      InitSolarThermalBu: 0.0883,
      InitSolarThermalEpsa: 0.62,
      InitSolarThermalFlowRate: 0.014,
      InitSolarThermalHeta0: 0.521,
      InitSolarThermalS: 1.95,
      InitSolarThermalHeadlossMceMax: 0.026,
      InitSolarTemperatureMin: -3,
      InitSolarDeltaTemp: 4
    }
  },
  CUSTOM: {
    labelId: 'stModel.custom',
    value: 'CUSTOM',
    linkedPv: 'CUSTOM',
    data: {
      InitSolarThermalB1: 12.58,
      InitSolarThermalB2: 3.49,
      InitSolarThermalBu: 0.044,
      InitSolarThermalEpsa: 0.85,
      InitSolarThermalFlowRate: 0.02,
      InitSolarThermalHeta0: 0.887,
      InitSolarThermalHeadlossMceMax: 0.09,
      InitSolarTemperatureMin: -3,
      InitSolarDeltaTemp: 4
    }
  }
});

const PV_EQUIPMENT = Object.freeze({
  VERTEX: {
    labelId: 'pvModel.vertex',
    value: 'Vertex S - Trina Solar 400',
    linkedSt: 'CUSTOM',
    data: {
      InitPVEfficiency: 0.208,
      InitPVModuleType: 'silicium_monocrystal',
      InitPVTemperatureCoefGammaModule: -0.0034,
      InitPVHybridEnabled: false
    }
  },
  DUALSUN: {
    // Plus utilisé : il est affiché en front car présent dans des projets, mais n'est plus sélectionnable.
    labelId: 'pvModel.dualsun',
    value: 'DUALSUN SPRING',
    linkedSt: 'DUALSUN SPRING',
    data: {
      InitPVEfficiency: 0.2,
      InitPVModuleType: 'silicium_monocrystal',
      InitPVTemperatureCoefGammaModule: -0.0034,
      InitPVHybridEnabled: true
    },
    disabled: true
  },
  DUALSUN_SPRING4: {
    labelId: 'pvModel.dualsun.spring4',
    value: 'DUALSUN SPRING4',
    linkedSt: 'DUALSUN SPRING4 not insulated',
    data: {
      InitPVEfficiency: 0.218,
      InitPVModuleType: 'silicium_monocrystal',
      InitPVTemperatureCoefGammaModule: -0.0031,
      InitPVHybridEnabled: true
    }
  },
  CUSTOM: {
    labelId: 'pvModel.custom',
    value: 'CUSTOM',
    linkedSt: 'CUSTOM',
    data: {
      InitPVEfficiency: 0.15,
      InitPVModuleType: 'silicium_monocrystal',
      InitPVTemperatureCoefGammaModule: -0.0034,
      InitPVHybridEnabled: false
    }
  }
});

const PV_MOUNTING_TYPE_EQUIPMENT = Object.freeze({
  CLOSE_MOUNT: {
    labelId: 'mountingType.closeMountGlassGlass',
    value: 'close_mount_glass_glass'
  },
  OPEN_GLASS_GLASS: {
    labelId: 'mountingType.openRackGlassGlass',
    value: 'open_rack_glass_glass'
  },
  OPEN_GLASS_POLYMER: {
    labelId: 'mountingType.openRackGlassPolymer',
    value: 'open_rack_glass_polymer'
  },
  INSULATED_GLASS_POLYMER: {
    labelId: 'mountingType.insulatedBackGlassPolymer',
    value: 'insulated_back_glass_polymer'
  }
});

const ITES_EQUIPMENT = Object.freeze({
  FAFCO: {
    labelId: 'itesModel.fafco',
    value: 'Fafco Icebat'
  }
});

module.exports = {
  ITES_EQUIPMENT,
  PV_EQUIPMENT,
  PV_MOUNTING_TYPE_EQUIPMENT,
  ST_EQUIPMENT
};
