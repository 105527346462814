import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useState } from 'react';
import './ExpandableProperty.css';

const ExpandableProperty = ({ expanded, title, children, level }) => {
  //#region [states]
  const [isOpen, setIsOpen] = useState(!!expanded);
  //#endregion

  //#region [render]
  return (
    <Fragment>
      <tr className='cursor-pointer' onClick={() => setIsOpen(!isOpen)}>
        <td colSpan={level === 0 ? (isOpen ? 2 : 1) : 2}>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              paddingLeft: `${level * 10}px`
            }}
          >
            <FontAwesomeIcon
              icon={isOpen ? 'caret-square-down' : 'caret-square-right'}
              className='expandable-prop-arrow'
            />
            <span>{title}</span>
          </div>
        </td>
      </tr>
      {isOpen ? children : null}
    </Fragment>
  );
  //#endregion
};

export default ExpandableProperty;
