const HP_COLS = Object.freeze({
  MODE: {
    mandatory: true,
    name: 'Mode'
  },
  SOURCE: {
    mandatory: true,
    name: 'Source'
  },
  TEVAP: {
    mandatory: true,
    name: 'Tevap'
  },
  TCOND: {
    mandatory: true,
    name: 'Tcond'
  },
  COP_EER: {
    mandatory: true,
    name: 'COP_EER_EN14511'
  },
  PELEC: {
    mandatory: false,
    name: 'Pelec'
  },
  PTHERM: {
    mandatory: false,
    name: 'Ptherm'
  }
});

module.exports = { HP_COLS };
