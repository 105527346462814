import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import PopupContext from '../../contexts/PopupContext';
import ResultContext from '../../contexts/ResultContext';
import { isArrNullOrEmpty } from '../../utils/data.utils';
import SectionTitle from '../SectionTitle';
import './ExpandableTable.css';

const ExpandableTable = ({ title, level, children }) => {
  //#region [contexts]
  const { comparedResults } = useContext(ResultContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  if (!children) {
    children = [];
  } else if (!Array.isArray(children)) {
    // if component has only one child then 'children' is not an array. We then convert children to Array(1)
    children = [children];
  }

  //#region [states]
  const [visibilities, setVisibilities] = useState(
    children.map((child) => !!child.props.open)
  );
  const [loaders, setLoaders] = useState(children.map((_) => false));
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        for (let i = 0; i < children.length; ++i) {
          if (visibilities[i]) await handleChildOpen(i);
        }
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, [comparedResults.length]);
  //#endregion

  //#region [methods]
  const handleChildOpen = async (index) => {
    try {
      if (!children[index].props.onOpen) return;
      setLoaders((loaders) => {
        loaders[index] = true;
        return [...loaders];
      });
      await children[index].props.onOpen();
      setLoaders((loaders) => {
        loaders[index] = false;
        return [...loaders];
      });
    } catch (err) {
      throw err;
    }
  };

  const handleTitleClick = async (index) => {
    try {
      await handleChildOpen(index);
      setVisibilities((visibilities) => {
        visibilities[index] = !visibilities[index];
        return [...visibilities];
      });
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  return isArrNullOrEmpty(children) ? null : (
    <div>
      {title && <SectionTitle title={title} level={level} />}
      <table className='expandable-table'>
        <tbody>
          {children.map((child, index) =>
            !child ? null : (
              <Fragment key={'visualElementRow_' + index}>
                <tr
                  onClick={async () => await handleTitleClick(index)}
                  className='alternate-two-rows cursor-pointer'
                >
                  <td>
                    <div className='expandable-table-child-title-td'>
                      <FontAwesomeIcon
                        icon={
                          visibilities[index]
                            ? 'caret-square-down'
                            : 'caret-square-right'
                        }
                      />
                      {child.props.title}
                      {loaders[index] && <Spinner animation='border' />}
                    </div>
                  </td>
                </tr>
                <tr
                  className={
                    visibilities[index] ? 'alternate-two-rows' : 'hidden'
                  }
                >
                  <td>{child}</td>
                </tr>
              </Fragment>
            )
          )}
        </tbody>
      </table>
    </div>
  );
  //#endregion
};

export default ExpandableTable;
