// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.opti-goals-section-caption {
    font-size: 11px;
    color: var(--medium-grey);
    padding-left: 10px;
    -webkit-box-decoration-break: clone;
    box-decoration-break: clone;
}

.opti-goals-counter {
    padding-left: 10px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 5px;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}`, "",{"version":3,"sources":["webpack://./src/pages/design/compute/components/ComputeForm/optimization/sections/OptiGoalsSection/OptiGoalsSection.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,yBAAyB;IACzB,kBAAkB;IAClB,mCAAmC;IACnC,2BAA2B;AAC/B;;AAEA;IACI,kBAAkB;IAClB,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,QAAQ;IACR,yBAAyB;QACrB,sBAAsB;YAClB,mBAAmB;AAC/B","sourcesContent":[".opti-goals-section-caption {\n    font-size: 11px;\n    color: var(--medium-grey);\n    padding-left: 10px;\n    -webkit-box-decoration-break: clone;\n    box-decoration-break: clone;\n}\n\n.opti-goals-counter {\n    padding-left: 10px;\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    gap: 5px;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
