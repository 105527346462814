import { useLingui } from '@lingui/react';
import { cloneDeep } from 'lodash';
import React, { useContext, useEffect, useState } from 'react';
import { Alert, Button, Spinner } from 'react-bootstrap';
import { CALCULATION_STATUS } from '../../../../../../../server/constants/calculation.constant';
import { COMPUTE_STATUS } from '../../../../../../../server/constants/compute.constant';
import { COMPUTE_TYPE } from '../../../../../../../server/constants/constraint.constant';
import { SOCKET_MSG_TYPE } from '../../../../../../../server/constants/socket.constant';
import { fetchCalculation } from '../../../../../api/compute.api';
import {
  cancelCalculation,
  checkForMissingData,
  createCalculation,
  fetchCurrentCalculation,
  fetchLastCalculationError
} from '../../../../../api/project.api';
import OpaqueLayer from '../../../../../components/OpaqueLayer/OpaqueLayer';
import PopupContext from '../../../../../contexts/PopupContext';
import ProjectContext from '../../../../../contexts/ProjectContext';
import ws from '../../../../../tools/ws';
import {
  isArrNullOrEmpty,
  isObjNullOrEmpty
} from '../../../../../utils/data.utils';
import Calculation from '../Calculation/Calculation';
import EngineErrorCard from '../ErrorCard/EngineErrorCard';
import MissingDataErrorCard from '../ErrorCard/MissingDataErrorCard';
import './CalculationForm.css';

const CalculationForm = ({
  calculationData,
  nbErrors,
  onResultsRefresh,
  children
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { project } = useContext(ProjectContext);
  const { openToast, openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [missingData, setMissingData] = useState();
  const [calculation, setCalculation] = useState();
  const [engineError, setEngineError] = useState(project.LastCalculationError);
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        const data = await checkForMissingData(project.AhsID);
        setMissingData(isObjNullOrEmpty(data) ? null : data);
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, [project.AhsID]);

  useEffect(() => {
    (async () => {
      try {
        await fetchCalc();
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, [project.AhsID, calculationData.resultId]);

  useEffect(() => {
    ws.connect(`ws_room_project_${project.AhsID}`, async (data) => {
      try {
        switch (data.type) {
          case SOCKET_MSG_TYPE.COMPUTE_REFRESH:
            await fetchCalc();
            break;
          case SOCKET_MSG_TYPE.COMPUTE_PROGRESS:
            handleComputeProgress(
              data.msg.computeId,
              data.msg.type,
              data.msg.message,
              data.msg.projectId,
              data.msg.goalConstraint,
              data.msg.progressData
            );
            break;
          case SOCKET_MSG_TYPE.CONNECTING_ERROR:
            openToast(
              i18n._('error.engine.connect.title'),
              i18n._('error.engine.connect.body')
            );
            await fetchCalc();
            break;
          case SOCKET_MSG_TYPE.COMPUTE_RESULT:
            await fetchCalc();
            await onResultsRefresh();
            break;
          case SOCKET_MSG_TYPE.COMPUTE_ERROR:
            await fetchCalc();
            await handleComputeError();
            break;
        }
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    });

    return () => ws.disconnect(`ws_room_project_${project.AhsID}`);
  }, [project.AhsID]);
  //#endregion

  //#region [methods]
  const fetchCalc = async () => {
    try {
      // on récupère le calculation en cours
      let calc = await fetchCurrentCalculation(project.AhsID);

      // pas de calculation en cours
      if (!calc) {
        // il n'y a pas encore de résultats sur ce projet
        if (!calculationData.resultId) return;

        // on récupère le calculation lié au calcul sélectionné
        calc = await fetchCalculation(calculationData.resultId);
      }

      // on met à jour le nom des descriptions du calculation
      if (calc && !isArrNullOrEmpty(calc.descriptions)) {
        calc.descriptions.forEach((desc) => {
          desc.name = !isFinite(desc.name)
            ? desc.name
            : i18n._('description', { index: desc.name });
        });
      }
      setCalculation(calc);
    } catch (err) {
      throw err;
    }
  };

  const handleStartClick = async (calculationData) => {
    try {
      // l'utilisateur lance un calcul
      if (engineError) setEngineError(null);
      await createCalculation(project.AhsID, calculationData);

      // on envoie un message au serveur pour savoir si le front et le moteur sont connnectés
      ws.send(`ws_room_project_${project.AhsID}`, {
        type: SOCKET_MSG_TYPE.LOAD,
        AhsID: project.AhsID
      });

      // le status du calculation passe à CONNECTING
      setCalculation(() => ({
        ...calculation,
        status: CALCULATION_STATUS.CONNECTING
      }));
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleCancelClick = async () => {
    try {
      // l'utilisateur annule un calcul
      const calculationId = await cancelCalculation(project.AhsID);

      // on envoie un message au serveur pour qu'il demande au moteur d'annuler le calcul
      ws.send(`ws_room_project_${project.AhsID}`, {
        type: SOCKET_MSG_TYPE.CANCEL,
        AhsID: project.AhsID,
        calculationId
      });

      await fetchCalc();
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleComputeProgress = (
    computeId,
    computeType,
    message,
    projectId,
    goalConstraint,
    progressData
  ) => {
    setCalculation((calc) => {
      const calcCopy = cloneDeep(calc);
      const { descriptions } = calcCopy;

      const descIndex = descriptions.findIndex(
        (desc) => desc.projectId === projectId
      );
      if (descIndex === -1) return calc;
      const compIndex = descriptions[descIndex].computes.findIndex(
        (comp) => comp.computeId === computeId
      );
      if (compIndex === -1) return calc;

      calcCopy.descriptions[descIndex].computes[compIndex].type = computeType;
      calcCopy.descriptions[descIndex].computes[compIndex].status =
        COMPUTE_STATUS.IN_PROGRESS;
      calcCopy.descriptions[descIndex].computes[compIndex].message = message;
      if (computeType === COMPUTE_TYPE.SIMU) return calcCopy;

      if (goalConstraint) {
        calcCopy.descriptions[descIndex].computes[compIndex].goalConstraint =
          goalConstraint;
      }
      if (progressData) {
        const { dataviz, percent } = progressData;
        calcCopy.descriptions[descIndex].computes[compIndex].dataviz = dataviz;
        calcCopy.descriptions[descIndex].computes[compIndex].percent = percent;
      }

      return calcCopy;
    });
  };

  const handleComputeError = async () => {
    try {
      const error = await fetchLastCalculationError(project.AhsID);
      setEngineError(error);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  return (
    <div className='calculation-form-wrapper'>
      <div className='calculation-form'>
        <OpaqueLayer
          visible={
            calculation?.status &&
            calculation?.status !== CALCULATION_STATUS.FINISHED
          }
        >
          <Alert animation='border' variant='primary'>
            {i18n._('calcBtn.status.inProgress')}
            <Spinner variant='light' />
          </Alert>
        </OpaqueLayer>
        {children}
      </div>
      {missingData && <MissingDataErrorCard missingData={missingData} />}
      <div className='calculation-form-btns'>
        {!!calculation?.status &&
        calculation?.status !== CALCULATION_STATUS.FINISHED ? (
          <Button variant='primary' className='calculation-form-btn' disabled>
            {i18n._('calcBtn.status.inProgress')}
          </Button>
        ) : (
          <Button
            variant='primary'
            className='calculation-form-btn'
            onClick={async () => await handleStartClick(calculationData)}
            disabled={!!nbErrors || !!missingData}
          >
            {i18n._('calcBtn.status.start')}
          </Button>
        )}
        <Button
          variant='outline-secondary'
          className='calculation-form-btn'
          disabled={calculation?.status !== CALCULATION_STATUS.IN_PROGRESS}
          onClick={async () => await handleCancelClick()}
        >
          {i18n._('cancelBtn.stop')}
        </Button>
      </div>
      {engineError && <EngineErrorCard error={engineError} />}
      {calculation && <Calculation calculation={calculation} />}
    </div>
  );
  //#endregion
};

export default CalculationForm;
