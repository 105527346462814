export const MODE = Object.freeze({
  LINES: 'lines',
  LINES_MARKERS: 'lines+markers',
  MARKERS: 'markers',
  NONE: 'none'
});

export const TYPE = Object.freeze({
  SCATTER: 'scatter'
});
