// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.descriptions-btns {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    gap: 15px;
    position: relative;
    margin-top: 15px;
}

.descriptions-previous-btn {
    position: absolute;
    top: 0;
    left: 0;
}

.descriptions-previous-btn>svg {
    margin-right: 7px;
}

.descriptions-tabs .dropdown-toggle {
    background-color: transparent !important;
    color: black !important;
}

.descriptions-errors {
    margin-top: 15px;
}`, "",{"version":3,"sources":["webpack://./src/components/DescriptionsTabs/DescriptionsTabs.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,wBAAwB;QACpB,qBAAqB;YACjB,uBAAuB;IAC/B,SAAS;IACT,kBAAkB;IAClB,gBAAgB;AACpB;;AAEA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;AACX;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,wCAAwC;IACxC,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;AACpB","sourcesContent":[".descriptions-btns {\n    width: 100%;\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-pack: center;\n        -ms-flex-pack: center;\n            justify-content: center;\n    gap: 15px;\n    position: relative;\n    margin-top: 15px;\n}\n\n.descriptions-previous-btn {\n    position: absolute;\n    top: 0;\n    left: 0;\n}\n\n.descriptions-previous-btn>svg {\n    margin-right: 7px;\n}\n\n.descriptions-tabs .dropdown-toggle {\n    background-color: transparent !important;\n    color: black !important;\n}\n\n.descriptions-errors {\n    margin-top: 15px;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
