import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import FormInput from '../../../../../components/Form/FormInput';
import SimulationContext from '../../../../../contexts/SimulationContext';
import './SubstationsInputs.css';

const SubstationInputs = ({ label, param }) => {
  //#region [contexts]
  const {
    calculationData,
    setCalculationData,
    setAreParamsChanged,
    addError,
    removeError
  } = useContext(SimulationContext);
  //#endregion

  //#region [methods]
  const setSstConstraint = (param, constraint, sstIndex) => {
    setCalculationData((data) => {
      const dataCopy = { ...data };
      dataCopy.constraints[param.path][param.key][sstIndex] = constraint;
      return dataCopy;
    });
    setAreParamsChanged(true);
  };
  //#endregion

  //#region [render]
  return (
    <div className='form-input-left'>
      <span className='form-input-label'>{label}</span>
      <div className='sst-inputs-rows'>
        <Row className='sst-inputs-row'>
          {calculationData.substations.map((sst, index) => (
            <Col
              key={param.key + '_sst_name' + index}
              className='sst-inputs-col sst-inputs-name'
            >
              {sst}
            </Col>
          ))}
        </Row>
        <Row className='sst-inputs-row'>
          {calculationData.substations.map((_, index) => (
            <Col
              key={param.key + '_sst_value_' + index}
              className='sst-inputs-col'
            >
              <FormInput
                value={
                  calculationData.constraints[param.path][param.key][index]
                }
                param={param}
                onBlur={(value) => setSstConstraint(param, value, index)}
                addError={addError}
                removeError={removeError}
              />
            </Col>
          ))}
        </Row>
      </div>
    </div>
  );
  //#endregion
};

export default SubstationInputs;
