const BTES_COST = Object.freeze({
  FR: {
    '01': {
      InitBtesCostA: 103.6,
      InitBtesCostB: 2500
    },
    '02': {
      InitBtesCostA: 88,
      InitBtesCostB: 0
    },
    '03': {
      InitBtesCostA: 100.3,
      InitBtesCostB: 2500
    },
    '04': {
      InitBtesCostA: 80.1,
      InitBtesCostB: 5000
    },
    '05': {
      InitBtesCostA: 80.1,
      InitBtesCostB: 5000
    },
    '06': {
      InitBtesCostA: 86,
      InitBtesCostB: 7500
    },
    '07': {
      InitBtesCostA: 100.3,
      InitBtesCostB: 5000
    },
    '08': {
      InitBtesCostA: 85.3,
      InitBtesCostB: 2500
    },
    '09': {
      InitBtesCostA: 73.5,
      InitBtesCostB: 7500
    },
    10: {
      InitBtesCostA: 77.6,
      InitBtesCostB: 0
    },
    11: {
      InitBtesCostA: 68.2,
      InitBtesCostB: 5000
    },
    12: {
      InitBtesCostA: 87.4,
      InitBtesCostB: 5000
    },
    13: {
      InitBtesCostA: 80.1,
      InitBtesCostB: 5000
    },
    14: {
      InitBtesCostA: 81,
      InitBtesCostB: 2500
    },
    15: {
      InitBtesCostA: 59.6,
      InitBtesCostB: 2500
    },
    16: {
      InitBtesCostA: 87.3,
      InitBtesCostB: 2500
    },
    17: {
      InitBtesCostA: 102.7,
      InitBtesCostB: 2500
    },
    18: {
      InitBtesCostA: 103.4,
      InitBtesCostB: 0
    },
    19: {
      InitBtesCostA: 81.4,
      InitBtesCostB: 2500
    },
    21: {
      InitBtesCostA: 68.5,
      InitBtesCostB: 2500
    },
    22: {
      InitBtesCostA: 78.6,
      InitBtesCostB: 2500
    },
    23: {
      InitBtesCostA: 87.3,
      InitBtesCostB: 2500
    },
    24: {
      InitBtesCostA: 89.3,
      InitBtesCostB: 2500
    },
    25: {
      InitBtesCostA: 87.4,
      InitBtesCostB: 2500
    },
    26: {
      InitBtesCostA: 102.4,
      InitBtesCostB: 5000
    },
    27: {
      InitBtesCostA: 81.1,
      InitBtesCostB: 0
    },
    28: {
      InitBtesCostA: 106.8,
      InitBtesCostB: 0
    },
    29: {
      InitBtesCostA: 81.2,
      InitBtesCostB: 5000
    },
    30: {
      InitBtesCostA: 94.5,
      InitBtesCostB: 5000
    },
    31: {
      InitBtesCostA: 80.8,
      InitBtesCostB: 5000
    },
    32: {
      InitBtesCostA: 65.3,
      InitBtesCostB: 5000
    },
    33: {
      InitBtesCostA: 87.3,
      InitBtesCostB: 5000
    },
    34: {
      InitBtesCostA: 90.2,
      InitBtesCostB: 5000
    },
    35: {
      InitBtesCostA: 70.6,
      InitBtesCostB: 2500
    },
    36: {
      InitBtesCostA: 96.7,
      InitBtesCostB: 0
    },
    37: {
      InitBtesCostA: 82.9,
      InitBtesCostB: 0
    },
    38: {
      InitBtesCostA: 102.5,
      InitBtesCostB: 5000
    },
    39: {
      InitBtesCostA: 88.1,
      InitBtesCostB: 2500
    },
    40: {
      InitBtesCostA: 85.1,
      InitBtesCostB: 5000
    },
    41: {
      InitBtesCostA: 70.4,
      InitBtesCostB: 0
    },
    42: {
      InitBtesCostA: 83.3,
      InitBtesCostB: 2500
    },
    43: {
      InitBtesCostA: 62.4,
      InitBtesCostB: 2500
    },
    44: {
      InitBtesCostA: 66.7,
      InitBtesCostB: 2500
    },
    45: {
      InitBtesCostA: 107.5,
      InitBtesCostB: 0
    },
    46: {
      InitBtesCostA: 65.4,
      InitBtesCostB: 5000
    },
    47: {
      InitBtesCostA: 87.3,
      InitBtesCostB: 5000
    },
    48: {
      InitBtesCostA: 82.4,
      InitBtesCostB: 5000
    },
    49: {
      InitBtesCostA: 56.9,
      InitBtesCostB: 2500
    },
    50: {
      InitBtesCostA: 81,
      InitBtesCostB: 2500
    },
    51: {
      InitBtesCostA: 116.6,
      InitBtesCostB: 0
    },
    52: {
      InitBtesCostA: 92,
      InitBtesCostB: 2500
    },
    53: {
      InitBtesCostA: 67.9,
      InitBtesCostB: 2500
    },
    54: {
      InitBtesCostA: 100.5,
      InitBtesCostB: 2500
    },
    55: {
      InitBtesCostA: 81,
      InitBtesCostB: 2500
    },
    56: {
      InitBtesCostA: 66.4,
      InitBtesCostB: 2500
    },
    57: {
      InitBtesCostA: 99.1,
      InitBtesCostB: 2500
    },
    58: {
      InitBtesCostA: 85.1,
      InitBtesCostB: 0
    },
    59: {
      InitBtesCostA: 87.4,
      InitBtesCostB: 2500
    },
    60: {
      InitBtesCostA: 106.6,
      InitBtesCostB: 0
    },
    61: {
      InitBtesCostA: 81,
      InitBtesCostB: 0
    },
    62: {
      InitBtesCostA: 85.8,
      InitBtesCostB: 2500
    },
    63: {
      InitBtesCostA: 81,
      InitBtesCostB: 2500
    },
    64: {
      InitBtesCostA: 87.3,
      InitBtesCostB: 7500
    },
    65: {
      InitBtesCostA: 73.6,
      InitBtesCostB: 7500
    },
    66: {
      InitBtesCostA: 78.6,
      InitBtesCostB: 7500
    },
    67: {
      InitBtesCostA: 84,
      InitBtesCostB: 2500
    },
    68: {
      InitBtesCostA: 84,
      InitBtesCostB: 2500
    },
    69: {
      InitBtesCostA: 107.6,
      InitBtesCostB: 2500
    },
    70: {
      InitBtesCostA: 84,
      InitBtesCostB: 2500
    },
    71: {
      InitBtesCostA: 89.2,
      InitBtesCostB: 2500
    },
    72: {
      InitBtesCostA: 67.9,
      InitBtesCostB: 0
    },
    73: {
      InitBtesCostA: 97.1,
      InitBtesCostB: 5000
    },
    74: {
      InitBtesCostA: 97.1,
      InitBtesCostB: 5000
    },
    75: {
      InitBtesCostA: 97.3,
      InitBtesCostB: 0
    },
    76: {
      InitBtesCostA: 82.7,
      InitBtesCostB: 0
    },
    77: {
      InitBtesCostA: 72.4,
      InitBtesCostB: 0
    },
    78: {
      InitBtesCostA: 108.6,
      InitBtesCostB: 0
    },
    79: {
      InitBtesCostA: 87.3,
      InitBtesCostB: 2500
    },
    80: {
      InitBtesCostA: 76.9,
      InitBtesCostB: 0
    },
    81: {
      InitBtesCostA: 66.6,
      InitBtesCostB: 5000
    },
    82: {
      InitBtesCostA: 77.1,
      InitBtesCostB: 5000
    },
    83: {
      InitBtesCostA: 80.1,
      InitBtesCostB: 7500
    },
    84: {
      InitBtesCostA: 69.8,
      InitBtesCostB: 5000
    },
    85: {
      InitBtesCostA: 83.9,
      InitBtesCostB: 2500
    },
    86: {
      InitBtesCostA: 87.3,
      InitBtesCostB: 2500
    },
    87: {
      InitBtesCostA: 108.3,
      InitBtesCostB: 2500
    },
    88: {
      InitBtesCostA: 86,
      InitBtesCostB: 2500
    },
    89: {
      InitBtesCostA: 85.1,
      InitBtesCostB: 0
    },
    90: {
      InitBtesCostA: 84,
      InitBtesCostB: 2500
    },
    91: {
      InitBtesCostA: 93.6,
      InitBtesCostB: 0
    },
    92: {
      InitBtesCostA: 98.1,
      InitBtesCostB: 0
    },
    93: {
      InitBtesCostA: 114.5,
      InitBtesCostB: 0
    },
    94: {
      InitBtesCostA: 97.3,
      InitBtesCostB: 0
    },
    95: {
      InitBtesCostA: 107.4,
      InitBtesCostB: 0
    },
    '2A': {
      InitBtesCostA: 80.5,
      InitBtesCostB: 10000
    },
    '2B': {
      InitBtesCostA: 80.5,
      InitBtesCostB: 10000
    },
    France: {
      InitBtesCostA: 86,
      InitBtesCostB: 5000
    }
  }
});

const ELEC_PRICE_EVOL_METHOD = Object.freeze({
  MANUAL: 'manual',
  STUDY: 'study'
});

const GAS_PRICE_EVOL_METHOD = Object.freeze({
  MANUAL: 'manual',
  STUDY: 'study'
});

const COMPASS_STUDY = Object.freeze({
  startYear: 2023,
  endYear: 2030,
  elec: {
    prices: [260.2, 227, 202.7, 194.3, 186.9, 179.3, 171.2, 166.1],
    evolPercents: [0, -12.8, -10.7, -4.1, -3.8, -4.1, -4.5, -3]
  },
  gas: {
    prices: [167.3, 142, 113.6, 107.7, 101.8, 95.9, 90.1, 84.2],
    evolPercents: [0, -15.1, -20.0, -5.2, -5.5, -5.8, -6.0, -6.5]
  }
});

module.exports = {
  BTES_COST,
  ELEC_PRICE_EVOL_METHOD,
  GAS_PRICE_EVOL_METHOD,
  COMPASS_STUDY
};
