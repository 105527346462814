// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.expandable-table {
    width: 100%;
    table-layout: fixed;
    margin-bottom: 15px;
    border: 10px;
}

.expandable-table>tbody>tr>td {
    padding: 0px 10px;
}

.expandable-table-child-title-td {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 10px;
    padding: 5px 0;
}

.expandable-table-child-title-td svg {
    font-size: 20px;
    color: var(--primary-color);
}

.expandable-table-child-title-td .spinner-border {
    width: 20px;
    height: 20px;
}

.expandable-table>tbody>tr:first-of-type>td:first-child {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.expandable-table>tbody>tr:last-of-type>td:first-child {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.alternate-two-rows>td {
    background-color: rgb(240, 240, 240);
}

.alternate-two-rows:nth-of-type(4n + 1)>td,
.alternate-two-rows:nth-of-type(4n + 2)>td {
    background-color: white;
}`, "",{"version":3,"sources":["webpack://./src/components/ExpandableTable/ExpandableTable.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,mBAAmB;IACnB,mBAAmB;IACnB,YAAY;AAChB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,yBAAyB;QACrB,sBAAsB;YAClB,mBAAmB;IAC3B,SAAS;IACT,cAAc;AAClB;;AAEA;IACI,eAAe;IACf,2BAA2B;AAC/B;;AAEA;IACI,WAAW;IACX,YAAY;AAChB;;AAEA;IACI,4BAA4B;IAC5B,6BAA6B;AACjC;;AAEA;IACI,+BAA+B;IAC/B,gCAAgC;AACpC;;AAEA;IACI,oCAAoC;AACxC;;AAEA;;IAEI,uBAAuB;AAC3B","sourcesContent":[".expandable-table {\n    width: 100%;\n    table-layout: fixed;\n    margin-bottom: 15px;\n    border: 10px;\n}\n\n.expandable-table>tbody>tr>td {\n    padding: 0px 10px;\n}\n\n.expandable-table-child-title-td {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    gap: 10px;\n    padding: 5px 0;\n}\n\n.expandable-table-child-title-td svg {\n    font-size: 20px;\n    color: var(--primary-color);\n}\n\n.expandable-table-child-title-td .spinner-border {\n    width: 20px;\n    height: 20px;\n}\n\n.expandable-table>tbody>tr:first-of-type>td:first-child {\n    border-top-left-radius: 10px;\n    border-top-right-radius: 10px;\n}\n\n.expandable-table>tbody>tr:last-of-type>td:first-child {\n    border-bottom-left-radius: 10px;\n    border-bottom-right-radius: 10px;\n}\n\n.alternate-two-rows>td {\n    background-color: rgb(240, 240, 240);\n}\n\n.alternate-two-rows:nth-of-type(4n + 1)>td,\n.alternate-two-rows:nth-of-type(4n + 2)>td {\n    background-color: white;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
