import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useLayoutEffect, useMemo } from 'react';
import { Alert, Button, Nav, NavDropdown, Tab } from 'react-bootstrap';
import { CHILDREN_LIMIT } from '../../../../server/constants/description.constant';
import DescriptionsPageContext from '../../contexts/DescriptionsPageContext';
import PopupContext from '../../contexts/PopupContext';
import { isObjNullOrEmpty } from '../../utils/data.utils';
import './DescriptionsTabs.css';
import DescriptionTab from './components/DescriptionTab/DescriptionTab';
import useDescriptions from './useDescriptions';

const DEFAULT_ACTIVE_TAB = '0';

const DescriptionsTabs = ({
  project,
  setProject,
  onSubmit,
  onPrevious,
  cantSaveErrors
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { openConfirmModal } = useContext(PopupContext);
  //#endregion

  //#region [logic]
  const {
    activeTab,
    nbErrorsByDesc,
    totalDescErrors,
    addErrorByDesc,
    removeErrorsByDesc,
    setActiveTab,
    handleNewChildClick,
    handleDeleteChildClick
  } = useDescriptions(project, setProject);
  //#endregion

  //#region [effects]
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion

  //#region [memos]
  const projectAndChildren = useMemo(() => {
    if (isObjNullOrEmpty(project)) return null;
    if (!project.children) project.children = [];
    return [project, ...project.children];
  }, [project]);
  //#endregion

  //#region [render]
  return projectAndChildren ? (
    <DescriptionsPageContext.Provider
      value={{
        projectParent: project,
        setProjectParent: setProject,
        addErrorByDesc,
        removeErrorsByDesc
      }}
    >
      <div className='descriptions-tabs'>
        <Tab.Container
          defaultActiveKey={DEFAULT_ACTIVE_TAB}
          activeKey={activeTab}
          onSelect={(tab) => setActiveTab(tab)}
        >
          <Nav variant='tabs'>
            <Nav.Item>
              <Nav.Link eventKey='0'>
                <span className='nav-link-span'>
                  {i18n._('description.descriptionTabLabel', { id: 1 })}
                </span>
              </Nav.Link>
            </Nav.Item>
            {project.children?.map((_, i) => (
              <Nav.Item key={'description_' + (i + 2)}>
                <Nav.Link eventKey={(i + 1).toString()}>
                  <span className='nav-link-span'>
                    {i18n._('description.descriptionTabLabel', {
                      id: i + 2
                    })}
                    <FontAwesomeIcon
                      icon='trash-alt'
                      onClick={() =>
                        openConfirmModal(
                          i18n._('description.deleteDescription.title'),
                          i18n._('description.deleteDescription.body'),
                          'danger',
                          async () => await handleDeleteChildClick(i)
                        )
                      }
                    />
                  </span>
                </Nav.Link>
              </Nav.Item>
            ))}
            {project.children.length < CHILDREN_LIMIT && (
              <NavDropdown title={<FontAwesomeIcon icon='square-plus' />}>
                <NavDropdown.Item
                  onClick={async () => await handleNewChildClick(0)}
                >
                  {i18n._('description.duplicateDescription', { id: 1 })}
                </NavDropdown.Item>
                {project.children &&
                  project.children.map((_, i) => (
                    <NavDropdown.Item
                      key={'dup' + i}
                      onClick={async () => await handleNewChildClick(i + 1)}
                    >
                      {i18n._('description.duplicateDescription', {
                        id: i + 2
                      })}
                    </NavDropdown.Item>
                  ))}
                <NavDropdown.Divider />
                <NavDropdown.Item
                  onClick={async () => await handleNewChildClick()}
                >
                  {i18n._('description.createEmptyDescrption')}
                </NavDropdown.Item>
              </NavDropdown>
            )}
          </Nav>
          <Tab.Content>
            {projectAndChildren.map((proj, i) => (
              <Tab.Pane eventKey={i} key={'proj_' + i}>
                <DescriptionTab project={proj} descIndex={i} />
              </Tab.Pane>
            ))}
          </Tab.Content>
        </Tab.Container>
        {totalDescErrors > 0 &&
          nbErrorsByDesc.map(
            (nbErrors, index) =>
              nbErrors > 0 && (
                <Alert
                  variant='danger'
                  key={'desc_error_' + index}
                  className='descriptions-errors'
                >
                  {i18n._('description.descriptionError', {
                    descriptionIndex: index + 1,
                    nbErrors
                  })}
                </Alert>
              )
          )}
        {cantSaveErrors.some((error) => error) &&
          cantSaveErrors.map((error, index) => (
            <Alert
              variant='danger'
              key={'cant_save_error_' + index}
              className='descriptions-errors'
            >
              {error}
            </Alert>
          ))}
        <div className='descriptions-btns'>
          {onPrevious && (
            <Button
              variant='secondary'
              className='descriptions-previous-btn'
              onClick={onPrevious}
            >
              <FontAwesomeIcon icon='arrow-left' />
              {i18n._('previous')}
            </Button>
          )}
          <Button
            variant='primary'
            onClick={onSubmit}
            disabled={totalDescErrors > 0}
          >
            {i18n._('save')}
          </Button>
        </div>
      </div>
    </DescriptionsPageContext.Provider>
  ) : null;
  //#endregion
};

export default DescriptionsTabs;
