import { A_MAX, A_MIN } from '../../../server/constants/geology.constant';
import { isArrNullOrEmpty, isObjNullOrEmpty } from './data.utils';

export const getChild = (projectParent, childId) => {
  if (isObjNullOrEmpty(projectParent) || !childId) return null;
  if (isArrNullOrEmpty(projectParent.children)) return null;
  return projectParent.children.find((child) => child.AhsID === childId);
};

export const getDescriptionByIndex = (projectParent, descriptionIndex) => {
  if (descriptionIndex < 0) return undefined;
  if (descriptionIndex === 0) return projectParent.description;
  if (
    isArrNullOrEmpty(projectParent.children) ||
    descriptionIndex > projectParent.children.length
  )
    return undefined;
  return projectParent.children[descriptionIndex - 1].description;
};

export const getDescriptionById = (i18n, projectParent, projectId) => {
  if (!projectId) return undefined;
  if (projectParent.AhsID === projectId) {
    return projectParent.description ?? i18n._('description', { index: 1 });
  }
  if (isArrNullOrEmpty(projectParent.children)) return undefined;
  const projectIndex = projectParent.children.findIndex(
    (child) => child.AhsID === projectId
  );
  return projectIndex === -1
    ? undefined
    : projectParent.children[projectIndex].description ||
        i18n._('description', { index: projectIndex + 2 });
};

export const getComputeDescriptionIndex = (projectParent, computeRefAhsID) => {
  if (projectParent.AhsID === computeRefAhsID) return 0;
  if (!isArrNullOrEmpty(projectParent.children)) {
    const childIndex = projectParent.children.findIndex(
      (child) => child.AhsID === computeRefAhsID
    );
    return childIndex === -1 ? -1 : childIndex + 1;
  }
  return -1;
};

export const checkSummaryData = (data) => {
  if (isObjNullOrEmpty(data) || data === 'null' || data === 'undefined')
    return false;
  let isEmpty = false;
  Object.values(data).forEach((need) => {
    if (isObjNullOrEmpty(need)) {
      isEmpty = true;
    }
  });
  return !isEmpty;
};

export const getDescriptionErrors = (i18n, project) => {
  if (isArrNullOrEmpty(project.children)) project.children = [];
  const projectAndChildren = [project, ...project.children];
  const errors = new Array(projectAndChildren.length);
  projectAndChildren.forEach((proj, descIndex) => {
    const {
      InitStationHasHeatNeeds,
      InitStationHasColdNeeds,
      InitStationHasHotWaterNeeds
    } = proj.substations[0];
    if (
      !InitStationHasHeatNeeds &&
      !InitStationHasColdNeeds &&
      !InitStationHasHotWaterNeeds
    ) {
      errors[descIndex] = i18n._('descError.noNeeds', {
        descIndex: descIndex + 1
      });
    } else if (proj.otherValues.ItesEnabled) {
      const hasHeatNeeds = proj.substations[0].InitStationHasHeatNeeds;
      const hasColdNeeds = proj.substations[0].InitStationHasColdNeeds;
      const hasHotWaterNeeds = proj.substations[0].InitStationHasHotWaterNeeds;
      if (hasColdNeeds && (hasHeatNeeds || hasHotWaterNeeds)) {
        errors[descIndex] = i18n._('descError.ites.onlyColdNeeds', {
          descIndex: descIndex + 1
        });
      } else if (proj.otherValues.InitBtesEnabled) {
        errors[descIndex] = i18n._('descError.ites.btesEnabled', {
          descIndex: descIndex + 1
        });
      } else if (!proj.otherValues.InitHPACoolingEnabled) {
        errors[descIndex] = i18n._('descError.ites.hpaCoolingDisabled', {
          descIndex: descIndex + 1
        });
      }
    }
  });
  return errors;
};

export const getAlphaError = (thermalConductivity, volumeHeatCapacity) => {
  const alpha = thermalConductivity / volumeHeatCapacity;
  return alpha < A_MIN || alpha > A_MAX;
};

export const getDefaultValue = (i18n, param) => {
  const translateParams = param.unit
    ? { value: param.default, unit: i18n._(`unit.${param.unit}`) }
    : { value: param.default };
  return i18n._('param.defaultValues', translateParams);
};
