import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import Section from '../../../../../../../components/Section/Section';
import CompanyContext from '../../../../../../../contexts/CompanyContext';
import ProjectContext from '../../../../../../../contexts/ProjectContext';
import { isArrNullOrEmpty } from '../../../../../../../utils/data.utils';
import MultiDescTable from './components/MultiDescTable/MultiDescTable';
import SstTable from './components/SstTable';
import { multiDescTemplate } from './templates';

const DescriptionSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  const { project } = useContext(ProjectContext);
  //#endregion

  //#region [memos]
  const projectAndChildren = useMemo(() => {
    return isArrNullOrEmpty(project.children)
      ? [project]
      : [project, ...project.children];
  }, [project]);

  const MDTemplate = useMemo(() => {
    return multiDescTemplate(projectAndChildren);
  }, [projectAndChildren]);
  //#endregion

  //#region [render]
  return (
    <Section
      title={i18n._('page.description')}
      editPath={`/company/${selectedCompany.id}/project/${project.AhsID}/description`}
      open
    >
      <MultiDescTable template={MDTemplate} />
      {project.substations.map((sst, sstIndex) => (
        <SstTable
          key={'desc_sst_table_' + sstIndex}
          projectAndChildren={projectAndChildren}
          substation={sst}
          sstIndex={sstIndex}
        />
      ))}
    </Section>
  );
  //#endregion
};

export default DescriptionSection;
