const SOCKET_MSG_TYPE = Object.freeze({
  LOAD_RESULT: 'load-result',
  LOAD: 'load',
  CONNECTING_ERROR: 'connecting-error',
  COMPUTE: 'compute',
  COMPUTE_CLIENT_IDS: 'compute-clientIDs',
  COMPUTE_REFRESH: 'compute-refresh',
  COMPUTE_RESULT: 'compute-result',
  COMPUTE_PROGRESS: 'compute-progress',
  COMPUTE_ERROR: 'compute-error',
  COMPUTE_DETAIL: 'compute-detail',
  CANCELED: 'canceled',
  CANCEL: 'cancel',
  COMPUTE_FINISHED: 'compute-finished',
  VERSION: 'version',
  WS_CONNECTED: 'ws-connected'
});

const VERSION_ROOM = 'compute-version';

module.exports = { SOCKET_MSG_TYPE, VERSION_ROOM };
