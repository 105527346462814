// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.worst {
    color: #ff4362;
}

.bad {
    color: #FF6600;
}

.middle {
    color: #f7d70c;
}

.good {
    color: #5a7c2e;
}

.best {
    color: #1fc574;
}`, "",{"version":3,"sources":["webpack://./src/pages/design/result/sections/summary/SummarySection.css"],"names":[],"mappings":"AAAA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB;;AAEA;IACI,cAAc;AAClB","sourcesContent":[".worst {\n    color: #ff4362;\n}\n\n.bad {\n    color: #FF6600;\n}\n\n.middle {\n    color: #f7d70c;\n}\n\n.good {\n    color: #5a7c2e;\n}\n\n.best {\n    color: #1fc574;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
