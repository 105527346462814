import { useLingui } from '@lingui/react';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Alert } from 'react-bootstrap';
import { COMPUTE_TYPE } from '../../../../../../../../server/constants/constraint.constant';
import {
  fetchNextCalculationData,
  fetchResultsByType
} from '../../../../../../api/project.api';
import ResultSelect from '../../../../../../components/ResultSelect/ResultSelect';
import OptimizationContext from '../../../../../../contexts/OptimizationContext';
import PopupContext from '../../../../../../contexts/PopupContext';
import ProjectContext from '../../../../../../contexts/ProjectContext';
import useAuth from '../../../../../../hooks/useAuth';
import { setCalculationGlobalParams } from '../../../../../../utils/compute.utils';
import {
  isArrNullOrEmpty,
  isObjNullOrEmpty
} from '../../../../../../utils/data.utils';
import CalculationForm from '../../CalculationForm/CalculationForm';
import OptiGeneralSection from './sections/OptiGeneralSection/OptiGeneralSection';
import OptiGoalsSection from './sections/OptiGoalsSection/OptiGoalsSection';
import OptiSizingSection from './sections/OptiSizingSection/OptiSizingSection';
import OptiTypeSection from './sections/OptiTypeSection/OptiTypeSection';

const OptimizationForm = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [auth]
  const { user } = useAuth();
  //#endregion

  //#region [contexts]
  const { project } = useContext(ProjectContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [groupedResults, setGroupedResults] = useState([]);
  const [selectedResultIndexes, setSelectedResultIndexes] = useState([0, 0]);
  const [calculationData, setCalculationData] = useState();
  const [areParamsChanged, setAreParamsChanged] = useState(false);
  const [nbErrors, setNbErrors] = useState(0);
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        await handleResultsRefresh();
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, []);
  //#endregion

  //#region [methods]
  const setConstraint = (param, constraint) => {
    setCalculationData((data) => {
      const dataCopy = { ...data };
      dataCopy.constraints[param.path][param.key] = constraint;
      return dataCopy;
    });
    setAreParamsChanged(true);
  };

  const setBounds = (key, bounds) => {
    setCalculationData((data) => {
      const dataCopy = { ...data };
      dataCopy.constraints.bounds[0][key] = bounds[0];
      dataCopy.constraints.bounds[1][key] = bounds[1];
      return dataCopy;
    });
    setAreParamsChanged(true);
  };

  const addError = () => {
    setNbErrors((prevErrors) => prevErrors + 1);
  };

  const removeError = () => {
    setNbErrors((prevErrors) => prevErrors - 1);
  };

  const fetchOptiResults = async () => {
    try {
      const groups = await fetchResultsByType(
        project.AhsID,
        COMPUTE_TYPE.OPTI,
        true
      );
      setGroupedResults(groups);
      return groups;
    } catch (err) {
      throw err;
    }
  };

  const fetchNextCalcData = async (computeId) => {
    try {
      let data = await fetchNextCalculationData(
        project.AhsID,
        computeId,
        COMPUTE_TYPE.OPTI
      );
      data = setCalculationGlobalParams(data, user.UserID, user.UserEmail);
      setCalculationData(data);
    } catch (err) {
      throw err;
    }
  };

  const handleResultsRefresh = async () => {
    try {
      const groups = await fetchOptiResults();
      await fetchNextCalcData(groups[0]?.computes[0]?.ComputeID);
    } catch (err) {
      throw err;
    }
  };

  const handleResultChange = async (data) => {
    try {
      setSelectedResultIndexes(data.indexes);
      await fetchNextCalcData(
        groupedResults[data.indexes[0]].computes[data.indexes[1]].ComputeID
      );
      setAreParamsChanged(false);
      setNbErrors(0);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  return !isObjNullOrEmpty(calculationData) ? (
    <OptimizationContext.Provider
      value={{
        calculationData,
        setCalculationData,
        setAreParamsChanged,
        setConstraint,
        setBounds,
        addError,
        removeError
      }}
    >
      <CalculationForm
        calculationData={calculationData}
        nbErrors={nbErrors}
        onResultsRefresh={handleResultsRefresh}
      >
        {!isArrNullOrEmpty(groupedResults) && (
          <Fragment>
            <ResultSelect
              onResultChange={handleResultChange}
              groupedResults={groupedResults}
              selectedResultIndexes={selectedResultIndexes}
            />
            {areParamsChanged && (
              <Alert variant='warning'>
                {i18n._('compute.simu.paramsChanged')}
              </Alert>
            )}
          </Fragment>
        )}
        <OptiGeneralSection />
        <OptiSizingSection />
        <OptiGoalsSection />
        <OptiTypeSection />
      </CalculationForm>
    </OptimizationContext.Provider>
  ) : null;
  //#endregion
};

export default OptimizationForm;
