// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.no-power-load {
    margin: 0;
}`, "",{"version":3,"sources":["webpack://./src/pages/design/result/sections/energyCarbon/components/PowerLoadCurve/PowerLoadCurve.css"],"names":[],"mappings":"AAAA;IACI,SAAS;AACb","sourcesContent":[".no-power-load {\n    margin: 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
