import { useLingui } from '@lingui/react';
import React, { useContext, useState } from 'react';
import { Button } from 'react-bootstrap';
import ResultContext from '../../contexts/ResultContext';
import { getCompareResultName } from '../../utils/compute.utils';
import './CompareResultsElements.css';

const CompareResultsElements = ({ element, elementProps }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { comparedResults } = useContext(ResultContext);
  //#endregion

  //#region [states]
  const [visibilities, setVisibilities] = useState(
    [true].concat(new Array(comparedResults.length - 1).fill(false))
  );
  //#endregion

  //#region [methods]
  const handleBtnClick = (index) => {
    setVisibilities((visibilities) => {
      visibilities[index] = !visibilities[index];
      return [...visibilities];
    });
  };
  //#endregion

  //#region [render]
  let Element = element;
  return (
    <div>
      {comparedResults.length > 1 && (
        <div className='compare-results-btns'>
          {comparedResults.map((_, index) => (
            <Button
              key={'compare_results_btn_' + index}
              onClick={() => handleBtnClick(index)}
              variant={visibilities[index] ? 'primary' : 'outline-secondary'}
              disabled={
                visibilities[index] &&
                visibilities.filter((v) => v).length === 1
              }
            >
              {i18n._('result', { index: index + 1 })}
            </Button>
          ))}
        </div>
      )}
      {comparedResults.map((result, index) => {
        const { ComputeName, Comment } = result;
        return visibilities[index] ? (
          <div key={'compare_result_element_' + ComputeName + '_' + index}>
            {comparedResults.length > 1 && (
              <p className='compare-results-title' title={ComputeName}>
                {getCompareResultName(i18n, Comment, index)}
              </p>
            )}
            <Element {...elementProps} result={result} />
          </div>
        ) : null;
      })}
    </div>
  );
  //#endregion
};

export default CompareResultsElements;
