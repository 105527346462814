import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { Fragment, useContext, useRef, useState } from 'react';
import { Overlay, Tooltip } from 'react-bootstrap';
import { UNIT } from '../../../../server/constants/unit.constant';
import ResultContext from '../../contexts/ResultContext';
import {
  formatValueWithUnit,
  getPercentsDifference,
  isNull
} from '../../utils/data.utils';
import './AccentaVsRefTable.css';

const AccentaVsRefTable = ({ template }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { comparedResults } = useContext(ResultContext);
  //#endregion

  //#region [states]
  const [showInfoTooltip, setShowInfoTooltip] = useState(false);
  //#endregion

  //#region [refs]
  const iconRef = useRef(null);
  //#endregion

  //#region [render]
  return (
    <table className='template-table results-table accenta-ref-table'>
      <thead>
        <tr>
          <th
            style={{ width: comparedResults.length > 2 ? '17%' : '35%' }}
          ></th>
          {comparedResults.map((result, i) => (
            <Fragment key={'th_accenta_ref_' + i}>
              <th className='result-table-title'>
                {i18n._('reference', { index: i + 1 })}
              </th>
              <th title={result.ComputeName}>
                <p className='result-table-title'>
                  {i18n._('result', { index: i + 1 })}
                </p>
                {result.Comment && (
                  <p className='result-table-details ellipsis'>
                    {result.Comment}
                  </p>
                )}
              </th>
            </Fragment>
          ))}
        </tr>
      </thead>
      <tbody>
        {template.rows.map((row, rowIndex) => (
          <tr key={'accenta_ref_row_' + rowIndex}>
            <td className='table-label'>
              {row.label}
              {row.tooltipInfo && (
                <>
                  <FontAwesomeIcon
                    icon={'circle-info'}
                    className='info-icon'
                    onMouseOver={() => setShowInfoTooltip(true)}
                    onMouseOut={() => setShowInfoTooltip(false)}
                    ref={iconRef}
                  />
                  <Overlay
                    target={iconRef.current}
                    show={showInfoTooltip}
                    placement='right'
                  >
                    {(props) => (
                      <Tooltip
                        id='form-input-info-tooltip'
                        {...props}
                        className='accenta-ref-table-tooltip'
                      >
                        {row.tooltipInfo}
                      </Tooltip>
                    )}
                  </Overlay>
                </>
              )}
            </td>
            {row.refValues.map((refValue, index) => {
              const refValueWithUnit = !isNull(refValue)
                ? formatValueWithUnit(i18n, refValue, row.unit, row.precision)
                : i18n._('noValue');

              const accentaValue = row.accentaValues[index];
              let accentaValueWithUnit = !isNull(accentaValue)
                ? formatValueWithUnit(
                    i18n,
                    accentaValue,
                    row.unit,
                    row.precision
                  )
                : i18n._('noValue');

              let comparisonValue;
              let comparisonPercent;
              if (typeof refValue === 'string') refValue = Number(refValue);
              if (refValue && !isNull(accentaValue)) {
                comparisonValue = getPercentsDifference(accentaValue, refValue);
                comparisonPercent = formatValueWithUnit(
                  i18n,
                  comparisonValue,
                  UNIT.PERCENT,
                  row.precision
                );
                if (comparisonValue > 0) {
                  comparisonPercent = i18n._('plusValue', {
                    value: comparisonPercent
                  });
                }
              }
              return (
                <Fragment key={'accenta_ref_values_' + index}>
                  <td className='ref-td' title={refValueWithUnit}>
                    <p>{refValueWithUnit}</p>
                    {comparisonPercent && (
                      <p>
                        {i18n._('accentaVsRef', {
                          percent: comparisonPercent
                        })}
                      </p>
                    )}
                  </td>
                  <td className='accenta-td' title={accentaValueWithUnit}>
                    <p>{accentaValueWithUnit}</p>
                    {comparisonPercent && (
                      <p>
                        {i18n._('accentaVsRef', {
                          percent: comparisonPercent
                        })}
                      </p>
                    )}
                  </td>
                </Fragment>
              );
            })}
          </tr>
        ))}
      </tbody>
    </table>
  );
  //#endregion
};

export default AccentaVsRefTable;
