import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  addComputeTag,
  removeComputeTag,
  replaceComputeTag,
  updateComputeComment
} from '../../../../../api/compute.api';
import CompareResultsElements from '../../../../../components/CompareResultsElements/CompareResultsElements';
import CompareResultsTable from '../../../../../components/CompareResultsTable/CompareResultsTable';
import Section from '../../../../../components/Section/Section';
import SectionTitle from '../../../../../components/SectionTitle';
import CompanyContext from '../../../../../contexts/CompanyContext';
import PopupContext from '../../../../../contexts/PopupContext';
import ProjectContext from '../../../../../contexts/ProjectContext';
import ResultContext from '../../../../../contexts/ResultContext';
import {
  getResultIndexes,
  getResultNameByNameData
} from '../../../../../utils/compute.utils';
import CommentAndTags from './components/CommentAndTags/CommentAndTags';
import PenaltiesTable from './components/PenaltiesTable/PenaltiesTable';
import WarningsAlerts from './components/WarningsAlerts';
import { generalTemplate } from './templates';

const GeneralSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  const { project } = useContext(ProjectContext);
  const {
    comparedResults,
    setSelectedResult,
    setGroupedResults,
    setResultsComparator
  } = useContext(ResultContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const navigateToResults = () => {
    const { AhsID } = project;
    navigate(`/company/${selectedCompany.id}/project/${AhsID}/results`);
  };

  const editComment = async (result, comment) => {
    try {
      const response = await updateComputeComment(result.ComputeID, comment);
      if (!response) {
        // le résultat que l'on veut modifier n'existe pas
        navigate(url);
        navigateToResults();
        openErrorToast(err);
      } else {
        const name = getResultNameByNameData(i18n, project, {
          ...result,
          Comment: comment
        });
        const comparedResultIndex = comparedResults.findIndex(
          (res) => res.ComputeID === result.ComputeID
        );
        if (comparedResultIndex === 0) {
          setSelectedResult((res) => ({
            ...res,
            Comment: comment,
            ComputeName: name
          }));
        } else if (comparedResultIndex !== -1) {
          setResultsComparator((results) => {
            const resultsCopy = [...results];
            results[comparedResultIndex - 1].Comment = comment;
            results[comparedResultIndex - 1].ComputeName = name;
            return resultsCopy;
          });
        }
        setGroupedResults((groups) => {
          const groupsCopy = [...groups];
          const indexes = getResultIndexes(groupsCopy, result.ComputeID);
          groupsCopy[indexes[0]].computes[indexes[1]].Comment = comment;
          groupsCopy[indexes[0]].computes[indexes[1]].ComputeName = name;
          return groupsCopy;
        });
      }
    } catch (err) {
      throw err;
    }
  };

  const addTag = async (resultId, tag) => {
    try {
      await addComputeTag(resultId, tag.value);
      changeTag(resultId, tag);
    } catch (err) {
      throw err;
    }
  };

  const removeTag = async (resultId, tag) => {
    try {
      await removeComputeTag(resultId, tag.value);
      changeTag(resultId, tag);
    } catch (err) {
      throw err;
    }
  };

  const changeTag = async (resultId, tag) => {
    try {
      const comparedResultIndex = comparedResults.findIndex(
        (result) => result.ComputeID === resultId
      );
      if (comparedResultIndex === 0) {
        setSelectedResult((result) => {
          const resultCopy = { ...result };
          resultCopy.tags[tag.key] = !resultCopy.tags[tag.key];
          return resultCopy;
        });
      } else if (comparedResultIndex !== -1) {
        setResultsComparator((results) => {
          const resultsCopy = [...results];
          results[comparedResultIndex - 1].tags[tag.key] =
            !results[comparedResultIndex - 1].tags[tag.key];
          return resultsCopy;
        });
      }
      setGroupedResults((groups) => {
        const groupsCopy = [...groups];
        const indexes = getResultIndexes(groupsCopy, resultId);
        groupsCopy[indexes[0]].computes[indexes[1]].tags[tag.key] =
          !groupsCopy[indexes[0]].computes[indexes[1]].tags[tag.key];
        return groupsCopy;
      });
    } catch (err) {
      throw err;
    }
  };

  const replaceTag = async (resultId, computeIdForReplacingTag, tag) => {
    try {
      await replaceComputeTag(resultId, computeIdForReplacingTag, tag.value);
      changeTag(resultId, tag);
      changeTag(computeIdForReplacingTag, tag);
    } catch (err) {
      throw err;
    }
  };
  //#endregion

  //#region [memos]
  const GTemplate = useMemo(() => {
    return generalTemplate(i18n, comparedResults);
  }, [comparedResults]);
  //#endregion

  //#region [render]
  return (
    <Section title={i18n._('results.general')} open>
      <CompareResultsTable level={2} template={GTemplate} />
      <SectionTitle level={2} title={i18n._('results.general.comment')} />
      <CompareResultsElements
        element={CommentAndTags}
        elementProps={{ editComment, addTag, removeTag, replaceTag }}
      />
      <SectionTitle level={2} title={i18n._('results.general.warnings')} />
      <CompareResultsElements element={WarningsAlerts} />
      <PenaltiesTable />
    </Section>
  );
  //#endregion
};

export default GeneralSection;
