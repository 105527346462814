import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { WATERTANK_HOTWATER } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import CostPageContext from '../../../../contexts/CostPageContext';
import { getDefaultValue } from '../../../../utils/project.utils';

const WaterTankHotwaterBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleOtherValueChange } =
    useContext(CostPageContext);
  //#endregion

  //#region [render]
  const {
    InitWaterTankHotwaterCostA,
    InitWaterTankHotwaterCostB,
    InitWaterTankHotwaterExchangerCostA,
    InitWaterTankHotwaterExchangerCostB,
    InitWaterTankHotwaterPumpCostA,
    InitWaterTankHotwaterPumpCostB
  } = tempProject.otherValues;
  return (
    <Bloc title={i18n._('cost.waterTankHotwater')}>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitWaterTankHotwaterCostA')}
            value={InitWaterTankHotwaterCostA}
            param={WATERTANK_HOTWATER.InitWaterTankHotwaterCostA}
            onBlur={(value) =>
              handleOtherValueChange('InitWaterTankHotwaterCostA', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_HOTWATER.InitWaterTankHotwaterCostA
            )}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitWaterTankHotwaterCostB')}
            value={InitWaterTankHotwaterCostB}
            param={WATERTANK_HOTWATER.InitWaterTankHotwaterCostB}
            onBlur={(value) =>
              handleOtherValueChange('InitWaterTankHotwaterCostB', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_HOTWATER.InitWaterTankHotwaterCostB
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitWaterTankHotwaterExchangerCostA')}
            value={InitWaterTankHotwaterExchangerCostA}
            param={WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostA}
            onBlur={(value) =>
              handleOtherValueChange(
                'InitWaterTankHotwaterExchangerCostA',
                value
              )
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostA
            )}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitWaterTankHotwaterExchangerCostB')}
            value={InitWaterTankHotwaterExchangerCostB}
            param={WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostB}
            onBlur={(value) =>
              handleOtherValueChange(
                'InitWaterTankHotwaterExchangerCostB',
                value
              )
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_HOTWATER.InitWaterTankHotwaterExchangerCostB
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitWaterTankHotwaterPumpCostA')}
            value={InitWaterTankHotwaterPumpCostA}
            param={WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostA}
            onBlur={(value) =>
              handleOtherValueChange('InitWaterTankHotwaterPumpCostA', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostA
            )}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitWaterTankHotwaterPumpCostB')}
            value={InitWaterTankHotwaterPumpCostB}
            param={WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostB}
            onBlur={(value) =>
              handleOtherValueChange('InitWaterTankHotwaterPumpCostB', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              WATERTANK_HOTWATER.InitWaterTankHotwaterPumpCostB
            )}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default WaterTankHotwaterBloc;
