import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import {
  CONSTRAINT_KEY,
  CONSTRAINT_SST_KEY
} from '../../../../../../../../server/constants/constraint.constant.js';
import TwoWayTable from '../../../../../../components/TwoWayTable/TwoWayTable.jsx';
import {
  formatValue,
  getSafeData
} from '../../../../../../utils/data.utils.js';

const ConstraintsTable = ({ result }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    const { params, bounds } = result.ComputeResult;
    const constraintsKeys = Object.values(CONSTRAINT_KEY);
    const substationConstraintsKeys = Object.values(CONSTRAINT_SST_KEY);
    let nbVerticalTitles = constraintsKeys.length;
    substationConstraintsKeys.forEach((key) => {
      if (params[key] && params[key].length > 0)
        nbVerticalTitles += params[key].length;
    });
    const values = new Array(nbVerticalTitles);
    const verticalTitles = new Array(nbVerticalTitles);

    for (let i = 0; i < constraintsKeys.length; ++i) {
      const currentKey = constraintsKeys[i];
      values[i] = new Array(3);
      values[i][0] = getSafeData(() => formatValue(params[currentKey]));
      values[i][1] = getSafeData(() => formatValue(bounds[0][currentKey]));
      values[i][2] = getSafeData(() => formatValue(bounds[1][currentKey]));
      verticalTitles[i] = i18n._(`constraint.${currentKey}`);
    }

    const { substations } = result;
    for (let i = 0; i < substationConstraintsKeys.length; ++i) {
      const currentKey = substationConstraintsKeys[i];
      if (params[currentKey] && params[currentKey].length > 0) {
        for (let j = 0; j < substations.length; ++j) {
          const currIndex = constraintsKeys.length + i * substations.length + j;
          values[currIndex] = new Array(3);
          values[currIndex][0] = getSafeData(() =>
            formatValue(params[currentKey][j])
          );
          values[currIndex][1] = getSafeData(() =>
            formatValue(bounds[0][currentKey][j])
          );
          values[currIndex][2] = getSafeData(() =>
            formatValue(bounds[1][currentKey][j])
          );
          verticalTitles[currIndex] = i18n._(`constraint.${currentKey}`, {
            substation: substations[j]
          });
        }
      }
    }
    return {
      horizontalTitles: [
        i18n._('results.simulationParams.constraints.table.retain'),
        i18n._('results.simulationParams.constraints.table.min'),
        i18n._('results.simulationParams.constraints.table.max')
      ],
      verticalTitles,
      values
    };
  }, [result.ComputeID]);
  //#endregion

  //#region [render]
  return data ? (
    <TwoWayTable
      horizontalTitles={data.horizontalTitles}
      verticalTitles={data.verticalTitles}
      values={data.values}
    />
  ) : (
    <Alert variant='warning'>{i18n._('results.noData')}</Alert>
  );
  //#endregion
};

export default ConstraintsTable;
