import axios from '../conf/axios.conf';

//#region [GET]
export const fetchEngineData = async (calculationId, projectId) => {
  try {
    const url = `/calculation/${calculationId}/desc/${projectId}/engineData`;
    const response = await axios.get(url);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion
