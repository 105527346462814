import axios from 'axios';
import { ACCESS_KEY } from '../../../server/constants/storage.constant';

const instance = axios.create();

instance.interceptors.request.use((config) => {
  const accessToken =
    localStorage.getItem(ACCESS_KEY) || sessionStorage.getItem(ACCESS_KEY);
  if (accessToken) config.headers['Authorization'] = 'Bearer ' + accessToken;
  if (!config.url.includes('/api-v1')) config.url = '/api-v1' + config.url;
  return config;
});

export default instance;
