import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Alert, Button, Col, Form, Nav, Row, Tab } from 'react-bootstrap';
import { HP_COLS } from '../../../../../../server/constants/hpCol.constant';
import { HP } from '../../../../../../server/models/design/performance.model';
import {
  fetchHeatpump,
  fetchHeatpumps,
  fetchHpFile,
  uploadHPFile
} from '../../../../api/heatpump.api';
import Bloc from '../../../../components/Bloc/Bloc';
import Card from '../../../../components/Card/Card';
import LinesChart from '../../../../components/Chart/LinesChart';
import FormInput from '../../../../components/Form/FormInput';
import HPSelect from '../../../../components/HPSelect/HPSelect';
import PerformancePageContext from '../../../../contexts/PerformancePageContext';
import PopupContext from '../../../../contexts/PopupContext';
import { isObjNullOrEmpty } from '../../../../utils/data.utils';
import {
  checkForFileError,
  downloadFile,
  parseFileName
} from '../../../../utils/file.utils';
import { getHPAData, getHPGData } from '../../../../utils/heatpump.utils';
import { getDefaultValue } from '../../../../utils/project.utils';

const HeatPumpsBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const {
    tempProject,
    addError,
    removeError,
    setTempProject,
    handleOtherValueChange,
    handleSSTParamChange
  } = useContext(PerformancePageContext);
  const { openInfoModal, openErrorToast, openToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [HPGs, setHPGs] = useState();
  const [HPAs, setHPAs] = useState();
  const [HPA, setHPA] = useState();
  const [HPG, setHPG] = useState();
  const [file, setFile] = useState();
  const [HPAWarning, setHPAWarning] = useState();
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        await getHeatPumps();
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      try {
        const { HPGFilename, HPGFileID } = tempProject.otherValues;
        const heatpump = await fetchHeatpump(HPGFileID);
        setHPG({
          data: getHPGData(i18n, heatpump),
          fileId: HPGFileID,
          filename: HPGFilename,
          manufacturer: HPGFilename.split('_')[1]
        });
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, [tempProject.otherValues.HPGFileID]);

  useEffect(() => {
    (async () => {
      try {
        const { HPAFilename, HPAFileID, InitHPAHeatingEnabled } =
          tempProject.otherValues;
        const heatpump = await fetchHeatpump(HPAFileID);
        const data = getHPAData(i18n, heatpump);
        setHPA(() => ({
          data,
          fileId: HPAFileID,
          filename: HPAFilename,
          manufacturer: HPAFilename.split('_')[1]
        }));

        const warning =
          isObjNullOrEmpty(data.Heating) && InitHPAHeatingEnabled
            ? i18n._('performance.HPA.warning.heatingEnabled')
            : null;
        setHPAWarning(() => warning);
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, [tempProject.otherValues.HPAFileID]);

  useEffect(() => {
    if (!file) return;
    (async () => {
      try {
        await uploadHPFile(file);
        await getHeatPumps();
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    })();
  }, [file]);
  //#endregion

  //#region [methods]
  const getHeatPumps = async () => {
    try {
      const { hpas, hpgs } = await fetchHeatpumps();
      setHPGs(hpgs);
      setHPAs(hpas);
    } catch (err) {
      throw err;
    }
  };

  const handleHpgFileChange = ({ fileId, filename }) => {
    const tempProjectCopy = { ...tempProject };
    tempProjectCopy.otherValues.HPGFileID = fileId;
    tempProjectCopy.otherValues.HPGFilename = filename;
    setTempProject(tempProjectCopy);
  };

  const handleHpaFileChange = ({ fileId, filename }) => {
    const tempProjectCopy = { ...tempProject };
    tempProjectCopy.otherValues.HPAFileID = fileId;
    tempProjectCopy.otherValues.HPAFilename = filename;
    setTempProject(tempProjectCopy);
  };

  const getLayout = (xAxis, yAxis) => ({
    xaxis: {
      title: {
        text: xAxis
      }
    },
    yaxis: {
      title: {
        text: yAxis
      }
    }
  });

  const handleFileChange = (evt) => {
    try {
      const csvReader = new FileReader();
      const file = evt.target.files[0];
      if (!file) return;

      // on vérifie le nom du fichier
      const pattern =
        /^(COP|EER|COP-EER)_[^_]+_(Chiller|HeatPump|RéseauChaleur)_(AirWater|WaterWater)_[^_]+(_[^_]+)?\.csv$/;
      if (!pattern.test(file.name)) {
        openToast(
          i18n._('hp.fileError.title'),
          <span>
            {i18n._('hp.fileError.body')}
            <FontAwesomeIcon
              icon='circle-question'
              className='info-icon'
              onClick={() =>
                openInfoModal(
                  i18n._('performance.hpModal.title'),
                  i18n._('performance.hpModal.body')
                )
              }
            />
          </span>
        );
        return;
      }

      // on vérifie le contenu du fichier
      const { fileExt } = parseFileName(file.name);
      csvReader.readAsText(file);
      csvReader.onload = (event) => {
        try {
          const error = checkForFileError(
            i18n,
            event.target.result,
            fileExt,
            HP_COLS
          );
          if (error) {
            console.error(error);
            openToast(i18n._('hp.error'), error);
          } else {
            setFile(file);
            openToast(
              i18n._('hp.success.title'),
              i18n._('hp.success.body', { file: file.name }),
              'success'
            );
          }
        } catch (err) {
          console.error(err);
          openErrorToast(err);
        }
      };
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleDlClick = async (hpFileId, hpFilename) => {
    try {
      const file = await fetchHpFile(hpFileId);
      downloadFile(hpFilename, new Blob([file], { type: 'text/csv' }));
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const hpgLayout = getLayout(
    i18n._('performance.hpg.xaxis'),
    i18n._('performance.hpg.yaxis')
  );

  const hpaHeatLayout = getLayout(
    i18n._('performance.hpa.heat.xaxis'),
    i18n._('performance.hpa.heat.yaxis')
  );

  const hpaColdLayout = getLayout(
    i18n._('performance.hpa.cold.xaxis'),
    i18n._('performance.hpa.cold.yaxis')
  );

  const {
    InitInjectionTemperatureDelta,
    InitInjectionTemperatureMin,
    HPAPenaltyTemperatureMin,
    HPAPenaltyTemperatureMax,
    HPAPenaltyPercentageMax
  } = tempProject.otherValues;
  const { InitStationCondTemperatureDelta } = tempProject.substations[0];
  return HPGs && HPAs && HPG && HPA ? (
    <Fragment>
      <Bloc
        className='performance-hp-bloc'
        title={i18n._('performance.heatPumps')}
      >
        <Row>
          <Col xs={6}>
            <FormInput
              label={i18n._('performance.InitStationCondTemperatureDelta')}
              value={InitStationCondTemperatureDelta}
              param={HP.InitStationCondTemperatureDelta}
              onBlur={(value) =>
                handleSSTParamChange('InitStationCondTemperatureDelta', value)
              }
              addError={addError}
              removeError={removeError}
              bottomText={getDefaultValue(
                i18n,
                HP.InitStationCondTemperatureDelta
              )}
              unit
            />
          </Col>
        </Row>
        <Form.Group controlId='formFile'>
          <span className='form-input-label'>
            <Form.Label>{i18n._('performance.uploadHP')}</Form.Label>
            <FontAwesomeIcon
              icon='circle-question'
              className='info-icon'
              onClick={() =>
                openInfoModal(
                  i18n._('performance.hpModal.title'),
                  i18n._('performance.hpModal.body')
                )
              }
            />
          </span>
          <Form.Control type='file' accept='.csv' onChange={handleFileChange} />
        </Form.Group>
        <div>
          <div>
            <Form.Group>
              <Form.Label className='form-input-label'>
                {i18n._('performance.HPGFilename')}
              </Form.Label>
              <div className='performance-hp-select'>
                <HPSelect
                  heatpumps={HPGs}
                  selectedHP={HPG}
                  onHPChange={(file) => handleHpgFileChange(file)}
                />
                <Button
                  variant='outline-secondary'
                  onClick={async () =>
                    await handleDlClick(HPG.fileId, HPG.filename)
                  }
                >
                  <FontAwesomeIcon icon='download' />
                  {i18n._('download')}
                </Button>
              </div>
            </Form.Group>
          </div>
          <Tab.Container defaultActiveKey='withGlycol'>
            <Nav variant='tabs'>
              {HPG.data?.B && (
                <Nav.Item>
                  <Nav.Link eventKey='withGlycol'>
                    <span className='nav-link-span'>
                      {i18n._('performance.withGlycol')}
                    </span>
                  </Nav.Link>
                </Nav.Item>
              )}
              {HPG.data?.W && (
                <Nav.Item>
                  <Nav.Link eventKey='withoutGlycol'>
                    <span className='nav-link-span'>
                      {i18n._('performance.withoutGlycol')}
                    </span>
                  </Nav.Link>
                </Nav.Item>
              )}
            </Nav>
            <Tab.Content>
              {HPG.data?.B && (
                <Tab.Pane eventKey='withGlycol'>
                  <div className='performance-charts-tab-pane'>
                    {HPG.data.B.Heating && (
                      <Card title={i18n._('performance.heating')}>
                        <LinesChart
                          data={Object.values(HPG.data.B.Heating)}
                          filename={i18n._('hpg.b.heating.filename', {
                            hpg: HPG.name
                          })}
                          layout={hpgLayout}
                        />
                      </Card>
                    )}
                  </div>
                </Tab.Pane>
              )}
              {HPG.data?.W && (
                <Tab.Pane eventKey='withoutGlycol'>
                  <div className='performance-charts-tab-pane'>
                    {HPG.data.W.Heating && (
                      <Card title={i18n._('performance.heating')}>
                        <LinesChart
                          data={Object.values(HPG.data.W.Heating)}
                          filename={i18n._('hpg.w.heating.filename', {
                            hpg: HPG.name
                          })}
                          layout={hpgLayout}
                        />
                      </Card>
                    )}
                  </div>
                </Tab.Pane>
              )}
            </Tab.Content>
          </Tab.Container>
        </div>
        <div className='mt-3'>
          <Form.Group>
            <Form.Label className='form-input-label'>
              {i18n._('performance.HPAFilename')}
            </Form.Label>
            <div className='performance-hp-select'>
              <HPSelect
                heatpumps={HPAs}
                selectedHP={HPA}
                onHPChange={(file) => handleHpaFileChange(file)}
              />
              <Button
                variant='outline-secondary'
                onClick={async () =>
                  await handleDlClick(HPA.fileId, HPA.filename)
                }
              >
                <FontAwesomeIcon icon='download' />
                {i18n._('download')}
              </Button>
            </div>
            {HPAWarning && (
              <Alert variant='warning' className='performance-warning'>
                {HPAWarning}
              </Alert>
            )}
          </Form.Group>
          <div className='performance-charts-tab-pane'>
            {HPA.data?.Heating && (
              <Card title={i18n._('performance.heating')}>
                <LinesChart
                  data={Object.values(HPA.data.Heating)}
                  filename={i18n._('hpa.heating.filename', {
                    hpa: HPA.name
                  })}
                  layout={hpaHeatLayout}
                />
              </Card>
            )}
            {HPA.data?.Cooling && (
              <Card title={i18n._('performance.cooling')}>
                <LinesChart
                  data={Object.values(HPA.data.Cooling)}
                  filename={i18n._('hpa.cooling.filename', {
                    hpa: HPA.name
                  })}
                  layout={hpaColdLayout}
                />
              </Card>
            )}
          </div>
        </div>
        <Row>
          <Col>
            <FormInput
              label={i18n._('performance.InitInjectionTemperatureDelta')}
              value={InitInjectionTemperatureDelta}
              param={HP.InitInjectionTemperatureDelta}
              onBlur={(value) =>
                handleOtherValueChange('InitInjectionTemperatureDelta', value)
              }
              addError={addError}
              removeError={removeError}
              bottomText={getDefaultValue(
                i18n,
                HP.InitInjectionTemperatureDelta
              )}
              unit
            />
          </Col>
          <Col>
            <FormInput
              label={i18n._('performance.InitInjectionTemperatureMin')}
              value={InitInjectionTemperatureMin}
              param={HP.InitInjectionTemperatureMin}
              onBlur={(value) =>
                handleOtherValueChange('InitInjectionTemperatureMin', value)
              }
              addError={addError}
              removeError={removeError}
              bottomText={getDefaultValue(i18n, HP.InitInjectionTemperatureMin)}
              unit
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput
              label={i18n._('performance.HPAPenaltyTemperatureMin')}
              value={HPAPenaltyTemperatureMin}
              param={HP.HPAPenaltyTemperatureMin}
              onBlur={(value) =>
                handleOtherValueChange('HPAPenaltyTemperatureMin', value)
              }
              addError={addError}
              removeError={removeError}
              bottomText={getDefaultValue(i18n, HP.HPAPenaltyTemperatureMin)}
              unit
            />
          </Col>
          <Col>
            <FormInput
              label={i18n._('performance.HPAPenaltyTemperatureMax')}
              value={HPAPenaltyTemperatureMax}
              param={HP.HPAPenaltyTemperatureMax}
              onBlur={(value) =>
                handleOtherValueChange('HPAPenaltyTemperatureMax', value)
              }
              addError={addError}
              removeError={removeError}
              bottomText={getDefaultValue(i18n, HP.HPAPenaltyTemperatureMax)}
              unit
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <FormInput
              label={i18n._('performance.HPAPenaltyPercentageMax')}
              value={HPAPenaltyPercentageMax}
              param={HP.HPAPenaltyPercentageMax}
              onBlur={(value) =>
                handleOtherValueChange('HPAPenaltyPercentageMax', value)
              }
              addError={addError}
              removeError={removeError}
              bottomText={getDefaultValue(i18n, HP.HPAPenaltyPercentageMax)}
              unit
            />
          </Col>
        </Row>
      </Bloc>
    </Fragment>
  ) : null;
  //#endregion
};

export default HeatPumpsBloc;
