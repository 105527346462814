import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { HPA } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import EditTable from '../../../../components/EditTable/EditTable';
import CostPageContext from '../../../../contexts/CostPageContext';

const HpaBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleTableValueChange } =
    useContext(CostPageContext);
  //#endregion

  //#region [render]
  const { InitHPACostHeat, InitHPACostHeatCold } = tempProject.otherValues;
  return (
    <Bloc title={i18n._('cost.hpa')}>
      <Row>
        <Col xs={6}>
          <span className='form-input-label'>
            {i18n._('cost.InitHPACostLabel')}
          </span>
          <EditTable
            titles={[
              i18n._('cost.InitHPACostSlices'),
              i18n._('cost.InitHPACostHeat'),
              i18n._('cost.InitHPACostHeatCold')
            ]}
            values={JSON.stringify([InitHPACostHeat, InitHPACostHeatCold])}
            params={[HPA.InitHPACostHeat, HPA.InitHPACostHeatCold]}
            onBlur={handleTableValueChange}
            addError={addError}
            removeError={removeError}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default HpaBloc;
