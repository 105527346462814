import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { PV } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import CostPageContext from '../../../../contexts/CostPageContext';
import { getDefaultValue } from '../../../../utils/project.utils';

const PhotovoltaicBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleParamChange } =
    useContext(CostPageContext);
  //#endregion

  //#region [render]
  const {
    InitPVModuleCostA,
    InitPVModuleCostB,
    InitPVModuleSupportCostA,
    InitPVModuleSupportCostB,
    InitPVInverterCostA,
    InitPVInverterCostB
  } = tempProject;
  return (
    <Bloc title={i18n._('cost.photovoltaic')}>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitPVModuleCostA')}
            value={InitPVModuleCostA}
            param={PV.InitPVModuleCostA}
            onBlur={(value) => handleParamChange('InitPVModuleCostA', value)}
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, PV.InitPVModuleCostA)}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitPVModuleCostB')}
            value={InitPVModuleCostB}
            param={PV.InitPVModuleCostB}
            onBlur={(value) => handleParamChange('InitPVModuleCostB', value)}
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, PV.InitPVModuleCostB)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitPVModuleSupportCostA')}
            value={InitPVModuleSupportCostA}
            param={PV.InitPVModuleSupportCostA}
            onBlur={(value) =>
              handleParamChange('InitPVModuleSupportCostA', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, PV.InitPVModuleSupportCostA)}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitPVModuleSupportCostB')}
            value={InitPVModuleSupportCostB}
            param={PV.InitPVModuleSupportCostB}
            onBlur={(value) =>
              handleParamChange('InitPVModuleSupportCostB', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, PV.InitPVModuleSupportCostB)}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitPVInverterCostA')}
            value={InitPVInverterCostA}
            param={PV.InitPVInverterCostA}
            onBlur={(value) => handleParamChange('InitPVInverterCostA', value)}
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, PV.InitPVInverterCostA)}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitPVInverterCostB')}
            value={InitPVInverterCostB}
            param={PV.InitPVInverterCostB}
            onBlur={(value) => handleParamChange('InitPVInverterCostB', value)}
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, PV.InitPVInverterCostB)}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default PhotovoltaicBloc;
