import React from 'react';
import { useNavigate } from 'react-router-dom';
import { COMPANY_KEY } from '../../../../server/constants/storage.constant';
import useAuth from '../../hooks/useAuth';
import './ProjectsPage.css';
import AdminProjectsBody from './components/AdminProjectsBody';
import ProjectsBody from './components/ProjectsBody';

const ProjectsPage = () => {
  //#region [router]
  const navigate = useNavigate();
  //#endregion

  //#region [auth]
  const { user } = useAuth();
  //#endregion

  //#region [methods]
  const handleCompanyChange = (companyId, isActive) => {
    localStorage.setItem(COMPANY_KEY, companyId);
    navigate(`/company/${companyId}/projects?active=${isActive}`);
  };
  //#endregion

  //#region [render]
  return user ? (
    user.IS_SUPERADMIN ? (
      <AdminProjectsBody onCompanyChange={handleCompanyChange} />
    ) : (
      <ProjectsBody onCompanyChange={handleCompanyChange} />
    )
  ) : null;
  //#endregion
};

export default ProjectsPage;
