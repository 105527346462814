import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Badge, Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { COMPUTE_STATUS } from '../../../../../../../server/constants/compute.constant';
import { COMPUTE_TYPE } from '../../../../../../../server/constants/constraint.constant';
import { UNIT } from '../../../../../../../server/constants/unit.constant';
import CompanyContext from '../../../../../contexts/CompanyContext';
import ProjectContext from '../../../../../contexts/ProjectContext';
import {
  formatValueWithUnit,
  roundNumber
} from '../../../../../utils/data.utils';
import { formatDateAndHour } from '../../../../../utils/date.utils';
import './ComputeCard.css';

const ComputeCard = ({ compute, chartColor }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  const { project } = useContext(ProjectContext);
  //#endregion

  //#region [render]
  const {
    type,
    goalConstraint,
    percent,
    message,
    dataviz,
    error,
    status,
    createdAt,
    computeId
  } = compute;

  let badge;
  let badgeClassName;
  let formattedPercent;
  let goal;

  switch (status) {
    case COMPUTE_STATUS.PENDING:
      badgeClassName = 'compute-card-badge-pending';
      badge = i18n._('computeCard.status.pending');
      break;
    case COMPUTE_STATUS.IN_PROGRESS:
      badgeClassName = 'compute-card-badge-inProgress';
      badge = i18n._('computeCard.status.inProgress');
      formattedPercent = formatValueWithUnit(
        i18n,
        roundNumber(percent, 0),
        UNIT.PERCENT
      );
      break;
    case COMPUTE_STATUS.SUCCESS:
      badgeClassName = 'compute-card-badge-success';
      badge = i18n._('computeCard.status.success');
      break;
    case COMPUTE_STATUS.ERROR:
      badgeClassName = 'compute-card-badge-error';
      badge = i18n._('computeCard.status.error');
      break;
    case COMPUTE_STATUS.CANCELED:
      badgeClassName = 'compute-card-badge-canceled';
      badge = i18n._('computeCard.status.canceled');
      break;
  }
  if (goalConstraint && goalConstraint.value) {
    const { key, value } = goalConstraint;
    goal = i18n._(`compute.goal.${key}`, {
      value: formatValueWithUnit(
        i18n,
        roundNumber(value * 100, 0),
        UNIT.PERCENT
      )
    });
  }

  return (
    <div
      className={`compute-card ${
        status === COMPUTE_STATUS.IN_PROGRESS ? 'inprogress' : ''
      }`}
    >
      <div className='compute-card-top'>
        <div className='compute-card-left'>
          <Badge className={badgeClassName}>{badge}</Badge>
          {type === COMPUTE_TYPE.OPTI &&
            (status === COMPUTE_STATUS.IN_PROGRESS ||
              status === COMPUTE_STATUS.SUCCESS) && (
              <FontAwesomeIcon
                icon='chart-line'
                style={{ color: chartColor }}
              />
            )}
          <span className='compute-card-left-span'>
            <FontAwesomeIcon icon='fa-regular fa-calendar' />
            {formatDateAndHour(i18n, createdAt)}
          </span>
          {goal && (
            <span className='compute-card-left-span'>
              <FontAwesomeIcon icon='calculator' />
              {goal}
            </span>
          )}
        </div>
        {formattedPercent && (
          <span className='compute-card-percent'>
            <Spinner variant='dark' />
            {formattedPercent}
          </span>
        )}
        {status === COMPUTE_STATUS.SUCCESS && (
          <Link
            to={`/company/${selectedCompany.id}/project/${project.AhsID}/results/${computeId}`}
          >
            <FontAwesomeIcon icon='eye' />
            {i18n._('computeCard.result')}
          </Link>
        )}
      </div>
      {type === COMPUTE_TYPE.OPTI &&
        (status === COMPUTE_STATUS.IN_PROGRESS ||
          status === COMPUTE_STATUS.SUCCESS) && (
          <div className='compute-card-bottom'>
            {dataviz && (
              <div className='compute-card-bottom-dataviz'>
                {status === COMPUTE_STATUS.IN_PROGRESS && (
                  <span>
                    <FontAwesomeIcon icon='stopwatch' />
                    {i18n._('computeCard.dataviz.remaining', {
                      remaining: dataviz.remaining
                    })}
                  </span>
                )}
                <span>
                  <FontAwesomeIcon icon='check-circle' />
                  {i18n._('computeCard.dataviz.success', {
                    success: dataviz.success
                  })}
                </span>
                <span>
                  <FontAwesomeIcon icon='exclamation-circle' />
                  {i18n._('computeCard.dataviz.failure', {
                    failure: dataviz.failure
                  })}
                </span>
              </div>
            )}
            {message && <p className='compute-card-bottom-msg'>{message}</p>}
          </div>
        )}
      {status === COMPUTE_STATUS.ERROR && (
        <div className='compute-card-bottom'>
          <span>
            <FontAwesomeIcon icon='xmark' />
            <span>{error}</span>
          </span>
        </div>
      )}
    </div>
  );
  //#endregion
};

export default React.memo(ComputeCard);
