import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import './Counter.css';

export const Counter = ({ min, max, value, onChange }) => {
  //#region [states]
  const [count, setCount] = useState(value);
  //#endregion

  //#region [methods]
  const handleMinusClick = () => {
    if (count === min) return;
    const newCount = count - 1;
    setCount(newCount);
    onChange(newCount);
  };

  const handlePlusClick = () => {
    if (count === max) return;
    const newCount = count + 1;
    setCount(newCount);
    onChange(newCount);
  };
  //#endregion

  //#region [render]
  return (
    <div className='counter'>
      <FontAwesomeIcon icon='circle-minus' onClick={handleMinusClick} />
      <span>{count}</span>
      <FontAwesomeIcon icon='circle-plus' onClick={handlePlusClick} />
    </div>
  );
  //#endregion
};
