// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compare-results-btns {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 5px;
    margin-top: 10px;
}

.compare-results-btns button {
    font-size: 15px;
}

.compare-results-btns button:disabled {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
    opacity: 1;
}

.compare-results-btns .btn-primary:focus {
    background-color: var(--primary-color);
    border-color: var(--primary-color);
}

.compare-results-title {
    font-size: 17px;
    font-weight: bold;
    color: var(--dark-grey);
    margin: 20px 0 10px 0;
}`, "",{"version":3,"sources":["webpack://./src/components/CompareResultsElements/CompareResultsElements.css"],"names":[],"mappings":"AAAA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,yBAAyB;QACrB,sBAAsB;YAClB,mBAAmB;IAC3B,QAAQ;IACR,gBAAgB;AACpB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,sCAAsC;IACtC,kCAAkC;IAClC,UAAU;AACd;;AAEA;IACI,sCAAsC;IACtC,kCAAkC;AACtC;;AAEA;IACI,eAAe;IACf,iBAAiB;IACjB,uBAAuB;IACvB,qBAAqB;AACzB","sourcesContent":[".compare-results-btns {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    gap: 5px;\n    margin-top: 10px;\n}\n\n.compare-results-btns button {\n    font-size: 15px;\n}\n\n.compare-results-btns button:disabled {\n    background-color: var(--primary-color);\n    border-color: var(--primary-color);\n    opacity: 1;\n}\n\n.compare-results-btns .btn-primary:focus {\n    background-color: var(--primary-color);\n    border-color: var(--primary-color);\n}\n\n.compare-results-title {\n    font-size: 17px;\n    font-weight: bold;\n    color: var(--dark-grey);\n    margin: 20px 0 10px 0;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
