import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Badge, Button, Form, Modal } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { deleteResults } from '../../../../../api/compute.api';
import CompanyContext from '../../../../../contexts/CompanyContext';
import PopupContext from '../../../../../contexts/PopupContext';
import ProjectContext from '../../../../../contexts/ProjectContext';
import ResultContext from '../../../../../contexts/ResultContext';
import { getSingleValueName } from '../../../../../utils/compute.utils';
import { isArrNullOrEmpty } from '../../../../../utils/data.utils';
import './DeleteModal.css';

const DeleteModal = ({ groupedResults, selectedResultId, isOpen, onClose }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  const { project } = useContext(ProjectContext);
  const { setGroupedResults, setResultsComparator } = useContext(ResultContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [modalList, setModalList] = useState([]);
  //#endregion

  //#region [refs]
  const lastCheckboxIndexClicked = useRef();
  //#endregion

  //#region [effects]
  useEffect(() => {
    setModalList(() => {
      if (!groupedResults || !selectedResultId || !isOpen) return [];
      const list = [];
      let resultIndex = 0;
      groupedResults.forEach((group) => {
        group.computes.forEach((result) => {
          if (result.ComputeID === selectedResultId) {
            lastCheckboxIndexClicked.current = resultIndex;
          }
          list.push({
            ...result,
            checked: result.ComputeID === selectedResultId,
            ComputeName: getSingleValueName(i18n, project, group, result)
          });
          resultIndex++;
        });
      });
      return list;
    });
  }, [groupedResults, selectedResultId, isOpen]);
  //#enedregion

  //#region [memos]
  const idsToDelete = useMemo(() => {
    return modalList
      .filter((result) => result.checked)
      .map((result) => result.ComputeID);
  }, [modalList]);
  //#endregion

  //#region [methods]
  const handleDeleteCheckboxClick = (evt, index) => {
    const prevLastIndex = lastCheckboxIndexClicked.current;
    setModalList((list) => {
      const copyList = [...list];
      if (copyList[index].checked || !evt.shiftKey || prevLastIndex >= index) {
        copyList[index].checked = !copyList[index].checked;
      } else {
        for (let i = prevLastIndex; i <= index; ++i) {
          copyList[i].checked = true;
        }
      }
      return copyList;
    });
    lastCheckboxIndexClicked.current = index;
  };

  const handleDeleteResultsClick = async () => {
    try {
      await deleteResults(idsToDelete);
      let newGroups = [...groupedResults];
      newGroups.forEach((group) => {
        group.computes = group.computes.filter(
          (result) => !idsToDelete.includes(result.ComputeID)
        );
      });
      newGroups = newGroups.filter(
        (group) => !isArrNullOrEmpty(group.computes)
      );
      setGroupedResults(() => newGroups);
      setResultsComparator((compareResults) => {
        const compareResultsCopy = [...compareResults];
        compareResults.forEach((result, index) => {
          if (idsToDelete.includes(result.ComputeID))
            compareResultsCopy.splice(index, 1);
        });
        return compareResultsCopy;
      });
      if (newGroups.length === 0) {
        navigate(
          `/company/${selectedCompany.id}/project/${project.AhsID}/noresults`
        );
      } else if (idsToDelete.includes(selectedResultId)) {
        navigate(
          `/company/${selectedCompany.id}/project/${project.AhsID}/results/${newGroups[0].computes[0].ComputeID}`
        );
      }
      onClose();
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  return (
    <Modal show={isOpen} onHide={onClose} size='xl' className='delete-modal'>
      <Modal.Header closeButton>
        {i18n._('results.deleteResults.title')}
        <FontAwesomeIcon icon='trash-alt' />
      </Modal.Header>
      <Modal.Body>
        <p className='delete-warning'>
          {i18n._('results.deleteResults.select')}{' '}
          <b>{i18n._('results.deleteResults.warning')}</b>
        </p>
        <div className='delete-list'>
          {modalList.map((result, index) => (
            <div key={'delete_option_' + index} className='result-page-option'>
              <Form.Check
                type='checkbox'
                checked={result.checked}
                onChange={() => {}} // ce onChange vide est obligatoire pour ne pas avoir de warning/erreur dans la console
                onClick={(evt) => handleDeleteCheckboxClick(evt, index)} // on choisit onClick plutôt que onChange pour avoir accès à la propriété evt.shiftKey
              />
              <div
                className='result-option'
                onClick={(evt) => handleDeleteCheckboxClick(evt, index)}
              >
                <span
                  className='result-option-label ellipsis'
                  title={result.ComputeName}
                >
                  {result.ComputeName}
                </span>
                {result.tags.feasibility && (
                  <Badge pill className='feasibility-badge'>
                    {i18n._('tag.feasibility')}
                  </Badge>
                )}
                {result.tags.project && (
                  <Badge pill className='project-badge'>
                    {i18n._('tag.project')}
                  </Badge>
                )}
              </div>
            </div>
          ))}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button variant='secondary' onClick={onClose}>
          {i18n._('cancel')}
        </Button>
        <Button
          variant='danger'
          onClick={handleDeleteResultsClick}
          disabled={idsToDelete.length === 0}
        >
          {i18n._('results.deleteResults', { nb: idsToDelete.length })}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteModal;
