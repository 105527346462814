import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { HPG } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import EditTable from '../../../../components/EditTable/EditTable';
import CostPageContext from '../../../../contexts/CostPageContext';

const HpgBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleTableValueChange } =
    useContext(CostPageContext);
  //#endregion

  //#region [render]
  const { InitHPGCost } = tempProject.otherValues;
  return (
    <Bloc title={i18n._('cost.hpg')}>
      <Row>
        <Col xs={6}>
          <span className='form-input-label'>
            {i18n._('cost.InitHPGCostLabel')}
          </span>
          <EditTable
            titles={[
              i18n._('cost.InitHPGCostSlices'),
              i18n._('cost.InitHPGCost')
            ]}
            values={JSON.stringify([InitHPGCost])}
            params={[HPG.InitHPGCost]}
            onBlur={handleTableValueChange}
            addError={addError}
            removeError={removeError}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default HpgBloc;
