import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import {
  NB_HOURS_PER_YEAR,
  NB_MILLISECONDS_PER_HOUR
} from '../../../../../../../../../server/constants/date.constant';
import { DETAILED_RESULTS_SST_KEYS } from '../../../../../../../../../server/constants/result.constant';
import { SOURCE } from '../../../../../../../../../server/constants/source.constant';
import LinesChart from '../../../../../../../components/Chart/LinesChart';
import { MODE } from '../../../../../../../constants/plot.constant';
import { DETAILED_RESULTS_SST_COLORS } from '../../../../../../../styles/colors/detailedResult.color';
import { SOURCES_CHART_COLORS } from '../../../../../../../styles/colors/source.color';
import {
  formatValue,
  isArrNullOrEmpty,
  isNull,
  isObjNullOrEmpty
} from '../../../../../../../utils/data.utils';
import './PowerLoadCurve.css';

const PowerLoadCurveTotal = ({ result, need, sorted }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memo]
  const data = useMemo(() => {
    if (isObjNullOrEmpty(result.details)) return null;
    const newData = [];

    // on récupère les clés des différentes sources
    const srcKeys = Object.keys(DETAILED_RESULTS_SST_KEYS[need].sources).filter(
      (srcKey) =>
        Object.keys(result.details).some((key) => key.includes(srcKey))
    );
    if (isArrNullOrEmpty(srcKeys)) return null;

    // on récupère la clé du total
    const totalKey = Object.keys(DETAILED_RESULTS_SST_KEYS[need].total).find(
      (srcKey) =>
        Object.keys(result.details).some((key) => key.includes(srcKey))
    );
    if (!totalKey) return null;

    // l'abcisse = nombre d'heures par année
    const x = new Array(NB_HOURS_PER_YEAR).fill(0).map((_, index) => index);

    // utils
    const getSrc = (srcKey) => {
      return Object.values(SOURCE).find((source) =>
        srcKey.toLowerCase().includes(source)
      );
    };

    const getArea = (y, srcY, src, fill) => {
      if (!sorted) {
        let timestamp = new Date(new Date().getFullYear(), 0, 0).getTime();
        for (let i = 0; i < NB_HOURS_PER_YEAR; ++i) {
          timestamp += NB_MILLISECONDS_PER_HOUR;
          x[i] = new Date(timestamp).toISOString();
        }
      }
      return {
        x,
        y,
        mode: MODE.NONE,
        fill,
        fillcolor: SOURCES_CHART_COLORS[src],
        name: i18n._(`source.${src}`),
        text: formatValue(srcY, 2),
        hovertemplate: '%{text}'
      };
    };

    // la courbe représentant la couverture des besoins
    const totalValues = new Array(NB_HOURS_PER_YEAR);
    result.substations.forEach((sst) => {
      for (let i = 0; i < totalValues.length; ++i) {
        if (isNull(totalValues[i])) totalValues[i] = { total: 0, hour: i };
        totalValues[i].total += result.details[`${sst}_${totalKey}`][i];
      }
    });

    // si c'est une monotone alors on la trie par valeurs décroissantes
    if (sorted) totalValues.sort((a, b) => b.total - a.total);

    const totalY = totalValues.map((value) => value.total);
    const name = sorted
      ? i18n._('results.energyAndCarbon.powerLoadCurve.name')
      : i18n._('results.energyAndCarbon.yearNeedsCurve.total.name', {
          need: i18n._(`need.${need}`)
        });
    newData.push({
      x,
      y: totalY,
      mode: MODE.LINES,
      name,
      line: {
        color: DETAILED_RESULTS_SST_COLORS[totalKey],
        width: sorted ? 2 : 0.5
      }
    });

    // les autres courbes
    /**
     * Ordre de somme des aires
        - Chauffage : (TFP + HPG) → HPSolaireThermique→ HPA → Gaz
        - ECS : (TFP + HPG) → HPSolaireThermique→ HPA → Gaz
        - Climatisation : (TFP + HPG) → HPA → ITES
     */
    let tempTotalValuesY;
    const tfpSrcKey = srcKeys.find(
      (sstSrcKey) => getSrc(sstSrcKey) === SOURCE.TFP
    );
    srcKeys
      .filter((sstSrcKey) => getSrc(sstSrcKey) !== SOURCE.TFP)
      .forEach((srcKey, i) => {
        const source = getSrc(srcKey);
        const valuesY = new Array(totalValues.length);
        const srcY = new Array(totalValues.length);
        if (source === SOURCE.HPG && tfpSrcKey) {
          // TFP est compris dans HPG
          totalValues.forEach((value, j) => {
            let sum = 0;
            result.substations.forEach((sst) => {
              sum +=
                result.details[`${sst}_${srcKey}`][value.hour] +
                result.details[`${sst}_${tfpSrcKey}`][value.hour];
            });
            // le total temporaire de toutes les valeurs à l'heure "value.hour"
            valuesY[j] = (tempTotalValuesY ? tempTotalValuesY[j] : 0) + sum;

            // la vraie valeur à l'heure "value.hour"
            srcY[j] = sum;
          });
        } else {
          totalValues.forEach((value, j) => {
            let sum = 0;
            result.substations.forEach((sst) => {
              sum += result.details[`${sst}_${srcKey}`][value.hour];
            });
            // le total temporaire de toutes les valeurs à l'heure "value.hour"
            valuesY[j] = (tempTotalValuesY ? tempTotalValuesY[j] : 0) + sum;

            // la vraie valeur à l'heure "value.hour"
            srcY[j] = sum;
          });
        }
        tempTotalValuesY = [...valuesY];
        newData.push(
          getArea(valuesY, srcY, source, i === 0 ? 'tozeroy' : 'tonexty')
        );
      });

    return newData;
  }, [result.details]);
  //#endregion

  //#region [render]
  let title;
  let layout = {
    margin: { b: 70 },
    xaxis: undefined,
    yaxis: {
      title: {
        text: i18n._('results.energyAndCarbon.powerLoadCurve.yaxis'),
        standoff: 30,
        showlegend: true
      }
    }
  };
  if (sorted) {
    title = i18n._('results.energyAndCarbon.powerLoadCurve.need', {
      need: i18n._(`need.${need}`).toLowerCase()
    });
    layout.xaxis = {
      title: i18n._('results.energyAndCarbon.powerLoadCurve.xaxis')
    };
  } else {
    title = i18n._('results.energyAndCarbon.yearNeedsCoverageCurve.need', {
      need: i18n._(`need.${need}`).toLowerCase()
    });
    layout.xaxis = {
      tickformat: '%b',
      dtick: 'M1'
    };
  }
  return data ? (
    <LinesChart
      data={data}
      layout={layout}
      title={title}
      filename={i18n._('results.energyAndCarbon.powerLoadCurveTotal.filename', {
        result: result.ComputeName,
        title
      })}
    />
  ) : (
    <Alert variant='warning' className='no-power-load'>
      {i18n._('results.energyAndCarbon.noPowerLoadCurve')}
    </Alert>
  );
  //#endregion
};

export default PowerLoadCurveTotal;
