import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import AccentaVsRefTable from '../../../../../components/AccentaVsRefTable/AccentaVsRefTable.jsx';
import CompareResultsCards from '../../../../../components/CompareResultsCards/CompareResultsCards.jsx';
import CompareResultsTable from '../../../../../components/CompareResultsTable/CompareResultsTable.jsx';
import ExpandableTable from '../../../../../components/ExpandableTable/ExpandableTable.jsx';
import Section from '../../../../../components/Section/Section.jsx';
import SectionTitle from '../../../../../components/SectionTitle.jsx';
import ResultContext from '../../../../../contexts/ResultContext.jsx';
import CapexBySSTTable from './components/CapexBySSTTable.jsx';
import DetailedOpexLinesChart from './components/DetailedOpexLinesChart.jsx';
import DetailedOpexTable from './components/DetailedOpexTable.jsx';
import OpexTable from './components/OpexTable.jsx';
import {
  capexOpexTotalCompareTemplate,
  capexTotalCompareTemplate,
  capexTotalTemplates,
  opexTemplates,
  opexTotalCompareTemplate,
  totalOpexCapexTemplate
} from './templates.js';

const EconomicAnalysisSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { selectedResult, comparedResults } = useContext(ResultContext);
  //#endregion

  //#region [memos]
  const CTTemplates = useMemo(() => {
    return capexTotalTemplates(i18n, comparedResults);
  }, [comparedResults]);

  const CTCTemplate = useMemo(() => {
    return capexTotalCompareTemplate(i18n, comparedResults);
  }, [comparedResults]);

  const OTemplates = useMemo(() => {
    return opexTemplates(i18n, comparedResults);
  }, [comparedResults]);

  const OTCTemplate = useMemo(() => {
    return opexTotalCompareTemplate(i18n, comparedResults);
  }, [comparedResults]);

  const COTCTemplate = useMemo(() => {
    return capexOpexTotalCompareTemplate(i18n, comparedResults);
  }, [comparedResults]);

  const TOCTemplate = useMemo(() => {
    return totalOpexCapexTemplate(i18n, comparedResults);
  }, [comparedResults]);
  //#endregion

  //#region [render]
  return (
    <Section title={i18n._('results.economicAnalysis')} open>
      {selectedResult.substations.length > 1 &&
        selectedResult.substations.map((sst) => (
          <CapexBySSTTable
            key={'economicAnalysis_capexBySst_' + sst}
            substation={sst}
          />
        ))}
      <SectionTitle
        level={2}
        title={i18n._('results.economicAnalysis.capexTotal')}
      />
      {CTTemplates.map((template, index) => (
        <CompareResultsTable
          key={'economicAnalysis_capexTotal_template_' + index}
          template={template}
          level={3}
        />
      ))}
      <SectionTitle level={2} title={i18n._('total')} />
      <AccentaVsRefTable template={CTCTemplate} />
      <SectionTitle level={2} title={i18n._('results.economicAnalysis.opex')} />
      {OTemplates.map((template, index) => (
        <CompareResultsTable
          key={'economicAnalysis_opex_template_' + index}
          template={template}
          level={3}
        />
      ))}
      <SectionTitle level={2} title={i18n._('total')} />
      <AccentaVsRefTable template={OTCTemplate} />
      <ExpandableTable>
        <CompareResultsCards
          title={i18n._('results.economicAnalysis.opexTable.title')}
          element={OpexTable}
        />
        <CompareResultsCards
          title={i18n._('results.economicAnalysis.detailedOpexTable.title')}
          element={DetailedOpexTable}
        />
        <CompareResultsCards
          title={i18n._(
            'results.economicAnalysis.detailedOpexLinesChart.title'
          )}
          element={DetailedOpexLinesChart}
        />
      </ExpandableTable>
      <SectionTitle level={2} title={i18n._('total')} />
      <AccentaVsRefTable template={COTCTemplate} />
      <CompareResultsTable template={TOCTemplate} level={3} />
    </Section>
  );
  //#endregion
};

export default EconomicAnalysisSection;
