import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import TwoWayTable from '../../../../../../../components/TwoWayTable/TwoWayTable.jsx';
import { formatValue } from '../../../../../../../utils/data.utils.js';

const COPEERPerYearTable = ({ result, substation }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    const copEerPerYear = substation
      ? !result.ComputeResult.summary.PerSub?.CopEerPerYear[substation]
        ? null
        : result.ComputeResult.summary.PerSub.CopEerPerYear[substation]
      : !result.ComputeResult.summary.COPEERPerYear
      ? null
      : result.ComputeResult.summary.COPEERPerYear;
    if (!copEerPerYear) return null;

    const { InitYearsSimulations } = result.ComputeResult.inp;
    const horizontalTitles = [i18n._('results.average')];
    for (let i = 0; i < InitYearsSimulations; ++i) {
      horizontalTitles.push(
        i18n._('results.year', {
          year: i + 1
        })
      );
    }

    const verticalTitles = [];
    const values = [];
    const copEerTypes = Object.keys(copEerPerYear);
    for (let i = 0; i < copEerTypes.length; ++i) {
      verticalTitles.push(
        i18n._(`results.energyAndCarbon.copEerPerYearTable.${copEerTypes[i]}`)
      );

      values[i] = [];
      for (let j = 0; j < horizontalTitles.length; ++j) {
        if (j === 0) {
          const average =
            copEerPerYear[copEerTypes[i]].reduce(
              (acc, current) => acc + parseFloat(current),
              0
            ) / InitYearsSimulations;
          values[i][j] = formatValue(average, 2);
        } else {
          values[i][j] = formatValue(copEerPerYear[copEerTypes[i]][j - 1], 2);
        }
      }
    }
    return {
      horizontalTitles,
      verticalTitles,
      values
    };
  }, [result.ComputeID, substation]);
  //#endregion

  //#region [render]
  return data ? (
    <TwoWayTable
      horizontalTitles={data.horizontalTitles}
      verticalTitles={data.verticalTitles}
      values={data.values}
    />
  ) : (
    <Alert variant='warning'>{i18n._('results.noData')}</Alert>
  );
  //#endregion
};

export default COPEERPerYearTable;
