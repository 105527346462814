import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useState } from 'react';
import SelectBtn from '../../../../components/SelectBtn/SelectBtn';
import OptimizationContext from '../../../../contexts/OptimizationContext';
import ProjectContext from '../../../../contexts/ProjectContext';
import { isObjNullOrEmpty } from '../../../../utils/data.utils';

const OptiDescriptionsSelect = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { project } = useContext(ProjectContext);
  const { calculationData, setCalculationData, setAreParamsChanged } =
    useContext(OptimizationContext);
  //#endregion

  //#region [states]
  const [descriptions, setDescriptions] = useState([]);
  //#endregion

  //#region [effects]
  useEffect(() => {
    if (!project.children) project.children = [];
    const newDescriptions = [project, ...project.children].map(
      (desc, descIndex) => ({
        selected: calculationData.selectedDescriptionsIndexes.some(
          (index) => index === descIndex
        ),
        text:
          desc.description || i18n._('description', { index: descIndex + 1 })
      })
    );
    // if no description is selected, the first description is automatically selected
    if (!newDescriptions.some((description) => description.selected)) {
      newDescriptions[0].selected = true;
    }
    setDescriptions(newDescriptions);
  }, [project.children, calculationData.selectedDescriptionsIndexes]);
  //#endregion

  //#region [methods]
  const handleDescriptionBtnClick = (descriptionIndex) => {
    if (
      calculationData.selectedDescriptionsIndexes.length === 1 &&
      descriptions[descriptionIndex].selected
    )
      return;

    const descriptionsCopy = [...descriptions];
    descriptionsCopy[descriptionIndex].selected =
      !descriptionsCopy[descriptionIndex].selected;

    const selectedDescriptionsIndexes = [];
    descriptionsCopy.forEach((description, index) => {
      if (description.selected) selectedDescriptionsIndexes.push(index);
    });

    setDescriptions(descriptionsCopy);
    setCalculationData((data) => ({ ...data, selectedDescriptionsIndexes }));
    setAreParamsChanged(true);
  };
  //#endregion

  //#region [render]
  return isObjNullOrEmpty(descriptions) ? null : (
    <div className='compute-descriptions-row'>
      <span className='form-input-label'>
        {i18n._('compute.selectDescriptions')}
      </span>
      <div className='compute-forms-descriptions-btns'>
        {descriptions.map((description, index) => (
          <SelectBtn
            key={description.text + '_' + index}
            onClick={() => handleDescriptionBtnClick(index)}
            selected={description.selected}
            disabled={
              description.selected &&
              calculationData.selectedDescriptionsIndexes.length === 1
            }
          >
            {description.text}
          </SelectBtn>
        ))}
      </div>
    </div>
  );
  //#endregion
};

export default OptiDescriptionsSelect;
