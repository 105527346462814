import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { ACCESS_KEY } from '../../../server/constants/storage.constant';
import useAuth from '../hooks/useAuth';

const RequireAuth = ({ children, redirectTo }) => {
  //#region [auth]
  const { user, handleUserChange, handleLogout } = useAuth();
  //#endregion

  //#region [states]
  const [loading, setLoading] = useState(true);
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        if (
          (!localStorage.getItem(ACCESS_KEY) &&
            !sessionStorage.getItem(ACCESS_KEY)) ||
          user?.UserID
        ) {
          setLoading(false);
        } else {
          setLoading(true);
          await handleUserChange();
          setLoading(false);
        }
      } catch (err) {
        console.error(err);
        await handleLogout();
      }
    })();
  }, [user?.UserID]);
  //#endregion

  //#region [render]
  const accessToken =
    localStorage.getItem(ACCESS_KEY) || sessionStorage.getItem(ACCESS_KEY);
  return loading ? (
    <></>
  ) : accessToken ? (
    children
  ) : (
    <Navigate to={redirectTo} />
  );
  //#endregion
};

export default RequireAuth;
