import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useMemo, useRef, useState } from 'react';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import { fetchProject } from '../../api/project.api';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import CompanyContext from '../../contexts/CompanyContext';
import PopupContext from '../../contexts/PopupContext';
import ProjectContext from '../../contexts/ProjectContext';
import './ProjectPage.css';
import ProjectHeader from './components/Header/Header';

const WITH_HEADER_PATHS = ['compute', 'results', 'no-results'];

const ProjectPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { projectId } = useParams();
  const location = useLocation();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [project, setProject] = useState();
  //#endregion

  //#region [refs]
  const prevPathname = useRef();
  //#endregion

  //#region [effects]
  useEffect(() => {
    // a chaque fois que la route change, on télécharge le projet SAUF SI on est sur la page results et qu'on change de résultat
    (async () => {
      try {
        if (!location || !projectId) return;
        if (
          !prevPathname.current ||
          (!location.pathname.includes('results') &&
            !location.pathname.includes('configs'))
        ) {
          const projectParent = await fetchProject(projectId, true);
          setProject(projectParent);
        }
        prevPathname.current = location.pathname;
      } catch (err) {
        console.error(err);
        if (err.response?.status === 404) {
          navigate('/notfound');
        } else {
          openErrorToast(err);
        }
      }
    })();
  }, [location, projectId]);
  //#endregion

  //#region [memos]
  const currentPage = useMemo(() => {
    if (!location) return null;
    const paths = location.pathname.split('/');
    return paths[5];
  }, [location]);

  const breadcrumb = useMemo(() => {
    if (!project || !currentPage) return null;
    const projectsLink = {
      to: '/',
      name: i18n._('breadcrumb.company', {
        company: selectedCompany.name
      }),
      state: { companyId: selectedCompany.id }
    };
    return WITH_HEADER_PATHS.includes(currentPage)
      ? {
          links: [projectsLink],
          current: project.AhsName
        }
      : {
          links: [
            projectsLink,
            {
              to: `/company/${selectedCompany.id}/project/${project.AhsID}/compute`,
              name: project.AhsName
            }
          ],
          current: i18n._(`page.${currentPage}`)
        };
  }, [project?.AhsName, selectedCompany, currentPage]);
  //#endregion

  //#region [render]
  return !project ? null : (
    <ProjectContext.Provider
      value={{
        project
      }}
    >
      <div className='project-page'>
        {breadcrumb && (
          <Breadcrumb links={breadcrumb.links} current={breadcrumb.current} />
        )}
        {WITH_HEADER_PATHS.includes(currentPage) && <ProjectHeader />}
        <Outlet />
      </div>
    </ProjectContext.Provider>
  );
  //#endregion
};

export default ProjectPage;
