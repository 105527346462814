import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Form } from 'react-bootstrap';
import './Form.css';

const FormSelect = ({
  className,
  value,
  disabled,
  onChange,
  param,
  label,
  bottomText,
  topText,
  labelLeft,
  onInfoClick,
  disabledOptions
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [methods]
  const handleParamChange = (val) => {
    if (param.type === 'number') val = +val;
    onChange(val);
  };
  //#endregion

  //#region [render]
  const inputClassName =
    !disabled && value && value != param.default ? 'form-input-changed' : '';
  return (
    <div className={className}>
      <div className={labelLeft ? 'form-input-left' : ''}>
        {label && (
          <span className='form-input-label'>
            {label}
            {param.required ? '*' : ''}
            {onInfoClick && (
              <FontAwesomeIcon
                icon='circle-question'
                className='info-icon'
                onClick={onInfoClick}
              />
            )}
          </span>
        )}
        <div className='form-input-body'>
          {topText && (
            <p className='form-input-small-text form-input-top-text'>
              {topText}
            </p>
          )}

          <Form.Select
            disabled={disabled}
            onChange={(evt) => handleParamChange(evt.target.value)}
            value={value !== null || value !== undefined ? value : ''}
            className={`${inputClassName} form-input ellipsis`}
          >
            {param.values.map((value, index) => (
              <option
                key={'input_' + param.key + '_value_' + index}
                value={value}
                disabled={disabledOptions?.includes(value)}
              >
                {param.type === 'number'
                  ? value
                  : i18n._(`${param.translateValuesIds[index]}`)}
              </option>
            ))}
          </Form.Select>
          {bottomText && (
            <span className='form-input-small-text'>{bottomText}</span>
          )}
        </div>
      </div>
    </div>
  );
  //#endregion
};

export default FormSelect;
