import { useLingui } from '@lingui/react';
import { merge } from 'lodash';
import React, { useMemo } from 'react';
import Plot from 'react-plotly.js';
import './Chart.css';

const LinesChart = ({ data, layout, title, filename }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const linesChartLayout = useMemo(() => {
    const defaultLayout = {
      margin: { b: 50, t: 50, pad: 10 },
      responsive: true,
      automargin: true
    };
    const chartLayout = layout
      ? merge({}, defaultLayout, layout)
      : defaultLayout;
    if (!chartLayout.autosize) {
      if (!chartLayout.width) chartLayout.width = 600;
      if (!chartLayout.height) chartLayout.height = 400;
    }
    return chartLayout;
  }, [layout]);
  //#endregion

  //#region [render]
  return !data ? null : (
    <div className='chart'>
      {title && <p>{title}</p>}
      <Plot
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
        data={data}
        layout={linesChartLayout}
        config={{
          displaylogo: false,
          displayModeBar: true,
          modeBarButtons: [
            [
              {
                name: i18n._('plotly.dl'),
                icon: Plotly.Icons.camera,
                click: (gd) => {
                  gd.layout.title = {
                    font: { size: 16 },
                    text: title ?? ''
                  };
                  Plotly.downloadImage(gd, {
                    filename: filename ?? title,
                    format: 'png',
                    width: 600,
                    height: 500
                  });
                }
              }
            ]
          ]
        }}
      />
    </div>
  );
  //#endregion
};

export default LinesChart;
