import { useLingui } from '@lingui/react';
import React, { useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import TwoWayTable from '../../../../../../../components/TwoWayTable/TwoWayTable.jsx';
import { formatValue } from '../../../../../../../utils/data.utils.js';

const EnergyConsumedDistributionTable = ({ result, substation }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [memos]
  const data = useMemo(() => {
    const consumptions = substation
      ? !result.ComputeResult.summary.PerSub?.Consumption[substation]
        ? null
        : result.ComputeResult.summary.PerSub.Consumption[substation]
      : !result.ComputeResult.summary.Consumptions
      ? null
      : result.ComputeResult.summary.Consumptions;
    if (!consumptions) return null;

    const { InitYearsSimulations } = result.ComputeResult.inp;
    const uses = Object.keys(consumptions);
    const sources = Object.keys(consumptions.Sum);
    const values = [];
    for (let i = 0; i < uses.length; ++i) {
      values[i] = [];
      for (let j = 0; j < sources.length; ++j) {
        values[i][j] = formatValue(
          (consumptions[uses[i]][sources[j]] ?? 0) / InitYearsSimulations
        );
      }
      values[i][sources.length] = formatValue(
        consumptions[uses[i]].Sum / InitYearsSimulations
      );
    }
    return {
      sources: sources.map((src) => i18n._(`source.${src.toLowerCase()}`)),
      uses: uses.map((use) => i18n._(`use.${use.toLowerCase()}`)),
      values
    };
  }, [result.ComputeID, substation]);
  //#endregion

  //#region [render]
  return data ? (
    <TwoWayTable
      horizontalTitles={data.sources}
      verticalTitles={data.uses}
      values={data.values}
    />
  ) : (
    <Alert variant='warning'>{i18n._('results.noData')}</Alert>
  );
  //#endregion
};

export default EnergyConsumedDistributionTable;
