import React from 'react';
import './ProjectSummary.css';
import CostSection from './sections/cost/CostSection';
import DescriptionSection from './sections/description/DescriptionSection';
import GeologySection from './sections/geology/GeologySection';
import PerformanceSection from './sections/performance/PerformanceSection';

const ProjectSummary = () => {
  //#region [render]
  return (
    <div className='project-summary'>
      <DescriptionSection />
      <GeologySection />
      <CostSection />
      <PerformanceSection />
    </div>
  );
  //#endregion
};

export default ProjectSummary;
