import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useState } from 'react';
import { Button } from 'react-bootstrap';
import SectionTitle from '../../../../../../../components/SectionTitle';
import CompareResultsChart from '../CompareResultsChart/CompareResultsChart';
import { DEFAULT_OPTIONS_KEYS } from '../CompareResultsChart/options';
import './CompareResultsCharts.css';

const CompareResultsCharts = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [states]
  const [charts, setCharts] = useState([DEFAULT_OPTIONS_KEYS]);
  //#endregion

  //#region [methods]
  const handleYLeftChange = (index, optnKey) => {
    setCharts((charts) => {
      charts[index].yLeftAxis = optnKey;
      return [...charts];
    });
  };

  const handleXChange = (index, optnKey) => {
    setCharts((charts) => {
      charts[index].xAxis = optnKey;
      return [...charts];
    });
  };

  const handleYRightChange = (index, optnKey) => {
    setCharts((charts) => {
      charts[index].yRightAxis = optnKey;
      return [...charts];
    });
  };

  const handleAddChartClick = () => {
    setCharts((charts) => [...charts, { ...DEFAULT_OPTIONS_KEYS }]);
  };

  const handleDeleteChart = (index) => {
    setCharts((charts) => {
      charts.splice(index, 1);
      return [...charts];
    });
  };
  //#endregion

  //#region [render]
  return (
    <div>
      <SectionTitle
        level={2}
        title={i18n._('results.summary.interactiveComparison')}
      />
      <div className='compare-results-charts'>
        {charts.map((chart, index) => (
          <CompareResultsChart
            key={'compare_results_chart_' + index}
            onYLeftChange={(optnKey) => handleYLeftChange(index, optnKey)}
            onXChange={(optnKey) => handleXChange(index, optnKey)}
            onYRightChange={(optnKey) => handleYRightChange(index, optnKey)}
            onDelete={
              charts.length === 1 ? null : () => handleDeleteChart(index)
            }
            yLeftAxis={chart.yLeftAxis}
            xAxis={chart.xAxis}
            yRightAxis={chart.yRightAxis}
          />
        ))}
        <Button onClick={handleAddChartClick} disabled={charts.length > 2}>
          {i18n._('results.summary.charts.add')} <FontAwesomeIcon icon='plus' />
        </Button>
      </div>
    </div>
  );
  //#endregion
};

export default CompareResultsCharts;
