import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useRef, useState } from 'react';
import { Button, Form, InputGroup } from 'react-bootstrap';
import { TAG } from '../../../../../../../../../server/constants/tag.constant';
import { fetchComputeIdByTag } from '../../../../../../../api/project.api';
import SelectBtn from '../../../../../../../components/SelectBtn/SelectBtn';
import PopupContext from '../../../../../../../contexts/PopupContext';
import ProjectContext from '../../../../../../../contexts/ProjectContext';
import './CommentAndTags.css';

const CommentAndTags = ({
  result,
  editComment,
  addTag,
  removeTag,
  replaceTag
}) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { project } = useContext(ProjectContext);
  const { openConfirmModal, openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [editing, setEditing] = useState(false);
  //#endregion

  //#region [refs]
  const commentInputRef = useRef();
  const commentEditBtnRef = useRef();
  //#endregion

  //#region [methods]
  const handleEditBtnClick = async () => {
    if (editing) {
      try {
        await editComment(result, commentInputRef.current.value);
        setEditing(false);
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      }
    } else {
      setEditing(true);
      commentEditBtnRef.current.blur();
      commentInputRef.current.focus();
    }
  };

  const handleFeasibilityTagClick = async () => {
    try {
      result.tags.feasibility
        ? await removeTag(result.ComputeID, TAG.FEASIBILITY)
        : await addTag(result.ComputeID, TAG.FEASIBILITY);
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleProjectTagClick = async () => {
    try {
      if (result.tags.project) {
        // on supprime le tag
        await removeTag(result.ComputeID, TAG.PROJECT);
      } else {
        // on vérifie que l'un des calculs ne possède déjà pas le tag
        const computeId = await fetchComputeIdByTag(
          project.AhsID,
          TAG.PROJECT.value
        );
        if (!computeId) {
          // on ajoute le tag projet
          await addTag(result.ComputeID, TAG.PROJECT);
        } else {
          // on demande à l'utilisateur si il est sûr de vouloir supprimer le tag projet de l'autre résultat
          openConfirmModal(
            i18n._('results.general.tagModal.title', {
              tag: i18n._('tag.project')
            }),
            i18n._('results.general.tagModal.body', {
              tag: i18n._('tag.project')
            }),
            'warning',
            async () => {
              await replaceTag(result.ComputeID, computeId, TAG.PROJECT);
            }
          );
        }
      }
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };

  const handleCommitmentTagClick = async () => {
    try {
      if (result.tags.contractCommitment) {
        // on supprime le tag
        await removeTag(result.ComputeID, TAG.CONTRACT_COMMITMENT);
      } else {
        // on vérifie que l'un des calculs ne possède déjà pas le tag
        const computeId = await fetchComputeIdByTag(
          project.AhsID,
          TAG.CONTRACT_COMMITMENT.value
        );
        if (!computeId) {
          // on ajoute le tag projet
          await addTag(result.ComputeID, TAG.CONTRACT_COMMITMENT);
        } else {
          // on demande à l'utilisateur si il est sûr de vouloir supprimer le tag projet de l'autre résultat
          openConfirmModal(
            i18n._('results.general.tagModal.title', {
              tag: i18n._('tag.contractCommitment')
            }),
            i18n._('results.general.tagModal.body', {
              tag: i18n._('tag.contractCommitment')
            }),
            'warning',
            async () => {
              await replaceTag(
                result.ComputeID,
                computeId,
                TAG.CONTRACT_COMMITMENT
              );
            }
          );
        }
      }
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  return (
    <div className='comment-tags'>
      <InputGroup>
        <Form.Control
          placeholder={i18n._('results.general.noSave')}
          type='text'
          disabled={!editing}
          ref={commentInputRef}
          defaultValue={result.Comment}
          name='commentInput'
        />
        <Button
          onClick={handleEditBtnClick}
          variant='primary'
          ref={commentEditBtnRef}
        >
          <FontAwesomeIcon icon={editing ? 'floppy-disk' : 'pen-to-square'} />
        </Button>
        <Button
          variant='outline-secondary'
          disabled={!editing}
          onClick={() => setEditing(false)}
        >
          <FontAwesomeIcon icon='xmark' />
        </Button>
      </InputGroup>
      <div className='results-tags'>
        <SelectBtn
          selected={result.tags.feasibility}
          bgColor='#F1C94B'
          onClick={handleFeasibilityTagClick}
        >
          {i18n._('tag.feasibility')}
        </SelectBtn>
        <SelectBtn
          selected={result.tags.project}
          bgColor='#03CDBF'
          onClick={handleProjectTagClick}
        >
          {i18n._('tag.project')}
        </SelectBtn>
        <SelectBtn
          selected={result.tags.contractCommitment}
          bgColor='#042264'
          onClick={handleCommitmentTagClick}
        >
          {i18n._('tag.contractCommitment')}
        </SelectBtn>
      </div>
    </div>
  );
  //#endregion
};

export default CommentAndTags;
