// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.results-otherPerSub-key-tr {
    background-color: var(--light-blue);
    color: white;
}

.results-otherPerSub-value-tr {
    font-size: 14px;
    color: var(--dark-grey);
    background-color: var(--light-grey);
}

.results-otherPerSub-separator {
    border-bottom: 1px solid var(--dark-grey);
}
`, "",{"version":3,"sources":["webpack://./src/pages/design/result/sections/simulationParams/components/OtherPerSubTable/OtherPerSubTable.css"],"names":[],"mappings":"AAAA;IACI,mCAAmC;IACnC,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,uBAAuB;IACvB,mCAAmC;AACvC;;AAEA;IACI,yCAAyC;AAC7C","sourcesContent":[".results-otherPerSub-key-tr {\n    background-color: var(--light-blue);\n    color: white;\n}\n\n.results-otherPerSub-value-tr {\n    font-size: 14px;\n    color: var(--dark-grey);\n    background-color: var(--light-grey);\n}\n\n.results-otherPerSub-separator {\n    border-bottom: 1px solid var(--dark-grey);\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
