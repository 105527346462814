import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Col, Form, Row } from 'react-bootstrap';
import {
  DEFAULT_CONFIGURABLE,
  NB_ITERATIONS
} from '../../../../../../../../../../server/constants/constraint.constant';
import { OPTI } from '../../../../../../../../../../server/models/design/constraint.model';
import FormInput from '../../../../../../../../components/Form/FormInput';
import OptimizationContext from '../../../../../../../../contexts/OptimizationContext';
import { OptiSection } from '../../../../OptiSection/OptiSection';
import './OptiTypeSection.css';

const OptiTypeSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const {
    calculationData,
    setCalculationData,
    setAreParamsChanged,
    addError,
    removeError
  } = useContext(OptimizationContext);
  //#endregion

  //#region [states]
  const [configurable, setConfigurable] = useState(DEFAULT_CONFIGURABLE);
  const [radioIndex, setRadioIndex] = useState(1);
  //#endregion

  //#region [effects]
  useEffect(() => {
    if (!calculationData.resultId) return;
    const { FuncEvaluations } = calculationData.constraints.inp;
    switch (FuncEvaluations) {
      case 1000:
        setRadioIndex(0);
        if (configurable !== DEFAULT_CONFIGURABLE)
          setConfigurable(() => DEFAULT_CONFIGURABLE);
        break;
      case 2500:
        setRadioIndex(1);
        if (configurable !== DEFAULT_CONFIGURABLE)
          setConfigurable(() => DEFAULT_CONFIGURABLE);
        break;
      default:
        setRadioIndex(2);
        setConfigurable(() => FuncEvaluations);
    }
  }, [calculationData.resultId]);
  //#endregion

  //#region [methods]
  const setComputeIterations = (iterations) => {
    if (iterations !== calculationData.constraints.inp.FuncEvaluations) {
      setAreParamsChanged(true);
    }
    setCalculationData((data) => {
      const dataCopy = { ...data };
      dataCopy.constraints.inp.FuncEvaluations = iterations;
      return dataCopy;
    });
  };
  //#endregion

  //#region [render]
  return (
    <OptiSection title={i18n._('compute.opti.optiType')}>
      <Form.Group className='opti-type-section-group'>
        {NB_ITERATIONS.map((iterations, index) => (
          <Row key={'iterations_' + iterations}>
            <Col xs={4}>
              <Form.Check
                type='radio'
                name='iterationsRadio'
                onChange={() => {
                  setRadioIndex(index);
                  setComputeIterations(iterations);
                }}
                checked={radioIndex === index}
                label={i18n._('compute.opti.iterations', {
                  iterations
                })}
                className='opti-inputs-label'
              />
            </Col>
          </Row>
        ))}
        <Row>
          <Col xs={4}>
            <Form.Check
              type='radio'
              name='iterationsRadio'
              onChange={() => {
                setRadioIndex(2);
                setComputeIterations(configurable);
              }}
              checked={radioIndex === 2}
              label={i18n._('compute.opti.configurable')}
              className='opti-inputs-label'
            />
          </Col>
          <Col xs={4}>
            <FormInput
              param={OPTI.ConfigurableIterations}
              value={configurable}
              addError={addError}
              removeError={removeError}
              onBlur={(value) => {
                setConfigurable(+value);
                setComputeIterations(+value);
              }}
              disabled={radioIndex === 0 || radioIndex === 1}
            />
          </Col>
        </Row>
      </Form.Group>
    </OptiSection>
    //#endregion
  );
};

export default OptiTypeSection;
