import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React from 'react';
import { Button } from 'react-bootstrap';
import './ErrorFallback.css';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [render]
  console.error(error);
  return (
    <div className='error-fallback'>
      <FontAwesomeIcon icon='face-frown' />
      <h2>{i18n._('error.fallback.header')}</h2>
      <h5>{i18n._('error.fallback.contact')}</h5>
      <Button variant='secondary' onClick={resetErrorBoundary}>
        {i18n._('error.fallback.try')}
      </Button>
    </div>
  );
  //#endregion
};

export default ErrorFallback;
