// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.custom-table {
    border-spacing: 0;
    border-collapse: separate;
    border-radius: 5px;
    border: 1px solid rgb(236, 237, 236);
    -webkit-box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);
    box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);
    margin-top: 10px;
    margin-bottom: 10px;
    overflow: hidden;
}

.custom-table th {
    background-color: var(--primary-color);
    font-weight: 500;
    text-align: center;
    color: white;
}

.custom-table th:not(:first-of-type) {
    border-left: 1px solid white;
}

.custom-table th,
.custom-table td {
    padding: 5px 15px;
    font-size: 14px;
}

.custom-table tbody tr:not(:last-of-type) td {
    border-bottom: 1px solid rgba(213, 210, 210, 0.7);
}

.custom-table tbody tr {
    background-color: white;
}

.custom-table td {
    padding: 5px 15px;
}

.custom-table input {
    font-size: 14px;
}

.custom-table tbody tr td:not(:first-of-type) {
    text-align: right;
    border-left: 1px solid rgba(213, 210, 210, 0.7);
}`, "",{"version":3,"sources":["webpack://./src/components/Table/Table.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,yBAAyB;IACzB,kBAAkB;IAClB,oCAAoC;IACpC,wDAAwD;IACxD,gDAAgD;IAChD,gBAAgB;IAChB,mBAAmB;IACnB,gBAAgB;AACpB;;AAEA;IACI,sCAAsC;IACtC,gBAAgB;IAChB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,4BAA4B;AAChC;;AAEA;;IAEI,iBAAiB;IACjB,eAAe;AACnB;;AAEA;IACI,iDAAiD;AACrD;;AAEA;IACI,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,eAAe;AACnB;;AAEA;IACI,iBAAiB;IACjB,+CAA+C;AACnD","sourcesContent":[".custom-table {\n    border-spacing: 0;\n    border-collapse: separate;\n    border-radius: 5px;\n    border: 1px solid rgb(236, 237, 236);\n    -webkit-box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);\n    box-shadow: 4px 4px 14px -5px rgba(0, 0, 0, 0.2);\n    margin-top: 10px;\n    margin-bottom: 10px;\n    overflow: hidden;\n}\n\n.custom-table th {\n    background-color: var(--primary-color);\n    font-weight: 500;\n    text-align: center;\n    color: white;\n}\n\n.custom-table th:not(:first-of-type) {\n    border-left: 1px solid white;\n}\n\n.custom-table th,\n.custom-table td {\n    padding: 5px 15px;\n    font-size: 14px;\n}\n\n.custom-table tbody tr:not(:last-of-type) td {\n    border-bottom: 1px solid rgba(213, 210, 210, 0.7);\n}\n\n.custom-table tbody tr {\n    background-color: white;\n}\n\n.custom-table td {\n    padding: 5px 15px;\n}\n\n.custom-table input {\n    font-size: 14px;\n}\n\n.custom-table tbody tr td:not(:first-of-type) {\n    text-align: right;\n    border-left: 1px solid rgba(213, 210, 210, 0.7);\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
