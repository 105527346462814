import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useLayoutEffect, useState } from 'react';
import { Alert, Button, Col, Form, Row } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import { PROJECT_ENDPOINT } from '../../../../../server/constants/endpoint.constant';
import { A_MAX, A_MIN } from '../../../../../server/constants/geology.constant';
import {
  BTES,
  SOIL,
  getBTESTestProbeLengthParam
} from '../../../../../server/models/design/geology.model';
import { updateParams } from '../../../api/project.api';
import Bloc from '../../../components/Bloc/Bloc';
import FormInput from '../../../components/Form/FormInput';
import CompanyContext from '../../../contexts/CompanyContext';
import PopupContext from '../../../contexts/PopupContext';
import ProjectContext from '../../../contexts/ProjectContext';
import { getAlphaError, getDefaultValue } from '../../../utils/project.utils';
import './GeologyPage.css';

const GeologyPage = () => {
  //#region [navigate]
  const navigate = useNavigate();
  //#endregion

  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  // TODO dans le cas où selectedCompany n'est utilisé que pour selectedCompany.id, utiliser plutôt const { companyId } = useParams();
  const { selectedCompany } = useContext(CompanyContext);
  const { project } = useContext(ProjectContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [tempProject, setTempProject] = useState(project); // TODO tempProject ne doit contenir que les informations liées à la page geologie
  const [trtChecked, setTRTChecked] = useState(
    project.InitBtesTestProbeLength > 0
  );
  const [nbErrors, setNbErrors] = useState(0);
  const [alphaError, setAlphaError] = useState();
  //#endregion

  //#region [effects]
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    if (trtChecked || tempProject.InitBtesTestProbeLength === 0) return;
    handleParamBlur('InitBtesTestProbeLength', 0);
  }, [trtChecked, tempProject.InitBtesTestProbeLength]);

  useEffect(() => {
    const K =
      tempProject.InitSoilThermalConductivity ||
      SOIL.InitSoilThermalConductivity.default;
    const Cp =
      tempProject.InitSoilVolumeHeatCapacity ||
      SOIL.InitSoilVolumeHeatCapacity.default;

    if (getAlphaError(K, Cp)) {
      setAlphaError(
        i18n._('geology.alphaError', {
          amin: A_MIN,
          amax: A_MAX
        })
      );
      addError();
    } else if (alphaError) {
      setAlphaError(null);
      removeError();
    }
  }, [
    tempProject.InitSoilThermalConductivity,
    tempProject.InitSoilVolumeHeatCapacity
  ]);
  //#endregion

  //#region [methods]
  const addError = () => setNbErrors((prevErrors) => prevErrors + 1);
  const removeError = () => setNbErrors((prevErrors) => prevErrors - 1);

  const handleParamBlur = (key, value) => {
    const tempProjectCopy = { ...tempProject };
    tempProjectCopy[key] = value;
    setTempProject(tempProjectCopy);
  };

  const handleOtherValueBlur = (key, value) => {
    const tempProjectCopy = { ...tempProject };
    tempProjectCopy.otherValues[key] = value;
    setTempProject(tempProjectCopy);
  };

  const handleSubmitClick = async () => {
    try {
      // TODO n'envoyer que les informations liées à la page géologie
      await updateParams(tempProject, PROJECT_ENDPOINT.GEOLOGY);
      navigate(
        `/company/${selectedCompany.id}/project/${project.AhsID}/compute`
      );
    } catch (err) {
      console.error(err);
      openErrorToast(err);
    }
  };
  //#endregion

  //#region [render]
  const {
    InitBtesTestProbeLength,
    InitSoilThermalConductivity,
    InitSoilVolumeHeatCapacity,
    InitSoilTemperature10m,
    InitBtesTemperatureMin,
    InitBtesTemperatureMax
  } = tempProject;
  const {
    InitBtesProbeResistance,
    InitBtesTemperatureDelta,
    YearsT2Final,
    InitBtesMaxWidth,
    InitBtesProbeInternalDiameter
  } = tempProject.otherValues;

  return (
    <div className='geology-page'>
      <Bloc title={i18n._('geology.trt')}>
        <Form.Check
          type='switch'
          label={i18n._('projectGeology.testDone')}
          checked={trtChecked}
          onChange={() => setTRTChecked(!trtChecked)}
          className={trtChecked ? 'bold' : ''}
        />
        <FormInput
          label={i18n._('geology.InitBtesTestProbeLength')}
          value={InitBtesTestProbeLength}
          param={getBTESTestProbeLengthParam(trtChecked)}
          onBlur={(value) => handleParamBlur('InitBtesTestProbeLength', value)}
          addError={addError}
          removeError={removeError}
          unit
          disabled={!trtChecked}
        />
      </Bloc>
      <Bloc title={i18n._('geology.soil')}>
        <Row>
          <Col>
            <FormInput
              label={i18n._('geology.InitSoilThermalConductivity')}
              value={InitSoilThermalConductivity}
              param={SOIL.InitSoilThermalConductivity}
              onBlur={(value) =>
                handleParamBlur('InitSoilThermalConductivity', value)
              }
              addError={addError}
              removeError={removeError}
              unit
              bottomText={getDefaultValue(
                i18n,
                SOIL.InitSoilThermalConductivity
              )}
            />
          </Col>
          <Col>
            <FormInput
              label={i18n._('geology.InitSoilVolumeHeatCapacity')}
              value={InitSoilVolumeHeatCapacity}
              param={SOIL.InitSoilVolumeHeatCapacity}
              onBlur={(value) =>
                handleParamBlur('InitSoilVolumeHeatCapacity', value)
              }
              addError={addError}
              removeError={removeError}
              unit
              bottomText={getDefaultValue(
                i18n,
                SOIL.InitSoilVolumeHeatCapacity
              )}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <FormInput
              label={i18n._('geology.InitSoilTemperature10m')}
              value={InitSoilTemperature10m}
              param={SOIL.InitSoilTemperature10m}
              onBlur={(value) =>
                handleParamBlur('InitSoilTemperature10m', value)
              }
              addError={addError}
              removeError={removeError}
              unit
              bottomText={getDefaultValue(i18n, SOIL.InitSoilTemperature10m)}
            />
          </Col>
        </Row>
        {alphaError && <Alert variant='danger'>{alphaError}</Alert>}
      </Bloc>
      <Bloc title={i18n._('geology.btes')}>
        <Row>
          <Col>
            <FormInput
              label={i18n._('geology.InitBtesTemperatureMin')}
              value={InitBtesTemperatureMin}
              param={BTES.InitBtesTemperatureMin}
              onBlur={(value) =>
                handleParamBlur('InitBtesTemperatureMin', value)
              }
              addError={addError}
              removeError={removeError}
              unit
              bottomText={getDefaultValue(i18n, BTES.InitBtesTemperatureMin)}
            />
          </Col>
          <Col>
            <FormInput
              label={i18n._('geology.InitBtesTemperatureMax')}
              value={InitBtesTemperatureMax}
              param={BTES.InitBtesTemperatureMax}
              onBlur={(value) =>
                handleParamBlur('InitBtesTemperatureMax', value)
              }
              addError={addError}
              removeError={removeError}
              unit
              bottomText={getDefaultValue(i18n, BTES.InitBtesTemperatureMax)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput
              label={i18n._('geology.InitBtesTemperatureDelta')}
              value={InitBtesTemperatureDelta}
              param={BTES.InitBtesTemperatureDelta}
              onBlur={(value) =>
                handleOtherValueBlur('InitBtesTemperatureDelta', value)
              }
              addError={addError}
              removeError={removeError}
              unit
              bottomText={getDefaultValue(i18n, BTES.InitBtesTemperatureDelta)}
            />
          </Col>
          <Col>
            <FormInput
              label={i18n._('geology.YearsT2Final')}
              value={YearsT2Final}
              param={BTES.YearsT2Final}
              onBlur={(value) => handleOtherValueBlur('YearsT2Final', value)}
              addError={addError}
              removeError={removeError}
              unit
              bottomText={getDefaultValue(i18n, BTES.YearsT2Final)}
            />
          </Col>
        </Row>
        <Row>
          <Col xs={6}>
            <FormInput
              label={i18n._('geology.InitBtesMaxWidth')}
              value={InitBtesMaxWidth}
              param={BTES.InitBtesMaxWidth}
              onBlur={(value) =>
                handleOtherValueBlur('InitBtesMaxWidth', value)
              }
              addError={addError}
              removeError={removeError}
              unit
              bottomText={getDefaultValue(i18n, BTES.InitBtesMaxWidth)}
            />
          </Col>
        </Row>
        <Row>
          <Col>
            <FormInput
              label={i18n._('geology.InitBtesProbeResistance')}
              value={InitBtesProbeResistance}
              param={BTES.InitBtesProbeResistance}
              onBlur={(value) =>
                handleOtherValueBlur('InitBtesProbeResistance', value)
              }
              addError={addError}
              removeError={removeError}
              unit
              bottomText={getDefaultValue(i18n, BTES.InitBtesProbeResistance)}
            />
          </Col>
          <Col>
            <FormInput
              label={i18n._('geology.InitBtesProbeInternalDiameter')}
              value={InitBtesProbeInternalDiameter}
              param={BTES.InitBtesProbeInternalDiameter}
              onBlur={(value) =>
                handleOtherValueBlur('InitBtesProbeInternalDiameter', value)
              }
              addError={addError}
              removeError={removeError}
              unit
              bottomText={getDefaultValue(
                i18n,
                BTES.InitBtesProbeInternalDiameter
              )}
            />
          </Col>
        </Row>
      </Bloc>
      <div className='geology-btns'>
        <Button
          variant='primary'
          onClick={handleSubmitClick}
          disabled={nbErrors > 0}
        >
          {i18n._('save')}
        </Button>
      </div>
    </div>
  );
  //#endregion
};

export default GeologyPage;
