import { useLingui } from '@lingui/react';
import React, { useLayoutEffect } from 'react';
import { Button } from 'react-bootstrap';
import PerformancePageContext from '../../../contexts/PerformancePageContext';
import './PerformancePage.css';
import ColdStorageBloc from './blocs/ColdStorageBloc';
import ExchangerBloc from './blocs/ExchangerBloc';
import GasBoilerBloc from './blocs/GasBoilerBloc';
import GeocoolingBloc from './blocs/GeocoolingBloc';
import HeatPumpsBloc from './blocs/HeatPumpsBloc';
import PhotovoltaicBloc from './blocs/PhotovoltaicBloc';
import SolarThermalBloc from './blocs/SolarThermalBloc';
import WeightLossBloc from './blocs/WeightLossBloc';
import usePerformance from './usePerformance';

const PerformancePage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [logic]
  const {
    tempProject,
    nbErrors,
    addError,
    removeError,
    setTempProject,
    handleParamChange,
    handleOtherValueChange,
    handleSSTParamChange,
    handleSubmitClick
  } = usePerformance();
  //#endregion

  //#region [effects]
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  //#endregion

  //#region [render]
  return (
    <PerformancePageContext.Provider
      value={{
        tempProject,
        nbErrors,
        addError,
        removeError,
        setTempProject,
        handleParamChange,
        handleOtherValueChange,
        handleSSTParamChange,
        handleSubmitClick
      }}
    >
      <div className='performance-page'>
        <ExchangerBloc />
        <SolarThermalBloc />
        <PhotovoltaicBloc />
        <GasBoilerBloc />
        <HeatPumpsBloc />
        <ColdStorageBloc />
        <WeightLossBloc />
        <GeocoolingBloc />
        <div className='performance-btns'>
          <Button
            variant='primary'
            onClick={handleSubmitClick}
            disabled={nbErrors > 0}
          >
            {i18n._('save')}
          </Button>
        </div>
      </div>
    </PerformancePageContext.Provider>
  );
  //#endregion
};

export default PerformancePage;
