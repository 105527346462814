const { NEED } = require('./need.constant');
const { UNIT } = require('./unit.constant');

const MAX_RESULTS_FOR_COMPARE = 5;

const NB_YEARS_FONDS_CHALEUR = 20;

const DETAILED_RESULTS_KEYS = Object.freeze({
  T1: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  T2: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  TEauInBtes: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  TEauOutBtes: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  Wpac: { unit: UNIT.KILOWATT, precision: 0 },
  Whpg: { unit: UNIT.KILOWATT, precision: 0 },
  QBtes: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 0 },
  TEauInSolar: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  TEauOutSolar: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  OPEX: { unit: UNIT.EURO, precision: 0 },
  NetOPEX: { unit: UNIT.EURO, precision: 0 },
  QHeadLoss: { unit: UNIT.KILOWATT_HOUR_ELECTRIC, precision: 2 },
  CO2kg: { unit: UNIT.KILOGRAM, precision: 0 },
  CO2kgref: { unit: UNIT.KILOGRAM, precision: 0 },
  BtesFlowRate: { unit: UNIT.CUBIC_METER_PER_HOUR, precision: 0 },
  QItesCharge: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
  WColdItes: { unit: UNIT.KILOWATT, precision: 0 },
  WItesHpa: { unit: UNIT.KILOWATT, precision: 0 },
  COPItesHpa: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  WColdHpa: { unit: UNIT.KILOWATT, precision: 0 },
  WHeatHpa: { unit: UNIT.KILOWATT, precision: 0 },
  WHotwHpa: { unit: UNIT.KILOWATT, precision: 0 },
  WGas: { unit: UNIT.KILOWATT, precision: 0 },
  QSolar: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
  TDistrib: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  TCondHt: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  TCondHw: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  WInjectionHpa: { unit: UNIT.KILOWATT, precision: 0 },
  COPInjectionHpa: { unit: UNIT.CELSIUS_DEGREE, precision: 2 },
  Qdemand: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
  RevPV: { unit: UNIT.EURO, precision: 0 },
  PVProd: { unit: UNIT.KILOWATT_HOUR_ELECTRIC, precision: 0 },
  PVSoldToGrid: { unit: UNIT.KILOWATT, precision: 0 },
  PVNotUsedForThermalNeeds: { unit: UNIT.KILOWATT, precision: 0 },
  PVUnused: { unit: UNIT.KILOWATT_HOUR_ELECTRIC, precision: 0 },
  QGeocooling: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
});

// l'ordre des sources est important, ne pas le modifier
const DETAILED_RESULTS_SST_KEYS = Object.freeze({
  [NEED.HEAT]: {
    sources: {
      Q_Heat_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Heat_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Heat_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Heat_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Heat_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    },
    total: {
      QTotal_Heat: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      QTotalHeat: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    }
  },
  [NEED.COLD]: {
    sources: {
      Q_Cold_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Cold_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Cold_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Cold_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Cold_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    },
    total: {
      QTotal_Cold: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      QTotalCold: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    }
  },
  [NEED.HOTWATER]: {
    sources: {
      Q_Hotwater_Tfp: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Hotwater_Hpg: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Hotwater_ST: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Hotwater_Hpa: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      Q_Hotwater_Gas: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    },
    total: {
      QTotal_Hotwater: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 },
      QTotalHotwater: { unit: UNIT.KILOWATT_HOUR_THERMAL, precision: 2 }
    }
  }
});

// TODO les translateId doivent être côté front
const BTES_TEMP = Object.freeze({
  T1: {
    translateId: 'btes.T1'
  },
  T2: {
    translateId: 'btes.T2'
  },
  TEauInBtes: {
    translateId: 'btes.TEauInBtes'
  },
  TEauOutBtes: {
    translateId: 'btes.TEauOutBtes'
  }
});

module.exports = {
  MAX_RESULTS_FOR_COMPARE,
  NB_YEARS_FONDS_CHALEUR,
  DETAILED_RESULTS_KEYS,
  DETAILED_RESULTS_SST_KEYS,
  BTES_TEMP
};
