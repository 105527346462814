const ABORTED_COMPUTE_ERRORS = ['runtime error', 'canceled'];

const ENGINE_ERROR = Object.freeze({
  unknown: {
    message: true
  },
  end_allreject: {
    message: true,
    causes: [
      {
        actions: 3
      }
    ]
  },
  end_all_crash: {
    message: true,
    causes: [
      {
        actions: 2
      },
      {
        actions: 2
      },
      {
        actions: 1
      }
    ]
  },
  pre_no_cold_in_std: {
    message: false,
    causes: [
      {
        actions: 2
      }
    ]
  },
  pre_no_hot_in_std: {
    message: false,
    causes: [
      {
        actions: 2
      }
    ]
  },
  pre_no_dhw_in_std: {
    message: false,
    causes: [
      {
        actions: 2
      }
    ]
  },
  pre_hybridpv_capex: {
    message: false,
    causes: [
      {
        actions: 1
      }
    ]
  },
  pre_no_hpa_power: {
    message: false,
    causes: [
      {
        actions: 2
      }
    ]
  },
  pre_no_sst_hpg_power: {
    message: false,
    causes: [
      {
        actions: 1
      }
    ]
  },
  pre_no_hpg_power: {
    message: false,
    causes: [
      {
        actions: 1
      }
    ]
  },
  pre_no_btes_length: {
    message: false,
    causes: [
      {
        actions: 1
      }
    ]
  },
  pre_btes_too_small: {
    message: false,
    causes: [
      {
        actions: 1
      },
      {
        actions: 2
      }
    ]
  },
  pre_btes_dist_depth_mismatch: {
    message: false,
    causes: [
      {
        actions: 2
      }
    ]
  }
});

const INPUT_ERROR_CODE = {
  REQUIRED: { translateId: 'input.error.required' },
  NOT_NUMBER: {
    translateId: 'input.error.notNumber'
  },
  MIN: {
    translateId: 'input.error.min',
    translateParams: { min: undefined }
  },
  MAX: {
    translateId: 'input.error.max',
    translateParams: { max: undefined }
  },
  MIN_LENGTH: {
    translateId: 'input.error.minLength',
    translateParams: { min: undefined }
  },
  MAX_LENGTH: {
    translateId: 'input.error.maxLength',
    translateParams: { max: undefined }
  },
  PATTERN: {
    translateId: 'input.error.pattern'
  },
  PATTERN_EXAMPLE: {
    translateId: 'input.error.pattern.example',
    translateParams: { example: undefined }
  }
};

const SST_INPUT_ERROR_CODE = {
  REQUIRED: {
    translateId: 'substation.input.error.required',
    translateParams: { substation: undefined }
  },
  NOT_NUMBER: {
    translateId: 'substation.input.error.notNumber',
    translateParams: { substation: undefined }
  },
  MIN: {
    translateId: 'substation.input.error.min',
    translateParams: { substation: undefined, min: undefined }
  },
  MAX: {
    translateId: 'substation.input.error.max',
    translateParams: { substation: undefined, max: undefined }
  }
};

const MIN_MAX_ERROR_CODE = {
  translateId: 'input.mutualError.minLessMax'
};

const STATUS_CODE = {
  NOT_FOUND: 404,
  UNAUTHORIZED: 401
};

const ENGINE_ERROR_CODE = (module.exports = {
  ABORTED_COMPUTE_ERRORS,
  ENGINE_ERROR,
  INPUT_ERROR_CODE,
  SST_INPUT_ERROR_CODE,
  MIN_MAX_ERROR_CODE,
  STATUS_CODE
});
