import React, { useEffect, useState } from 'react';
import {
  SOCKET_MSG_TYPE,
  VERSION_ROOM
} from '../../../../server/constants/socket.constant';
import ws from '../../tools/ws';
import './Version.css';

const VersionPage = () => {
  //#region [states]
  const [info, setInfo] = useState();
  //#endregion

  //#region [effects]
  useEffect(() => {
    ws.connect(VERSION_ROOM, async (data) => {
      switch (data.type) {
        case SOCKET_MSG_TYPE.WS_CONNECTED:
          ws.send(VERSION_ROOM, { type: SOCKET_MSG_TYPE.VERSION });
          break;
        case SOCKET_MSG_TYPE.VERSION:
          setInfo(() => ({
            buildDate: data.msg.buildDate,
            commit: data.msg.commit,
            computer: data.msg.computer,
            version: data.msg.version
          }));
          ws.disconnect(VERSION_ROOM);
          break;
      }
    });

    return () => ws.disconnect(VERSION_ROOM);
  }, []);
  //#endregion

  //#region [render]
  return info ? (
    <div className='version-page'>
      {Object.keys(info).map((key) => (
        <div key={key}>
          <span className='bold'>{key}</span> - {info[key]}
        </div>
      ))}
    </div>
  ) : null;
  //#endregion
};

export default VersionPage;
