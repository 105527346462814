import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import ExpandableTable from '../../../../../../../components/ExpandableTable/ExpandableTable';
import Section from '../../../../../../../components/Section/Section';
import SectionTitle from '../../../../../../../components/SectionTitle';
import Table from '../../../../../../../components/Table/Table';
import TemplateTable from '../../../../../../../components/TemplateTables/TemplateTable';
import CompanyContext from '../../../../../../../contexts/CompanyContext';
import ProjectContext from '../../../../../../../contexts/ProjectContext';
import { btesTemplate, capexTemplates, opexTemplates } from './templates';

const CostSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { selectedCompany } = useContext(CompanyContext);
  const { project } = useContext(ProjectContext);
  //#endregion

  //#region [memos]
  const btesTableCols = useMemo(() => {
    const { InitBtesDiscountDict } = project.otherValues;
    return [
      Object.keys(InitBtesDiscountDict),
      Object.values(InitBtesDiscountDict)
    ];
  }, [project.otherValues.InitBtesDiscountDict]);

  const hpgTableCols = useMemo(() => {
    const { InitHPGCost } = project.otherValues;
    return [Object.keys(InitHPGCost), Object.values(InitHPGCost)];
  }, [project.otherValues.InitHPGCost]);

  const hpaTableCols = useMemo(() => {
    const { InitHPACostHeat, InitHPACostHeatCold } = project.otherValues;
    return [
      Object.keys(InitHPACostHeat),
      Object.values(InitHPACostHeat),
      Object.values(InitHPACostHeatCold)
    ];
  }, [
    project.otherValues.InitHPACostHeat,
    project.otherValues.InitHPACostHeatCold
  ]);

  const gasTableCols = useMemo(() => {
    const { InitCapexGas } = project.otherValues;
    return [Object.keys(InitCapexGas), Object.values(InitCapexGas)];
  }, [project.otherValues.InitCapexGas]);

  const BTemplate = useMemo(() => {
    return btesTemplate(i18n, project);
  }, [project]);

  const CTemplates = useMemo(() => {
    return capexTemplates(i18n, project);
  }, [project]);

  const OTemplates = useMemo(() => {
    return opexTemplates(i18n, project);
  }, [project]);
  //#endregion

  //#region [render]
  return (
    <Section
      title={i18n._('page.costs')}
      editPath={`/company/${selectedCompany.id}/project/${project.AhsID}/costs`}
    >
      <SectionTitle level={2} title={i18n._('capex.hypotheses')} />
      <TemplateTable template={BTemplate} level={3} />
      <ExpandableTable>
        <Table
          title={i18n._('cost.InitBtesDiscountLabel')}
          titles={[
            i18n._('cost.InitBtesDiscountDictSlices'),
            i18n._('cost.InitBtesDiscountDict')
          ]}
          cols={btesTableCols}
        />
      </ExpandableTable>
      <ExpandableTable level={3} title={i18n._('cost.hpg')}>
        <Table
          title={i18n._('cost.InitHPGCostLabel')}
          titles={[
            i18n._('cost.InitHPGCostSlices'),
            i18n._('cost.InitHPGCost')
          ]}
          cols={hpgTableCols}
        />
      </ExpandableTable>
      <ExpandableTable level={3} title={i18n._('cost.hpa')}>
        <Table
          title={i18n._('cost.InitHPACostLabel')}
          titles={[
            i18n._('cost.InitHPACostSlices'),
            i18n._('cost.InitHPACostHeat'),
            i18n._('cost.InitHPACostHeatCold')
          ]}
          cols={hpaTableCols}
        />
      </ExpandableTable>
      <ExpandableTable level={3} title={i18n._('cost.gasBoiler')}>
        <Table
          title={i18n._('cost.InitCapexGasLabel')}
          titles={[
            i18n._('cost.InitCapexGasSlices'),
            i18n._('cost.InitCapexGas')
          ]}
          cols={gasTableCols}
        />
      </ExpandableTable>
      {CTemplates.map((template, index) => (
        <TemplateTable
          key={'cost_table_capex_' + index}
          template={template}
          level={3}
        />
      ))}
      <SectionTitle level={2} title={i18n._('opex.hypotheses')} />
      {OTemplates.map((template, index) => (
        <TemplateTable
          key={'cost_table_opex_' + index}
          template={template}
          level={3}
        />
      ))}
    </Section>
  );
  //#endregion
};

export default CostSection;
