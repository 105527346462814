import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { Fragment, useContext, useMemo } from 'react';
import { Alert } from 'react-bootstrap';
import CompareResultsTable from '../../../../../../../components/CompareResultsTable/CompareResultsTable';
import SectionTitle from '../../../../../../../components/SectionTitle';
import PopupContext from '../../../../../../../contexts/PopupContext';
import ResultContext from '../../../../../../../contexts/ResultContext.jsx';
import {
  nonComplianceUserPenaltiesTemplate,
  penaltiesTemplate,
  physicalConstraintsTemplate
} from '../../templates';
import './PenaltiesTable.css';

const PenaltiesTable = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { comparedResults } = useContext(ResultContext);
  const { openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [memos]
  const PCTemplate = useMemo(() => {
    return physicalConstraintsTemplate(i18n, comparedResults);
  }, [comparedResults]);

  const NCUPTemplate = useMemo(() => {
    return nonComplianceUserPenaltiesTemplate(i18n, comparedResults);
  }, [comparedResults]);

  const PTemplate = useMemo(() => {
    return penaltiesTemplate(i18n, comparedResults);
  }, [comparedResults]);
  //#endregion

  //#region [render]
  return (
    <Fragment>
      <div>
        <div className='penalties-title'>
          <SectionTitle
            level={2}
            title={i18n._('results.simulationParams.penalties')}
          />
          <FontAwesomeIcon
            icon='circle-question'
            className='info-icon'
            onClick={() =>
              openInfoModal(
                i18n._('results.general.penalties.modal.title'),
                i18n._('results.general.penalties.modal.body')
              )
            }
          />
        </div>
        <SectionTitle
          level={3}
          title={i18n._(
            'results.general.penalties.physicalConstraintsPenalties'
          )}
        />
        {PCTemplate && PCTemplate.rows.length > 0 ? (
          <CompareResultsTable level={3} template={PCTemplate} />
        ) : (
          <Alert variant='success'>
            {i18n._(
              'results.general.penalties.physicalConstraintsPenalties.noPenalties'
            )}
          </Alert>
        )}
        <SectionTitle
          level={3}
          title={i18n._(
            'results.general.penalties.nonComplianceObjUserPenalties'
          )}
        />
        {NCUPTemplate ? (
          <CompareResultsTable level={3} template={NCUPTemplate} />
        ) : (
          <Alert variant='info'>
            {i18n._(
              'results.general.penalties.nonComplianceObjUserPenalties.noPenalties'
            )}
          </Alert>
        )}
        {PTemplate && <CompareResultsTable level={3} template={PTemplate} />}
      </div>
    </Fragment>
  );
  //#endregion
};

export default PenaltiesTable;
