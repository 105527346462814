import {
  INPUT_ERROR_CODE,
  MIN_MAX_ERROR_CODE,
  SST_INPUT_ERROR_CODE
} from '../../../server/constants/error.constant';
import { isNull } from './data.utils';

export const getInputErrorCode = (value, param, isSubstationInput) => {
  const inputErrorObj = isSubstationInput
    ? SST_INPUT_ERROR_CODE
    : INPUT_ERROR_CODE;
  let errorCode;

  // le champ est vide et non-obligatoire : pas d'erreur
  if (
    !param.required &&
    (isNull(value) || value.toString().trim().length === 0)
  )
    return null;

  if (
    param.required &&
    (isNull(value) || value.toString().trim().length === 0)
  ) {
    // le param est obligatoire mais la valeur est manquante
    errorCode = inputErrorObj.REQUIRED;
  } else if (param.type === 'number') {
    // le param est un nombre
    if (isNaN(value)) {
      errorCode = inputErrorObj.NOT_NUMBER;
    } else if (param.min !== null && +value < param.min) {
      errorCode = inputErrorObj.MIN;
      errorCode.translateParams = { min: param.min };
    } else if (param.max !== null && +value > param.max) {
      errorCode = inputErrorObj.MAX;
      errorCode.translateParams = { max: param.max };
    }
  } else if (param.type === 'text' || param.type === 'password') {
    value = value.toString();
    if (param.minLength && value.length < param.minLength) {
      errorCode = inputErrorObj.MIN_LENGTH;
      errorCode.translateParams = { min: param.minLength };
    } else if (param.maxLength && value.length > param.maxLength) {
      errorCode = inputErrorObj.MAX_LENGTH;
      errorCode.translateParams = { max: param.maxLength };
    } else if (param.pattern && !param.pattern.test(value)) {
      if (!param.example) {
        errorCode = inputErrorObj.PATTERN;
      } else {
        errorCode = inputErrorObj.PATTERN_EXAMPLE;
        errorCode.translateParams = { example: param.example };
      }
    }
  }

  return errorCode;
};

export const getMinMaxErrorCode = (min, max) => {
  if (min <= max) return null;
  return MIN_MAX_ERROR_CODE;
};
