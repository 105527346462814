import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext, useEffect, useState } from 'react';
import { Spinner } from 'react-bootstrap';
import { Navigate, useParams } from 'react-router-dom';
import { fetchLastResultId } from '../../../api/project.api';
import PopupContext from '../../../contexts/PopupContext';
import ProjectContext from '../../../contexts/ProjectContext';
import './ResultsPage.css';

const ResultsPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const { resultId } = useParams();
  //#endregion

  //#region [contexts]
  const { project } = useContext(ProjectContext);
  const { openErrorToast } = useContext(PopupContext);
  //#endregion

  //#region [states]
  const [isLoading, setIsLoading] = useState(true);
  const [selectedResultId, setSelectedResultId] = useState();
  //#endregion

  //#region [effects]
  useEffect(() => {
    (async () => {
      try {
        const newSelectedResultId =
          resultId ?? (await fetchLastResultId(project.AhsID));
        setSelectedResultId(newSelectedResultId);
      } catch (err) {
        console.error(err);
        openErrorToast(err);
      } finally {
        setIsLoading(false);
      }
    })();
  }, [resultId, project.AhsID]);
  //#endregion

  //#region [render]
  return isLoading ? (
    <div className='results-page'>
      <Spinner variant='dark' />
    </div>
  ) : selectedResultId ? (
    <Navigate to={selectedResultId} replace />
  ) : (
    <div className='results-page'>
      <FontAwesomeIcon icon='fa-regular fa-face-rolling-eyes' />
      <h2>{i18n._('noResults')}</h2>
    </div>
  );
  //#endregion
};

export default ResultsPage;
