// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.comment-tags {
    margin-bottom: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 10px;
    width: 100%;
}

.comment-tags input:disabled {
    color: rgb(108, 108, 108);
}

.comment-tags .input-group .btn {
    z-index: 0 !important;
}

.results-tags {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 15px;
    width: 100%;
}

.results-tag-btn-feasibility.select-btn-active {
    background-color: pink;
}`, "",{"version":3,"sources":["webpack://./src/pages/design/result/sections/general/components/CommentAndTags/CommentAndTags.css"],"names":[],"mappings":"AAAA;IACI,mBAAmB;IACnB,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,4BAA4B;IAC5B,6BAA6B;QACzB,0BAA0B;YACtB,sBAAsB;IAC9B,SAAS;IACT,WAAW;AACf;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,qBAAqB;AACzB;;AAEA;IACI,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,SAAS;IACT,WAAW;AACf;;AAEA;IACI,sBAAsB;AAC1B","sourcesContent":[".comment-tags {\n    margin-bottom: 20px;\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n        -ms-flex-direction: column;\n            flex-direction: column;\n    gap: 10px;\n    width: 100%;\n}\n\n.comment-tags input:disabled {\n    color: rgb(108, 108, 108);\n}\n\n.comment-tags .input-group .btn {\n    z-index: 0 !important;\n}\n\n.results-tags {\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    gap: 15px;\n    width: 100%;\n}\n\n.results-tag-btn-feasibility.select-btn-active {\n    background-color: pink;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
