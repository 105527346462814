import { useLingui } from '@lingui/react';
import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { fetchUserIdByResetPwdToken, resetUserPwd } from '../../api/auth.api';
import PwdInput from '../../components/PwdInput/PwdInput';
import Toast from '../../components/Toast/Toast';
import logo from '../../image/logo.svg';

const ResetPwdPage = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [router]
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  //#endregion

  //#region [states]
  const [userId, setUserId] = useState();
  const [error, setError] = useState();
  //#endregion

  //#region [refs]
  const newPwdInput = useRef();
  const confirmNewPwdInput = useRef();
  const sendNewPwdBtn = useRef();
  //#endregion

  //#region [effects]
  useEffect(() => {
    if (!searchParams || !searchParams.has('token')) return;
    (async () => {
      try {
        const token = searchParams.get('token');
        const { userId } = await fetchUserIdByResetPwdToken(token);
        setUserId(userId);
      } catch (err) {
        console.error(err);
        setError(err);
        navigate('/login');
      }
    })();
  }, [searchParams]);
  //#endregion

  //#region [methods]
  const handleNewPwdFormSubmit = async (evt) => {
    evt.preventDefault();
    try {
      const newPwd = newPwdInput.current.value;
      const confirmNewPwd = confirmNewPwdInput.current.value;
      const token = searchParams.get('token');
      const isEmpty = (str) => !str || !str.trim();

      let err;
      if (isEmpty(newPwd) || isEmpty(confirmNewPwd)) {
        err = i18n._('login.newPwd.emptyPwd');
      } else if (newPwd !== confirmNewPwd) {
        err = i18n._('login.newPwd.pwdsNotEquals');
      } else {
        const regex = new RegExp(
          '^(?=.{8,})((?=.*\\d)(?=.*[a-z])(?=.*[A-Z])|(?=.*\\d)(?=.*[a-zA-Z])(?=.*[\\W_])|(?=.*[a-z])(?=.*[A-Z])(?=.*[\\W_])).{8,}'
        );
        if (!regex.test(newPwd)) {
          err = i18n._('login.newPwd.invalidPwd');
        }
      }

      if (err) {
        sendNewPwdBtn.current.blur();
        setError(err);
      } else {
        await resetUserPwd(userId, newPwd, token);
        navigate('/login');
      }
    } catch (err) {
      console.error(err);
      setError(err);
    }
  };
  //#endregion

  //#region [render]
  return userId ? (
    <div className='login-body'>
      <div className='login-left-body'>
        <img src={logo} alt='logo' className='login-logo' />
        {i18n._('login.optimizedDesign')}
      </div>
      <div className='login-right-body'>
        <Form className='login-form' onSubmit={handleNewPwdFormSubmit}>
          <h1>{i18n._('login.newPwd')}</h1>
          <PwdInput
            label={i18n._('login.pwd.label')}
            placeholder={i18n._('login.pwd.placeholder')}
            ref={newPwdInput}
          />
          <PwdInput
            label={i18n._('login.confirmPwd.label')}
            placeholder={i18n._('login.confirmPwd.placeholder')}
            ref={confirmNewPwdInput}
          />
          <Button type='submit' ref={sendNewPwdBtn}>
            {i18n._('login.send')}
          </Button>
        </Form>
      </div>
      <Toast
        level='danger'
        isOpen={!!error}
        body={error}
        header={i18n._('login.confirmPwd.error')}
        onClose={() => setError(null)}
      />
    </div>
  ) : null;
  //#endregion
};

export default ResetPwdPage;
