// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.compare-results-charts {
    background-color: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 20px;
    padding: 10px 15px;
    border: 1px solid rgb(216, 216, 216);
    border-radius: 5px;
}

.compare-results-charts .card-content {
    position: relative;
    padding-right: 20px;
}

.compare-results-charts>button {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 5px;
    font-size: 15px;
    -ms-flex-item-align: end;
        align-self: flex-end;
}`, "",{"version":3,"sources":["webpack://./src/pages/design/result/sections/summary/components/CompareResultsCharts/CompareResultsCharts.css"],"names":[],"mappings":"AAAA;IACI,uBAAuB;IACvB,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,4BAA4B;IAC5B,6BAA6B;QACzB,0BAA0B;YACtB,sBAAsB;IAC9B,SAAS;IACT,kBAAkB;IAClB,oCAAoC;IACpC,kBAAkB;AACtB;;AAEA;IACI,kBAAkB;IAClB,mBAAmB;AACvB;;AAEA;IACI,0BAA0B;IAC1B,uBAAuB;IACvB,kBAAkB;IAClB,oBAAoB;IACpB,oBAAoB;IACpB,aAAa;IACb,yBAAyB;QACrB,sBAAsB;YAClB,mBAAmB;IAC3B,QAAQ;IACR,eAAe;IACf,wBAAwB;QACpB,oBAAoB;AAC5B","sourcesContent":[".compare-results-charts {\n    background-color: white;\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-orient: vertical;\n    -webkit-box-direction: normal;\n        -ms-flex-direction: column;\n            flex-direction: column;\n    gap: 20px;\n    padding: 10px 15px;\n    border: 1px solid rgb(216, 216, 216);\n    border-radius: 5px;\n}\n\n.compare-results-charts .card-content {\n    position: relative;\n    padding-right: 20px;\n}\n\n.compare-results-charts>button {\n    width: -webkit-fit-content;\n    width: -moz-fit-content;\n    width: fit-content;\n    display: -webkit-box;\n    display: -ms-flexbox;\n    display: flex;\n    -webkit-box-align: center;\n        -ms-flex-align: center;\n            align-items: center;\n    gap: 5px;\n    font-size: 15px;\n    -ms-flex-item-align: end;\n        align-self: flex-end;\n}"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
