const process = require('process');

function isEnv (env) {
  return process.env.NODE_ENV === env;
}

module.exports = {
  isEnv,
  isProd: () => isEnv('production')
};
