import EconomicAnalysisSection from './economicAnalysis/EconomicAnalysisSection';
import EnergyCarbonSection from './energyCarbon/EnergyCarbonSection';
import GeneralSection from './general/GeneralSection';
import GeomodelingSection from './geomodeling/GeomodelingSection';
import SimulationParamsSection from './simulationParams/SimulationParamsSection';
import SizingSection from './sizing/SizingSection';
import SummarySection from './summary/SummarySection';

// if you want to add/remove a section, you have to do it too here (WARNING: order is important)
const SECTIONS = [
  {
    id: 'summary',
    component: SummarySection
  },
  {
    id: 'general',
    component: GeneralSection
  },
  {
    id: 'sizing',
    component: SizingSection
  },
  {
    id: 'energyAndCarbon',
    component: EnergyCarbonSection
  },
  {
    id: 'economicAnalysis',
    component: EconomicAnalysisSection
  },
  {
    id: 'geomodeling',
    component: GeomodelingSection
  },
  {
    id: 'simulationParams',
    component: SimulationParamsSection
  }
];

export default SECTIONS;
