import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import { OTHER_OPEX_REF } from '../../../../../../server/models/design/cost.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import CostPageContext from '../../../../contexts/CostPageContext';
import { getDefaultValue } from '../../../../utils/project.utils';

const ReferenceOtherOpex = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { tempProject, addError, removeError, handleOtherValueChange } =
    useContext(CostPageContext);
  //#endregion

  //#region [render]
  const {
    InitRefOpexGaPerf,
    InitRefOpexGaPerfEvol,
    InitRefOpexMaint,
    InitRefOpexMaintEvol,
    InitRefOpexOther,
    InitRefOpexOtherEvol
  } = tempProject.otherValues;
  return (
    <Bloc
      title={i18n._('cost.referenceOtherOpex')}
      style={{ borderBottom: 'none' }}
    >
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitRefOpexGaPerf')}
            value={InitRefOpexGaPerf}
            param={OTHER_OPEX_REF.InitRefOpexGaPerf}
            onBlur={(value) =>
              handleOtherValueChange('InitRefOpexGaPerf', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, OTHER_OPEX_REF.InitRefOpexGaPerf)}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitRefOpexGaPerfEvol')}
            value={InitRefOpexGaPerfEvol}
            param={OTHER_OPEX_REF.InitRefOpexGaPerfEvol}
            onBlur={(value) =>
              handleOtherValueChange('InitRefOpexGaPerfEvol', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_OPEX_REF.InitRefOpexGaPerfEvol
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitRefOpexMaint')}
            value={InitRefOpexMaint}
            param={OTHER_OPEX_REF.InitRefOpexMaint}
            onBlur={(value) =>
              handleOtherValueChange('InitRefOpexMaint', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, OTHER_OPEX_REF.InitRefOpexMaint)}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitRefOpexMaintEvol')}
            value={InitRefOpexMaintEvol}
            param={OTHER_OPEX_REF.InitRefOpexMaintEvol}
            onBlur={(value) =>
              handleOtherValueChange('InitRefOpexMaintEvol', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_OPEX_REF.InitRefOpexMaintEvol
            )}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('cost.InitRefOpexOther')}
            value={InitRefOpexOther}
            param={OTHER_OPEX_REF.InitRefOpexOther}
            onBlur={(value) =>
              handleOtherValueChange('InitRefOpexOther', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(i18n, OTHER_OPEX_REF.InitRefOpexOther)}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('cost.InitRefOpexOtherEvol')}
            value={InitRefOpexOtherEvol}
            param={OTHER_OPEX_REF.InitRefOpexOtherEvol}
            onBlur={(value) =>
              handleOtherValueChange('InitRefOpexOtherEvol', value)
            }
            addError={addError}
            removeError={removeError}
            unit
            bottomText={getDefaultValue(
              i18n,
              OTHER_OPEX_REF.InitRefOpexOtherEvol
            )}
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default ReferenceOtherOpex;
