import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useLingui } from '@lingui/react';
import React, { useContext } from 'react';
import { Col, Row } from 'react-bootstrap';
import {
  PV_EQUIPMENT,
  ST_EQUIPMENT
} from '../../../../../../server/constants/performance.constant';
import { UNIT } from '../../../../../../server/constants/unit.constant';
import {
  getPVParams,
  getSTParams
} from '../../../../../../server/models/design/performance.model';
import Bloc from '../../../../components/Bloc/Bloc';
import FormInput from '../../../../components/Form/FormInput';
import FormSelect from '../../../../components/Form/FormSelect';
import PerformancePageContext from '../../../../contexts/PerformancePageContext';
import PopupContext from '../../../../contexts/PopupContext';
import azimuthImg from '../../../../image/azimuth.png';
import { roundNumber } from '../../../../utils/data.utils';
import { getDefaultValue } from '../../../../utils/project.utils';

const PhotovoltaicBloc = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const {
    tempProject,
    addError,
    removeError,
    setTempProject,
    handleOtherValueChange
  } = useContext(PerformancePageContext);
  const { openInfoModal } = useContext(PopupContext);
  //#endregion

  //#region [methods]
  const getModel = (models, value) => {
    return models.find((model) => model.value === value);
  };

  const handlePVModelChange = (modelValue) => {
    const { InitSolarThermalModel } = tempProject.otherValues;
    const pvModel = getModel(Object.values(PV_EQUIPMENT), modelValue);
    if (!pvModel.data) return;
    if (pvModel.linkedSt !== InitSolarThermalModel) {
      handleOtherValueChange('InitSolarThermalModel', pvModel.linkedSt);
    }
    Object.entries(pvModel.data).forEach(([key, value]) => {
      tempProject.otherValues[key] = value;
    });
    tempProject.otherValues.InitPhotovoltaicModel = modelValue;
    setTempProject(() => ({ ...tempProject }));
  };
  //#endregion

  //#region [render]
  const {
    InitSolarThermalModel,
    InitSolarSurfaceTilt,
    InitSolarSurfaceAzimuth,
    InitPhotovoltaicModel,
    InitPVEfficiency,
    InitPVTemperatureCoefGammaModule,
    InitPVDCLossesAgeModule,
    InitPVInverterMaxPowerPerWc,
    InitPVInverterNominalEfficiency,
    InitPVMountingType
  } = tempProject.otherValues;

  const pvModel = getModel(Object.values(PV_EQUIPMENT), InitPhotovoltaicModel);
  const stModel = getModel(Object.values(ST_EQUIPMENT), InitSolarThermalModel);
  const { isCombined } = pvModel;
  const PV = getPVParams(pvModel);
  const ST = getSTParams(stModel);
  const disabledOptions = Object.values(PV_EQUIPMENT)
    .filter((pv) => pv.disabled)
    .map((pv) => pv.value);

  return (
    <Bloc title={i18n._('performance.photovoltaic')}>
      <div className='performance-row'>
        <FormSelect
          label={i18n._('performance.InitPhotovoltaicModel')}
          value={InitPhotovoltaicModel}
          param={PV.InitPhotovoltaicModel}
          onChange={handlePVModelChange}
          disabledOptions={disabledOptions}
        />
        {isCombined && (
          <span className='performance-combined'>
            <FontAwesomeIcon icon='exclamation-circle' />
            {i18n._('performance.combined.pv')}
          </span>
        )}
      </div>
      <Row>
        <Col>
          <FormInput
            label={i18n._('performance.InitPVEfficiency')}
            value={roundNumber(InitPVEfficiency * 100, 4)}
            param={PV.InitPVEfficiency}
            onBlur={(value) =>
              handleOtherValueChange('InitPVEfficiency', value / 100)
            }
            addError={addError}
            removeError={removeError}
            topText={i18n._('performance.topInitPVEfficiency')}
            bottomText={i18n._('param.defaultValues', {
              value: roundNumber(PV.InitPVEfficiency.default * 100, 4),
              unit: i18n._(`unit.${UNIT.PERCENT}`)
            })}
            unit
            disabled={InitPhotovoltaicModel !== PV_EQUIPMENT.CUSTOM.value}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('performance.InitPVTemperatureCoefGammaModule')}
            value={roundNumber(InitPVTemperatureCoefGammaModule * 100, 4)}
            param={PV.InitPVTemperatureCoefGammaModule}
            onBlur={(value) =>
              handleOtherValueChange(
                'InitPVTemperatureCoefGammaModule',
                value / 100
              )
            }
            addError={addError}
            removeError={removeError}
            bottomText={i18n._('param.defaultValues', {
              value: roundNumber(
                PV.InitPVTemperatureCoefGammaModule.default * 100,
                4
              ),
              unit: i18n._(`unit.${UNIT.PERCENT_PER_KELVIN_DEGREES}`)
            })}
            unit
            disabled={InitPhotovoltaicModel !== PV_EQUIPMENT.CUSTOM.value}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <FormInput
            label={i18n._('performance.InitPVDCLossesAgeModule')}
            value={roundNumber(InitPVDCLossesAgeModule * 100, 4)}
            param={PV.InitPVDCLossesAgeModule}
            onBlur={(value) =>
              handleOtherValueChange('InitPVDCLossesAgeModule', value / 100)
            }
            addError={addError}
            removeError={removeError}
            topText={i18n._('performance.topInitPVDCLossesAgeModule')}
            bottomText={i18n._('param.defaultValues', {
              value: roundNumber(PV.InitPVDCLossesAgeModule.default * 100, 4),
              unit: i18n._(`unit.${UNIT.PERCENT_PER_YEAR}`)
            })}
            unit
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <FormInput
            label={i18n._('performance.InitPVInverterMaxPowerPerWc')}
            value={InitPVInverterMaxPowerPerWc}
            param={PV.InitPVInverterMaxPowerPerWc}
            onBlur={(value) =>
              handleOtherValueChange('InitPVInverterMaxPowerPerWc', value)
            }
            addError={addError}
            removeError={removeError}
            bottomText={getDefaultValue(i18n, PV.InitPVInverterMaxPowerPerWc)}
          />
        </Col>
        <Col>
          <FormInput
            label={i18n._('performance.InitPVInverterNominalEfficiency')}
            value={roundNumber(InitPVInverterNominalEfficiency * 100, 4)}
            param={PV.InitPVInverterNominalEfficiency}
            onBlur={(value) =>
              handleOtherValueChange(
                'InitPVInverterNominalEfficiency',
                value / 100
              )
            }
            addError={addError}
            removeError={removeError}
            bottomText={i18n._('param.defaultValues', {
              value: roundNumber(
                PV.InitPVInverterNominalEfficiency.default * 100,
                4
              ),
              unit: i18n._(`unit.${UNIT.PERCENT}`)
            })}
            unit
          />
        </Col>
      </Row>
      <FormSelect
        label={i18n._('performance.InitPVMountingType')}
        value={InitPVMountingType}
        param={PV.InitPVMountingType}
        onChange={(value) =>
          handleOtherValueChange('InitPVMountingType', value)
        }
      />
      <Row>
        <Col>
          <FormInput
            label={i18n._('performance.InitSolarSurfaceTilt')}
            value={InitSolarSurfaceTilt}
            param={ST.InitSolarSurfaceTilt}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarSurfaceTilt', value)
            }
            addError={addError}
            removeError={removeError}
            topText={i18n._('performance.topPVInitSolarSurfaceTilt')}
            bottomText={getDefaultValue(i18n, ST.InitSolarSurfaceTilt)}
            unit
          />
        </Col>
        <Col className='performance-azimuth-col'>
          <FormInput
            label={i18n._('performance.InitSolarSurfaceAzimuth')}
            value={InitSolarSurfaceAzimuth}
            param={ST.InitSolarSurfaceAzimuth}
            onBlur={(value) =>
              handleOtherValueChange('InitSolarSurfaceAzimuth', value)
            }
            addError={addError}
            removeError={removeError}
            topText={i18n._('performance.topPVInitSolarSurfaceAzimuth')}
            bottomText={getDefaultValue(i18n, ST.InitSolarSurfaceAzimuth)}
            unit
            showInfoIcon
            onInfoClick={() =>
              openInfoModal(
                i18n._('performance.azimuthModalTitle'),
                <img
                  src={azimuthImg}
                  alt='azimuthImg'
                  style={{ width: '90%', height: '90%' }}
                />
              )
            }
          />
        </Col>
      </Row>
    </Bloc>
  );
  //#endregion
};

export default PhotovoltaicBloc;
