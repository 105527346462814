import axios from '../conf/axios.conf';

//#region [GET]
export const fetchResult = async (computeId, loadDetails) => {
  try {
    const response = await axios.get(`/compute/${computeId}`, {
      params: { loadDetails }
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchComputeDetails = async (computeId) => {
  try {
    const response = await axios.get(`/compute/${computeId}/details`);
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchResultsByList = async (ids) => {
  try {
    const response = await axios.get(`/compute/list`, { params: { ids } });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const fetchCalculation = async (computeId) => {
  try {
    const response = await axios.get(`/compute/${computeId}/calculation`);
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [PUT]
export const updateComputeComment = async (computeId, comment) => {
  try {
    const response = await axios.put(`/compute/${computeId}/comment`, {
      comment
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const addComputeTag = async (computeId, tag) => {
  try {
    const response = await axios.put(`/compute/${computeId}/addTag`, { tag });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const removeComputeTag = async (computeId, tag) => {
  try {
    const response = await axios.put(`/compute/${computeId}/removeTag`, {
      tag
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};

export const replaceComputeTag = async (
  computeId,
  computeIdForReplacingTag,
  tag
) => {
  try {
    const response = await axios.put(`/compute/${computeId}/replaceTag`, {
      computeIdForReplacingTag,
      tag
    });
    return response.data;
  } catch (err) {
    throw err;
  }
};
//#endregion

//#region [DELETE]
export const deleteResults = async (ids) => {
  try {
    await axios.delete(`/compute`, {
      data: { ids }
    });
  } catch (err) {
    throw err;
  }
};
//#endregion
