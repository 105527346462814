const UNIT = Object.freeze({
  CELSIUS_DEGREE: 'celsiusDegree',
  CUBIC_METER: 'cubicMeter',
  CUBIC_METER_PER_HOUR: 'cubicMeterPerHour',
  DEGREES: 'degrees',
  DN_OD: 'dnod',
  EURO: 'euro',
  EURO_PER_CUBIC_METER: 'euroPerCubicMeter',
  EURO_PER_CUBIC_METER_PER_HOUR: 'euroPerCubicMeterPerHour',
  EURO_PER_KILOVOLT_AMPERE: 'euroPerKilovoltAmpere',
  EURO_PER_KILOWATT: 'euroPerKilowatt',
  EURO_PER_KILOWATT_HOUR: 'euroPerKilowattHour',
  EURO_PER_KILOWATT_THERMAL: 'euroPerKilowattThermal',
  EURO_PER_KILOWATT_HOUR_ELECTRIC: 'euroPerKilowattHourElectric',
  EURO_PER_KILOWATT_HOUR_THERMAL: 'euroPerKilowattHourThermal',
  EURO_PER_METER: 'euroPerMeter',
  EURO_PER_SQUARE_METER: 'euroPerSquareMeter',
  EURO_PER_YEAR: 'euroPerYear',
  GRAM_PER_SQUARE_METER: 'gramPerSquareMeter',
  HOUR: 'hour',
  JOULE_PER_CUBIC_METER_PER_KELVIN: 'joulePerCubicMeterPerKelvin',
  JOULE_PER_KELVIN_PER_CUBIC_METER: 'joulePerKelvinPerCubicMeter',
  KELVIN_METER_PER_WATT: 'kelvinMeterPerWatt',
  KILOGRAM: 'kilogram',
  KILOGRAM_CO2EQ: 'kilogramCO2Eq',
  KILOGRAM_CO2EQ_PER_YEAR: 'kilogramCO2EqPerYear',
  KILOGRAM_PER_SECOND_PER_SQUARE_METER: 'kilogramPerSecondPerSquareMeter',
  KILOVOLT_AMPERE: 'kilovoltampere',
  KILOWATT: 'kilowatt',
  KILOWATT_HOUR: 'kilowattHour',
  KILOWATT_HOUR_ELECTRIC: 'kilowattHourElectric',
  KILOWATT_HOUR_PER_METER: 'kilowattHourPerMeter',
  KILOWATT_HOUR_PER_SQUARE_METER: 'kilowattHourPerSquareMeter',
  KILOWATT_HOUR_PER_YEAR: 'kilowattHourPerYear',
  KILOWATT_HOUR_THERMAL: 'kilowattHourThermal',
  KILOWATT_PEAK: 'kilowattPeak',
  KILOWATT_PER_SQUARE_METER: 'kilowattPerSquareMeter',
  MEGAWATT_HOUR: 'megawattHour',
  METER: 'meter',
  METER_PER_SECOND: 'meterPerSecond',
  MINUTES: 'minutes',
  PERCENT: 'percent',
  PERCENT_PER_KELVIN_DEGREES: 'percentPerKelvinDegrees',
  PERCENT_PER_YEAR: 'percentPerYear',
  PRIMARY_ENERGY_KILOWATT_HOUR: 'primaryEnergyKilowattHour',
  SECOND_PER_METER: 'secondPerMeter',
  SQUARE_METER: 'squareMeter',
  WATER_COLUMN_METER: 'waterColumnMeter',
  WATT: 'watt',
  WATT_HOUR_PER_SQUARE_METER: 'wattHourPerSquareMeter',
  WATT_PER_METER: 'wattPerMeter',
  WATT_PER_METER_KELVIN: 'wattPerMeterKelvin',
  WATT_PER_METER_PER_KELVIN: 'wattPerMeterPerKelvin',
  WATT_PER_SQUARE_METER_KELVIN: 'wattPerSquareMeterKelvin',
  WATT_SECOND_PER_CUBIC_METER_KELVIN: 'wattSecondPerCubicMeterKelvin',
  YEAR: 'year'
});

module.exports = { UNIT };
