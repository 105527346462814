import { useLingui } from '@lingui/react';
import React, { useContext, useMemo } from 'react';
import CompareResultsCards from '../../../../../components/CompareResultsCards/CompareResultsCards.jsx';
import CompareResultsTable from '../../../../../components/CompareResultsTable/CompareResultsTable.jsx';
import ExpandableTable from '../../../../../components/ExpandableTable/ExpandableTable';
import Section from '../../../../../components/Section/Section';
import SectionTitle from '../../../../../components/SectionTitle';
import ResultContext from '../../../../../contexts/ResultContext';
import ConstraintsTable from './components/ConstraintsTable';
import DetailedResults from './components/DetailedResults/DetailedResults';
import EnergyEvolsTable from './components/EnergyEvolsTable';
import OtherPerSubTable from './components/OtherPerSubTable/OtherPerSubTable';
import InputsParamsTable from './components/RecursiveTable/InputsParamsTable';
import {
  detailedParamsTemplate,
  inputDataTemplates,
  simuTemplate
} from './templates';

const SimulationParamsSection = () => {
  //#region [lingui]
  const { i18n } = useLingui();
  //#endregion

  //#region [contexts]
  const { comparedResults, loadDetails } = useContext(ResultContext);
  //#endregion

  //#region [memos]
  const IDTemplates = useMemo(() => {
    return inputDataTemplates(i18n, comparedResults);
  }, [comparedResults]);

  const STemplate = useMemo(() => {
    return simuTemplate(i18n, comparedResults);
  }, [comparedResults]);

  const DPTemplates = useMemo(() => {
    return detailedParamsTemplate(i18n, comparedResults);
  }, [comparedResults]);
  //#endregion

  //#region [render]
  return (
    <Section title={i18n._('results.simulationParams')} open>
      <SectionTitle
        level={2}
        title={i18n._('results.simulationParams.inputData')}
      />
      {IDTemplates.map((template, index) => (
        <CompareResultsTable
          level={3}
          template={template}
          key={'simulationParams_inputData_template_' + index}
        />
      ))}
      <ExpandableTable level={3}>
        <CompareResultsCards
          title={i18n._(
            'results.simulationParams.inputData.energyPricing.energyEvols'
          )}
          element={EnergyEvolsTable}
        />
      </ExpandableTable>
      <ExpandableTable
        level={2}
        title={i18n._('results.simulationParams.constraints')}
      >
        <CompareResultsCards
          title={i18n._('results.simulationParams.constraints.table.title')}
          element={ConstraintsTable}
        />
      </ExpandableTable>
      <CompareResultsTable level={2} template={STemplate} />
      <ExpandableTable
        level={2}
        title={i18n._('results.simulationParams.detailed')}
      >
        <CompareResultsCards
          title={i18n._('results.simulationParams.detailed.title')}
          element={DetailedResults}
          onOpen={loadDetails}
        />
      </ExpandableTable>
      <CompareResultsTable level={2} template={DPTemplates} />
      <ExpandableTable
        level={2}
        title={i18n._('results.simulationParams.otherParams')}
      >
        <CompareResultsCards
          title={i18n._(
            'results.simulationParams.otherParams.perSubParams.table.title'
          )}
          element={OtherPerSubTable}
        />
        <CompareResultsCards
          title={i18n._('results.simulationParams.otherParams.inputsParams')}
          element={InputsParamsTable}
        />
      </ExpandableTable>
    </Section>
  );
  //#endregion
};

export default SimulationParamsSection;
